import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';

import { TimelineCardFooterComponent } from './timeline-card-footer.component';
import { ReactionModule } from '../reaction/reaction.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuoteModule } from '../quote/quote.module';
import { TimelineCardEditorModule } from '../../organisms/timeline-card-editor/timeline-card-editor.module';

const components: Type<any>[] = [TimelineCardFooterComponent];

@NgModule({
  imports: [CommonModule, ReactionModule, FontAwesomeModule, QuoteModule, TimelineCardEditorModule],
  declarations: [components],
  exports: [components],
})
export class TimelineCardFooterModule { }
