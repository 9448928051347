import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeedbackDocumentDto } from 'domain/kernel/feedback-document/dto';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFeedbackDocumentApi } from 'serverside/batch/api/interface/feedback-document.api.interface';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDocumentApiClient implements IFeedbackDocumentApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }

  createFeedback(feedbackDocumentDto: FeedbackDocumentDto): Promise<FeedbackDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/feedback-document/create-feedback', feedbackDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

  updateFeedback(feedbackDocumentDto: FeedbackDocumentDto): Promise<FeedbackDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/feedback-document/update-feedback', feedbackDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

}
