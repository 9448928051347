<div class="text" [class.isPassword]="isPassword">
  <p class="text__label" [class.-required]="required" *ngIf="label !== null">{{ label }}</p>
  <p *ngIf="hint !== null">{{ hint }}</p>
  <div class="content">
    <input
      #text
      appIMEEvent
      [type]="type"
      [value]="value"
      [placeholder]="placeholder"
      [maxLength]="maxLength"
      [class.-error]="control.touched && control.invalid"
      (input)="onChange(text.value)"
      (blur)="onTouched(text.value)"
      (onEnterKey)="onKeyupEnter()"
      autocomplete="off"
      [readOnly]="readOnly"
    />
    <ng-container *ngIf="isPassword">
      <fa-icon [icon]="icon" (click)="onChangeMode()"></fa-icon>
    </ng-container>
  </div>
</div>
