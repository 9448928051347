import { NgModule, Type } from '@angular/core';

import { AvatarModule } from './../../atoms/avatar/avatar.module';
import { CommentAttachmentViewModule } from '../comment-attachment-view/comment-attachment-view.module';
import { CommentComponent } from './comment.component';
import { CommentFormModule } from '../comment-form/comment-form.module';
import { CommonModule } from '@angular/common';
import { DeletedUserModule } from '../../atoms/deleted-user/deleted-user.module';
import { FormsModule } from '@angular/forms';
import { KnowledgeAttachmentViewModule } from '../knowledge-attachment-view/knowledge-attachment-view.module';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { TimelineCardAttachmentModule } from '../timeline-card-attachment/timeline-card-attachment.module';

const components: Type<any>[] = [CommentComponent];

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    DeletedUserModule,
    TimelineCardAttachmentModule,
    CommentFormModule,
    FormsModule,
    PipesModule,
    KnowledgeAttachmentViewModule,
    CommentAttachmentViewModule
  ],
  declarations: [components],
  exports: [components],
})
export class CommentModule { }
