import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { AuthService } from 'frontend/lib/service/auth.service';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { LocalStorageConstant } from './shared/constants/local-storage.constant';
import { LocalStorageService } from './shared/services/local-storage.service';
import { AppQuery } from './state/app.query';
import { AppService } from './state/app.service';
import { AppState } from './state/app.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent {
  public store$: Observable<AppState>;

  constructor(
    private readonly appQuery: AppQuery,
    private readonly authService: AuthService,
    private readonly appService: AppService,
    private readonly localStorageService: LocalStorageService,
  ) {
    super();
    this.store$ = this.appQuery.select();
  }

  protected override onDestroy(): void {
    this.appService.resetStore();
  }

  protected override onInit(): void {
    this.preloadLogo();

    this.listenToUserEvents();

    this.subscribe(this.authService
      .selectAuthState()
      .pipe(
        take(1),
        filter(account => account !== null && account.email !== ''),
        tap(account => this.appService.updateCurrentAccount(account)),
      ), _ => { })
  }

  private listenToUserEvents(): void {
    window.addEventListener('storage', event => {
      if (event.key == LocalStorageConstant.LOGOUT_EVENT) {
        if (this.localStorageService.getItem(LocalStorageConstant.LOGOUT_EVENT)) {
          this.appService.logout(false);
          this.localStorageService.removeItem(LocalStorageConstant.LOGOUT_EVENT);
        }
      }
    });
  }

  private preloadLogo(): void {
    new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = resolve;
      image.onerror = reject;
      image.src = this.AssetConstant.LOGO_URL
    });
  }
}
