import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { BadgeModule } from '../../atoms/badge/badge.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CardViewHeaderAttachmentCountModule } from '../../molecules/card-view-header-attachment-count/card-view-header-attachment-count.module';
import { CardViewHeaderAttachmentModule } from '../../molecules/card-view-header-attachment/card-view-header-attachment.module';
import { CardViewHeaderLinkModule } from '../../molecules/card-view-header-link/card-view-header-link.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuoteKnowledgeModalComponent } from './quote-knowledge-modal.component';

const components: Type<any>[] = [QuoteKnowledgeModalComponent];

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    BadgeModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    CardViewHeaderAttachmentModule,
    CardViewHeaderLinkModule,
    CardViewHeaderAttachmentCountModule
  ],
  declarations: [components],
  exports: [components],
})
export class QuoteKnowledgeModalModule { }
