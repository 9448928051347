<div class="search-box">
  <input
    appIMEEvent
    [type]="type"
    [placeholder]="placeholder"
    [(ngModel)]="searchInput"
    (onEnterKey)="onSearch()"
    (ngModelChange)="onChange()"
    [preventEnterKeyCreateNewLine]="true"
  />
  <fa-icon [icon]="appData.faSearch" class="search-box__icon"></fa-icon>
  <fa-icon *ngIf="searchInput.length > 0" (click)="clearSearchInput()" [icon]="appData.faRemove" class="search-box__icon--clear"></fa-icon>
</div>
