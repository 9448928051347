import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
// tslint:disable:no-any
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { faSearch, faSpinnerThird } from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-suggestible-text',
  templateUrl: './suggestible-text.component.html',
  styleUrls: ['./suggestible-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestibleTextComponent extends BaseComponent implements ControlValueAccessor {
  @Input() data: object[];
  @Input() itemTemplate: TemplateRef<any>;
  @Input() emptyTemplate: TemplateRef<any>;
  @Input() label = null;
  @Input() placeholder = 'メールで検索';
  @Input() loading = false;
  @Input() isSearch: false;

  @Output() select: EventEmitter<object> = new EventEmitter();
  @ViewChild('text') element: ElementRef;

  faSearch = faSearch;
  faSpinnerThird = faSpinnerThird;
  required = false;

  onChange: (value: any) => void;
  onTouched: (value: any) => void;

  constructor(formBuilder: FormBuilder, @Self() public control: NgControl) {
    super();
    this.control.valueAccessor = this;
  }

  protected override onInit(): void {
    const validator = this.control.control.validator;
    this.required = validator ? validator({} as AbstractControl)?.required || false : false;
  }

  // ControlValueAccessor
  writeValue(value: any) {
    if (this.element) {
      this.element.nativeElement.value = value;
    }
  }

  onSelect(value: any) {
    value['inProcess'] = true;
    this.select.emit(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
