import { Observable, concat, of } from 'rxjs';
import { OrganizationAlreadyExistsError, OrganizationNotFoundError } from '../exception';
import {
  OrganizationDisplayName,
  OrganizationEmail,
  OrganizationId,
  OrganizationImg,
  OrganizationName,
  OrganizationShortImg
} from '../value';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountId } from '../../account/value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IOrganizationRepository } from '../repository';
import { Organization } from '../entity';
import { OrganizationDto } from '../dto';

export class OrganizationDomainService {
  constructor(private readonly organizationRepository: IOrganizationRepository) { }

  select(organizationId: string) {
    return this.organizationRepository.select(OrganizationId.create(organizationId)).pipe(map(item => this.convertDto(item)));
  }

  selectOrganization(organizationId: string): Observable<OrganizationDto> {
    return this.organizationRepository.select(OrganizationId.create(organizationId)).pipe(
      take(1),
      map(item => this.convertDto(item)),
      catchError(_ => {
        throw new OrganizationNotFoundError(`organization id [${organizationId}] is not found`)
      })
    );
  }

  selectByOrganizationId(organizationId: string): Observable<OrganizationDto | null> {
    return concat(
      this.organizationRepository.select(OrganizationId.create(organizationId)),
      of(null as Organization)
    ).pipe(
      take(1),
      // tap(item => {
      //   if (item === null) {
      //     throw new OrganizationNotFoundError(`organization [${organizationId}] is not found`);
      //   }
      // }),
      map(item => item !== null ? this.convertDto(item) : null)
    );
  }

  selectIfExistsByName(name: string): Observable<OrganizationDto | null> {
    return concat(
      this.organizationRepository.selectAll(new FirestoreQueryBuilder<Organization>().equalWhere('name', name)),
      of(null as Organization)
    ).pipe(
      take(1),
      map(item => item !== null ? this.convertDto(item) : null)
    );
  }

  insertOrganizationNoDistinct(accountId: string, email: string, displayName: string, name: string, img: string, shortImg: string) {
    const organization = new Organization(this.organizationRepository.generateId());
    organization.createdBy = AccountId.create(accountId);
    organization.email = OrganizationEmail.create(email?.toLowerCase());
    organization.displayName = OrganizationDisplayName.create(displayName);
    organization.name = OrganizationName.create(name);
    organization.img = OrganizationImg.create(img);
    organization.shortImg = OrganizationShortImg.create(shortImg);
    return this.organizationRepository.insert(organization).pipe(map(item => this.convertDto(item)))
  }

  insertOrganization(accountId: string, email: string, displayName: string, name: string, img: string, shortImg: string) {
    return concat(
      this.organizationRepository.selectAll(new FirestoreQueryBuilder<Organization>().equalWhere('name', name)),
      of(null as Organization)
    ).pipe(
      take(1),
      tap(item => {
        if (item !== null) {
          throw new OrganizationAlreadyExistsError(`this organization「${name}」already exists`, item.name.value);
        }
      }),
      map(_ => {
        const organization = new Organization(this.organizationRepository.generateId());
        organization.createdBy = AccountId.create(accountId);
        organization.email = OrganizationEmail.create(email?.toLowerCase());
        organization.displayName = OrganizationDisplayName.create(displayName);
        organization.name = OrganizationName.create(name);
        organization.img = OrganizationImg.create(img);
        organization.shortImg = OrganizationShortImg.create(shortImg);
        return organization;
      }),
      mergeMap(organization => this.organizationRepository.insert(organization).pipe(map(item => this.convertDto(item))))
    );
  }

  update(organizationDto: OrganizationDto) {
    return this.organizationRepository.select(OrganizationId.create(organizationDto.id)).pipe(
      take(1),
      map(currentOrganization => {
        currentOrganization.createdBy = AccountId.create(organizationDto.createdBy);
        currentOrganization.email = OrganizationEmail.create(organizationDto.email);
        currentOrganization.displayName = OrganizationDisplayName.create(organizationDto.displayName);
        currentOrganization.name = OrganizationName.create(organizationDto.name);
        currentOrganization.img = OrganizationImg.create(organizationDto.img);
        currentOrganization.shortImg = OrganizationShortImg.create(organizationDto.shortImg);
        return currentOrganization;
      }),
      mergeMap(organization => this.organizationRepository.update(organization).pipe(map(item => this.convertDto(item))))
    );
  }

  updateDisplayName(organizationDto: OrganizationDto) {
    const organization = new Organization(OrganizationId.create(organizationDto.id));
    organization.displayName = OrganizationDisplayName.create(organizationDto.displayName);
    return this.organizationRepository.update(organization).pipe(map(item => this.convertDto(item)));
  }

  updateAvatar(organizationDto: OrganizationDto) {
    const organization = new Organization(OrganizationId.create(organizationDto.id));
    organization.shortImg = OrganizationShortImg.create(organizationDto.shortImg);
    organization.img = OrganizationImg.create(organizationDto.img);
    return this.organizationRepository.update(organization).pipe(map(item => this.convertDto(item)));
  }

  private convertDto(organization: Organization): OrganizationDto {
    return Organization.allFields.reduce((p, key) => {
      if (organization[key] === undefined) {
        return p;
      }
      const value = organization[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as OrganizationDto);
  }
}
