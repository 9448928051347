import { EMPTY, Observable } from 'rxjs';
import { FeedbackBody, FeedbackId, FeedbackKnowledgeId, FeedbackTitle, FeedbackType } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { Feedback } from '../../entity';
import { FeedbackNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFeedbackRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class FeedbackFirestoreRepository implements IFeedbackRepository {
  private static readonly collectionId = 'feedback';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: FeedbackId): Observable<Feedback> {
    return this.firestoreService.getDocument(FeedbackFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new FeedbackNotFoundError(`feedback id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: Feedback): Observable<Feedback> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(FeedbackFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(feedback: Feedback): Observable<Feedback> {
    feedback.updatedAt = Timestamp.createByMillsec(+new Date());
    feedback.isDeleted = IsDeleted.create(false);
    return this.firestoreService.getDocument(FeedbackFirestoreRepository.collectionId, feedback.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new FeedbackNotFoundError(`feedback id「${feedback.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(FeedbackFirestoreRepository.collectionId, this.convertToMap(feedback));
      }),
      map(_ => feedback)
    );
  }

  delete(id: FeedbackId): Observable<void> {
    const proposal = new Feedback(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(FeedbackFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): FeedbackId {
    return FeedbackId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<Feedback>) {
    return this.firestoreService.getCollection(FeedbackFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(FeedbackFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  private convertToMap(feedback: Feedback): object {
    return Feedback.allFields.reduce((p, key) => {
      if (feedback[key] === undefined) {
        return p;
      }
      const value = feedback[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const feedback = new Feedback(FeedbackId.create(item.id));
    feedback.title = FeedbackTitle.create(item.title);
    feedback.body = FeedbackBody.create(item.body);
    feedback.type = FeedbackType.create(item.type);
    feedback.sendBy = AccountOrganizationId.create(item.sendBy);
    feedback.knowledgeId = item && item?.knowledgeId ? FeedbackKnowledgeId.create(item.knowledgeId) : undefined;
    feedback.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    feedback.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    feedback.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return feedback;
  }
}
