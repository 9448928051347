import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { FeedbackDeliveryId, FeedbackDeliveryReceiverArchived, FeedbackDeliverySenderArchived, FeedbackDeliveryUnread } from '../value';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from '../../../../utility/model/entity.model';
import { FeedbackId } from '../../feedback/value';
import { IsDeleted } from '../../base/value';
import { OrganizationId } from '../../organization/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class FeedbackDelivery implements Entity {
  @Column()
  readonly id: FeedbackDeliveryId;
  @Column()
  feedbackId: FeedbackId;
  @Column()
  sendBy: AccountOrganizationId;
  @Column()
  receivedBy: AccountOrganizationId;
  @Column()
  organizationId: OrganizationId;
  @Column()
  unread: FeedbackDeliveryUnread;
  @Column()
  receiverArchived: FeedbackDeliveryReceiverArchived;
  @Column()
  senderArchived: FeedbackDeliverySenderArchived;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: FeedbackDeliveryId) {
    this.id = id;
  }

  static get allFields(): (keyof FeedbackDelivery)[] {
    return getColumns(new FeedbackDelivery(FeedbackDeliveryId.create('')));
  }

  equals(value: FeedbackDelivery): boolean {
    return this.id.equals(value.id);
  }
}
