<div class="send-feedback-modal">
  <ng-container modal-content>
    <div class="send-feedback-modal__header">
      <div class="send-feedback-modal__header-group">
        <a (click)="backToQuote()">
          <fa-icon class="back-icon" [icon]="faChevronLeft"></fa-icon>
          <span>ナレッジの引用方法を選択</span>
        </a>
      </div>
    </div>
    <div class="send-feedback-modal__body">
      <div class="send-feedback-modal__body-title">
        <span>{{ knowledgeSummary.title }}</span>
        <div class="attachment">
          <ng-container *ngIf="links?.length > 0 && files.length > 0; else option">
            <app-card-view-header-attachment-count [count]="files.length + links.length"></app-card-view-header-attachment-count>
          </ng-container>

          <ng-template #option>
            <ng-container *ngIf="links?.length > 0; then linkTmpl; else fileTmpl"> </ng-container>
            <ng-template #linkTmpl>
              <app-card-view-header-link [links]="links"></app-card-view-header-link>
            </ng-template>
            <ng-template #fileTmpl>
              <app-card-view-header-attachment [files]="files"></app-card-view-header-attachment>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="send-feedback-modal__body-actions">
        <div class="action">
          <div class="action-group">
            <a class="action-icon-send">
              <fa-icon class="" [icon]="faEnvelope"></fa-icon>
            </a>
            <span>フィードバックとして送信する</span>
          </div>
        </div>
        <div class="note">送信先</div>
        <div class="send-feedback-modal-users-wrapper">
          <ng-container *ngIf="initLoad">
            <div class="send-feedback-modal-users-container" *ngIf="sendFeedbackModalQuery.selectAccounts() | async as accounts">
              <ng-container *ngIf="accounts?.length > 0; else noData">
                <ng-container *ngFor="let user of accounts">
                  <ng-container *ngIf="user && user.name">
                    <div class="send-feedback-modal-users-container__item" (click)="onSelectUser(user)">
                      <div class="send-feedback-modal-users-container__item-avatar">
                        <app-avatar [altText]="user.email" [source]="user.shortImg" [width]="66" [height]="66"></app-avatar>
                        <ng-container *ngIf="isSelectedUSer(user)">
                          <div class="user-selected">
                            <fa-icon [icon]="faCheck"></fa-icon>
                          </div>
                        </ng-container>
                      </div>
                      <div class="send-feedback-modal-users-container__item-name">
                        {{ user?.name && user.name?.first }} {{ user?.name && user.name?.last }}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #noData>
                <div class="my-knowledge-sharing-vendor-sidebar__notfound">
                  <app-notfound-record-text [top]="50"></app-notfound-record-text>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="send-feedback-modal__footer">
      <div class="button__wrapper">
        <app-button [disabled]="inProcess" (clickButton)="onSendFeedback()">ナレッジ送信</app-button>
      </div>
    </div>
  </ng-container>
</div>
