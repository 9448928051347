import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommentService } from '../comment-group/state/comment.service';

@Component({
  selector: 'app-knowledge-reply',
  templateUrl: './knowledge-reply.component.html',
  styleUrls: ['./knowledge-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeReplyComponent extends BaseComponent {
  subscription: Subscription;
  totalAnswer: number = 0;
  shortDate: string = null;
  @Input() knowledgeSummary: KnowledgeSummary;
  @Output() showDetail = new EventEmitter();

  constructor(
    private commentService: CommentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  protected override onDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected override onInit(): void {
    this.subscription = this.commentService.getCommentByPost(this.knowledgeSummary).pipe(
      tap(comments => {
        if (comments.filter(it => it !== null).length > 0) {
          const commentsFilter = comments.filter(it => it !== null).map(cm => cm.commentedBy);
          // get distinct answer by user
          this.totalAnswer = commentsFilter.filter((x, i, a) => a.indexOf(x) == i).length;
        } else {
          this.totalAnswer = 0;
        }
        this.shortDate = '';
      })
    ).subscribe({ complete: () => this.changeDetectorRef.detectChanges() });
  }

  onCreateReply($event: any) {
    // const modal = this.modalService.open(AnswerKnowledgeComponent);
    // modal.instance.knowledgeSummary = this.knowledgeSummary;
    this.showDetail.emit(this.knowledgeSummary);
  }
}
