import { BoardCriteria, BoardId, BoardTitle, IsDefault } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { Board } from '../../entity';
import { BoardNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IBoardRepository } from '..';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { Timestamp } from 'utility/model/timestamp.value';

export class BoardFirestoreRepository implements IBoardRepository {
  private static readonly collectionId = 'board';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: BoardId): Observable<Board> {
    return this.firestoreService.getDocument(BoardFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new BoardNotFoundError(`board_filter id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<Board>): Observable<Board> {
    return this.firestoreService.getCollection(BoardFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(BoardFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  selectIfExists(builder: FirestoreQueryBuilder<Board>): Observable<any> {
    return this.firestoreService.getCollection(BoardFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items[0] : null),
    );
  }

  insert(item: Board): Observable<Board> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(BoardFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  clone(item: Board): Observable<Board> {
    return this.firestoreService.setDocument(BoardFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(board: Board): Observable<Board> {
    board.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(BoardFirestoreRepository.collectionId, board.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new BoardNotFoundError(`board_filter id「${board.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(BoardFirestoreRepository.collectionId, this.convertToMap(board));
      }),
      map(_ => board)
    );
  }

  delete(id: BoardId): Observable<void> {
    const proposal = new Board(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(BoardFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): BoardId {
    return BoardId.create(this.firestoreService.generateId());
  }

  private convertToMap(board: Board): object {
    return Board.allFields.reduce((p, key) => {
      if (board[key] === undefined) {
        return p;
      }
      const value = board[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const board = new Board(BoardId.create(item.id));
    board.title = BoardTitle.create(item.title);
    board.criteria = BoardCriteria.create(item.criteria);
    board.createdBy = AccountOrganizationId.create(item.createdBy);
    board.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    board.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    board.isDefault = item && item.isDefault ? IsDefault.create(item.isDefault) : IsDefault.create(false);
    board.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return board;
  }
}
