import { EMPTY, Observable } from 'rxjs';
import { SlackOAuthAccessToken, SlackOAuthAppId, SlackOAuthAuthedUser, SlackOAuthBotId, SlackOAuthId, SlackOAuthScope, SlackOAuthStatus, SlackOAuthTokenType } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { ESlackOAuthStatus } from '../../enum';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISlackOAuthRepository } from '../../repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { SlackOAuth } from '../../entity';
import { SlackOAuthNotFoundError } from '../../exception';
import { SlackTeamId } from '../../../slack-team/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SlackOAuthFirestoreRepository implements ISlackOAuthRepository {
  private static readonly collectionId = 'slack_oauth';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SlackOAuthId): Observable<SlackOAuth> {
    return this.firestoreService.getDocument(SlackOAuthFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SlackOAuthNotFoundError(`slack_oauth id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<SlackOAuth>): Observable<SlackOAuth> {
    return this.firestoreService.getCollection(SlackOAuthFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(SlackOAuthFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: SlackOAuth): Observable<SlackOAuth> {
    item.status = SlackOAuthStatus.create(ESlackOAuthStatus.ACTIVE);
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SlackOAuthFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(team: SlackOAuth): Observable<SlackOAuth> {
    team.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(SlackOAuthFirestoreRepository.collectionId, team.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SlackOAuthNotFoundError(`slack_oauth id「${team.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SlackOAuthFirestoreRepository.collectionId, this.convertToMap(team));
      }),
      map(_ => team)
    );
  }

  delete(id: SlackOAuthId): Observable<void> {
    const proposal = new SlackOAuth(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SlackOAuthFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SlackOAuthId {
    return SlackOAuthId.create(this.firestoreService.generateId());
  }

  private convertToMap(reaction: SlackOAuth): object {
    return SlackOAuth.allFields.reduce((p, key) => {
      if (reaction[key] === undefined) {
        return p;
      }
      const value = reaction[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const slackOAuth = new SlackOAuth(SlackOAuthId.create(item.id));

    slackOAuth.authedUser = SlackOAuthAuthedUser.create(item.authedUser);
    slackOAuth.scope = SlackOAuthScope.create(item.scope);
    slackOAuth.tokenType = SlackOAuthTokenType.create(item.tokenType);
    slackOAuth.accessToken = SlackOAuthAccessToken.create(item.accessToken);
    slackOAuth.botUserId = SlackOAuthBotId.create(item.botUserId);

    // slackOAuth.userScope = SlackOAuthUserScope.create(item.userScope);
    // slackOAuth.userToken = SlackOAuthUserToken.create(item.userToken);
    // slackOAuth.botScope = SlackOAuthBotScope.create(item.botScope);
    // slackOAuth.botToken = SlackOAuthBotToken.create(item.botToken);

    slackOAuth.appId = SlackOAuthAppId.create(item.appId);
    slackOAuth.teamId = SlackTeamId.create(item.teamId);
    slackOAuth.status = SlackOAuthStatus.create(item.status);
    slackOAuth.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    slackOAuth.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    slackOAuth.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return slackOAuth;
  }
}
