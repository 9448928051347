import { SlackChannelId, SlackChannelName, SlackChannelStatus } from '../value';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

import { EMPTY } from 'rxjs';
import { ESlackChannelStatus } from '../enum';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { ISlackChannelRepository } from '../repository';
import { SlackChannel } from '../entity';
import { SlackChannelDto } from '../dto';
import { SlackChannelNotFoundError } from '../exception';
import { SlackTeamId } from '../../slack-team/value';

export class SlackChannelDomainService {
  constructor(private readonly slackChannelRepository: ISlackChannelRepository) { }

  selectChannel(id: string) {
    return this.slackChannelRepository.select(SlackChannelId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectChannelByTeamId(teamId: string) {
    return this.slackChannelRepository.selectAll(new FirestoreQueryBuilder<SlackChannel>().equalWhere('teamId', teamId)).pipe(
      map(item => this.convertDto(item))
    );
  }

  insertSlackChannel(id: string, name: string, teamId: string, status = ESlackChannelStatus.ACTIVE) {
    return this.slackChannelRepository.select(SlackChannelId.create(id)).pipe(
      take(1),
      catchError(err => {
        if (err instanceof SlackChannelNotFoundError) {
          const slackChannel = new SlackChannel(SlackChannelId.create(id));
          slackChannel.name = SlackChannelName.create(name);
          slackChannel.teamId = SlackTeamId.create(teamId);
          slackChannel.status = SlackChannelStatus.create(status);
          return this.slackChannelRepository.insert(slackChannel);
        } else {
          return EMPTY;
        }
      }),
      map(item => this.convertDto(item)),
      mergeMap(item => this.activeSlackChannel(item)),
    );
  }

  suspendSlackChannel(slackChannelDto: SlackChannelDto) {
    return this.slackChannelRepository.selectIfExists(SlackChannelId.create(slackChannelDto.id)).pipe(
      take(1),
      filter(item => item !== null),
      map(item => {
        const slackChannel = new SlackChannel(SlackChannelId.create(slackChannelDto.id));
        slackChannel.status = SlackChannelStatus.create(ESlackChannelStatus.SUSPEND);
        return slackChannel;
      }),
      mergeMap(slackChannel => this.slackChannelRepository.update(slackChannel)),
      map(item => this.convertDto(item))
    );
  }

  activeSlackChannel(slackChannelDto: SlackChannelDto) {
    return this.slackChannelRepository.selectIfExists(SlackChannelId.create(slackChannelDto.id)).pipe(
      take(1),
      filter(item => item !== null),
      map(item => {
        const slackChannel = new SlackChannel(SlackChannelId.create(slackChannelDto.id));
        slackChannel.status = SlackChannelStatus.create(ESlackChannelStatus.ACTIVE);
        slackChannel.createdAt = item.createdAt;
        return slackChannel;
      }),
      mergeMap(slackChannel => this.slackChannelRepository.update(slackChannel)),
      map(item => this.convertDto(item))
    );
  }

  private convertDto(channel: SlackChannel): SlackChannelDto {
    return SlackChannel.allFields.reduce((p, key) => {
      if (channel[key] === undefined) {
        return p;
      }
      const value = channel[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as SlackChannelDto);
  }
}
