import { AccessToken, IntegrationEmail, IntegrationId, IntegrationStatus, TargetId, TargetType, TeamId, TeamName, UserName } from '../value';
import { AccountId, AccountOrganizationId } from '../../account/value';
import { IntegrationAlreadyExistsError, IntegrationNotFoundError } from '../exception';
// tslint:disable:no-any
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map, mergeAll, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { EIntegrationStatus } from '../enum';
import { EIntegrationTargetType } from '../enum';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IIntegrationRepository } from '../repository';
import { Integration } from '../entity';
import { IntegrationDto } from '../dto';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class IntegrationDomainService {
  constructor(private readonly integrationRepository: IIntegrationRepository) { }

  selectIntegration(integrationId: string) {
    return this.integrationRepository.select(IntegrationId.create(integrationId)).pipe(
      map(item => this.convertDto(item)),
    );
  }

  selectIfExists(slackUserId: string, teamId: string, targetType: EIntegrationTargetType, accountOrganizationId: string) {
    return this.integrationRepository
      .selectAll(
        new FirestoreQueryBuilder<Integration>()
          .equalWhere('targetId', slackUserId)
          .equalWhere('accountOrganizationId', accountOrganizationId)
      )
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.teamId === teamId && item.targetType === targetType),
      );
  }

  selectAllIntegration() {
    return this.integrationRepository.selectAll(new FirestoreQueryBuilder<Integration>()).pipe(
      map(item => this.convertDto(item)),
    );
  }

  selectAllByAccountOrganizationId(accountOrganizationId: string) {
    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('accountOrganizationId', accountOrganizationId)
    return this.integrationRepository.selectAll(builder).pipe(
      map(item => this.convertDto(item)),
    );
  }

  checkByAccountOrganizationId(accountOrganizationId: string): Observable<IntegrationDto> {
    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('accountOrganizationId', accountOrganizationId)
    return this.integrationRepository.selectAll(builder).pipe(
      toArray(),
      tap(items => {
        if (!items.length) {
          console.log(`integration-accountOrganizationId id「${accountOrganizationId}」 is not found`)
          throw new IntegrationNotFoundError(`integration-accountOrganizationId id「${accountOrganizationId}」 is not found`)
        }
      }),
      mergeAll(),
      map(item => this.convertDto(item)),
    );
  }

  selectByAccountOrganizationId(accountOrganizationId: string) {
    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('accountOrganizationId', accountOrganizationId)
    return concat(
      this.integrationRepository.selectAll(builder).pipe(
        map(item => this.convertDto(item)),
      ),
      of(null)
    )
  }

  selectByTargetIdAndTargetType(targetId: string, targetType: EIntegrationTargetType) {
    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('targetId', targetId).equalWhere('targetType', targetType)
    return this.integrationRepository.selectAll(builder).pipe(
      toArray(),
      tap(items => {
        if (!items.length) {
          throw new IntegrationNotFoundError('Integration is not found')
        }
      }),
      mergeAll(),
      map(item => this.convertDto(item)),
    );
  }

  // for insert knowledge document use case
  selectIntegrationsByTargetIdsAndTargetType(targetIds: string[], targetType: EIntegrationTargetType): Observable<Integration> {
    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('targetType', targetType).in('targetId', targetIds)
    return this.integrationRepository.selectAll(builder).pipe();
  }

  insertIntegration(
    accountOrganizationId: string,
    targetId: string,
    targetType: EIntegrationTargetType,
    accessToken: string,
    teamName: string,
    userName: string,
    teamId: string,
    email: string = null,
  ): Observable<IntegrationDto> {

    const builder = new FirestoreQueryBuilder<Integration>().equalWhere('accountOrganizationId', accountOrganizationId).equalWhere('targetType', targetType);

    return this.integrationRepository.selectAll(builder).pipe(
      toArray(),
      map(integrations => {
        if (integrations.length > 0) {
          throw new IntegrationAlreadyExistsError();
        }
        const integration = new Integration(this.integrationRepository.generateId());
        integration.accountOrganizationId = AccountOrganizationId.create(accountOrganizationId);
        integration.targetId = TargetId.create(targetId);
        integration.targetType = TargetType.create(targetType);
        integration.accessToken = AccessToken.create(accessToken);
        integration.teamName = TeamName.create(teamName);
        integration.teamId = TeamId.create(teamId);
        integration.userName = UserName.create(userName);
        integration.email = IntegrationEmail.create(email.toLowerCase());
        integration.status = IntegrationStatus.create(EIntegrationStatus.ACTIVE);
        return integration;
      }),
      mergeMap(integration => this.integrationRepository.insert(integration).pipe(map(item => this.convertDto(item))))
    );
  }

  updateIntegration(integrationDto: IntegrationDto) {
    const integration = new Integration(IntegrationId.create(integrationDto.id));
    integration.accountOrganizationId = AccountId.create(integrationDto.accountOrganizationId);
    integration.targetId = TargetId.create(integrationDto.targetId);
    integration.targetType = TargetType.create(integrationDto.targetType);
    integration.accessToken = AccessToken.create(integrationDto.accessToken);
    integration.teamName = TeamName.create(integrationDto.teamName);
    integration.teamId = TeamId.create(integrationDto.teamId);
    integration.userName = UserName.create(integrationDto.userName);
    integration.email = IntegrationEmail.create(integrationDto.email.toLowerCase());
    integration.status = IntegrationStatus.create(integrationDto.status);
    integration.createdAt = Timestamp.createByDate(integrationDto.createdAt);
    return this.integrationRepository.update(integration).pipe(map(item => this.convertDto(item)));
  }

  suspendIntegration(integrationId: string) {
    const integration = new Integration(IntegrationId.create(integrationId));
    integration.status = IntegrationStatus.create(EIntegrationStatus.SUSPEND);
    return this.integrationRepository.update(integration).pipe(map(item => this.convertDto(item)));
  }

  activeIntegration(integrationId: string, email: string, accessToken: string) {
    const integration = new Integration(IntegrationId.create(integrationId));
    integration.status = IntegrationStatus.create(EIntegrationStatus.ACTIVE);
    integration.email = IntegrationEmail.create(email.toLowerCase());
    integration.accessToken = AccessToken.create(accessToken);
    return this.integrationRepository.update(integration).pipe(map(item => this.convertDto(item)));
  }

  private convertDto(integration: Integration): IntegrationDto {
    return Integration.allFields.reduce((p, key) => {
      if (integration[key] === undefined) {
        return p;
      }
      const value = integration[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as IntegrationDto);
  }
}
