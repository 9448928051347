import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class FeedbackDeliveryReceiverArchived extends PrimitiveValueObject<boolean> {
  static create(value: boolean): FeedbackDeliveryReceiverArchived {
    if (value === null) {
      return new FeedbackDeliveryReceiverArchived(false);
    }
    return new FeedbackDeliveryReceiverArchived(value);
  }
}
