import { filter, map, toArray } from 'rxjs/operators';

import { AssetStorageDomainService } from 'domain/kernel/asset-storage/service';
import { Injectable } from '@angular/core';
import { KnowledgeFileSummary } from '../../model/knowledge-file-summary.model';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private readonly assetStorageDomainService: AssetStorageDomainService,
  ) { }

  public getKnowledgeFiles(knowledgeId: string) {
    return this.assetStorageDomainService.selectAllByKnowledgeId(knowledgeId).pipe(
      filter(file => file !== null),
      map(assetStorageFile => ({
        id: assetStorageFile.id,
        knowledgeFile: assetStorageFile.id,
        slackFileId: assetStorageFile?.slackFileId,
        fileName: assetStorageFile.name,
        name: assetStorageFile.name,
        type: assetStorageFile.type,
        mimetype: assetStorageFile.mimeType,
        size: assetStorageFile.size,
        storageLink: assetStorageFile.downloadUrl,
        storagePath: assetStorageFile.downloadUrl
      } as KnowledgeFileSummary)),
      toArray()
    );
  }
}
