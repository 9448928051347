import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CreateFeedbackModalState, CreateFeedbackModalStore } from './create-feedback-modal.store';

@Injectable({ providedIn: 'root' })
export class CreateFeedbackModalQuery extends Query<CreateFeedbackModalState> {
  constructor(protected store: CreateFeedbackModalStore) {
    super(store);
  }
  selectLoading() {
    return this.select('loading');
  }

  selectFailed() {
    return this.select('failed');
  }

  selectErrMsgs() {
    return this.select('errMsgs');
  }
}
