export class AssetConstant {
  public static readonly LOGO_URL = 'assets/img/logo.png';

  public static readonly ICON_DASHBOARD_URL = 'assets/images/icon-dashboard.svg';
  public static readonly ICON_LIKE_URL = 'assets/images/icon-like.svg';
  public static readonly ICON_COMMENT_URL = 'assets/images/icon-comment.svg';
  public static readonly ICON_HELP_URL = 'assets/images/icon-help.svg';
  public static readonly ICON_TIMELINE_URL = 'assets/images/icon-timeline.svg';
  public static readonly ICON_FOLDER_URL = 'assets/images/icon-folder.svg';
  public static readonly ICON_CLOSE_BLACK_URL = 'assets/images/icon-close-black.svg';
  public static readonly ICON_CLOSE_URL = 'assets/images/icon-close.svg';
  public static readonly ICON_ADD_URL = 'assets/images/icon-add.svg';
  public static readonly ICON_ADD_SMALL_URL = 'assets/images/icon-add-small.svg';
  public static readonly ICON_ARROW_URL = 'assets/images/icon-arrow.svg';

  public static readonly ICON_ATTACHMENTS_URL = {
    DEFAULT: 'assets/images/icon-clip.svg',
    PDF: 'assets/images/icon-pdf.svg',
    WORD: 'assets/images/icon-word.svg',
    EXCEL: 'assets/images/icon-excel.svg',
    POWERPOINT: 'assets/images/icon-ppt.svg',
    URL: 'assets/images/icon-link.svg'
  };

}
