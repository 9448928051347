import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { SendEmailApiClient } from '../api/send-email.api.client';
import { Observable } from 'rxjs';
import { EmailSendRequest } from 'serverside/batch/api/model/email-send.request';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  constructor(private readonly sendEmailApiClient: SendEmailApiClient) { }

  sendFeedbackEmail(emailRequest: EmailSendRequest): Observable<string> {
    return from(this.sendEmailApiClient.sendFeedbackEmail(emailRequest));
  }
}
