<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="comment-form">
    <div class="flash" *ngIf="isFailed">
      <div class="flash__wrapper" *ngIf="errMsgs && errMsgs.length > 0">
        <div>
          <p *ngFor="let errMsg of errMsgs">{{ errMsg }}</p>
        </div>
        <div class="flash__button" (click)="closeValidate()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
      </div>
    </div>
    <app-add-files-upload [multiple]="true" [selectFiles]="filesAttachment" (upload)="onUploadFiles($event)">
      <ng-container content>
        <input #file type="file" style="display: none" (change)="onSelectFile($event)" multiple="true" />
        <div class="comment-form__wrapper d-flex gap-20">
          <fa-icon class="comment-form-plus-icon" [icon]="faPlus" (click)="file.click()"> </fa-icon>
          <!-- <input
            appIMEEvent
            class="comment-form-textarea"
            placeholder="コメントを追加する"
            formControlName="title"
            (onEnterKey)="onSubmit()"
          /> -->
          <app-textarea
            class="comment-form-textarea"
            placeholder="コメントを追加する"
            formControlName="title"
            (submit)="onSubmit()"
            minRows="1"
            maxRows="1"
            [preventEnterKeyCreateNewLine]="true"
          ></app-textarea>
          <div *ngIf="!attachmentFiles.fileSizeExceedsLimit">
            <fa-icon class="comment-form-icon" [icon]="faPaperPlane" (click)="onSubmit()"> </fa-icon>
          </div>
          <div *ngIf="attachmentFiles.fileSizeExceedsLimit" [ngClass]="{ '-disabled': attachmentFiles.fileSizeExceedsLimit }">
            <fa-icon class="comment-form-icon" [icon]="faPaperPlane"> </fa-icon>
          </div>
        </div>
      </ng-container>
    </app-add-files-upload>
    <div *ngIf="hasCancelButton" class="btn-cancel" (click)="onCancel()"> キャンセル </div>
  </form>
</div>
