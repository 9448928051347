import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { BadgeModule } from '../../atoms/badge/badge.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { SuggestibleTextModule } from '../../molecules/suggestible-text/suggestible-text.module';
import { InviteAccountModalComponent } from './invite-account-modal.component';

const components: Type<any>[] = [InviteAccountModalComponent];

@NgModule({
  imports: [
    CommonModule,
    SuggestibleTextModule,
    BadgeModule,
    ButtonModule,
    AvatarModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    FormsModule
  ],
  declarations: [components],
  exports: [components],
})
export class InviteAccountModalModule { }
