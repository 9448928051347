import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Version } from 'frontend/admin/app/shared/enums/version.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() type = 'default';
  @Input() disabled = false;
  @Input() popup = '';

  @Input() version: Version = Version.ONE;
  @Input() label: string;

  @Output() clickButton = new EventEmitter();
  @Output() clickEmitter = new EventEmitter<PointerEvent>();

  public readonly Version = Version;

  click() {
    this.clickButton.emit();
  }

  public getClassNameByType(): string {
    return this.type;
  }

  public onClick($event: PointerEvent): void {
    this.clickEmitter.emit($event);
  }
}
