import { AccountId, AccountOrganizationId, AccountOrganizationRole, AccountOrganizationStatus } from '../value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';

import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../../kernel/base/value';
import { OrganizationId } from '../../organization/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class AccountOrganization implements Entity {
  @Column()
  readonly id: AccountOrganizationId;
  @Column()
  accountId: AccountId;
  @Column()
  organizationId: OrganizationId;
  @Column()
  role: AccountOrganizationRole;
  @Column()
  status: AccountOrganizationStatus;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: AccountOrganizationId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof AccountOrganization)[] {
    return getColumns(new AccountOrganization(AccountOrganizationId.create('')));
  }

  equals(value: AccountOrganization): boolean {
    return this.id.equals(value.id);
  }
}
