import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CreateKnowledgeModalState {
  loading: boolean;
  errMsgs: string[];
  failed: boolean;
}

export function createInitialState(): CreateKnowledgeModalState {
  return {
    loading: false,
    failed: false,
    errMsgs: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'create-knowledge-modal' })
export class CreateKnowledgeModalStore extends Store<CreateKnowledgeModalState> {
  constructor() {
    super(createInitialState());
  }
}
