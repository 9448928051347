import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/fontawesome-pro-light';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Subscription, from, EMPTY } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FeedbackSummary } from './../../../model/feedback-summary.model';
import { ForwardFeedbackModalQuery } from './state/forward-feedback-modal.query';
import { ForwardFeedbackModalService } from './state/forward-feedback-modal.service';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { LoadingService } from 'frontend/admin/app/shared/services/loading.service';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { FeedbackFireStoreService } from 'frontend/admin/app/shared/services/feedback-firestore.service';
import { DOCUMENT } from '@angular/common';
import { AppRoutingConstant } from 'frontend/admin/app/shared/constants/app-routing.constant';

@Component({
  selector: 'app-forward-feedback-modal',
  templateUrl: './forward-feedback-modal.component.html',
  styleUrls: ['./forward-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForwardFeedbackModalComponent extends BaseComponent {
  @Input() feedbackSummary: FeedbackSummary = null;
  readonly appData = {
    faTimes: faTimes,
    faCheck: faCheck
  }
  ngSelectTags = {
    tags: [],
    selectedTags: [],
    knowledgeSummaries: [],
    hideSelected: true,
    multiple: true,
    isViewExtra: true,
  };

  formGroup: FormGroup;
  subscription: Subscription;
  avatarPostOwner: string = null;
  namePostOwner: string = null;
  inProcess: boolean = true;
  knowledgeSummary: KnowledgeSummary = null;
  selectedUsers: AccountMember[] = [];
  isOpenReceiverEditor: boolean = false;
  selectedKnowledgeSummaries: KnowledgeSummary[] = [];
  isOpenSelectKnowledge: boolean = false;

  public readonly ELimitCharacters = ELimitCharacters;

  constructor(
    readonly appQuery: AppQuery,
    readonly forwardFeedbackModalQuery: ForwardFeedbackModalQuery,
    private readonly formBuilder: FormBuilder,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly forwardFeedbackModalService: ForwardFeedbackModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly nzModalRef: NzModalRef<ForwardFeedbackModalComponent>,
    private readonly notificationService: NotificationService,
    private readonly _loadingService: LoadingService,
    private readonly feedbackFireStoreService: FeedbackFireStoreService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      body: ['', [Validators.required, Validators.maxLength(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH)]],
    });
  }

  protected override onDestroy(): void {
    this.forwardFeedbackModalService.resetStore();
  }

  protected override onInit(): void {
    const knowledgeIds = this.feedbackSummary?.knowledgeId?.split(',');
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    if (Array.isArray(knowledgeIds) && knowledgeIds.length > 0 && currentAccountOrganization) {
      this.subscribe(from(knowledgeIds).pipe(
        mergeMap(id => this.forwardFeedbackModalService.fetchKnowledgeById(id, currentAccountOrganization))
      ), data => {
        this.selectedKnowledgeSummaries = [...this.selectedKnowledgeSummaries, data];
        this.formGroup.setValue({
          title: this.feedbackSummary?.title ?? '',
          body: this.feedbackSummary?.body ?? '',
        });
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  onUpsertRequest() {
    const validForm = this.validateCreateFeedback();
    if (!validForm.isValid) {
      this.forwardFeedbackModalService.updateFailed(true, validForm.errMsgs);
      return;
    }
    this.inProcess = true;
    this._loadingService.setStateLoading(true);
    return this.onSendFeedback()
  }

  onSelectedTags(event: any) {
    this.ngSelectTags.selectedTags = event;
    if (this.ngSelectTags.selectedTags.length > 0) {
      this.inProcess = false;
    }
  }

  onSelectUser($event: AccountMember[]) {
    this.selectedUsers = $event;
    if (this.selectedUsers.length > 0) {
      this.inProcess = false;
    }
  }

  isSelectedUSer(account: AccountMember) {
    return this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email);
  }

  closeValidate() {
    this.forwardFeedbackModalService.updateFailed(false, []);
  }

  onToggleOpenSelectReceiver() {
    this.isOpenReceiverEditor = !this.isOpenReceiverEditor;
  }

  onToggleOpenSelectKnowledge() {
    this.isOpenSelectKnowledge = !this.isOpenSelectKnowledge;
  }

  onSelectKnowledgeSummary($event: KnowledgeSummary[]) {
    this.selectedKnowledgeSummaries = $event;
    if (this.selectedKnowledgeSummaries.length > 0) {
      this.inProcess = false;
    }
  }

  onRemoveSelectedKnowledge(knowledge: KnowledgeSummary) {
    this.selectedKnowledgeSummaries = this.selectedKnowledgeSummaries.filter(item => item.id !== knowledge.id);
  }

  onSendFeedback() {
    this.inProcess = true;
    const subscription$ = this.processSendFeedback().subscribe({
      complete: () => {
        this.inProcess = false;
        this.notificationService.open('フィードバックを送信しました。');
      },
      error: err => {
        this.inProcess = false;
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private processSendFeedback() {
    if (!this.validateCreateFeedback()) {
      return EMPTY;
    } else {
      this._loadingService.setStateLoading(true);
      const currentAccountOrganization = this.workspaceQuery.getValue()?.currentAccountOrganization;
      const knowledgeLink = this.document.location.origin + '/' + currentAccountOrganization?.organizationId + AppRoutingConstant.POST[0] + this.selectedKnowledgeSummaries[0].id
      return this.feedbackFireStoreService.sendFeedback(
        currentAccountOrganization?.id,
        this.selectedUsers,
        this.selectedKnowledgeSummaries[0],
        knowledgeLink,
        currentAccountOrganization?.organizationId,
        this.formGroup.value['title'].trim(),
        this.formGroup.value['body'].trim()
      ).pipe(
        tap(feedbackSummaries => {
          this.nzModalRef.close(feedbackSummaries)
          this._loadingService.setStateLoading(false);
        })
      )
    }
  }

  private validateCreateFeedback() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.selectedUsers.length <= 0) {
      rs.isValid = false;
      rs.errMsgs.push('受信機を選択してください!');
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }

    if (this.selectedKnowledgeSummaries.length == 0) {
      rs.isValid = false;
      rs.errMsgs.push('ナレッジを選択してください!');
    }

    return rs;
  }
}
