import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { StateType } from 'frontend/admin/app/shared/enums/state-type.enum';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ConfirmModalData } from './models/confirm-dialog.interface';
import { Version } from 'frontend/admin/app/shared/enums/version.enum';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent extends BaseComponent {
  @Input() data: ConfirmModalData;

  public readonly StateType = StateType;
  public readonly Version = Version;

  private backdropElement: HTMLElement;

  /** https://github.com/NG-ZORRO/ng-zorro-antd/issues/6777 */
  private readonly CLASS_CUSTOM_NAME = 'cdk-overlay-backdrop__custom';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly nzModalRef: NzModalRef<ConfirmModalComponent>
  ) {
    super();
  }

  protected override onAfterViewInit(): void {
    this.backdropElement = this.document.querySelector('.cdk-overlay-backdrop');
    if (this.backdropElement) {
      this.backdropElement.classList.add(this.CLASS_CUSTOM_NAME);
    }
  }

  protected override onDestroy(): void {
    if (this.backdropElement) {
      this.backdropElement.classList.remove(this.CLASS_CUSTOM_NAME);
    }
  }

  public onPrimaryBtnClick(): void {
    if (!this.data?.onPrimaryBtnClick) {
      this.nzModalRef.close();
      return;
    }
    this.data.onPrimaryBtnClick(this.nzModalRef);
  }

  public onSecondaryBtnClick(): void {
    if (!this.data?.onSecondaryBtnClick) {
      this.nzModalRef.close();
      return;
    }
    this.data.onSecondaryBtnClick(this.nzModalRef);
  }

}
