<div class="header-card">
  <div class="header-card__container">
    <div class="header-card-identity">
      <ng-container *ngIf="anonymous"><app-anonymous></app-anonymous></ng-container>
      <!-- <ng-container *ngIf="deletedUser"><app-deleted-user></app-deleted-user></ng-container> -->
      <ng-content select="[avatar]" *ngIf="hasUser"> </ng-content>
      <div class="header-card-identity-name">
        <ng-content select="[displayName]"></ng-content>
      </div>
    </div>
    <div class="header-card-action">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
</div>
