import { BoardCriteria, BoardId, BoardTitle, IsDefault } from '../value';
import { filter, map } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { Board } from '../entity';
import { BoardDto } from '../dto';
import { BoardNotFoundError } from '../exception';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IBoardRepository } from '../repository';
import { IsDeleted } from '../../base/value';
import { Observable } from 'rxjs';
import { Timestamp } from 'utility/model/timestamp.value';

export class BoardDomainService {
  constructor(private readonly boardRepository: IBoardRepository) { }

  select(id: string) {
    return this.boardRepository.select(BoardId.create(id))
      .pipe(map(item => this.convertDto(item)));
  }

  selectDefaultByAccountOrganizationId(accountOrganizationId: string) {
    return this.boardRepository.selectIfExists(new FirestoreQueryBuilder<Board>()
      .equalWhere('isDefault', true).equalWhere('createdBy', accountOrganizationId).equalWhere('isDeleted', false))
      .pipe(
        map(item => {
          if (!item) {
            throw new BoardNotFoundError(`board_filter id is not found`);
          } else {
            return this.convertDto(item);
          }
        })
      );
  }

  selectByTitleAndCreatedBy(title: string, createdBy: string) {
    return this.boardRepository.selectIfExists(new FirestoreQueryBuilder<Board>()
      .equalWhere('title', title)
      .equalWhere('createdBy', createdBy)
      .equalWhere('isDeleted', false)
    );
  }

  selectByCreatedBy(createdBy: string) {
    const _createBy = AccountOrganizationId.create(createdBy);
    const _isDelete = IsDeleted.create(false);
    const builder = new FirestoreQueryBuilder<Board>()
      .orderBy('createdAt', 'asc')
      .limit(1000)

    return this.boardRepository.selectAll(builder).pipe(
      filter(item => item.createdBy.equals(_createBy) && item.isDeleted.equals(_isDelete)),
      map(item => this.convertDto(item)),
    );
  }

  selectBoardDefaultByCreatedBy(createdBy: string): Observable<BoardDto> {
    const builder = new FirestoreQueryBuilder<Board>()
      .equalWhere('createdBy', createdBy)
      .equalWhere('isDefault', true)
    return this.boardRepository.selectAll(builder).pipe(
      map(item => this.convertDto(item)),
    );
  }

  insert(proposal: BoardDto): Observable<BoardDto> {
    const board = new Board(this.boardRepository.generateId());
    board.title = BoardTitle.create(proposal.title);
    board.criteria = BoardCriteria.create(proposal.criteria);
    board.createdBy = AccountOrganizationId.create(proposal.createdBy);
    board.isDefault = IsDefault.create(proposal.isDefault);
    return this.boardRepository.insert(board).pipe(map(item => this.convertDto(item)));
  }

  selectAll() {
    return this.boardRepository.selectAll(new FirestoreQueryBuilder<Board>()
      .equalWhere('isDeleted', false))
      .pipe(
        filter(item => !item.isDeleted.value),
        map(item => this.convertDto(item))
      );
  }

  update(proposal: BoardDto) {
    const board = new Board(BoardId.create(proposal.id));
    board.title = BoardTitle.create(proposal.title);
    board.criteria = BoardCriteria.create(proposal.criteria);
    return this.boardRepository.update(board).pipe(map(item => this.convertDto(item)));
  }

  clone(proposal: BoardDto) {
    const board = new Board(BoardId.create(proposal.id));
    board.title = BoardTitle.create(proposal.title);
    board.criteria = BoardCriteria.create(proposal.criteria);
    board.createdBy = AccountOrganizationId.create(proposal.createdBy);
    board.isDefault = IsDefault.create(proposal.isDefault);
    board.createdAt = Timestamp.createByDate(proposal.createdAt);
    board.updatedAt = Timestamp.createByDate(proposal.updatedAt);
    board.isDeleted = IsDeleted.create(proposal.isDeleted);
    return this.boardRepository.clone(board).pipe(map(item => this.convertDto(item)));
  }

  delete(id: string) {
    const board = new Board(BoardId.create(id));
    board.isDeleted = IsDeleted.create(true);
    return this.boardRepository.update(board).pipe(map(item => this.convertDto(item)));;
  }

  private convertDto(board: Board): BoardDto {
    return Board.allFields.reduce((p, key) => {
      if (board[key] === undefined) {
        return p;
      }
      const value = board[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as BoardDto);
  }
}
