import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { AccountDto } from 'domain/kernel/account/dto';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { Subscription, concat, of } from 'rxjs';
import { tap, take, mergeMap, filter } from 'rxjs/operators';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';

@Component({
  selector: 'app-timeline-card-header',
  templateUrl: './timeline-card-header.component.html',
  styleUrls: ['./timeline-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineCardHeaderComponent extends BaseComponent implements OnChanges {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() isQuotation: boolean = null;
  @Input() boardId: string;
  @Output() showDetail = new EventEmitter();

  readonly postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  subscription: Subscription;
  writer: AccountDto = null;
  writerUrl: string = null;
  deletedUser: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly accountDomainService: AccountDomainService,
    private readonly dashboardService: DashboardService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']) {
      const { currentValue, firstChange } = changes?.['knowledgeSummary'];
      if (currentValue && firstChange && !this.knowledgeSummary['writer']) {
        this.init().subscribe(_ => { this.changeDetectorRef.detectChanges() })
      }
    }
  }

  protected override onDestroy(): void { }

  protected override onInit(): void {
    if (this.knowledgeSummary?.['writer']) {
      this.writer = this.knowledgeSummary['writer'];
      this.writerUrl = this.knowledgeSummary['writerUrl'] ? this.knowledgeSummary['writerUrl'] : null;
    } else {
      this.init().subscribe(_ => {
        this.changeDetectorRef.detectChanges()
      });
    }
  }

  public onStorageAvatar($event: any) {
    if ($event) {
      const summary = this.knowledgeSummary;
      summary.writerUrl = this.writerUrl = $event;
      if (this.boardId) {
        this.dashboardService.updateSummaryInBoard(summary, this.boardId);
      }
    }
  }

  private getAccountOrganization(accountOrganizationId: string) {
    return concat(
      this.accountOrganizationDomainService.selectById(accountOrganizationId).pipe(
        take(1),
        filter(accountOrganization => accountOrganization !== null),
        mergeMap(accountOrganization => this.accountDomainService.selectAccount(accountOrganization.accountId)),
      ),
      of(null)
    )
  }

  private init() {
    const summary = this.knowledgeSummary;

    return this.getAccountOrganization(this.knowledgeSummary.sendBy).pipe(
      take(1),
      tap(rs => {
        if (rs !== null) {
          this.writer = summary.writer = rs;
        } else {
          this.deletedUser = true;
        }
        if (this.boardId) {
          this.dashboardService.updateSummaryInBoard(summary, this.boardId);
        }
      }),
    );
  }
}
