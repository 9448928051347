import { EMPTY, Observable } from 'rxjs';
import { SharingVendorId, SharingVendorIntegrationId } from '../../value';
import { TargetId, TargetType } from '../../../integration/value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISharingVendorIntegrationRepository } from '../../repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { SharingVendorIntegration } from '../../entity';
import { SharingVendorIntegrationNotFoundError } from '../../exception';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SharingVendorIntegrationFirestoreRepository implements ISharingVendorIntegrationRepository {
  private static readonly collectionId = 'sharing_vendor_integration';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SharingVendorIntegrationId): Observable<SharingVendorIntegration> {
    return this.firestoreService.getDocument(SharingVendorIntegrationFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SharingVendorIntegrationNotFoundError(`sharing_vendor_integration id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: SharingVendorIntegration): Observable<SharingVendorIntegration> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SharingVendorIntegrationFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(integration: SharingVendorIntegration): Observable<SharingVendorIntegration> {
    integration.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(SharingVendorIntegrationFirestoreRepository.collectionId, integration.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SharingVendorIntegrationNotFoundError(`sharing_vendor_integration id「${integration.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SharingVendorIntegrationFirestoreRepository.collectionId, this.convertToMap(integration));
      }),
      map(_ => integration)
    );
  }

  delete(id: SharingVendorIntegrationId): Observable<void> {
    const proposal = new SharingVendorIntegration(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SharingVendorIntegrationFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SharingVendorIntegrationId {
    return SharingVendorIntegrationId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<SharingVendorIntegration>) {
    return this.firestoreService.getCollection(SharingVendorIntegrationFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(
              SharingVendorIntegrationFirestoreRepository.collectionId,
              builder.startAfter(items[items.length - 1].id).limit(100)
            )
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  private convertToMap(integration: SharingVendorIntegration): object {
    return SharingVendorIntegration.allFields.reduce((p, key) => {
      if (integration[key] === undefined) {
        return p;
      }
      const value = integration[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const integration = new SharingVendorIntegration(SharingVendorIntegrationId.create(item.id));
    integration.sharingVendorId = SharingVendorId.create(item.sharingVendorId);
    integration.targetId = TargetId.create(item.targetId);
    integration.targetType = TargetType.create(item.targetType);
    integration.createdAt = item.createdAt ? Timestamp.createByMillsec(item.createdAt.seconds * 1000) : undefined;
    integration.updatedAt = item.updatedAt ? Timestamp.createByMillsec(item.updatedAt.seconds * 1000) : undefined;
    integration.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return integration;
  }
}
