import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { StatisticReactionComponent } from './statistic-reaction.component';

const components: Type<any>[] = [StatisticReactionComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class StatisticReactionModule { }
