import { CommentBody, CommentId, CommentParentId, CommentType } from '../../value';
import { EMPTY, Observable, of } from 'rxjs';
import { PostId, PostType } from '../../../post';
import { count, distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { Comment } from '../../entity';
import { CommentNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { ICommentRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { Timestamp } from 'utility/model/timestamp.value';

export class CommentFirestoreRepository implements ICommentRepository {
  private static readonly collectionId = 'comment';
  constructor(private readonly firestoreService: IFirestoreService) { }

  selectAll(builder: FirestoreQueryBuilder<Comment>): Observable<Comment> {
    return this.firestoreService.getCollection(CommentFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(CommentFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item)),
    );
  }

  countAll(builder: FirestoreQueryBuilder<Comment>) {
    return this.firestoreService.getCollection(CommentFirestoreRepository.collectionId, builder.limit(10000)).pipe(
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true))
  }

  selectIfExist(builder: FirestoreQueryBuilder<Comment>): Observable<any> {
    return this.firestoreService.getCollection(CommentFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items[0] : null),
    );
  }

  select(id: CommentId): Observable<Comment> {
    return this.firestoreService.getDocument(CommentFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new CommentNotFoundError(`comment id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: Comment): Observable<Comment> {
    item.createdAt = Timestamp.createByMillsec(+new Date());
    item.updatedAt = Timestamp.createByMillsec(+new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(CommentFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(comment: Comment): Observable<Comment> {
    comment.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(CommentFirestoreRepository.collectionId, comment.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new CommentNotFoundError(`comment id「${comment.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(CommentFirestoreRepository.collectionId, this.convertToMap(comment));
      }),
      map(_ => comment)
    );
  }

  delete(id: CommentId): Observable<any> {
    const proposal = new Comment(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(CommentFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): CommentId {
    return CommentId.create(this.firestoreService.generateId());
  }

  private convertToMap(comment: Comment): object {
    return Comment.allFields.reduce((p, key) => {
      if (comment[key] === undefined) {
        return p;
      }
      const value = comment[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const comment = new Comment(CommentId.create(item.id));
    comment.postId = PostId.create(item.postId);
    comment.postType = PostType.create(item.postType);
    comment.type = CommentType.create(item.type);
    comment.body = CommentBody.create(item.body);
    comment.commentedBy = AccountOrganizationId.create(item.commentedBy);
    comment.parentId = item.parentId ? CommentParentId.create(item.parentId) : undefined;
    comment.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    comment.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    comment.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return comment;
  }
}
