import { Store, StoreConfig } from '@datorama/akita';

import { Injectable } from '@angular/core';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';

export interface ForwardFeedbackModalState {
  loading: boolean;
  errMsgs: string[];
  failed: boolean;
  accounts: AccountMember[];
}

export function createInitialState(): ForwardFeedbackModalState {
  return {
    loading: false,
    failed: false,
    errMsgs: [],
    accounts: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'forward-feedback-modal' })
export class ForwardFeedbackModalStore extends Store<ForwardFeedbackModalState> {
  constructor() {
    super(createInitialState());
  }
}
