import { Store, StoreConfig } from '@datorama/akita';

import { BoardDto } from 'domain/kernel/board/dto';
import { Injectable } from '@angular/core';

export interface SidebarMenuDashboardState {
  boards: BoardDto[];
  selectedBoard: BoardDto;
  totalBoardCount: number;
}

export function createInitialState(): SidebarMenuDashboardState {
  return ({
    boards: [],
    selectedBoard: null,
    totalBoardCount: 0
  } as SidebarMenuDashboardState);
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sidebar-menu-dashboard' })
export class SidebarMenuDashboardStore extends Store<SidebarMenuDashboardState> {
  constructor() {
    super(createInitialState());
  }
}
