import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAccountOrganizationApi } from 'serverside/batch/api/interface/account-organization.api.interface';
import { AccountOrganizationBoardFilterInsertRequest } from 'serverside/batch/api/model/account-organization-board-filter-insert.request';
import { AccountOrganizationInvitationEmailSendRequest } from 'serverside/batch/api/model/account-organization-invitation-email-send.request';
import { AccountOrganizationWelcomeEmailSendRequest } from 'serverside/batch/api/model/account-organization-welcome-email-send.request';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class SendInvitationEmailApiClient implements IAccountOrganizationApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }
  createBoardDefault(accountOrganizationBoardFilterInsertRequest: AccountOrganizationBoardFilterInsertRequest): Promise<any> {
    throw new Error('Method not implemented.');
  }
  syncBoardDefault(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  sendWelcomeEmail(request: AccountOrganizationWelcomeEmailSendRequest): Promise<string> {
    throw new Error('Method not implemented.');
  }

  sendInvitationEmail(accountOrganizationInvitationEmailSendRequest: AccountOrganizationInvitationEmailSendRequest): Promise<string> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/account-organization/send-invitation-email', accountOrganizationInvitationEmailSendRequest)
      .pipe(
        map(response => response as string),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }
}
