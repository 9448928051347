import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { EAccountOrganizationRole, EAccountOrganizationStatus } from 'domain/kernel/account/enum';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, take, toArray } from 'rxjs/operators';

import { Account } from 'frontend/lib/model/account.model';
import { AccountOrganizationAlreadyExistsError } from 'domain/kernel/account/exception';
import { BoardDomainService } from 'domain/kernel/board/service';
import { BoardDto } from 'domain/kernel/board/dto';
import { Injectable } from '@angular/core';
import { InviteAccountModalStore } from './invite-account-modal.store';

@Injectable({ providedIn: 'root' })
export class InviteAccountModalService {
  constructor(
    private readonly inviteAccountModalStore: InviteAccountModalStore,
    private readonly accountDomainService: AccountDomainService,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly BoardDomainService: BoardDomainService,
  ) { }

  searchAccounts(email: string) {
    return this.accountDomainService.selectAllAccountByEmail(email.toLowerCase()).pipe(
    );
  }

  addAccountOrganization(accountId: string, organizationId: string) {
    return this.accountOrganizationDomainService.selectIfExistAccountOrganization(accountId, organizationId).pipe(
      toArray(),
      map(items => items && items.length > 0 ? items[0] as any : null),
      mergeMap(accountOrganization => {
        if (accountOrganization && accountOrganization.status === EAccountOrganizationStatus.SUSPEND) {
          return this.accountOrganizationDomainService.activeAccountOrganization(accountOrganization)
        } else if (accountOrganization === null || accountOrganization === undefined) {
          return this.accountOrganizationDomainService.insertAccountOrganization(accountId, organizationId, EAccountOrganizationRole.READER).pipe(
            take(1),
            filter(accountOrganization => accountOrganization !== null),
            mergeMap(accountOrganization => {
              return combineLatest([
                this.BoardDomainService.insert({ isDefault: true, title: 'ALL', criteria: '{}', createdBy: accountOrganization.id } as BoardDto),
                of(accountOrganization)
              ])
            }),
            mergeMap(rs => of(rs[1])),
          )
        } else {
          throw new AccountOrganizationAlreadyExistsError('this account organization already exists.', accountId, organizationId);
        }
      }
      )
    );
  }

  // Akita
  updateAccounts(accounts: Account[]) {
    this.inviteAccountModalStore.update({ accounts });
  }

  updateSelectedAccount(selectedAccount: Account) {
    this.inviteAccountModalStore.update({ selectedAccount });
  }

  updateLoading(loading: boolean) {
    this.inviteAccountModalStore.update({ loading });
  }

  resetStore() {
    this.inviteAccountModalStore.reset();
  }
}
