import { EMPTY, Observable } from 'rxjs';
import { SharingVendorFileId, SharingVendorFileStorageLink } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISharingVendorFileRepository } from '../sharing-vendor-file.repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { SharingVendorFile } from '../../entity';
import { SlackFileId } from '../../../../slack/slack-file/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SharingVendorFileFirestoreRepository implements ISharingVendorFileRepository {
  private static readonly collectionId = 'sharing_vendor_file';
  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SharingVendorFileId): Observable<SharingVendorFile> {
    return this.firestoreService.getDocument(SharingVendorFileFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          return null as SharingVendorFile;
        } else {
          return this.convertToEntity(item);
        }
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<SharingVendorFile>): Observable<SharingVendorFile> {
    return this.firestoreService.getCollection(SharingVendorFileFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(SharingVendorFileFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item)),
    );
  }

  insert(item: SharingVendorFile): Observable<SharingVendorFile> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SharingVendorFileFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(sharingVendorFile: SharingVendorFile): Observable<SharingVendorFile> {
    sharingVendorFile.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(SharingVendorFileFirestoreRepository.collectionId, sharingVendorFile.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new Error(`sharing_vendor_file id「${sharingVendorFile.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SharingVendorFileFirestoreRepository.collectionId, this.convertToMap(sharingVendorFile));
      }),
      map(_ => sharingVendorFile)
    );
  }

  delete(id: SharingVendorFileId): Observable<void> {
    const proposal = new SharingVendorFile(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SharingVendorFileFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SharingVendorFileId {
    return SharingVendorFileId.create(this.firestoreService.generateId());
  }

  private convertToMap(sharingVendorFile: SharingVendorFile): object {
    return SharingVendorFile.allFields.reduce((p, key) => {
      if (sharingVendorFile[key] === undefined) {
        return p;
      }
      const value = sharingVendorFile[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const sharingVendorFile = new SharingVendorFile(SharingVendorFileId.create(item.id));
    sharingVendorFile.slackFileId = SlackFileId.create(item.slackFileId);
    sharingVendorFile.storageLink = SharingVendorFileStorageLink.create(item.storageLink);
    sharingVendorFile.storagePath = SharingVendorFileStorageLink.create(item.storagePath);
    sharingVendorFile.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    sharingVendorFile.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    sharingVendorFile.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return sharingVendorFile;
  }
}
