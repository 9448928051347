import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReactionDomainService } from 'domain/kernel/reaction/service';
import { EReactionType } from 'domain/kernel/reaction/enum';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { of } from 'rxjs';
import { take, tap, mergeMap } from 'rxjs/operators';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { DashboardQuery } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.query';
import { ObjectUtils } from 'frontend/admin/app/shared/utils/objects.utils';
import { KnowledgeSearchHeaderQuery } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.query';
import { KnowledgeSearchHeaderService } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.service';
import { EPostType } from 'domain/kernel/post';
import { MyKnowledgeSearchQuery } from 'frontend/admin/app/pages/my-knowledge/organisms/my-knowledge-search/state/my-knowledge-search.query';
import { MyKnowledgeSearchService } from 'frontend/admin/app/pages/my-knowledge/organisms/my-knowledge-search/state/my-knowledge-search.service';
import { MyKnowledgeFireStoreService } from 'frontend/admin/app/shared/services/my-knowledge-firestore.service';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactionComponent extends BaseComponent implements OnChanges {
  public chosen: boolean = false;
  public inProcess: boolean = false;

  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() boardId: string = null;

  constructor(
    private readonly reactionDomainService: ReactionDomainService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardService: DashboardService,
    private readonly dashboardQuery: DashboardQuery,
    private readonly knowledgeSearchHeaderQuery: KnowledgeSearchHeaderQuery,
    private readonly knowledgeSearchHeaderService: KnowledgeSearchHeaderService,
    private readonly myKnowledgeSearchQuery: MyKnowledgeSearchQuery,
    private readonly myKnowledgeSearchService: MyKnowledgeSearchService,
    private readonly myKnowledgeFireStoreService: MyKnowledgeFireStoreService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']) {
      const { currentValue, previousValue, firstChange } = changes?.['knowledgeSummary'];
      if (
        (firstChange && currentValue?.['reactionTotal'] !== null)
        ||
        !ObjectUtils.isComparisonObject(previousValue?.['reactionTotal'], currentValue?.['reactionTotal'])
      ) {
        this.init();
      }
    }
  }

  protected override onDestroy(): void { }
  protected override onInit(): void { }

  private loadReactionByPost(knowledgeSummary: KnowledgeSummary) {
    return this.reactionDomainService.selectByPostIdAndReactedBy(knowledgeSummary.id, knowledgeSummary.loggedUser.id)
      .pipe(
        take(1),
        tap(reaction => this.chosen = reaction && reaction.chosen === true ? reaction.chosen : null)
      );
  }

  onClick() {
    this.inProcess = true;
    return this.reactionDomainService.toggle(this.knowledgeSummary.id, this.knowledgeSummary?.loggedUser.id, this.knowledgeSummary?.postType, EReactionType.LIKE)
      .pipe(
        take(1),
        tap(reaction => {
          let total = this.knowledgeSummary?.reactionTotal ? this.knowledgeSummary.reactionTotal : 0;
          this.knowledgeSummary.reactionTotal = reaction && reaction.chosen && reaction.chosen === true ? total + 1 : total - 1;
          this.chosen = reaction && reaction.chosen && reaction.chosen === true ? reaction.chosen : null;
          this.detectSyncToState(this.knowledgeSummary.reactionTotal, this.chosen);
          this.changeDetectorRef.detectChanges();
        }),
        mergeMap(reaction => {
          if (this.knowledgeSummary.postType && this.knowledgeSummary.postType === EPostType.KNOWLEDGE) {
            return this.myKnowledgeFireStoreService.updateAfterLikeAction(this.knowledgeSummary, reaction.chosen)
          } else {
            return of(null)
          }
        })
      ).subscribe(_ => {
        this.inProcess = false;
      });
  }

  private init() {
    this.subscribe(this.loadReactionByPost(this.knowledgeSummary), _ => {
      this.updatePropToState();
      this.changeDetectorRef.detectChanges();
    });
  }

  private updatePropToState() {
    const { summaries } = this.knowledgeSearchHeaderQuery.getValue();
    // update in dashboard search result
    if (summaries?.length > 0 && this.chosen !== null) {
      this.knowledgeSearchHeaderService.updateKnowledgeSummary(
        this.knowledgeSummary,
        { reactionChosen: this.chosen }
      );
    }
    // update in dashboard board
    if (this.boardId && this.chosen !== null) {
      this.dashboardService.updateKnowledgeStateInBoard(
        this.knowledgeSummary,
        { reactionChosen: this.chosen },
        this.boardId
      );
    }
  }

  private detectSyncToState(total: number, chosen: boolean = false) {
    const { boards } = this.dashboardQuery.getValue();
    const { summaries } = this.knowledgeSearchHeaderQuery.getValue();
    const { knowledges } = this.myKnowledgeSearchQuery.getValue();

    // for dashboard timeline
    if (Object.keys(boards)?.length > 0) {
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary,
        {
          reactionTotal: total,
          chosenReation: chosen
        });
    }

    // for dashboard search
    if (summaries?.length > 0) {
      this.knowledgeSearchHeaderService.updateKnowledgeSummary(this.knowledgeSummary,
        {
          reactionTotal: total,
          reactionChosen: chosen
        });
    }

    // for my-knowledge 
    if (knowledges?.length > 0) {
      if (!chosen) {
        this.myKnowledgeSearchService.popMyKnowledgeSummary(this.knowledgeSummary.id);
      }
    }
  }
}
