//service
import {
  AccountDomainService,
  AccountOrganizationDomainService
} from "domain/kernel/account/service";
//repository
import {
  IAccountOrganizationRepository,
  IAccountRepository
} from "domain/kernel/account/repository";
import {
  ISharingVendorIntegrationRepository,
  ISharingVendorRepository,
} from "domain/kernel/sharing-vendor/repository";
import {
  SharingVendorDomainService,
  SharingVendorIntegrationDomainService
} from "domain/kernel/sharing-vendor/service";

import {
  ActivityDomainService
} from "domain/kernel/activity/service";
import {
  AssetStorageDomainService
} from "domain/kernel/asset-storage/service";
import {
  BoardDomainService
} from "domain/kernel/board/service";
import {
  CategoryDomainService,
} from "domain/kernel/category/service";
import {
  CommentDomainService
} from "domain/kernel/comment/service";
import {
  FeedbackDeliveryDomainService
} from "domain/kernel/feedback-delivery/service";
import {
  FeedbackDomainService
} from "domain/kernel/feedback/service";
import {
  FeedbackToSlackDomainService
} from "domain/kernel/feedback-to-slack/service";
import {
  IActivityRepository
} from "domain/kernel/activity/repository";
import {
  IAssetStorageRepository
} from "domain/kernel/asset-storage/repository";
import {
  IBoardRepository
} from "domain/kernel/board/repository";
import {
  ICategoryRepository
} from "domain/kernel/category/repository";
import {
  ICommentRepository
} from "domain/kernel/comment/repository";
import {
  IFeedbackDeliveryRepository
} from "domain/kernel/feedback-delivery/repository";
import {
  IFeedbackRepository
} from "domain/kernel/feedback/repository";
import {
  IFeedbackToSlackRepository
} from "domain/kernel/feedback-to-slack/repository";
import {
  IIntegrationRepository
} from "domain/kernel/integration/repository";
import {
  IKnowledgeActivityRepository
} from "domain/kernel/knowledge-activity/repository";
import {
  IKnowledgeRepository
} from "domain/kernel/knowledge/repository";
import {
  IMyKnowledgeRepository
} from "domain/kernel/my-knowledge/repository";
import {
  IOrganizationRepository
} from "domain/kernel/organization/repository";
import {
  IReactionRepository
} from "domain/kernel/reaction/repository";
import {
  ISlackChannelRepository
} from "domain/slack/slack-channel/repository";
import {
  ISlackFileRepository
} from "domain/slack/slack-file/repository";
import {
  ISlackMessageRepository
} from "domain/slack/slack-message/repository";
import {
  ISlackOAuthRepository
} from "domain/slack/slack-oauth/repository";
import {
  ISlackReactionRepository
} from "domain/slack/slack-reaction/repository";
import {
  ISlackUserRepository
} from "domain/slack/slack-user/repository";
import {
  IntegrationDomainService
} from "domain/kernel/integration/service";
import {
  KnowledgeActivityDomainService
} from "domain/kernel/knowledge-activity/service";
import {
  KnowledgeDomainService
} from "domain/kernel/knowledge/service";
import {
  MyKnowledgeDomainService
} from "domain/kernel/my-knowledge/service";
import {
  OrganizationDomainService
} from "domain/kernel/organization/service";
import {
  ReactionDomainService
} from "domain/kernel/reaction/service";
import {
  SlackChannelDomainService
} from "domain/slack/slack-channel/service";
import { SlackConfiguration } from "./config/slack.configuration";
import {
  SlackFileDomainService
} from "domain/slack/slack-file/service";
import {
  SlackMessageDomainService
} from "domain/slack/slack-message/service";
import {
  SlackOAuthDomainService
} from "domain/slack/slack-oauth/service";
import {
  SlackReactionDomainService
} from "domain/slack/slack-reaction/service";
import {
  SlackUserDomainService
} from "domain/slack/slack-user/service";
import { environment } from "../environments/environment";

export const APP_DOMAIN_SERVICE_PROVIDERS = [
  {
    provide: AccountDomainService,
    useFactory: (accountRepository: IAccountRepository) => new AccountDomainService(accountRepository),
    deps: [IAccountRepository]
  },
  {
    provide: SlackMessageDomainService,
    useFactory: (slackMessageRepository: ISlackMessageRepository) => new SlackMessageDomainService(slackMessageRepository),
    deps: [ISlackMessageRepository]
  },
  {
    provide: AccountOrganizationDomainService,
    useFactory: (accountOrganizationRepository: IAccountOrganizationRepository) => new AccountOrganizationDomainService(accountOrganizationRepository),
    deps: [IAccountOrganizationRepository]
  },
  {
    provide: OrganizationDomainService,
    useFactory: (organizationRepository: IOrganizationRepository) => new OrganizationDomainService(organizationRepository),
    deps: [IOrganizationRepository]
  },
  {
    provide: KnowledgeDomainService,
    useFactory: (knowledgeRepository: IKnowledgeRepository) => new KnowledgeDomainService(knowledgeRepository),
    deps: [IKnowledgeRepository]
  },
  {
    provide: CategoryDomainService,
    useFactory: (categoryRepository: ICategoryRepository) => new CategoryDomainService(categoryRepository),
    deps: [ICategoryRepository]
  },
  {
    provide: IntegrationDomainService,
    useFactory: (integrationRepository: IIntegrationRepository) => new IntegrationDomainService(integrationRepository),
    deps: [IIntegrationRepository]
  },
  {
    provide: ActivityDomainService,
    useFactory: (activityRepository: IActivityRepository) => new ActivityDomainService(activityRepository),
    deps: [IActivityRepository]
  },
  {
    provide: FeedbackDomainService,
    useFactory: (feedbackRepository: IFeedbackRepository) => new FeedbackDomainService(feedbackRepository),
    deps: [IFeedbackRepository]
  },
  {
    provide: KnowledgeActivityDomainService,
    useFactory: (KnowledgeActivityRepository: IKnowledgeActivityRepository) => new KnowledgeActivityDomainService(KnowledgeActivityRepository),
    deps: [IKnowledgeActivityRepository]
  },
  {
    provide: FeedbackToSlackDomainService,
    useFactory: (FeedbackToSlackRepository: IFeedbackToSlackRepository) => new FeedbackToSlackDomainService(FeedbackToSlackRepository),
    deps: [IFeedbackToSlackRepository]
  },
  {
    provide: FeedbackDeliveryDomainService,
    useFactory: (FeedbackDeliveryRepository: IFeedbackDeliveryRepository) => new FeedbackDeliveryDomainService(FeedbackDeliveryRepository),
    deps: [IFeedbackDeliveryRepository]
  },
  {
    provide: SlackReactionDomainService,
    useFactory: (slackReactionRepository: ISlackReactionRepository) => new SlackReactionDomainService(slackReactionRepository),
    deps: [ISlackReactionRepository]
  },
  {
    provide: SlackOAuthDomainService,
    useFactory: (slackOAutheRepository: ISlackOAuthRepository) => new SlackOAuthDomainService(slackOAutheRepository),
    deps: [ISlackOAuthRepository]
  },
  {
    provide: SlackFileDomainService,
    useFactory: (slackFileRepository: ISlackFileRepository) => new SlackFileDomainService(slackFileRepository),
    deps: [ISlackFileRepository]
  },
  {
    provide: SlackUserDomainService,
    useFactory: (slackUserRepository: ISlackUserRepository) => new SlackUserDomainService(slackUserRepository),
    deps: [ISlackUserRepository]
  },
  {
    provide: SlackChannelDomainService,
    useFactory: (slackChennelRepository: ISlackChannelRepository) => new SlackChannelDomainService(slackChennelRepository),
    deps: [ISlackChannelRepository]
  },
  {
    provide: SlackConfiguration,
    useValue: new SlackConfiguration(environment.slack.clientId, environment.slack.slackScope)
  },
  {
    provide: SharingVendorDomainService,
    useFactory: (sharingVendorRepository: ISharingVendorRepository) => new SharingVendorDomainService(sharingVendorRepository),
    deps: [ISharingVendorRepository]
  },
  {
    provide: SharingVendorIntegrationDomainService,
    useFactory: (sharingVendorIntegrationRepository: ISharingVendorIntegrationRepository) => new SharingVendorIntegrationDomainService(sharingVendorIntegrationRepository),
    deps: [ISharingVendorIntegrationRepository]
  },
  {
    provide: AssetStorageDomainService,
    useFactory: (assetStorageRepository: IAssetStorageRepository) => new AssetStorageDomainService(assetStorageRepository),
    deps: [IAssetStorageRepository]
  },
  {
    provide: BoardDomainService,
    useFactory: (boardRepository: IBoardRepository) => new BoardDomainService(boardRepository),
    deps: [IBoardRepository]
  },
  {
    provide: ReactionDomainService,
    useFactory: (reactionRepository: IReactionRepository) => new ReactionDomainService(reactionRepository),
    deps: [IReactionRepository]
  },
  {
    provide: CommentDomainService,
    useFactory: (commentRepository: ICommentRepository) => new CommentDomainService(commentRepository),
    deps: [ICommentRepository]
  },
  {
    provide: MyKnowledgeDomainService,
    useFactory: (myKnowledgeRepository: IMyKnowledgeRepository) => new MyKnowledgeDomainService(myKnowledgeRepository),
    deps: [IMyKnowledgeRepository]
  },
]
