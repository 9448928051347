import { AccountOrganizationView, RootWorkspaceStore } from './root.store';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { Injectable } from '@angular/core';
import { OrganizationDomainService } from 'domain/kernel/organization/service';

@Injectable({ providedIn: 'root' })
export class RootWorkspaceService {
  constructor(
    private rootWorkspaceStore: RootWorkspaceStore,
    private readonly appQuery: AppQuery,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly organizationDomainService: OrganizationDomainService,
  ) { }

  fetch() {
    const currentAccount = this.appQuery.getValue().currentAccount;
    return this.accountOrganizationDomainService.selectAllAccountOrganizationByAccountId(currentAccount.id).pipe(
      filter(organization => organization !== null),
      mergeMap(accountOrganization =>
        this.organizationDomainService.selectByOrganizationId(accountOrganization.organizationId).pipe(
          take(1),
          filter(organization => organization !== null),
          map(organization => ({
            id: organization.id,
            displayName: organization.displayName,
            name: organization.name,
            shortImg: organization.shortImg,
            role: accountOrganization.role,
            createdAt: organization.createdAt,
            updatedAt: organization.updatedAt
          } as AccountOrganizationView))
        )
      ),
      toArray(),
      tap(organizations => this.updateOrganization(organizations.reverse()))
    );
  }

  leaveOrganization(organizationId: string) {
    const currentAccount = this.appQuery.getValue().currentAccount;
    return this.accountOrganizationDomainService.selectAllAccountOrganizationByAccountIdAndOrganizationId(currentAccount.id, organizationId).pipe(
      take(1),
      mergeMap(accountOrganization => this.accountOrganizationDomainService.suspendAccountOrganization(accountOrganization)),
      tap(_ => this.removeOrganization(organizationId))
    );
  }

  // Akita
  updateOrganization(organizationViews: AccountOrganizationView[]) {
    this.rootWorkspaceStore.update({
      organizations: organizationViews
    });
  }

  removeOrganization(organizationId: string) {
    const currentOrganizations = this.rootWorkspaceStore.getValue().organizations;
    this.rootWorkspaceStore.update({
      organizations: currentOrganizations.filter(organization => organization.id !== organizationId)
    });
  }

  resetStore() {
    this.rootWorkspaceStore.reset();
  }
}
