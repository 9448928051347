import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent {
  @Input() type = 'default';
  @Input() placeholder = '';
  @Input() searchInput = '';
  @Output() onKeyUpPress = new EventEmitter();
  @Output() onChangeKeywords = new EventEmitter();
  @Output() clearSearch = new EventEmitter();

  appData = {
    faSearch: faSearch,
    faRemove: faTimes
  };

  clearSearchInput() {
    this.searchInput = '';
    this.onChangeKeywords.emit(this.searchInput);
    this.clearSearch.emit(true);
  }

  onSearch() {
    this.onKeyUpPress.emit(this.searchInput);
  }

  onChange() {
    this.onChangeKeywords.emit(this.searchInput);
  }
}
