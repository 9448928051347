import { EMPTY, Observable } from 'rxjs';
import { SlackReactionId, SlackReactionTs, SlackReactionType } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISlackReactionRepository } from '../../repository';
// tslint:disable:no-any
import { IsDeleted } from '../../../../kernel/base/value';
import { SlackMessageId } from '../../../slack-message/value';
import { SlackReaction } from '../../entity';
import { SlackReactionNotFoundError } from '../../exception';
import { SlackUserId } from '../../../slack-user/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SlackReactionFirestoreRepository implements ISlackReactionRepository {
  private static readonly collectionId = 'slack_reaction';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SlackReactionId): Observable<SlackReaction> {
    return this.firestoreService.getDocument(SlackReactionFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SlackReactionNotFoundError(`slack_reaction id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<SlackReaction>): Observable<SlackReaction> {
    return this.firestoreService.getCollection(SlackReactionFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(SlackReactionFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: SlackReaction): Observable<SlackReaction> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SlackReactionFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(reaction: SlackReaction): Observable<SlackReaction> {
    reaction.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(SlackReactionFirestoreRepository.collectionId, reaction.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SlackReactionNotFoundError(`slack_reaction id「${reaction.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SlackReactionFirestoreRepository.collectionId, this.convertToMap(reaction));
      }),
      map(_ => reaction)
    );
  }

  delete(id: SlackReactionId): Observable<void> {
    const proposal = new SlackReaction(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SlackReactionFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SlackReactionId {
    return SlackReactionId.create(this.firestoreService.generateId());
  }

  private convertToMap(reaction: SlackReaction): object {
    return SlackReaction.allFields.reduce((p, key) => {
      if (reaction[key] === undefined) {
        return p;
      }
      const value = reaction[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const slackReaction = new SlackReaction(SlackReactionId.create(item.id));
    slackReaction.userId = SlackUserId.create(item.userId);
    slackReaction.messageId = SlackMessageId.create(item.messageId);
    slackReaction.type = SlackReactionType.create(item.type);
    slackReaction.ts = SlackReactionTs.create(item.ts);
    slackReaction.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    slackReaction.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    slackReaction.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return slackReaction;
  }
}
