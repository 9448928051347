import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { LocalStorageConstant } from 'frontend/admin/app/shared/constants/local-storage.constant';
import { HttpStatus } from 'frontend/admin/app/shared/enums/http-status.enum';
import { LocalStorageService } from 'frontend/admin/app/shared/services/local-storage.service';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../lib/gcp/service/firestore.service';
import { Entity } from '../../../../utility/model/entity.model';
import { IdValueObject } from '../../../../utility/model/id-value-object.model';
import { UnauthorizedError } from '../exception/Unauthorized.exception';
import { AppRoutingConstant } from './../../../admin/app/shared/constants/app-routing.constant';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService implements IFirestoreService {
  private readonly CERTIFICATION = ['/auth', '/invite'];

  constructor(
    private readonly router: Router,
    private readonly angularFirestore: AngularFirestore,
    private readonly localStorageService: LocalStorageService,
  ) { }

  sendEmail(receiver: string, subject: string, html: string) {
    throw new Error('Method not implemented.');
  }

  getCollection<T extends Entity>(collection: string, builder: FirestoreQueryBuilder<T>): Observable<T[]> {
    return this.angularFirestore
      .collection<T>(collection, ref => builder.build(ref as firebase.firestore.CollectionReference<T>))
      .valueChanges({ idField: 'id' });
  }

  getDocument<T extends IdValueObject, U extends Entity>(collection: string, id: T): Observable<U | undefined> {
    return this.angularFirestore.doc<U>(`${collection}/${id.value}`).valueChanges();
  }

  public setDocument(collection: string, data: object): Observable<void | never> {
    const token = this.localStorageService.getObject(LocalStorageConstant.USER_TOKEN);
    const isThrough = this.CERTIFICATION.some(x => this.router.url.includes(x));

    if (!token) {
      if (isThrough) {
        const id = (data as any).id;
        return from(this.angularFirestore.doc(`${collection}/${id}`).set(data, { merge: true }));
      }
      this.router.navigate(AppRoutingConstant.ERROR, { state: { statusCode: HttpStatus.NotAuthorized } });
      throw new UnauthorizedError(`${HttpStatus.NotAuthorized} Unauthorized`);
    }
    // Dirty code
    const id = (data as any).id;
    return from(this.angularFirestore.doc(`${collection}/${id}`).set(data, { merge: true }));
  }

  deleteDocument<T extends IdValueObject>(collection: string, id: T): Observable<void> {
    return from(this.angularFirestore.doc<T>(`${collection}/${id.value}`).delete());
  }

  runTransaction<T>(updateFunction: (transaction) => Promise<T>): Observable<T> {
    return from(this.angularFirestore.firestore.runTransaction<T>(updateFunction));
  }

  generateId(): string {
    return this.angularFirestore.createId();
  }

  //getAngularFireStore(): firebase.firestore.Firestore {
  //   return this.angularFirestore.firestore;
  // }
}
