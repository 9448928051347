<div class="detail-post-modal">
  <div class="detail-post-modal__header" [class.--request]="knowledgeSummary.postType === postType.KNOWLEDGE_REQUEST">
    <app-header-card
      [hasUser]="user && knowledgeSummary.postType !== postType.KNOWLEDGE_REQUEST"
      [anonymous]="knowledgeSummary.postType === postType.KNOWLEDGE_REQUEST"
    >
      <ng-container avatar>
        <app-avatar *ngIf="user" [altText]="user.email" [source]="user.shortImg" [width]="25" [height]="25"></app-avatar>
      </ng-container>
      <ng-container displayName>
        <div *ngIf="user && knowledgeSummary.postType !== postType.KNOWLEDGE_REQUEST">{{ user.name?.first }} {{ user.name?.last }}</div>
      </ng-container>
      <ng-container action>
        <app-knowledge-quotation
          *ngIf="knowledgeSummary.postType === postType.SHARING_VENDOR"
          label="ナレッジ化する"
          [knowledgeSummary]="knowledgeSummary"
        ></app-knowledge-quotation>
        <div class="btn-edit" [class.--request]="knowledgeSummary.postType === postType.KNOWLEDGE_REQUEST">
          <app-button *ngIf="isKnowledgeWriter" (clickButton)="onEditKnowledge()"> 編集</app-button>
          <app-button *ngIf="isRequestWriter" (clickButton)="onEditRequest()"> 編集</app-button>
        </div>
        <div class="btn-quotation">
          <app-button *ngIf="knowledgeSummary.postType === postType.KNOWLEDGE" (clickButton)="onOpenQuotation()">
            ナレッジを引用
          </app-button>
        </div>
      </ng-container>
    </app-header-card>
  </div>
  <div class="detail-post-modal__container">
    <div class="detail-post-modal__wrapper">
      <div class="detail-post-modal__title">
        <app-knowledge-body-title [isShowUpdatedAtJIT]="true">
          <ng-container title *ngIf="knowledgeSummary.postType === postType.SHARING_VENDOR; else title">
            <div class="detail-post-modal__title--text"># slack からのシェア</div>
          </ng-container>
          <ng-container jit>
            <div>{{ knowledgeSummary.updatedAtShortStr }}更新</div></ng-container
          >
        </app-knowledge-body-title>
        <ng-template #title>
          <div class="detail-post-modal__title--content">{{ knowledgeSummary.title }}</div>
        </ng-template>
      </div>
      <div class="detail-post-modal__content detail-post-modal__content">
        <app-body-knowledge-detail [knowledgeSummary]="knowledgeSummary">
          <ng-container body>
            <div class="content__text" [class.hasTag]="tags.length > 0 && files.length > 0">
              <p
                [ngClass]="{ 'sharing-vendor-body': knowledgeSummary.postType === postType.SHARING_VENDOR }"
                [innerHTML]="knowledgeSummary.body | slackMarked"
              ></p>
            </div>
            <div class="detail-post-modal__tags" *ngIf="initLoad">
              <app-categories-anchor
                label="ナレッジタグ"
                [knowledgeSummary]="knowledgeSummary"
                (tagsEmitter)="getTags($event)"
              ></app-categories-anchor>
            </div>
          </ng-container>
          <ng-container attachment>
            <app-knowledge-attachment-view
              [knowledgeSummary]="knowledgeSummary"
              [isReadonly]="true"
              (filesEmitter)="getFiles($event)"
            ></app-knowledge-attachment-view>
          </ng-container>
        </app-body-knowledge-detail>
      </div>
    </div>
  </div>

  <div class="detail-post-modal__footer">
    <div class="reaction">
      <app-knowledge-card-footer
        [knowledgeSummary]="knowledgeSummary"
        (close)="close()"
        [totalComment]="totalComment"
        [isCommentActivated]="isCommentActivated"
      ></app-knowledge-card-footer>
    </div>
    <div class="comment" [class.--request]="knowledgeSummary.postType === postType.KNOWLEDGE_REQUEST">
      <app-comment-group
        [currentUserId]="knowledgeSummary.loggedUser.id"
        [postId]="knowledgeSummary.id"
        [knowledgeSummary]="knowledgeSummary"
        (totalCommentRef)="onTotalCommentChange($event)"
      >
      </app-comment-group>
    </div>
  </div>
</div>
