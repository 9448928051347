import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { FeedbackDocumentApiClient } from '../api/feedback-document.api.client';
import { FeedbackDocumentDto } from 'domain/kernel/feedback-document/dto';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDocumentService {
  constructor(private readonly feedbackDocumentApiClient: FeedbackDocumentApiClient) { }

  createFeedbackDocument(feedbackDocumentDto: FeedbackDocumentDto): Observable<FeedbackDocumentDto> {
    return from(this.feedbackDocumentApiClient.createFeedback(feedbackDocumentDto));
  }

  updateFeedbackDocument(feedbackDocumentDto: FeedbackDocumentDto): Observable<FeedbackDocumentDto> {
    return from(this.feedbackDocumentApiClient.updateFeedback(feedbackDocumentDto));
  }
}
