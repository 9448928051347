import * as Sentry from '@sentry/angular';

import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NZ_I18N, ja_JP } from 'ng-zorro-antd/i18n';

import { APP_DOMAIN_SERVICE_PROVIDERS } from './app-domain-service.provider';
import { APP_ENV_PROVIDERS } from './app-env.provider';
import { APP_REPOSITORY_PROVIDERS } from './app-repository.provider';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CreateBoardFolderModalModule } from './infrastructure/modal/create-board-folder-modal/create-board-folder-modal.module';
import { CreateFeedbackModalModule } from './infrastructure/modal/create-feedback-modal/create-feedback-modal.module';
import { CreateKnowledgeModalModule } from './infrastructure/modal/create-knowledge-modal/create-knowledge-modal.module';
import { CreateQuotationModalModule } from './infrastructure/modal/create-quotation-modal/create-quotation-modal.module';
import { CreateRequestModalModule } from './infrastructure/modal/create-request-modal/create-request-modal.module';
import { CustomInterceptor } from './interceptor/custom.interceptor';
import { DetailPostModalModule } from './infrastructure/modal/detail-post-modal/detail-post-modal.module';
import { FirestoreService } from 'frontend/lib/service/gcp/firestore.service';
import { ForwardFeedbackModalModule } from './infrastructure/modal/forward-feedback-modal/forward-feedback-modal.module';
import { IFirestoreService } from 'lib/gcp/service/firestore.service';
import { InviteAccountModalModule } from './infrastructure/modal/invite-account-modal/invite-account-modal.module';
import { LoadingModule } from './infrastructure/atoms/loading/loading.module';
import { NgxMaskModule } from 'ngx-mask';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { QuoteKnowledgeModalModule } from './infrastructure/modal/quote-knowledge-modal/quote-knowledge-modal.module';
import { Router } from '@angular/router';
import { SendFeedbackModalModule } from './infrastructure/modal/send-feedback-modal/send-feedback-modal.module';
import { SentryService } from './service/sentry.service';
import { environment } from '../environments/environment';
import ja from '@angular/common/locales/ja';
import { registerLocaleData } from '@angular/common';

registerLocaleData(ja);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    // Akita: store management
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,

    // angular fire
    AngularFireModule.initializeApp(environment.firebase, environment.firebase.projectId),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,

    // 3rd-lbs
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),

    // TODO: optimal modal NzModal
    NzModalModule,
    NzNotificationModule,
    CreateKnowledgeModalModule,
    CreateRequestModalModule,
    SendFeedbackModalModule,
    DetailPostModalModule,
    QuoteKnowledgeModalModule,
    InviteAccountModalModule,
    ForwardFeedbackModalModule,
    CreateQuotationModalModule,
    CreateFeedbackModalModule,
    CreateBoardFolderModalModule,

    // loading
    LoadingModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: NZ_I18N,
      useValue: ja_JP
    },
    {
      provide: IFirestoreService,
      useClass: FirestoreService
    },
    { provide: PERSISTENCE, useValue: 'local' },
    // env
    ...APP_ENV_PROVIDERS,
    // Repository zone
    ...APP_REPOSITORY_PROVIDERS,
    // Domain Service zone
    ...APP_DOMAIN_SERVICE_PROVIDERS
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
