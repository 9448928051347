import {
  AUTH_API_BASE_URL,
  BATCH_API_ENDPOINT,
  CORS_SITE_DOMAIN,
  ELASTIC_APP_SEARCH_ENDPOINT,
  ELASTIC_APP_SEARCH_SEARCH_KEY,
  HASH_SECRET_KEY,
  SLACK_API_ENDPOINT,
  SYSTEM_DOMAIN
} from "frontend/lib/token";

import { environment } from "../environments/environment";

export const APP_ENV_PROVIDERS = [
  {
    provide: AUTH_API_BASE_URL,
    useValue: environment.api.urls.auth
  },
  {
    provide: CORS_SITE_DOMAIN,
    useValue: environment.corsDomain
  },
  {
    provide: SYSTEM_DOMAIN,
    useValue: environment.systemDomain
  },
  {
    provide: HASH_SECRET_KEY,
    useValue: environment.hashSecretKey
  },
  {
    provide: SLACK_API_ENDPOINT,
    useValue: environment.api.urls.slackBase
  },
  {
    provide: BATCH_API_ENDPOINT,
    useValue: environment.api.urls.batchBase
  },
  {
    provide: ELASTIC_APP_SEARCH_SEARCH_KEY,
    useValue: environment.elasticAppSearch.searchKey
  },
  {
    provide: ELASTIC_APP_SEARCH_ENDPOINT,
    useValue: environment.elasticAppSearch.endpointBase
  },
]
