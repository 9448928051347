import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { faStar, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { EPostType } from 'domain/kernel/post';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import { SlackReactionDomainService } from 'domain/slack/slack-reaction/service';
import { ESlackReactionType } from 'domain/slack/slack-reaction/enum';
import { SlackUserDomainService } from 'domain/slack/slack-user/service';
import { SharingVendorIntegrationDomainService } from 'domain/kernel/sharing-vendor/service';
import * as moment from 'moment';
import { GetDistinctArray } from 'frontend/utl/builder/array-transform.builder';

@Component({
  selector: 'app-statistic-reaction',
  templateUrl: './statistic-reaction.component.html',
  styleUrls: ['./statistic-reaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticReactionComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;
  readonly appData = {
    faUpload: faUpload,
    faStar: faStar
  };

  readonly postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  userReactions = {
    users: [],//SlackUserDto[] =
    displayNameReactions: null,
    total: 0,
    updateAtShortStr: "",
  }

  subscription: Subscription;
  initLoad: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly slackReactionDomainService: SlackReactionDomainService,
    private readonly slackUserDomainService: SlackUserDomainService,
    private readonly sharingVendorIntegrationDomainService: SharingVendorIntegrationDomainService,
  ) {
    super();
  }

  protected override onInit(): void {
    this.subscription = this.init().subscribe(_ => {
      this.bindStatisticReaction();
      this.initLoad = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  appendString(str1: string, str2: string) {
    return str1.concat(str2);
  }

  private init() {

    if (this.knowledgeSummary.postType === EPostType.SHARING_VENDOR) {
      return this.sharingVendorIntegrationDomainService.selectSharingVendorIntegrationBySharingVendorId(this.knowledgeSummary.id)
        .pipe(
          take(1),
          mergeMap(integration =>
            combineLatest([
              this.getTotalReactions(integration.targetId),
              this.getUserReactions(integration.targetId),
              this.getLatestReaction(integration.targetId),
            ])
          )
        );
    }
  }

  private getUserReactions(msgId: string) {
    return this.slackReactionDomainService.selectAllSlackReactionByMessageIdAndReactionType(msgId, ESlackReactionType.STAR, 2)
      .pipe(
        filter(user => user !== null),
        take(2),
        mergeMap(user => this.slackUserDomainService.selectUser(user.userId)),
        filter(user => user !== null),
        tap(user => this.userReactions.users.push(user))
      );
  }

  private getTotalReactions(msgId: string) {
    return this.slackReactionDomainService.countOfSlackReactionByMessageIdAndReactionType(msgId, ESlackReactionType.STAR)
      .pipe(
        take(1),
        tap(counting => this.userReactions.total = counting)
      )
  }

  private getLatestReaction(msgId: string) {
    return this.slackReactionDomainService.selectLatestSlackReactionByMessageIdAndReactionType(msgId, ESlackReactionType.STAR)
      .pipe(
        take(1),
        filter(reaction => reaction !== null),
        tap(reaction => this.userReactions.updateAtShortStr = moment(reaction.createdAt).fromNow()),
      )
  }

  private bindStatisticReaction() {
    let str = '';
    if (this.userReactions.users && this.userReactions.users?.length > 0) {
      this.userReactions.users = GetDistinctArray(this.userReactions.users, 'id');
    }
    if (this.userReactions.total > 0 && this.userReactions.users && this.userReactions.users.length > 0) {
      // Aによって投稿がシェアされました
      if (this.userReactions.total == 1) {
        str = this.userReactions.users[0] && this.userReactions.users[0].name ? `${this.userReactions.users[0].name?.first} ${this.userReactions.users[0].name?.last}によって投稿がシェアされました` : '削除済みユーザ';
        // A, Bによって投稿がシェアされました
      } else if (this.userReactions.total == 2) {
        str = this.userReactions.users.map(user => user && user.name ? `${user.name?.first} ${user.name?.last}` : '削除済みユーザ').join(', ');
        str += 'によって投稿がシェアされました';
        // A, B 他8名によって投稿がシェアされました
      } else {
        str = this.userReactions.users.map(user => user && user.name ? `${user.name?.first} ${user.name?.last}` : '削除済みユーザ').join(', ');
        str += `他${this.userReactions.total - 2}名によって投稿がシェアされました`;
      }
    }
    this.userReactions.displayNameReactions = str;
  }
}
