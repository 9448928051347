import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CollapseComponent } from './collapse.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const components: Type<any>[] = [CollapseComponent];

@NgModule({
  imports: [CommonModule, NzSelectModule, FontAwesomeModule, FormsModule],
  declarations: [components],
  exports: [components],
})
export class CollapseModule { }
