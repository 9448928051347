import { Observable, concat, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IMyKnowledgeRepository } from '../repository';
import { IsDeleted } from '../../../kernel/base/value';
import { KnowledgeId } from '../../knowledge/value';
import { MyKnowledge } from '../entity';
import { MyKnowledgeDto } from '../dto';
import { MyKnowledgeId } from '../value';
import { MyKnowledgeNotFoundError } from '../exception';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class MyKnowledgeDomainService {
  constructor(private readonly myKnowledgeRepository: IMyKnowledgeRepository) { }

  select(id: string) {
    return this.myKnowledgeRepository.select(MyKnowledgeId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectByKnowledgeId(knowledgeId: string) {
    return this.myKnowledgeRepository
      .selectAll(new FirestoreQueryBuilder<MyKnowledge>().equalWhere('knowledgeId', knowledgeId))
      .pipe(map(item => this.convertDto(item)));
  }

  selectForUpdateEs(myKnowledgeId: string) {
    return this.myKnowledgeRepository.selectForUpdateEs(MyKnowledgeId.create(myKnowledgeId)).pipe(
      map(item => item ? this.convertDto(item) : null)
    );
  }

  selectExistsCreatedByAndByKnowledgeId(createdBy: string, knowledgeId: string) {
    return concat(
      this.myKnowledgeRepository.selectAll(
        new FirestoreQueryBuilder<MyKnowledge>().equalWhere('createdBy', createdBy).equalWhere('knowledgeId', knowledgeId)
      ),
      of(null)
    ).pipe(
      take(1),
      tap(myKnowledge => {
        if (myKnowledge === null) {
          throw new MyKnowledgeNotFoundError('myKnowledge is not found');
        }
      }),
      filter(myKnowledge => myKnowledge !== null),
      map(item => this.convertDto(item))
    );
  }

  selectAllByCreatedBy(createdBy: string) {
    return concat(
      this.myKnowledgeRepository.selectAll(
        new FirestoreQueryBuilder<MyKnowledge>().equalWhere('createdBy', createdBy)
      ),
      of(null)
    ).pipe(
      filter(item => item !== null),
      map(item => this.convertDto(item))
    );
  }

  searchMyKnowledge(createdBy: string, fromDate: Date, toDate: Date) {
    const builders = new FirestoreQueryBuilder<MyKnowledge>()

    if (fromDate !== null && toDate !== null) {
      builders.orderBy('createdAt', 'asc')
        .greaterOrEqualThan('createdAt', fromDate)
        .lessOrEqualThan('createdAt', toDate)
        .limit(1000)
        .equalWhere('createdBy', createdBy);
    } else {
      builders.orderBy('createdAt', 'asc')
        .limit(1000)
        .equalWhere('createdBy', createdBy);
    }

    return concat(
      this.myKnowledgeRepository.selectAll(builders),
      of(null)
    ).pipe(
      filter(item => item !== null),
      map(item => this.convertDto(item))
    );
  }

  selectAll() {
    return this.myKnowledgeRepository.selectAll(new FirestoreQueryBuilder<MyKnowledge>()).pipe(map(item => this.convertDto(item)));
  }

  selectAfterLikeAction(createdBy: string, knowledgeId: string) {
    const builder = new FirestoreQueryBuilder<MyKnowledge>().equalWhere('createdBy', createdBy).equalWhere('knowledgeId', knowledgeId)
    return this.myKnowledgeRepository.selectIfExists(builder).pipe(
      take(1),
    );
  }

  // updateAfterLikeAction(createdBy: string, knowledgeId: string, likeChosen: boolean): Observable<any> {
  //   const builder = new FirestoreQueryBuilder<MyKnowledge>().equalWhere('createdBy', createdBy).equalWhere('knowledgeId', knowledgeId)
  //   return this.myKnowledgeRepository.selectIfExists(builder).pipe(
  //     take(1),
  //     mergeMap(myKnowledge => {
  //       if (myKnowledge === null && likeChosen === true) {
  //         return this.insert({
  //           knowledgeId: knowledgeId,
  //           createdBy: createdBy
  //         } as MyKnowledgeDto);
  //       } else if (myKnowledge !== null && likeChosen === true) {
  //         return this.reInsert(myKnowledge.id.value);
  //       }
  //       else if (myKnowledge !== null && likeChosen === false) {
  //         return this.delete(myKnowledge.id.value);
  //       } else {
  //         return of(null);
  //       }
  //     }),
  //   );
  // }

  reInsert(id: string): Observable<MyKnowledgeDto> {
    const myKnowledge = new MyKnowledge(MyKnowledgeId.create(id));
    return this.myKnowledgeRepository.reInsert(myKnowledge).pipe(map(item => this.convertDto(item)));
  }

  insert(proposal: MyKnowledgeDto): Observable<MyKnowledgeDto> {
    const myKnowledge = new MyKnowledge(this.myKnowledgeRepository.generateId());
    myKnowledge.knowledgeId = KnowledgeId.create(proposal.knowledgeId);
    myKnowledge.createdBy = AccountOrganizationId.create(proposal.createdBy);
    return this.myKnowledgeRepository.insert(myKnowledge).pipe(map(item => this.convertDto(item)));
  }

  update(myKnowledgeDto: MyKnowledgeDto) {
    const myKnowledge = new MyKnowledge(MyKnowledgeId.create(myKnowledgeDto.id));
    myKnowledge.knowledgeId = KnowledgeId.create(myKnowledgeDto.knowledgeId);
    myKnowledge.createdBy = AccountOrganizationId.create(myKnowledgeDto.createdBy);
    myKnowledge.createdAt = Timestamp.createByDate(myKnowledgeDto.createdAt);
    myKnowledge.isDeleted = myKnowledgeDto?.isDeleted !== null ? IsDeleted.create(myKnowledgeDto.isDeleted) : IsDeleted.create(false);
    return this.myKnowledgeRepository.update(myKnowledge).pipe(map(item => this.convertDto(item)));
  }

  delete(myKnowledgeId: string) {
    return this.myKnowledgeRepository.delete(MyKnowledgeId.create(myKnowledgeId));
  }

  private convertDto(myKnowledge: MyKnowledge): MyKnowledgeDto {
    return MyKnowledge.allFields.reduce((p, key) => {
      if (myKnowledge[key] === undefined) {
        return p;
      }
      const value = myKnowledge[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as MyKnowledgeDto);
  }
}
