import {
  AssetStorageDownloadUrl,
  AssetStorageId,
  AssetStorageMimeType,
  AssetStorageName,
  AssetStorageSize,
  AssetStorageSlackFileId,
  AssetStorageType
} from '../value';
import { count, map, take } from 'rxjs/operators';

import { AssetStorage } from '../entity';
import { AssetStorageDto } from '../dto';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IAssetStorageRepository } from '../repository';
import { Observable } from 'rxjs';
import { PostId } from '../../post';

export class AssetStorageDomainService {
  constructor(private readonly assetStorageRepository: IAssetStorageRepository) { }

  select(assetStorageId: string) {
    return this.assetStorageRepository.select(AssetStorageId.create(assetStorageId)).pipe(map(item => this.convertDto(item)));
  }

  selectAllAssetStorage(builder = new FirestoreQueryBuilder<AssetStorage>()) {
    return this.assetStorageRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  selectAllByKnowledgeId(knowledgeId: string) {
    return this.assetStorageRepository
      .selectAll(new FirestoreQueryBuilder<AssetStorage>()
        .equalWhere('postId', knowledgeId)
        .equalWhere('isDeleted', false)
        .limit(1000)
      )
      .pipe(map(item => item ? this.convertDto(item) : null));
  }

  countAllByKnowledgeId(knowledgeId: string) {
    return this.assetStorageRepository
      .selectAll(new FirestoreQueryBuilder<AssetStorage>()
        .equalWhere('postId', knowledgeId)
        .equalWhere('isDeleted', false)
      ).pipe(count());
  }

  insert(proposal: AssetStorageDto): Observable<AssetStorageDto> {
    const assetStorage = new AssetStorage(this.assetStorageRepository.generateId());
    assetStorage.postId = PostId.create(proposal.postId);
    assetStorage.downloadUrl = AssetStorageDownloadUrl.create(proposal.downloadUrl);
    assetStorage.mimeType = AssetStorageMimeType.create(proposal.mimeType);
    assetStorage.name = AssetStorageName.create(proposal.name);
    assetStorage.permalink = AssetStorageName.create(proposal.permalink);
    assetStorage.size = AssetStorageSize.create(proposal.size);
    assetStorage.type = AssetStorageType.create(proposal.type);
    assetStorage.slackFileId = proposal?.slackFileId ? AssetStorageSlackFileId.create(proposal.slackFileId) : AssetStorageSlackFileId.create(null);
    return this.assetStorageRepository.insert(assetStorage).pipe(map(item => this.convertDto(item)));
  }

  delete(id: string) {
    return this.assetStorageRepository.delete(AssetStorageId.create(id));
  }

  selectIfExistsByPostId(postId: string) {
    return this.assetStorageRepository
      .selectIfExists(new FirestoreQueryBuilder<AssetStorage>()
        .equalWhere('postId', postId)
        .equalWhere('isDeleted', false)
      )
      .pipe(
        map(item => item ? item : null),
      );
  }

  selectByPostIdAndSlackFileId(postId: string, slackFileId: string): Observable<AssetStorage | null> {
    return this.assetStorageRepository
      .selectIfExists(new FirestoreQueryBuilder<AssetStorage>()
        .equalWhere('postId', postId)
        .equalWhere('slackFileId', slackFileId)
        .equalWhere('isDeleted', false)
      )
      .pipe(
        map(item => item ? item : null),
        take(1)
      );
  }

  private convertDto(assetStorage: AssetStorage): AssetStorageDto {
    return AssetStorage.allFields.reduce((p, key) => {
      if (assetStorage[key] === undefined) {
        return p;
      }
      const value = assetStorage[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as AssetStorageDto);
  }
}
