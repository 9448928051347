import { ButtonModule } from './../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { KnowledgeReplyComponent } from './knowledge-reply.component';

const components: Type<any>[] = [KnowledgeReplyComponent];

@NgModule({
  imports: [CommonModule, ButtonModule, PipesModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeReplyModule { }
