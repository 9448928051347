import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-card-view-header-attachment',
  templateUrl: './card-view-header-attachment.component.html',
  styleUrls: ['./card-view-header-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardViewHeaderAttachmentComponent {

  @Input() files: KnowledgeFileSummary[] = [];
  readonly config = {
    faFileCount: faPaperclip
  }
}
