import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CreateRequestModalState, CreateRequestModalStore } from './create-request-modal.store';

@Injectable({ providedIn: 'root' })
export class CreateRequestModalQuery extends Query<CreateRequestModalState> {
  constructor(protected store: CreateRequestModalStore) {
    super(store);
  }
  selectLoading() {
    return this.select('loading');
  }

  selectFailed() {
    return this.select('failed');
  }

  selectErrMsgs() {
    return this.select('errMsgs');
  }
}
