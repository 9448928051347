import { FeedbackDeliveryReceiverArchived, FeedbackDeliverySenderArchived, FeedbackDeliveryUnread } from '../value';
import { count, distinct, filter, map } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { FeedbackDelivery } from '../entity';
import { FeedbackDeliveryDto } from '../dto';
import { FeedbackDeliveryId } from '../value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFeedbackDeliveryRepository } from '../repository';
import { Observable } from 'rxjs';
import { OrganizationId } from '../../organization/value';

export class FeedbackDeliveryDomainService {
  constructor(private readonly feedbackDeliveryRepository: IFeedbackDeliveryRepository) { }

  select(id: string) {
    return this.feedbackDeliveryRepository.select(FeedbackDeliveryId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectForUpdateEs(id: string) {
    return this.feedbackDeliveryRepository.selectForUpdateEs(FeedbackDeliveryId.create(id)).pipe(map(item => item ? this.convertDto(item) : null));
  }

  selectAll(builder = new FirestoreQueryBuilder<FeedbackDelivery>()) {
    return this.feedbackDeliveryRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  selectAllByFeedbackIdAndReceivedBy(feedbackId: string, receivedBy: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .equalWhere('feedbackId', feedbackId)
        .equalWhere('receivedBy', receivedBy)
        .equalWhere('isDeleted', false)
      )
      .pipe(map(item => this.convertDto(item)));
  }

  selectAllByOrganization(organizationId: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        // .equalWhere('organizationId', organizationId)
        // .equalWhere('isDeleted', false)
        .orderBy('createdAt', 'desc')
        .limit(1000)
      )
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.organizationId === organizationId && item.isDeleted === false),
      );
  }

  selectAllByReceiver(received: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>().equalWhere('receivedBy', received).equalWhere('isDeleted', false))
      .pipe(map(item => this.convertDto(item)));
  }

  selectAllReceiverUnread(received: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .equalWhere('receivedBy', received)
        .equalWhere('unread', true)
        .equalWhere('isDeleted', false))
      .pipe(map(item => this.convertDto(item)));
  }

  countAllReceiverUnread(received: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .equalWhere('receivedBy', received)
        .equalWhere('unread', true)
        .equalWhere('isDeleted', false)
        .equalWhere('receiverArchived', false)
      )
      .pipe(count());
  }

  selectAllBySender(sender: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .equalWhere('sendBy', sender)
        .equalWhere('isDeleted', false))
      .pipe(map(item => this.convertDto(item)));
  }

  selectReceiversyBySenderDesc(sender: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .orderBy('createdAt', 'desc')
        .limit(1000)
        .equalWhere('sendBy', sender)
        .equalWhere('isDeleted', false))
      .pipe(
        filter(fb => fb !== null),
        map(item => this.convertDto(item)),
        map(fb => fb.receivedBy),
        distinct(),
      );
  }

  selectFeebacksBySenderDesc(sender: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .orderBy('createdAt', 'desc')
        .limit(1000)
        .equalWhere('sendBy', sender)
        .equalWhere('isDeleted', false))
      .pipe(
        map(item => this.convertDto(item)),
        filter(fb => fb !== null),
        map(fb => fb.feedbackId),
        distinct(),
      );
  }

  countAllBySender(sender: string) {
    return this.feedbackDeliveryRepository
      .selectAll(new FirestoreQueryBuilder<FeedbackDelivery>()
        .equalWhere('sendBy', sender)
        .equalWhere('isDeleted', false))
      .pipe(count())
  }

  insert(feedbackDeliveryDto: FeedbackDeliveryDto): Observable<FeedbackDeliveryDto> {
    const feedbackDelivery = new FeedbackDelivery(this.feedbackDeliveryRepository.generateId());
    feedbackDelivery.feedbackId = FeedbackDeliveryId.create(feedbackDeliveryDto.feedbackId);
    feedbackDelivery.sendBy = AccountOrganizationId.create(feedbackDeliveryDto.sendBy);
    feedbackDelivery.receivedBy = AccountOrganizationId.create(feedbackDeliveryDto.receivedBy);
    feedbackDelivery.organizationId = OrganizationId.create(feedbackDeliveryDto.organizationId);
    feedbackDelivery.receiverArchived = FeedbackDeliveryReceiverArchived.create(false);
    feedbackDelivery.senderArchived = FeedbackDeliverySenderArchived.create(false);
    feedbackDelivery.unread = FeedbackDeliveryUnread.create(true);
    return this.feedbackDeliveryRepository.insert(feedbackDelivery).pipe(map(item => this.convertDto(item)));
  }

  update(feedbackDeliveryDto: FeedbackDeliveryDto) {
    const feedbackDelivery = new FeedbackDelivery(FeedbackDeliveryId.create(feedbackDeliveryDto.id));
    feedbackDelivery.feedbackId = FeedbackDeliveryId.create(feedbackDeliveryDto.feedbackId);
    feedbackDelivery.sendBy = AccountOrganizationId.create(feedbackDeliveryDto.sendBy);
    feedbackDelivery.receivedBy = AccountOrganizationId.create(feedbackDeliveryDto.receivedBy);
    feedbackDelivery.organizationId = OrganizationId.create(feedbackDeliveryDto.organizationId);
    feedbackDelivery.receiverArchived = FeedbackDeliveryReceiverArchived.create(feedbackDeliveryDto.receiverArchived);
    feedbackDelivery.senderArchived = FeedbackDeliverySenderArchived.create(feedbackDeliveryDto.senderArchived);
    return this.feedbackDeliveryRepository.update(feedbackDelivery).pipe(map(item => this.convertDto(item)));
  }

  updateRead(feedbackDeliveryDto: FeedbackDeliveryDto) {
    const feedbackDelivery = new FeedbackDelivery(FeedbackDeliveryId.create(feedbackDeliveryDto.id));
    feedbackDelivery.feedbackId = FeedbackDeliveryId.create(feedbackDeliveryDto.feedbackId);
    feedbackDelivery.unread = FeedbackDeliveryUnread.create(false);
    return this.feedbackDeliveryRepository.update(feedbackDelivery).pipe(map(item => this.convertDto(item)));
  }

  updateReceiverArchived(id: string) {
    const feedbackDelivery = new FeedbackDelivery(FeedbackDeliveryId.create(id));
    feedbackDelivery.receiverArchived = FeedbackDeliveryReceiverArchived.create(true);
    return this.feedbackDeliveryRepository.update(feedbackDelivery).pipe(map(item => this.convertDto(item)));
  }

  updateSenderArchived(id: string) {
    const feedbackDelivery = new FeedbackDelivery(FeedbackDeliveryId.create(id));
    feedbackDelivery.senderArchived = FeedbackDeliverySenderArchived.create(true);
    return this.feedbackDeliveryRepository.update(feedbackDelivery).pipe(map(item => this.convertDto(item)));
  }

  delete(id: string) {
    return this.feedbackDeliveryRepository.delete(FeedbackDeliveryId.create(id));
  }

  private convertDto(feedbackDelivery: FeedbackDelivery): FeedbackDeliveryDto {
    return FeedbackDelivery.allFields.reduce((p, key) => {
      if (feedbackDelivery[key] === undefined) {
        return p;
      }
      const value = feedbackDelivery[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as FeedbackDeliveryDto);
  }
}
