import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/fontawesome-pro-light';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { WindowRef } from 'frontend/admin/app/shared/services/window-ref.service';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { LoadingService } from './../../../shared/services/loading.service';
import { CreateKnowledgeModalQuery } from './state/create-knowledge-modal.query';
import { CreateKnowledgeModalService } from './state/create-knowledge-modal.service';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { KnowledgeFirestoreService } from 'frontend/admin/app/shared/services/knowledge-firestore.service';
import { EKnowledgeType } from 'domain/kernel/knowledge/enum';

@Component({
  selector: 'app-create-knowledge-modal',
  templateUrl: './create-knowledge-modal.component.html',
  styleUrls: ['./create-knowledge-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateKnowledgeModalComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;

  readonly appData = {
    faTimes: faTimes,
    createBtn: 'ナレッジを作成して投稿',
    updateBtn: 'ナレッジを作成して更新'
  }

  ngSelectTags = {
    tags: [],// CategoryDto[] all categories belong to workspace
    selectedTags: [],// string[] all categoryIds have selected
    // initTags: [], // from ES
    categoryKnowledge: [],
    hideSelected: true,
    // addTag: this.addTagFn,
    multiple: true,
    // isOpen: false
  };

  attachmentFiles = {
    inputFiles: [],
    remainFiles: [],
    files: [],
    fileSizeExceedsLimit: false
  };

  formGroup: FormGroup;
  subscription: Subscription;
  namePostOwner: string = null;
  inProcess: boolean = false;

  public readonly ELimitCharacters = ELimitCharacters;

  constructor(
    readonly appQuery: AppQuery,
    readonly createKnowledgeModalQuery: CreateKnowledgeModalQuery,
    private readonly formBuilder: FormBuilder,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly createKnowledgeModalService: CreateKnowledgeModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly nzModalRef: NzModalRef<CreateKnowledgeModalComponent>,
    private readonly notificationService: NotificationService,
    private readonly _window: WindowRef,
    private readonly _loadingService: LoadingService,
    private readonly knowledgeFirestoreService: KnowledgeFirestoreService
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      body: ['', [Validators.required, Validators.maxLength(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH)]],
    });
  }

  protected override onDestroy(): void {
    this.createKnowledgeModalService.resetStore();
  }

  protected override onInit(): void {
    const currentAccountOrganization = this.appQuery.getValue().currentAccount;
    this.namePostOwner = `${currentAccountOrganization.name?.first} ${currentAccountOrganization.name?.last}`

    this.subscription = this.init().subscribe(rs => {
      if (rs && rs.length > 0) {
        this.attachmentFiles.inputFiles = this.attachmentFiles.remainFiles = rs;
      }

      if (this.knowledgeSummary) {
        this.formGroup.setValue({
          title: this.knowledgeSummary.title || '',
          body: this.knowledgeSummary.body || '',
        });
        const tempArr = this.knowledgeSummary.categories ? this.knowledgeSummary.categories.split(',') : [];
        const initTags = this.ngSelectTags.tags.filter(tag => tempArr.includes(tag.value));
        this.ngSelectTags.selectedTags = initTags ? initTags.map(tag => tag.value) : []
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  close(data?: any) {
    this.nzModalRef.close(data);
  }

  closeValidate() {
    this.createKnowledgeModalService.updateFailed(false, []);
  }

  onSelectedTags(event: any) {
    this.ngSelectTags.selectedTags = event;
  }

  onUploadFiles($event: any): void {
    this.attachmentFiles.files = $event && $event.files;
    this.attachmentFiles.remainFiles = $event && $event.inputFiles;
    this.attachmentFiles.fileSizeExceedsLimit = $event && $event.fileSizeExceedsLimit;
  }

  onUpsertPost() {
    const validForm = this.validateCreateKnowledge();
    if (!validForm.isValid) {
      this.createKnowledgeModalService.updateFailed(true, validForm.errMsgs);
      return;
    }
    if (this.ngSelectTags.selectedTags.length > CommonConstant.ITEM_TAG_MAXIMUM) {
      return;
    }

    this.inProcess = true;
    this._loadingService.setStateLoading(true);

    this.knowledgeSummary ? this.update() : this.insert();
  }

  private insert(): void {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    this.checkFileError();

    const subscription$ = this.knowledgeFirestoreService.create(
      currentAccountOrganization?.id,
      EKnowledgeType.NORMAL,
      this.formGroup.value,
      this.ngSelectTags.selectedTags,
      this.attachmentFiles.files,
    ).pipe(
      tap(knowledge => this.close(knowledge)),
      tap(_ => {
        this.inProcess = false;
        this._loadingService.setStateLoading(false);
      })
    ).subscribe({
      next: () => {
        this.notificationService.open('ナレッジを作成しました。');
      },
      error: err => {
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private update(): void {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    const inputFileIds = this.attachmentFiles.inputFiles.map(file => file.id);
    const remainFileIds = this.attachmentFiles.remainFiles.map(file => file.id)
    const deleteFiles = inputFileIds.filter(n => !remainFileIds.includes(n));
    this.checkFileError();

    const subscription$ = this.knowledgeFirestoreService.update(
      currentAccountOrganization.id,
      EKnowledgeType.NORMAL,
      this.knowledgeSummary.id,
      this.formGroup.value,
      this.ngSelectTags.selectedTags,
      deleteFiles,
      this.attachmentFiles.files
    ).pipe(
      tap(knowledge => this.close(knowledge)),
      tap(_ => {
        this.inProcess = false;
        this._loadingService.setStateLoading(false);
      }),
    ).subscribe({
      next: (rs) => {
        this.notificationService.open('ナレッジを編集しました。');
      },
      error: err => {
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private init() {
    //  load categories
    return this.workspaceQuery.selectOrganization().pipe(
      filter(organization => organization !== null),
      mergeMap(organization => this.knowledgeFirestoreService.getCategoryByOrganizationId(organization.id)),
      tap(result => this.ngSelectTags.tags = result.map(x => ({ value: x.id, label: x.label } as any))),
      mergeMap(_ => this.knowledgeSummary ? this.knowledgeFirestoreService.getFilesByKnowledgeId(this.knowledgeSummary.id) : of(null))
    );
  }

  private validateCreateKnowledge() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }

    return rs;
  }

  private checkFileError(): void {
    if (this.attachmentFiles.files.length > 0) {
      this.attachmentFiles.files.forEach((file: File, i) => {
        if (file.type.includes('image/')) {
          const url = this._window.nativeWindow.URL || this._window.nativeWindow.webkitURL;
          const image = new Image();
          image.onerror = () => {
            this.notificationService.open('ファイルが見つかりません。');
            this.close();
            this.attachmentFiles.files.splice(i, 1);
          };
          image.src = url.createObjectURL(file);
        }
      });
    }
  }
}
