import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MyKnowledgeSearchState, MyKnowledgeSearchStore } from './my-knowledge-search.store';

@Injectable({ providedIn: 'root' })
export class MyKnowledgeSearchQuery extends Query<MyKnowledgeSearchState> {
  constructor(protected store: MyKnowledgeSearchStore) {
    super(store);
  }

  selectKnowledges() {
    return this.select("knowledges");
  }
}
