import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEmailApi } from 'serverside/batch/api/interface/email.api.interface';
import { EmailSendRequest } from 'serverside/batch/api/model/email-send.request';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class SendEmailApiClient implements IEmailApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }

  sendFeedbackEmail(emailRequest: EmailSendRequest): Promise<any> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/email/send-feedback', emailRequest)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }
}
