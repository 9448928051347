import { PrimitiveValueObject } from 'utility/model/primitive-value-object.model';

export class IsDefault extends PrimitiveValueObject<boolean> {
  static create(value: boolean): IsDefault {
    if (value === null || value === undefined) {
      return new IsDefault(false);
    }
    return new IsDefault(value);
  }
}
