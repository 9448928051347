import { Comment } from '../entity';
import { CommentId } from '../value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class ICommentRepository extends IRepository<CommentId, Comment> {
  abstract selectAll(builder: FirestoreQueryBuilder<Comment>): Observable<Comment>;
  abstract countAll(builder: FirestoreQueryBuilder<Comment>): Observable<Comment>;
  abstract selectIfExist(builder: FirestoreQueryBuilder<Comment>): Observable<any>;
}
