import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EActiveCommentType } from 'frontend/admin/app/enum/active-comment.enum';
import { ActiveComment } from 'frontend/admin/app/model/active-comment.model';
import { CommentModel } from 'frontend/admin/app/model/comment.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { CommentService } from '../comment-group/state/comment.service';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent extends BaseComponent {
  @Input() comment!: CommentModel;
  @Input() activeComment!: ActiveComment | null;
  @Input() replies!: CommentModel[];
  @Input() currentUserId!: string;
  @Input() parentId!: string | null;
  @Input() postId!: string;
  @Input() knowledgeSummary!: KnowledgeSummary;

  @Output() setActiveComment = new EventEmitter<ActiveComment | null>();
  @Output() deleteComment = new EventEmitter<string>();
  @Output() addComment = new EventEmitter<{ text: string; parentId: string | null }>();
  @Output() updateComment = new EventEmitter<{ text: string; commentId: string }>();

  canReply: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  activeCommentType = EActiveCommentType;
  replyId: string | null = null;

  commentAttachment: KnowledgeSummary = null;
  isOpenEditor: boolean = false;
  inputFiles: [];
  public loading: boolean = false;

  constructor(
    private readonly commentService: CommentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  protected override onInit(): void {
    if (this.comment) {
      this.loading = true;
      const subscription$ = this.commentService.getAccountOrganization(this.comment?.commentedBy).pipe(
        take(1),
        tap(account => this.updateCommentUser(account)),
      ).subscribe({
        complete: () => {
          this.canReply = Boolean(this.currentUserId);
          this.canEdit = this.currentUserId === this.comment.commentedBy;
          this.canDelete = this.currentUserId === this.comment.commentedBy && this.replies.length === 0;
          this.replyId = this.parentId ? this.parentId : this.comment.id;
          this.commentAttachment = ({
            id: this.comment.id,
            postType: null
          } as KnowledgeSummary);

          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }
      });

      this.addSubscription(subscription$);
    }
  }

  isReplying(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === this.activeCommentType.replying
    );
  }

  isEditing(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === this.activeCommentType.editing &&
      this.isOpenEditor
    );
  }

  onOpenEditor(comment: any) {
    this.isOpenEditor = true;
    this.setActiveComment.emit({ id: comment.id, type: this.activeCommentType.editing })
  }

  cancelEditComment() {
    this.isOpenEditor = false;
    this.setActiveComment.emit(null)
  }

  private updateCommentUser(account: any) {
    if (account) {
      this.comment.displayName = `${account?.name?.first} ${account?.name?.last}`
      this.comment.emailPostOwner = account?.email;
      this.comment.avatarPostOwner = account?.shortImg;
      this.comment.deletedUser = false;
    } else {
      this.comment.deletedUser = true;
    }
  }
}
