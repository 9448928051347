import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ButtonComponent } from './button.component';

const components: Type<any>[] = [ButtonComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class ButtonModule { }
