import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SuggestibleTextComponent } from './suggestible-text.component';

const components: Type<any>[] = [SuggestibleTextComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class SuggestibleTextModule { }
