import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FilesUploadModule } from '../files-upload/files-upload.module';
import { KnowledgeAttachmentViewComponent } from './knowledge-attachment-view.component';

const components: Type<any>[] = [KnowledgeAttachmentViewComponent];

@NgModule({
  imports: [CommonModule, FilesUploadModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeAttachmentViewModule { }
