import { NgModule, Type } from '@angular/core';

import { ButtonModule } from './../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KnowledgeDeleteActionComponent } from './knowledge-delete-action.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';

const components: Type<any>[] = [KnowledgeDeleteActionComponent];

@NgModule({
  imports: [CommonModule, NzDropDownModule, ButtonModule, NzModalModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeDeleteActionModule { }
