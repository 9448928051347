<div class="feedback-select-receiver" *ngIf="initLoad">
  <ng-container *ngIf="workspaceQuery.selectMembers() | async as users">
    <div class="feedback-select-receiver-container" *ngIf="users && users.length > 0">
      <ng-container *ngFor="let user of users">
        <ng-container *ngIf="user && user.name">
          <div class="feedback-select-receiver-container__item" (click)="onSelectUser(user); $event.stopPropagation()">
            <div class="feedback-select-receiver-container__item-avatar">
              <app-avatar [altText]="user.email" [source]="user.shortImg" [width]="66" [height]="66"></app-avatar>
              <ng-container *ngIf="isSelectedUSer(user)">
                <div class="user-selected">
                  <fa-icon [icon]="faCheck"></fa-icon>
                </div>
              </ng-container>
            </div>
            <div class="feedback-select-receiver-container__item-name">
              {{ user?.name && user.name?.first }} {{ user?.name && user.name?.last }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
