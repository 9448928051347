import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SendInvitationEmailApiClient } from '../api/send-invitation-email.api.client';
import { AccountOrganizationInvitationEmailSendRequest } from 'serverside/batch/api/model/account-organization-invitation-email-send.request';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendInvitationEmailService {
  constructor(private readonly sendInvitationEmailApiClient: SendInvitationEmailApiClient) { }

  sendInvitation(accountOrganizationInvitationEmailSendRequest: AccountOrganizationInvitationEmailSendRequest): Observable<string> {
    return from(this.sendInvitationEmailApiClient.sendInvitationEmail(accountOrganizationInvitationEmailSendRequest));
  }
}
