import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuoteComponent } from './quote.component';

const components: Type<any>[] = [QuoteComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class QuoteModule { }
