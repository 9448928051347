import { AccountAuthority, AccountEmail, AccountId, AccountImg, AccountName, AccountPhone, AccountUid } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { Account } from '../../entity';
import { AccountNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IAccountRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class AccountFirestoreRepository implements IAccountRepository {
  private static readonly collectionId = 'account';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: AccountId): Observable<Account> {
    return this.firestoreService.getDocument(AccountFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new AccountNotFoundError(`account id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<Account>): Observable<Account> {
    return this.firestoreService.getCollection(AccountFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(AccountFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: Account): Observable<Account> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(AccountFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(account: Account): Observable<Account> {
    account.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(AccountFirestoreRepository.collectionId, account.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new AccountNotFoundError(`account id「${account.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(AccountFirestoreRepository.collectionId, this.convertToMap(account));
      }),
      map(_ => account)
    );
  }

  delete(id: AccountId): Observable<void> {
    const proposal = new Account(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(AccountFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  sendEmail(receiver: string, subject: string, html: string) {
    return this.firestoreService.sendEmail(receiver, subject, html);
  }

  generateId(): AccountId {
    return AccountId.create(this.firestoreService.generateId());
  }

  private convertToMap(account: Account): object {
    return Account.allFields.reduce((p, key) => {
      if (account[key] === undefined) {
        return p;
      }
      const value = account[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const account = new Account(AccountId.create(item.id));
    account.uid = AccountUid.create(item.uid);
    account.email = AccountEmail.create(item.email);
    account.phone = item.phone ? AccountPhone.create(item.phone) : undefined;
    account.name = AccountName.create(item.name);
    account.img = AccountImg.create(item.img);
    account.shortImg = AccountImg.create(item.shortImg);
    account.authority = AccountAuthority.create(item.authority);
    account.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    account.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    account.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return account;
  }
}
