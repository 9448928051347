import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { CommentFormModule } from '../comment-form/comment-form.module';
import { CommentGroupComponent } from './comment-group.component';
import { CommentModule } from '../comment/comment.module';

const components: Type<any>[] = [CommentGroupComponent];

@NgModule({
  imports: [CommonModule, CommentModule, CommentFormModule],
  declarations: [components],
  exports: [components],
})
export class CommentGroupModule { }
