import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EIntegrationStatus } from '../enum/integration-status.enum';

export class IntegrationStatus extends PrimitiveValueObject<EIntegrationStatus> {
  static create(value: EIntegrationStatus): IntegrationStatus {
    if (value === null) {
      return new IntegrationStatus(EIntegrationStatus.ACTIVE)
    }
    return new IntegrationStatus(value);
  }
}
