<div class="board">
  <ng-container *ngIf="isOpenBoard">
    <ng-template *ngIf="isDetailFilter; then detailFilter; else simpleFilter"> </ng-template>
    <div *ngIf="dashboardModeView === EDashboardVisualization.TIMELINE_VALUE" class="board__message">
      <span>フィルター設定中…</span>
    </div>
  </ng-container>
  <ng-template #simpleFilter>
    <form class="form" [formGroup]="formGroup">
      <div class="board__header">
        <div class="title">
          <div>
            <app-text type="text" [value]="formGroup.value.boardName" formControlName="boardName" placeholder="#タイトルを入力"></app-text>
          </div>
        </div>
        <!-- addition feature delete a board filter -->
        <div class="action">
          <ng-container *ngIf="isEditForm; else readOnly">
            <app-board-delete-action [board]="board" (close)="close.emit()"></app-board-delete-action>
          </ng-container>
          <ng-template #readOnly>
            <fa-icon [icon]="appData.faTimes" (click)="onCloseModal()"></fa-icon>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          formGroup.controls['boardName'].invalid && (formGroup.controls['boardName'].dirty || formGroup.controls['boardName'].touched)
        "
        class="group__error"
      >
        <p *ngIf="formGroup.controls['boardName'].errors.required" class="error"> {{ MessagesUtils.required('タイトル') }} </p>
        <p *ngIf="formGroup.controls['boardName'].errors.maxlength" class="error"> {{ MessagesUtils.max(50) }} </p>
      </div>

      <div class="board__body">
        <div class="flash" *ngIf="isFailed">
          <div class="flash__wrapper">
            <div *ngFor="let errMsg of errMsgs">
              <p>{{ errMsg }}</p>
            </div>
            <div class="flash__button" (click)="closeValidate()">
              <fa-icon [icon]="appData.faTimes"></fa-icon>
            </div>
          </div>
        </div>
        <div class="form__body">
          <div class="form-group form-group-small">
            <!-- post type  -->
            <div class="item type">
              <label>カテゴリー</label>
              <app-select-checkbox [dataSource]="ngSelectPostType.tags" (selected)="onSelectedPostType($event)"> </app-select-checkbox>
            </div>
            <!-- knowledge resource - writer -->
            <div class="item">
              <label>メンバー</label>
              <app-search-user-autocomplete
                [data]="ngSelectMemberInclude.tags"
                [initValue]="ngSelectMemberInclude.selectedTags"
                nameKey="設定なし"
                placeholderInput="メンバーを検索"
                (selectItem)="onSelectedWriters($event)"
              ></app-search-user-autocomplete>
            </div>
            <!-- keyword search-->
            <div class="item">
              <label>キーワード</label>
              <app-text type="text" [value]="formGroup.value.keyword" formControlName="keyword" placeholder="設定なし"> </app-text>
            </div>
          </div>
        </div>
      </div>
      <div class="board__footer">
        <a class="board__footer-add" (click)="onSwitchToBoardDetail()">
          <fa-icon [icon]="appData.faExchange"></fa-icon>
          <span class="text">条件を追加</span>
        </a>
        <div class="board__footer-action">
          <app-button class="btn-save" [disabled]="inProcess" (clickButton)="onUpsertBoard()">
            {{ board ? appData.updateBtn : appData.createBtn }}
          </app-button>
          <span class="close">
            <fa-icon [icon]="appData.faChevronUp" (click)="onCloseModal()"></fa-icon>
          </span>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #detailFilter>
    <form class="form" [formGroup]="formGroup">
      <div class="board__header">
        <div class="title">
          <div>
            <app-text type="text" [value]="formGroup.value.boardName" formControlName="boardName" placeholder="#タイトルを入力"></app-text>
          </div>
        </div>
        <span class="action">
          <ng-container *ngIf="isEditForm; else readOnly">
            <app-board-delete-action [board]="board" (close)="close.emit()"></app-board-delete-action>
          </ng-container>
          <ng-template #readOnly>
            <fa-icon [icon]="appData.faTimes" (click)="onCloseModal()"></fa-icon>
          </ng-template>
        </span>
      </div>
      <div class="board__body">
        <div class="flash" *ngIf="isFailed">
          <div class="flash__wrapper">
            <div *ngFor="let errMsg of errMsgs">
              <p>{{ errMsg }}</p>
            </div>
            <div class="flash__button" (click)="closeValidate()">
              <fa-icon [icon]="appData.faTimes"></fa-icon>
            </div>
          </div>
        </div>
        <div class="form__body">
          <div class="form-group">
            <!-- post type  -->
            <div class="item post-type">
              <label>カテゴリー</label>
              <app-select-checkbox [dataSource]="ngSelectPostType.tags" (selected)="onSelectedPostType($event)"> </app-select-checkbox>
            </div>
            <!-- knowledge post of member -->
            <div class="item member custom--member">
              <app-collapse [isCollapsed]="false" [disable]="true">
                <ng-container header>
                  <span class="collapse-header-name">メンバー</span>
                </ng-container>
                <ng-container body>
                  <!-- knowledge resource - writer -->
                  <div class="app-collapse__body-item">
                    <label>フォロー</label>

                    <app-search-user-autocomplete
                      [data]="ngSelectMemberInclude.tags"
                      [initValue]="ngSelectMemberInclude.selectedTags"
                      nameKey="設定なし"
                      placeholderInput="メンバーを検索"
                      (selectItem)="onSelectedWriters($event)"
                    ></app-search-user-autocomplete>
                  </div>
                  <div class="app-collapse__body-item">
                    <label>表示しない</label>

                    <app-search-user-autocomplete
                      [data]="ngSelectMemberNotInclude.tags"
                      [initValue]="ngSelectMemberNotInclude.selectedTags"
                      nameKey="設定なし"
                      placeholderInput="メンバーを検索"
                      (selectItem)="onSelectMemberNotInclude($event)"
                    ></app-search-user-autocomplete>
                  </div>
                </ng-container>
              </app-collapse>
            </div>
            <div class="item keyword has-sub">
              <app-collapse [isCollapsed]="false" [disable]="true">
                <ng-container header>
                  <span class="collapse-header-name">キーワード</span>
                </ng-container>
                <ng-container body>
                  <div class="app-collapse__body-item">
                    <div class="app-collapse__body-item-input">
                      <label>含む</label>
                      <app-text type="text" [value]="formGroup.value.keyword" formControlName="keyword" placeholder="設定なし"></app-text>
                    </div>
                    <app-and-or-condition
                      [initValue]="keywordLogicOperator"
                      (selectItem)="onChangeKeywordLogicOption($event)"
                    ></app-and-or-condition>
                  </div>
                  <div class="app-collapse__body-item">
                    <label>含まない</label>
                    <app-text
                      type="text"
                      [value]="formGroup.value.keywordNotInclude"
                      formControlName="keywordNotInclude"
                      placeholder="設定なし"
                    ></app-text>
                  </div>
                </ng-container>
              </app-collapse>
            </div>
            <div class="item has-sub">
              <app-collapse [isCollapsed]="false" [disable]="true">
                <ng-container header>
                  <span class="collapse-header-name">タグ</span>
                </ng-container>
                <ng-container body>
                  <div class="app-collapse__body-item">
                    <!-- add tags -->
                    <div class="app-collapse__body-item-input">
                      <label>含む</label>

                      <app-search-tag-autocomplete
                        [data]="ngSelectTags.tags"
                        [initValue]="ngSelectTags.selectedTags"
                        (selectItem)="onSelectedTags($event)"
                      >
                      </app-search-tag-autocomplete>
                    </div>
                    <app-and-or-condition
                      [initValue]="categoriesLogicOperator"
                      (selectItem)="onChangeTagLogicOption($event)"
                    ></app-and-or-condition>
                  </div>
                </ng-container>
              </app-collapse>
            </div>
            <!-- date range -->
            <div class="item date-range">
              <label>日時範囲</label>
              <span *ngIf="!isShowDatePicker" class="placeholder" (click)="onToggleDatePicker()">設定なし</span>
              <app-datepicker
                *ngIf="isShowDatePicker"
                type="dateRange"
                [size]="'small'"
                [start]="start"
                [end]="end"
                (onChange)="onChangeRange($event)"
              >
              </app-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="board__footer">
        <a class="board__footer-add" (click)="onSwitchToBoardDetail()">
          <fa-icon [icon]="appData.faExchange"></fa-icon>
          <span class="text">シンプル検索</span>
        </a>
        <div class="board__footer-action">
          <app-button class="btn-save" [disabled]="inProcess" (clickButton)="onUpsertBoard()">
            {{ board ? appData.updateBtn : appData.createBtn }}
          </app-button>
          <span class="close">
            <fa-icon [icon]="appData.faChevronUp" (click)="onCloseModal()"></fa-icon>
          </span>
        </div>
      </div>
    </form>
  </ng-template>
</div>
