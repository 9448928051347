import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { SLACK_API_ENDPOINT } from '../token';
import { EMPTY } from 'rxjs';

import { IUserTokenApi } from 'serverside/slackbot/api/interface/user-token.api.interface';
import { GenerateTokenStateRequest } from 'serverside/slackbot/api/model/generate-token-state.request.model';
import { GenerateTokenStateResponse } from 'serverside/slackbot/api/model/generate-token-state.response.model';
import { DecodeTokenStateRequest } from 'serverside/slackbot/api/model/decode-token-state.request.model';
import { DecodeTokenStateReponse } from 'serverside/slackbot/api/model/decode-token-state.reponse.model';

@Injectable({
  providedIn: 'root'
})
export class UserTokenApiClient implements IUserTokenApi {
  constructor(private readonly http: HttpClient, @Inject(SLACK_API_ENDPOINT) private readonly slackApiEndpoint: string) { }

  generateToken(generateTokenStateRequest: GenerateTokenStateRequest): Promise<GenerateTokenStateResponse> {
    return this.http
      .post(this.slackApiEndpoint + '/v1/user/token/generate', generateTokenStateRequest)
      .pipe(
        map(response => response as GenerateTokenStateResponse),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

  decodeToken(token: DecodeTokenStateRequest): Promise<DecodeTokenStateReponse> {
    return this.http
      .post(this.slackApiEndpoint + '/v1/user/token/decode', token)
      .pipe(
        map(response => response as DecodeTokenStateReponse),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }
}
