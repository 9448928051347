import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as moment from 'moment';
moment.locale('ja');

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({ resettable: true });

Sentry.init({
  dsn: 'https://0dac05fe3fc44ebe90772f462d97d369@o458753.ingest.sentry.io/5829563',
  environment: environment.production ? 'Production' : 'Development',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', `https://${environment.firebase.projectId}.web.app`]
    })
  ],
  maxBreadcrumbs: 50,
  tracesSampleRate: 1.0
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
