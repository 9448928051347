import { ButtonModule } from './../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { KnowledgeQuotationComponent } from './knowledge-quotation.component';

const components: Type<any>[] = [KnowledgeQuotationComponent];

@NgModule({
  imports: [CommonModule, ButtonModule, PipesModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeQuotationModule { }
