<div class="comment-group">
  <div class="comment-group__wrapper" id="commentBoxId">
    <ng-container *ngIf="!isLoading">
      <div *ngIf="!!!totalComment" class="comment-group-statistic">
        <div class="comment-group-statistic-no-comment-text">コメントはありません</div>
      </div>
    </ng-container>
    <div class="comment-group-list">
      <div class="comment-group-list-container">
        <app-comment
          *ngFor="let rootComment of getRootComments()"
          [comment]="rootComment"
          [replies]="getReplies(rootComment.id)"
          [activeComment]="activeComment"
          (setActiveComment)="setActiveComment($event)"
          (addComment)="addComment($event)"
          (deleteComment)="deleteComment($event)"
          (updateComment)="updateComment($event)"
          [currentUserId]="currentUserId"
          [postId]="postId"
          [knowledgeSummary]="knowledgeSummary"
        >
        </app-comment>
      </div>
    </div>
  </div>
</div>
<app-comment-form (handleSubmit)="addComment({ comment: $event, parentId: null })"> </app-comment-form>
