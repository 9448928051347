import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { FeedbackBody, FeedbackId, FeedbackKnowledgeId, FeedbackTitle, FeedbackType } from '../value';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../base/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class Feedback implements Entity {
  @Column()
  readonly id: FeedbackId;
  @Column()
  sendBy: AccountOrganizationId;
  @Column()
  title: FeedbackTitle;
  @Column()
  body: FeedbackBody;
  @Column()
  type: FeedbackType;
  @Column()
  knowledgeId?: FeedbackKnowledgeId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: FeedbackId) {
    this.id = id;
  }

  static get allFields(): (keyof Feedback)[] {
    return getColumns(new Feedback(FeedbackId.create('')));
  }

  equals(value: Feedback): boolean {
    return this.id.equals(value.id);
  }
}
