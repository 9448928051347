import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { CategoryDomainService } from 'domain/kernel/category/service';
import { DateRange } from 'frontend/lib/model/date-range.model';
import { Injectable } from '@angular/core';
import { MyKnowledgeSearchRequest } from 'frontend/admin/app/model/my-knowledge-search-request.model';
import { MyKnowledgeSearchStore } from './my-knowledge-search.store';
import { MyKnowledgeSummary } from 'frontend/admin/app/model/my-knowledge-summary.model';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';
import { ESortOptions } from 'frontend/admin/app/enum/sort-options.enum';
import { convertToMyKnowledgeSummary } from 'frontend/utl/generate/my-knowledge-summary.generate';
import { AccountOrganization } from "frontend/lib/model/account-organization.model";
import { MyKnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/my-knowledge-elastic.service';

@Injectable({ providedIn: 'root' })
export class MyKnowledgeSearchService {
  constructor(
    private readonly categoryDomainService: CategoryDomainService,
    private readonly workspaceService: WorkspaceService,
    private readonly myKnowledgeSearchStore: MyKnowledgeSearchStore,
    private readonly myKnowledgeElasticsearchService: MyKnowledgeElasticsearchService,
  ) { }

  public fetch(organizationId: string): Observable<any> {
    return combineLatest([
      this.loadCategories(organizationId),
      this.workspaceService.fetchSuspendedAndActiveMembers(organizationId)
    ]).pipe(
      map(result => ({ categories: result[0], accountOrganizations: result[1] } as any))
    )
  }

  public searchMyKnowledge(createdBy: AccountOrganization, keyword: string, creators: string[], tags: string[], dataRange: DateRange, currentPageIndex: number, pageSize: number) {
    const query = keyword.trim() || '';
    const opt = {
      search_fields: { title: {}, body: {}, },
      query: keyword,
      filters: this.genFilterQueryES(createdBy.id, creators, tags, dataRange),
      sort: [{ updated_at: ESortOptions.DESC }],
      page: {
        size: pageSize,
        current: currentPageIndex
      }
    };
    return this.myKnowledgeElasticsearchService.search(query, opt)
      .pipe(
        tap(search => this.updateMaxPage(search.page.total_pages)),
        tap(search => this.updateTotalRecords(search.page.total_results)),
        mergeMap(search => search.rawResults),
        map(rs => convertToMyKnowledgeSummary(rs, createdBy)),
        toArray(),
      );
  }

  private genFilterQueryES(createdBy: string, knowledgeCreators: string[], tags: string[], dataRange: DateRange) {
    const filters: any = { all: [{ created_by: createdBy }, { is_deleted: 'false' }] };

    // knowledgeCreators
    if (knowledgeCreators?.length > 0) {
      filters.all.push({ send_by: knowledgeCreators })
    }

    // tags
    if (tags?.length > 0) {
      filters.all.push({ category: tags })
    }

    // dataRange
    let dateRangeCondition = {}
    if (dataRange?.from) {
      dateRangeCondition['from'] = dataRange.from;
    }
    if (dataRange?.to) {
      dateRangeCondition['to'] = dataRange.to;
    }
    if (Object.keys(dateRangeCondition).length > 0) {
      filters.all.push({ created_at: { ...dateRangeCondition } });
    }

    return filters;
  }

  // Akita
  public updatecurrent(curentPage: number) {
    if (curentPage >= 0) {
      this.myKnowledgeSearchStore.update({ current: curentPage });
    }
  }

  public updateMaxPage(maxPage: number) {
    if (maxPage >= 0) {
      this.myKnowledgeSearchStore.update({ max: maxPage });
    }
  }

  public updateTotalRecords(total: number) {
    if (total >= 0) {
      this.myKnowledgeSearchStore.update({ total });
    }
  }

  public updatePagination(totalRecords: number, pageSize: number) {
    if (totalRecords >= 0) {
      this.myKnowledgeSearchStore.update({
        total: totalRecords,
        max: Math.ceil(totalRecords / pageSize)
      });
    }
  }

  public updateMyKnowledgeSummary(proposal: MyKnowledgeSummary[]) {
    this.myKnowledgeSearchStore.update({ knowledges: proposal });
  }

  public appendMyKnowledgeSummary(proposal: MyKnowledgeSummary[]) {
    if (proposal?.length > 0) {
      const { knowledges } = this.myKnowledgeSearchStore.getValue();
      this.myKnowledgeSearchStore.update({ knowledges: proposal.concat(knowledges) });
    }
  }

  public updateSearchRequest(proposal: MyKnowledgeSearchRequest) {
    this.myKnowledgeSearchStore.update({ filterRequest: proposal });
  }

  public updateMyKnowledge(knowledgeId: string, props: any) {
    const currentSummaries = this.myKnowledgeSearchStore.getValue().knowledges;
    if (currentSummaries?.length > 0) {
      const index = currentSummaries.findIndex(item => item.knowledgeId === knowledgeId);
      if (index >= 0) {
        Object.keys(props).map(key => {
          currentSummaries[index] = { ...currentSummaries[index], [key]: props[key] };
        });
        this.myKnowledgeSearchStore.update({ knowledges: [...currentSummaries] });
      }
    }
  }

  public popMyKnowledgeSummary(knowledgeId: string) {
    const { knowledges } = this.myKnowledgeSearchStore.getValue();
    this.myKnowledgeSearchStore.update({ knowledges: [...knowledges.filter(item => item.knowledgeId !== knowledgeId)] });
  }

  public resetStore() {
    this.myKnowledgeSearchStore.reset()
  }

  private loadCategories(organizationId: string): Observable<any[]> {
    return this.categoryDomainService.selectAllByOrganizationId(organizationId).pipe(toArray());
  }
}
