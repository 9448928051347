import { EPostType } from '../../post';
import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgePostType extends PrimitiveValueObject<EPostType> {
  static create(value: EPostType): KnowledgePostType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new KnowledgePostType(value);
  }
}
