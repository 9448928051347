import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';

import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-knowledge-card-footer',
  templateUrl: './knowledge-card-footer.component.html',
  styleUrls: ['./knowledge-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeCardFooterComponent extends BaseComponent {
  subscription: Subscription;
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() allowQuote: boolean = true;
  @Input() isShowEditor: boolean = true;
  @Input() totalComment: number = 0;
  @Input() isCommentActivated = false;

  @Output() showDetail = new EventEmitter();
  @Output() close = new EventEmitter();

  appData = {
    faComment: faComment,
  }
  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  isCommentOpened: boolean = false;
  isShowQuote: boolean = false;

  constructor(
    readonly workspaceQuery: WorkspaceQuery,
  ) {
    super();
  }

  protected override onDestroy(): void {
    // this.subscription.unsubscribe();
  }

  protected override onInit(): void {
    this.isShowQuote = this.knowledgeSummary.postType === this.postType.KNOWLEDGE && this.allowQuote === true;

    // this.subscription = this.commentService.getCommentByPost(this.knowledgeSummary)
    //   .pipe(tap(comments => this.totalComments = comments && comments.length > 0 ? comments.length : null))
    //   .subscribe({ complete: () => this.changeDetectorRef.detectChanges() });
  }

  onDisplayCommentBox($event: any) {
    $event.preventDefault();
    this.isCommentOpened = true;
  }
}
