import { Category } from '../entity';
import { CategoryId } from '../value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class ICategoryRepository extends IRepository<CategoryId, Category> {
  abstract selectIfExists(builder: FirestoreQueryBuilder<Category>): Observable<any>;
  abstract selectAll(builder: FirestoreQueryBuilder<Category>): Observable<Category>;
}
