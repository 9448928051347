import { KnowledgeBody, KnowledgeCategory, KnowledgeId, KnowledgeQuotationTargetId, KnowledgeTitle, KnowledgeType } from '../value';

import { AccountOrganizationId } from '../../account/value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IKnowledgeRepository } from '../repository';
import { Knowledge } from '../entity';
import { KnowledgeDto } from '../dto';
import { Observable } from 'rxjs';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { map } from 'rxjs/operators';

export class KnowledgeDomainService {
  constructor(private readonly knowledgeRepository: IKnowledgeRepository) { }

  select(knowledgeId: string) {
    return this.knowledgeRepository.select(KnowledgeId.create(knowledgeId)).pipe(map(item => this.convertDto(item)));
  }

  selectForUpdateEs(knowledgeId: string) {
    return this.knowledgeRepository.selectForUpdateEs(KnowledgeId.create(knowledgeId)).pipe(map(item => item ? this.convertDto(item) : null));
  }

  insertKnowledge(proposal: KnowledgeDto): Observable<KnowledgeDto> {
    const knowledge = new Knowledge(this.knowledgeRepository.generateId());
    knowledge.sendBy = AccountOrganizationId.create(proposal.sendBy);
    knowledge.registeredBy = proposal?.registeredBy ? AccountOrganizationId.create(proposal.registeredBy) : AccountOrganizationId.create('');
    knowledge.title = KnowledgeTitle.create(proposal.title);
    knowledge.body = KnowledgeBody.create(proposal.body);
    knowledge.type = KnowledgeType.create(proposal.type);
    knowledge.category = proposal?.category ? KnowledgeCategory.create(proposal.category) : KnowledgeCategory.create('');
    knowledge.quotationTargetId = proposal.quotationTargetId ? KnowledgeQuotationTargetId.create(proposal.quotationTargetId) : KnowledgeQuotationTargetId.create('');
    knowledge.wroteAt = Timestamp.createByMillsec(Date.now());
    return this.knowledgeRepository.insert(knowledge).pipe(map(item => this.convertDto(item)));
  }

  selectAllKnowledge() {
    return this.knowledgeRepository.selectAll(new FirestoreQueryBuilder<Knowledge>()).pipe(map(item => this.convertDto(item)));
  }

  updateKnowledge(proposal: KnowledgeDto) {
    const knowledge = new Knowledge(KnowledgeId.create(proposal.id));
    knowledge.sendBy = AccountOrganizationId.create(proposal.sendBy);
    knowledge.registeredBy = proposal?.registeredBy ? AccountOrganizationId.create(proposal.registeredBy) : AccountOrganizationId.create('');
    knowledge.title = KnowledgeTitle.create(proposal.title);
    knowledge.body = KnowledgeBody.create(proposal.body);
    knowledge.type = KnowledgeType.create(proposal.type);
    knowledge.category = proposal?.category ? KnowledgeCategory.create(proposal.category) : KnowledgeCategory.create('');
    knowledge.quotationTargetId = proposal.quotationTargetId ? KnowledgeQuotationTargetId.create(proposal.quotationTargetId) : KnowledgeQuotationTargetId.create('');
    return this.knowledgeRepository.update(knowledge).pipe(map(item => this.convertDto(item)));
  }

  migrateKnowledge(proposal: KnowledgeDto) {
    const knowledge = new Knowledge(KnowledgeId.create(proposal.id));
    knowledge.sendBy = AccountOrganizationId.create(proposal.sendBy);
    knowledge.registeredBy = AccountOrganizationId.create(proposal.registeredBy);
    knowledge.title = KnowledgeTitle.create(proposal.title);
    knowledge.body = KnowledgeBody.create(proposal.body);
    knowledge.type = KnowledgeType.create(proposal.type);
    knowledge.category = proposal?.category ? KnowledgeCategory.create(proposal.category) : KnowledgeCategory.create('');
    knowledge.quotationTargetId = proposal.quotationTargetId ? KnowledgeQuotationTargetId.create(proposal.quotationTargetId) : KnowledgeQuotationTargetId.create('');
    knowledge.wroteAt = Timestamp.createByMillsec(Date.now());
    return this.knowledgeRepository.insert(knowledge).pipe(map(item => this.convertDto(item)));
  }

  deleteKnowledge(knowledgeId: string) {
    return this.knowledgeRepository.delete(KnowledgeId.create(knowledgeId)).pipe();
  }

  private convertDto(knowledge: Knowledge): KnowledgeDto {
    return Knowledge.allFields.reduce((p, key) => {
      if (knowledge[key] === undefined) {
        return p;
      }
      const value = knowledge[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as KnowledgeDto);
  }
}
