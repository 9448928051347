import { FeedbackDelivery } from '../entity';
import { FeedbackDeliveryId } from '../value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class IFeedbackDeliveryRepository extends IRepository<FeedbackDeliveryId, FeedbackDelivery> {
  abstract selectAll(builder: FirestoreQueryBuilder<FeedbackDelivery>): Observable<FeedbackDelivery>;
  abstract selectForUpdateEs(id: FeedbackDeliveryId): Observable<FeedbackDelivery> | null;
}
