import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EAccountOrganizationStatus } from '../enum/account-organization-status.enum';

export class AccountOrganizationStatus extends PrimitiveValueObject<EAccountOrganizationStatus> {
  static create(value: EAccountOrganizationStatus): AccountOrganizationStatus {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new AccountOrganizationStatus(value);
  }
}
