// tslint:disable:no-any
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { SharingVendorId } from '../../sharing-vendor/value';
import { SharingVendorIntegrationDto } from '../dto';
import { SharingVendorIntegration } from '../entity';
import { ISharingVendorIntegrationRepository } from '../repository';
import { EIntegrationTargetType } from '../../integration/enum';
import { TargetId, TargetType } from '../../integration/value';

export class SharingVendorIntegrationDomainService {
  constructor(private readonly sharingVendorIntegrationRepository: ISharingVendorIntegrationRepository) { }

  selectSharingVendorIntegrationByTargetId(targetId: string) {
    return this.sharingVendorIntegrationRepository
      .selectAll(new FirestoreQueryBuilder<SharingVendorIntegration>().equalWhere('targetId', targetId))
      .pipe(
        take(1),
        map(item => this.convertDto(item))
      );
  }

  selectSharingVendorIntegrationBySharingVendorId(sharingVendorId: string) {
    return this.sharingVendorIntegrationRepository
      .selectAll(new FirestoreQueryBuilder<SharingVendorIntegration>().equalWhere('sharingVendorId', sharingVendorId)).pipe(
        take(1),
        map(item => this.convertDto(item))
      );
  }

  insertSharingVendorIntegration(sharingVendorId: string, targetId: string, targetType: EIntegrationTargetType): Observable<SharingVendorIntegrationDto> {
    const sharingVendorIntegration = new SharingVendorIntegration(this.sharingVendorIntegrationRepository.generateId());
    sharingVendorIntegration.sharingVendorId = SharingVendorId.create(sharingVendorId);
    sharingVendorIntegration.targetId = TargetId.create(targetId);
    sharingVendorIntegration.targetType = TargetType.create(targetType);
    return this.sharingVendorIntegrationRepository.insert(sharingVendorIntegration).pipe(map(item => this.convertDto(item)));
  }

  deleteAllSharingVendorIntegrationByTargetId(targetId: string) {
    return this.sharingVendorIntegrationRepository
      .selectAll(new FirestoreQueryBuilder<SharingVendorIntegration>().equalWhere('targetId', targetId))
      .pipe(mergeMap(sharingVendorIntegration => this.sharingVendorIntegrationRepository.delete(sharingVendorIntegration.id)));
  }

  private convertDto(sharingVendorIntegration: SharingVendorIntegration): SharingVendorIntegrationDto {
    return SharingVendorIntegration.allFields.reduce((p, key) => {
      if (sharingVendorIntegration[key] === undefined) {
        return p;
      }
      const value = sharingVendorIntegration[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as SharingVendorIntegrationDto);
  }
}
