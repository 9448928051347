import * as moment from "moment";
import { KnowledgeDocumentBody, KnowledgeDocumentCommentTotal, KnowledgeDocumentConverted, KnowledgeDocumentId, KnowledgeDocumentQuotationTargetId, KnowledgeDocumentReactionTotal, KnowledgeDocumentTitle } from "domain/kernel/knowledge-document/value";
import { KnowledgePostType, KnowledgeType } from "domain/kernel/knowledge/value";
import { AccountOrganization } from "frontend/lib/model/account-organization.model";
import { AccountOrganizationId } from "domain/kernel/account/value";
import { CategoryLabel } from "domain/kernel/category/value";
import { EDateFormat } from "frontend/admin/app/enum/date-format.enum";
import { EKnowledgeType } from "domain/kernel/knowledge/enum";
import { EPostType } from "domain/kernel/post";
import { KnowledgeSummary } from "frontend/admin/app/model/knowledge-summary.model";
import { OrganizationId } from "domain/kernel/organization/value";
import { Timestamp } from "utility/model/timestamp.value";
import { get } from "utility/validator/custom-lodash";

function convertToKnowledgeSummary(item: any, accountOrganization: AccountOrganization): KnowledgeSummary {
  const postType = KnowledgePostType.create(get(item, 'post_type.raw', EPostType.SHARING_VENDOR)).value;
  return {
    id: KnowledgeDocumentId.create(item.id.raw).value,
    organizationId: item.organization_id && item.organization_id.raw ? OrganizationId.create(get(item, 'organization_id.raw', '')).value : null,
    registeredBy: item.registered_by && item.registered_by.raw ? AccountOrganizationId.create(get(item, 'registered_by.raw', '')).value : null,
    sendBy: item.send_by && item.send_by.raw ? AccountOrganizationId.create(get(item, 'send_by.raw', '')).value : null,
    sendByDisplayName: '',
    title: item.title && item.title.raw ? KnowledgeDocumentTitle.create(get(item, 'title.raw', '')).value : null,
    body: item.body && item.body.raw ? KnowledgeDocumentBody.create(get(item, 'body.raw', '')).value : null,
    categories: item.category && item.category.raw ? CategoryLabel.create(get(item, 'category.raw', '')).value : null,
    createdAt: item.created_at && item.created_at.raw ? Timestamp.createByDate(item.created_at.raw).value : null,
    updatedAt: item.updated_at && item.updated_at.raw ? Timestamp.createByDate(item.updated_at.raw).value : null,
    updatedAtShortStr: item.updated_at && item.updated_at.raw ? moment(Timestamp.createByDate(item.updated_at.raw).value).fromNow() : null,
    updatedAtDetailStr: item.updated_at && item.updated_at.raw ? moment(Timestamp.createByDate(item.updated_at.raw).value).format(EDateFormat.DAYTIME_FORMAT_LLLL) : null,
    postType: postType,//KnowledgePostType.create(get(item, 'post_type.raw', EPostType.SHARING_VENDOR)).value,
    knowledgeType: postType === EPostType.KNOWLEDGE ?
      KnowledgeType.create(get(item, 'knowledge_type.raw', EKnowledgeType.NORMAL)).value : '',
    loggedUser: accountOrganization,
    wroteAt: item.wrote_at && item.wrote_at.raw ? Timestamp.createByDate(item.wrote_at.raw).value : null,
    wroteAtShortStr: item.wrote_at && item.wrote_at.raw ? moment(Timestamp.createByDate(item.wrote_at.raw).value).fromNow() : null,
    wroteAtDetailStr: item.wrote_at && item.wrote_at.raw ? moment(Timestamp.createByDate(item.wrote_at.raw).value).format(EDateFormat.DAYTIME_FORMAT_LLLL) : null,

    knowledgeConverted: KnowledgeDocumentConverted.create(item?.knowledge_converted?.raw === 'true').value,
    quotationTargetId: item.quotation_target_id && item.quotation_target_id.raw ? KnowledgeDocumentQuotationTargetId.create(get(item, 'quotation_target_id.raw', '')).value : null,
    commentTotal: item.comment_total && item.comment_total.raw ? KnowledgeDocumentCommentTotal.create(get(item, 'comment_total.raw', 0)).value : 0,
    reactionTotal: item.reaction_total && item.reaction_total.raw ? KnowledgeDocumentReactionTotal.create(get(item, 'reaction_total.raw', 0)).value : 0,
  } as KnowledgeSummary;
}

export { convertToKnowledgeSummary };
