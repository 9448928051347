import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notfound-record-text',
  templateUrl: './notfound-record-text.component.html',
  styleUrls: ['./notfound-record-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotfoundRecordTextComponent {
  @Input() message: string = 'データなし';
  @Input() top: number = 0;
  constructor() { }
}
