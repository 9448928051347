import { Board } from '../entity';
import { BoardId } from '../value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IRepository } from 'utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class IBoardRepository extends IRepository<BoardId, Board> {
  abstract clone(board: Board): Observable<Board>;
  abstract selectAll(builder: FirestoreQueryBuilder<Board>): Observable<Board>;
  abstract selectIfExists(builder: FirestoreQueryBuilder<Board>): Observable<any>;
}
