import { SlackTeamId } from '../../slack-team/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { SlackOAuthAppId, SlackOAuthId, SlackOAuthStatus, SlackOAuthTokenType, SlackOAuthScope, SlackOAuthAccessToken, SlackOAuthBotId, SlackOAuthAuthedUser } from '../value';
import { IsDeleted } from '../../../kernel/base/value';

export class SlackOAuth implements Entity {
  @Column()
  readonly id: SlackOAuthId;
  @Column()
  teamId: SlackTeamId;
  @Column()
  appId: SlackOAuthAppId;

  @Column()
  authedUser?: SlackOAuthAuthedUser;
  @Column()
  scope?: SlackOAuthScope;
  @Column()
  tokenType?: SlackOAuthTokenType;
  @Column()
  accessToken?: SlackOAuthAccessToken;
  @Column()
  botUserId?: SlackOAuthBotId;
  @Column()
  status: SlackOAuthStatus;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: SlackOAuthId) {
    this.id = id;
  }

  static get allFields(): (keyof SlackOAuth)[] {
    return getColumns(new SlackOAuth(SlackOAuthId.create('')));
  }

  equals(value: SlackOAuth): boolean {
    return this.id.equals(value.id);
  }
}
