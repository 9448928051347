import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { MyKnowledgeSummary } from '../../../../../model/my-knowledge-summary.model';
import { MyKnowledgeSearchRequest } from 'frontend/admin/app/model/my-knowledge-search-request.model';

export interface MyKnowledgeSearchState {
  knowledges: MyKnowledgeSummary[],
  current: number, // current page index
  total: number, // total records
  max: number, // total pages
  filterRequest: MyKnowledgeSearchRequest
}

export function createInitialState(): MyKnowledgeSearchState {
  return ({
    knowledges: [],
    current: 1, // current page index
    total: 0, // total records
    max: 10000, // total pages
    filterRequest: {
      creators: [],
      tags: [],
      query: '',
      dateRange: { from: null, to: null }
    }
  } as MyKnowledgeSearchState);
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-knowledge-search' })
export class MyKnowledgeSearchStore extends Store<MyKnowledgeSearchState> {
  constructor() {
    super(createInitialState());
  }
}
