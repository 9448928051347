import { Observable, from, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AccountDomainService } from 'domain/kernel/account/service';
import { AccountDto } from 'domain/kernel/account/dto';
import { AuthApiClient } from '../api/auth.api.client';
import { FireAuthService } from './gcp/fireauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _account: AccountDto;

  constructor(
    private readonly fireauthService: FireAuthService,
    private readonly accountDomainService: AccountDomainService,
    private readonly authApiClient: AuthApiClient
  ) { }

  public getAccount(): AccountDto {
    return this._account;
  }

  public resetAccount(): void {
    this._account = null;
  }

  selectAuthState() {
    if (!this._account) {
      return this.fireauthService.getAuthState().pipe(
        mergeMap(user =>
          user
            ? this.accountDomainService.selectAccountByUid(user.uid).pipe(
              tap(account => this._account = account),
              take(1),
              mergeMap(account => this.accountDomainService.selectAccount(account.id))
            )
            : of<AccountDto>(null)
        )
      );
    }
    return of(this._account)
  }

  getOauthStateToken(accountOrganizationId: string) {
    return from(
      this.fireauthService.getAuthState().pipe(
        mergeMap(user => user.getIdToken()),
        mergeMap(idToken => this.authApiClient.getOauthState({ accountOrganizationId }, `Bearer ${idToken}`))
      )
    );
  }

  login(email: string, password: string) {
    return this.fireauthService.login(email, password);
  }

  logout(isUserInput = true) {
    return this.fireauthService.logout(isUserInput);
  }

  createAccount(email: string, password: string) {
    return this.fireauthService.createAccount(email, password);
  }

  sendPasswordResetEmail(email: string) {
    return this.fireauthService.sendPasswordResetEmail(email);
  }

  changePassword(newPwd: string) {
    return from(this.fireauthService.changePassword(newPwd));
  }

  public confirmPasswordReset(code: string, password: string): Observable<void> {
    return this.fireauthService.confirmPasswordReset(code, password);
  }

  public verifyPasswordResetCode(code: string): Observable<string> {
    return this.fireauthService.verifyPasswordResetCode(code);
  }
}
