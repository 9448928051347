import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { TimelineCardBodyComponent } from './timeline-card-body.component';
import { KnowledgeAttachmentViewModule } from '../knowledge-attachment-view/knowledge-attachment-view.module';
import { StatisticReactionModule } from '../statistic-reaction/statistic-reaction.module';
import { TimelineCardHeaderModule } from '../timeline-card-header/timeline-card-header.module';
import { TimelineCardAttachmentModule } from '../timeline-card-attachment/timeline-card-attachment.module';
import { CategoriesAnchorModule } from '../categories-anchor/categories-anchor.module';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';

const components: Type<any>[] = [TimelineCardBodyComponent];

@NgModule({
  imports: [
    CommonModule,
    KnowledgeAttachmentViewModule,
    StatisticReactionModule,
    TimelineCardHeaderModule,
    TimelineCardAttachmentModule,
    CategoriesAnchorModule,
    PipesModule,
    RouterModule
  ],
  declarations: [components],
  exports: [components],
})
export class TimelineCardBodyModule { }
