import { ChangeDetectionStrategy, Component, Input, Inject, Output, EventEmitter } from '@angular/core';

import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { ELogicOperators } from 'domain/kernel/board/enum';
import { Options } from './../../../shared/interfaces/common.interface';
import { DOCUMENT } from '@angular/common';


const options: Options[] = [
  { value: ELogicOperators.AND, label: 'and検索' },
  { value: ELogicOperators.OR, label: 'or検索' },
];

@Component({
  selector: 'app-and-or-condition',
  templateUrl: './and-or-condition.component.html',
  styleUrls: ['./and-or-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AndOrConditionComponent extends BaseComponent {
  @Input() initValue: string;

  @Output() selectItem = new EventEmitter();

  public hasOpen = false;

  public options = options;
  public value: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
  }

  public onVisibleChange($event: boolean): void {
    this.hasOpen = $event;
    const boardElement = this.document.getElementById('boardId');
    if (boardElement) {
      if (!$event) {
        boardElement?.classList.remove('hidden__scroll');
      } else {
        boardElement?.classList.add('hidden__scroll');
      }
    }
  }

  public onSelect(item: Options): void {
    this.value = item.label;
    this.selectItem.next(item.value);
  }


  protected override onInit(): void {
    this.value = !this.initValue ? options[0].label : options.find(x => x.value === this.initValue).label;
  }
}
