import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import { BoardDeleteActionService } from './state/board-delete-action.service';
import { BoardSummary } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.store';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { faTimes } from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-board-delete-action',
  templateUrl: './board-delete-action.component.html',
  styleUrls: ['./board-delete-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardDeleteActionComponent {
  @ViewChild('panel') panel!: ElementRef;
  readonly boardZoneId = 'boardZoneId';
  appData = {
    faTimes: faTimes, //QUOTATION
  };
  isVisibleTop = false;
  visibleDropdown = false;
  isOpenDropdown: boolean = false;
  @Input() board: BoardSummary = null;
  @Input() allowEdit: boolean = true;
  @Output() close = new EventEmitter();
  @Output() edit = new EventEmitter();
  constructor(
    private readonly boardDeleteActionService: BoardDeleteActionService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private notificationService: NotificationService,
  ) { }

  onTriggerDelete() {
    // this.visibleDropdown = false;
    // this.isVisibleTop = true;
    this.isOpenDropdown = false;
    return this.boardDeleteActionService.deleteBoard(this.board.id).subscribe({
      complete: () => {
        this.changeDetectorRef.detectChanges()
        this.notificationService.open('ボードフィルータをアカイブしました。')
      },
    });
  }

  handleDelete() {
    this.isVisibleTop = false;
    this.close.emit();
    return this.boardDeleteActionService.deleteBoard(this.board.id)
      .subscribe({ complete: () => this.changeDetectorRef.detectChanges() });
  }

  handleCancelDelete(): void {
    this.isVisibleTop = false;
  }

  onOpenDropdownMenu() {
    this.isOpenDropdown = true;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement): void {
    if (target.id === this.boardZoneId) {
      return;
    }
    const clickedInside = this.panel?.nativeElement.contains(target);
    if (!clickedInside) {
      this.isOpenDropdown = false;
    }
  }
} 
