import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faEye, faPlus, faTimes } from '@fortawesome/fontawesome-pro-light';

import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { EAttachmentFile } from 'frontend/admin/app/enum/attachment-file.enum';
import { FirestorageService } from 'frontend/admin/app/service/firestorage.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesUploadComponent extends BaseComponent implements OnChanges {
  @Input() label: string = '';
  @Input() multiple: boolean = true;
  @Input() accept: string = '';//EAttachmentFile.ACCEPT_FILE_TYPE;
  @Input() limitFileSize: number = EAttachmentFile.FILE_SIZE_EXCEEDS_LIMIT * 1024 * 1024;
  @Input() limitFileSizeMessage: string = EAttachmentFile.FILE_SIZE_EXCEEDS_LIMIT_MESSAGE;
  @Input() limitFileNumber: number = EAttachmentFile.FILE_NUMBER_EXCEEDS_LIMIT;
  @Input() limitFileNumberMessage: string = EAttachmentFile.FILE_NUMBER_EXCEEDS_LIMIT_MESSAGE;
  @Input() inputFiles = [];
  @Input() isShowSummary: boolean = false;
  @Input() isShowOverviewSummary: boolean = true;
  @Input() isReadonly: boolean = false;

  @Output() upload = new EventEmitter();

  public readonly appData = {
    faEye: faEye,
    faTimes: faTimes,
    faPlus: faPlus,
  }
  public files = [];
  public totalFileSize: number = 0;
  public fileSizeExceedsLimit: boolean = false;

  constructor(private readonly firestorageService: FirestorageService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["inputFiles"]) {
      this.totalFileSize = this.calcFileSize(this.inputFiles);
      this.processEmitFileUpload();
    }
  }

  public onRemoveInputFile(file: any): void {
    this.inputFiles = this.inputFiles.filter(item => item.id !== file['id']);
    const combineFiles = this.files.concat(this.inputFiles);
    this.totalFileSize = this.calcFileSize(combineFiles);
    this.processEmitFileUpload();
  }

  public onRemoveFile(file: any): void {
    this.files = this.files.filter(item => item.id !== file['id']);
    const combineFiles = this.files.concat(this.inputFiles);
    this.totalFileSize = this.calcFileSize(combineFiles);
    this.processEmitFileUpload();
  }

  public onSelectFile(event: Event): void {
    if ((event.target as HTMLInputElement)?.files && (event?.target as HTMLInputElement)?.files[0]) {
      const attachedFs = (event?.target as HTMLInputElement)?.files;
      for (var i = 0; i < attachedFs?.length; i++) {
        //Generate id for new file upload.
        attachedFs[i]['id'] = uuidv4();
        this.files.push(attachedFs[i]);
      }

      const combineFiles = this.files.concat(this.inputFiles);
      this.totalFileSize = this.calcFileSize(combineFiles);
    }

    this.processEmitFileUpload();
  }


  public openFile(file: any): void {
    if (!this.isReadonly) {
      return;
    }

    if (!file.storagePath) {
      return;
    }

    this.subscribe(this.firestorageService.getDownloadUrl$(file.storagePath), res => {
      window.open(res, '_blank').focus();
    });
  }

  public isExceedMaxFileNumber(): boolean {
    return (this.files?.length + this.inputFiles?.length) > this.limitFileNumber;
  }

  public isExceedMaxFileSize(file: any): boolean {
    return file?.size > this.limitFileSize;
  }

  public isShowExceedMaxFileSizeMessage(file: any): boolean {
    return this.isExceedMaxFileSize(file) && !this.isReadonly;
  }

  private calcFileSize(files: any[]): number {
    return this.files.map(item => item?.size).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  private emitFileUpload(): void {
    this.upload.emit({
      files: this.files,
      inputFiles: this.inputFiles,
      fileSizeExceedsLimit: this.fileSizeExceedsLimit || this.isExceedMaxFileNumber(),
    });
  }

  private processEmitFileUpload(): void {
    this.computeFileUploadChange();
    this.emitFileUpload();
  }

  private computeFileUploadChange(): void {
    this.fileSizeExceedsLimit = false;
    const files = this.files.concat(this.inputFiles);
    files.map(item => {
      if (this.isExceedMaxFileSize(item)) {
        this.fileSizeExceedsLimit = true;
        return;
      }
    });
  }
}
