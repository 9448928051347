import { NzNotificationDataOptions, NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';

import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private _nzNotificationService: NzNotificationService) { }

  public open(title: string, options?: NzNotificationDataOptions): NzNotificationRef {
    const config: NzNotificationDataOptions = {
      ...CommonConstant.DEFAULT_NOTIFICATION_CONFIG,
      ...options
    };
    return this._nzNotificationService.blank(title, null, config);
  }

  public closeAll(): void {
    this._nzNotificationService.remove();
  }
}
