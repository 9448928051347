import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class ReactionChosen extends PrimitiveValueObject<boolean> {
  static create(value: boolean): ReactionChosen {
    if (value === null) {
      return new ReactionChosen(false);
    }
    return new ReactionChosen(value);
  }
}
