import { BoardCriteria, BoardId, BoardTitle } from '../value';
import { Column, getColumns } from 'utility/decorator/entity/column.decorator';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from 'utility/model/entity.model';
import { IsDefault } from '../value';
import { IsDeleted } from '../../base/value';
import { Timestamp } from 'utility/model/timestamp.value';

export class Board implements Entity {
  @Column()
  readonly id: BoardId;
  @Column()
  title: BoardTitle;
  @Column()
  criteria: BoardCriteria;
  @Column()
  createdBy: AccountOrganizationId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDefault: IsDefault;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: BoardId) {
    this.id = id;
  }

  static get allFields(): (keyof Board)[] {
    return getColumns(new Board(BoardId.create('')));
  }

  equals(value: Board): boolean {
    return this.id.equals(value.id);
  }
}
