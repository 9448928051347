import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EAccountOrganizationRole } from '../enum/account-organization-role.enum';

export class AccountOrganizationRole extends PrimitiveValueObject<EAccountOrganizationRole> {
  static create(value: EAccountOrganizationRole): AccountOrganizationRole {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new AccountOrganizationRole(value);
  }
}
