import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { BodyKnowledgeDetailModule } from '../../molecules/body-knowledge-detail/body-knowledge-detail.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { CreateKnowledgeModalComponent } from './create-knowledge-modal.component';
import { CreateTagModule } from './../../molecules/create-tag/create-tag.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderCardModule } from '../header-card/header-card.module';
import { KnowledgeAttachmentViewModule } from '../../molecules/knowledge-attachment-view/knowledge-attachment-view.module';
import { KnowledgeBodyTitleModule } from '../../molecules/knowledge-body-title/knowledge-body-title.module';
import { TextModule } from '../../atoms/text/text.module';
import { TextareaModule } from '../../atoms/textarea/textarea.module';

const components: Type<any>[] = [CreateKnowledgeModalComponent];

@NgModule({
  imports: [
    CommonModule,
    TextModule,
    TextareaModule,
    HeaderCardModule,
    KnowledgeBodyTitleModule,
    BodyKnowledgeDetailModule,
    KnowledgeAttachmentViewModule,
    AvatarModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    // TODO: why not load image
    AngularSvgIconModule,
    CreateTagModule
  ],
  declarations: [components],
  exports: [components],
})
export class CreateKnowledgeModalModule { }
