import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
  @Input() type = 'light-warning';
  @Input() value = '';
  @Output() clickButton = new EventEmitter();

  click() {
    this.clickButton.emit();
  }
}
