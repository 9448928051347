import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IAuthApi } from 'serverside/slackbot/api/interface/auth.api.interface';
import { GetOauthStateRequest } from 'serverside/slackbot/api/model/get-oauth-state.request.model';
import { GetOauthStateResponse } from 'serverside/slackbot/api/model/get-oauth-state.response.model';
import { AUTH_API_BASE_URL } from '../token';

@Injectable({
  providedIn: 'root'
})
export class AuthApiClient implements IAuthApi {
  constructor(private readonly http: HttpClient, @Inject(AUTH_API_BASE_URL) private readonly baseUrl: string) { }

  getOauthState(request: GetOauthStateRequest, bearerToken: string): Promise<GetOauthStateResponse> {
    return this.http
      .post(this.baseUrl, request, { headers: new HttpHeaders({ Authorization: bearerToken }) })
      .pipe(map(response => response as GetOauthStateResponse))
      .toPromise();
  }
}
