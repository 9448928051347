import { Store, StoreConfig } from '@datorama/akita';

import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { Injectable } from '@angular/core';
import { Organization } from 'frontend/lib/model/organization.model';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';

export interface WorkspaceState {
  organization: Organization;
  currentAccountOrganization: AccountOrganization;
  integrated: boolean;
  // initial: boolean;
  members: AccountMember[];
}

export function createInitialState(): WorkspaceState {
  return {
    organization: null,
    currentAccountOrganization: null,
    integrated: false,
    // initial: true
    members: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace' })
export class WorkspaceStore extends Store<WorkspaceState> {
  constructor() {
    super(createInitialState());
  }
}
