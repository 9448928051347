import { Observable, Subject, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { AvatarDummyBuilder } from 'frontend/utl/builder/avatar-dummy.builder';
import { FirestorageService } from 'frontend/admin/app/service/firestorage.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AvatarService {
  private imageChange$ = new Subject<string>();

  constructor(private readonly firestorageService: FirestorageService) { }

  public getImageChangeObservable(): Observable<string> {
    return this.imageChange$.asObservable();
  }

  public setImage(url: string): void {
    this.imageChange$.next(url);
  }

  public getViewOnly(altText: string, imagePath: string): Observable<string> {
    const aliasName = altText && altText.length > 0 ? altText.substring(0, 1).toUpperCase() : 'N';
    return imagePath && imagePath !== '' ?
      this.firestorageService.getDownloadUrl$(imagePath).pipe(
        take(1),
        catchError(err => {
          return of(aliasName);
        })
      ) : of(aliasName)
  }

  public getViewUrl(altText: string, imagePath: string): Observable<string> {
    const aliasName = altText && altText.length > 0 ? altText.substring(0, 1).toUpperCase() : 'N';
    return imagePath && imagePath !== '' ?
      this.firestorageService.getDownloadUrl$(imagePath).pipe(
        take(1),
        catchError(err => {
          return of(AvatarDummyBuilder(aliasName));
        })
      ) : of(AvatarDummyBuilder(aliasName))
  }
}
