import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { EPostType } from '../../../kernel/post';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeActivityTargetType extends PrimitiveValueObject<EPostType> {
  static create(value: EPostType): KnowledgeActivityTargetType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new KnowledgeActivityTargetType(value);
  }
}
