import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';

// update for addition search tags, createdBy
// model for scroll paging Elastic search
export interface BucketResultQuery {
  summaries: KnowledgeSummary[],
  current: number,
  max: number,
  total: number,
  query: string,
  pageSize: number,
  // for tracking scrolling ES
  esQuery: any,
  esIsOverLimit: boolean
}

export interface KnowledgeSearchHeaderState {
  // for scroll elastic search
  scrollId: number,
  maxScrollId: number,

  // buckets: BucketResultQuery[], // only for develop

  // store all documents (distinct)
  vault: KnowledgeSummary[],

  // for paging on client side
  summaries: KnowledgeSummary[],
  current: number,
  max: number,
  total: number,
  keyword: string
}

// TODO: need to optimize store
// buckets: need to identify with the query
// incase scroll Elasticsearch, just only scroll the query < limit pageSize
export function createInitialState(): KnowledgeSearchHeaderState {
  return {
    // for scroll elastic search
    scrollId: 1,
    maxScrollId: 1,

    // buckets: [],// only for develop

    // store all documents (distinct)
    vault: [],

    // for paging on client side
    summaries: [],
    current: 1,
    max: 0,
    total: 0,
    keyword: ''
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'knowledge-search-header' })
export class KnowledgeSearchHeaderStore extends Store<KnowledgeSearchHeaderState> {
  constructor() {
    super(createInitialState());
  }
}
