import { NgModule, Type } from '@angular/core';

import { BoardDeleteActionComponent } from './board-delete-action.component';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';

const components: Type<any>[] = [BoardDeleteActionComponent];

@NgModule({
  imports: [CommonModule, NzDropDownModule, ButtonModule, NzModalModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class BoardDeleteActionModule { }
