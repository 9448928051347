import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { ECommentType } from 'domain/kernel/comment/enum';
import { CommentDto } from 'domain/kernel/comment/dto';
import { CommentDomainService } from 'domain/kernel/comment/service';
import { Observable, combineLatest, concat, of, EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { AssetStorageDomainService } from 'domain/kernel/asset-storage/service';
import { AssetStorageDto } from 'domain/kernel/asset-storage/dto';
import { CommentModel } from 'frontend/admin/app/model/comment.model';
import { EPostType } from 'domain/kernel/post';
import { FirestorageService } from 'frontend/admin/app/service/firestorage.service';
import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { CommentNotFoundError } from 'domain/kernel/comment/exception';

@Injectable({ providedIn: 'root' })
export class CommentService {
  constructor(
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly accountDomainService: AccountDomainService,
    private readonly commentDomainService: CommentDomainService,
    private readonly firestorageService: FirestorageService,
    private readonly assetStorageDomainService: AssetStorageDomainService,
  ) { }

  getAccountOrganization(accountOrganizationId: string) {
    return concat(
      this.accountOrganizationDomainService.selectById(accountOrganizationId).pipe(
        take(1),
        filter(accountOrganization => accountOrganization !== null),
        mergeMap(accountOrganization => this.accountDomainService.selectAccount(accountOrganization.accountId)),
      ),
      of(null)
    )
  }

  getCommentByPost(summary: KnowledgeSummary) {
    return this.commentDomainService.selectByPostId(summary.id).pipe(
      mergeMap(comment =>
        this.getAccountOrganization(comment.commentedBy).pipe(
          take(1),
          mergeMap(account => {
            if (account) {
              return of({
                id: comment.id,
                postId: comment.postId,
                postType: comment.postType,
                body: comment.body,
                type: comment.type,
                commentedBy: comment.commentedBy,
                parentId: comment.parentId,
                createdAt: comment.createdAt,
                updatedAt: comment.updatedAt,
                displayName: `${account.name?.first} ${account.name?.last}`,
                emailPostOwner: account.email,
                avatarPostOwner: account.shortImg,
                deletedUser: false
              } as CommentModel)
            } else {
              return of({
                id: comment.id,
                postId: comment.postId,
                postType: comment.postType,
                body: comment.body,
                type: comment.type,
                commentedBy: comment.commentedBy,
                parentId: comment.parentId,
                createdAt: comment.createdAt,
                updatedAt: comment.updatedAt,
                deletedUser: true
              } as CommentModel)
            }
          }),
        )),
      toArray()
    )
  }

  public getCommentsOnlyByPost(summary: KnowledgeSummary): Observable<CommentDto[]> {
    return summary ? this.commentDomainService.selectByPostId(summary?.id).pipe(
      toArray(),
    ) : of([]);
  }

  public getCommentByUser(summary: KnowledgeSummary, commentedBy: string) {
    return summary ? this.commentDomainService.selectByPostIdAndCommentedBy(summary?.id, commentedBy).pipe(
      take(1),
      catchError(err => {
        if (err instanceof CommentNotFoundError) {
          return of(null);
        }
        return EMPTY;
      })
    ) : of(null);
  }

  update(commentId: string, body: string, postId: string, commentedBy: string, postType: EPostType, attachedFiles: File[], parentId?: string) {
    // TODO
    // update files
    return this.commentDomainService.update(commentId, body, postId, commentedBy, postType, ECommentType.NORMAL, parentId).pipe(
      mergeMap(comment =>
        this.getAccountOrganization(comment.commentedBy).pipe(
          take(1),
          filter(account => account !== null),
          map(account => ({
            id: comment.id,
            postId: comment.postId,
            postType: comment.postType,
            type: comment.type,
            body: comment.body,
            commentedBy: comment.commentedBy,
            parentId: comment.parentId,
            createdAt: comment.createdAt,
            updatedAt: comment.updatedAt,
            displayName: `${account.name?.first} ${account.name?.last}`,
            emailPostOwner: account.email,
            avatarPostOwner: account.shortImg,
          } as CommentModel))
        )),
    )
  }

  insert(postId: string, commentedBy: string,
    postType: EPostType, body: string, parentId: string = null,
    type: ECommentType = ECommentType.NORMAL, attachedFiles: File[]) {
    let commentId = '';
    return this.commentDomainService.insert
      (postId, commentedBy, postType, body, type, parentId).pipe(
        take(1),
        tap(comment => commentId = comment.id),
        mergeMap(comment => {
          if (attachedFiles && attachedFiles.length > 0) {
            return combineLatest(this.processSaveImg(comment.id, attachedFiles))
              .pipe(take(1))
          } else {
            return of(null)
          }
        }),
        take(1),
        mergeMap(_ =>
          this.getAccountOrganization(commentedBy).pipe(
            take(1),
            filter(account => account !== null),
            map(account => ({
              id: commentId,
              postId: postId,
              postType: postType,
              type: type,
              body: body,
              commentedBy: commentedBy,
              parentId: parentId,
              createdAt: new Date(),
              updatedAt: new Date(),
              displayName: `${account.name?.first} ${account.name?.last}`,
              emailPostOwner: account.email,
              avatarPostOwner: account.shortImg,
            } as CommentModel))
          )),
      )
  }

  delete(commentId: string) {
    return combineLatest([
      this.commentDomainService.delete(commentId),
      this.assetStorageDomainService.selectIfExistsByPostId(commentId).pipe(
        mergeMap(assets => Array.isArray(assets) && assets.length > 0 ?
          of(assets).pipe(
            mergeMap(assets => assets),
            mergeMap(asset => this.assetStorageDomainService.delete(asset.id)),
          ) : of(null))
      )
    ]);
  }

  private processSaveImg(postId: string, attachedFiles: File[],) {
    const combineLatest = [];
    if (attachedFiles == null || attachedFiles.length == 0) {
      return combineLatest;
    }

    attachedFiles.forEach(file => {
      combineLatest.push(this.firestorageService.uploadAttachmentFile(file, postId)
        .pipe(
          take(1),
          map(url => ({
            postId: postId,
            downloadUrl: url,
            mimeType: '',
            name: file.name,
            permalink: '',
            size: file.size,
            type: file.type
          } as AssetStorageDto)),
          mergeMap(asset => this.assetStorageDomainService.insert(asset))
        )
      )
    });

    return combineLatest;
  }
}
