import { Injectable } from '@angular/core';
import { FeedbackSelectReceiverStore } from './feedback-select-receiver.store';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';

@Injectable({ providedIn: 'root' })
export class FeedbackSelectReceiverService {
  constructor(
    private readonly feedbackSelectReceiverStore: FeedbackSelectReceiverStore,
    private readonly workspaceService: WorkspaceService,
  ) { }

  fetch(organizationId: string) {
    return this.workspaceService.fetchActiveMembers(organizationId);
  }

  resetStore() {
    this.feedbackSelectReceiverStore.reset();
  }
}
