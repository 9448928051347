import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faEye, faPlus, faTimes } from '@fortawesome/fontawesome-pro-light';

import { EAttachmentFile } from 'frontend/admin/app/enum/attachment-file.enum';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-files-upload',
  templateUrl: './add-files-upload.component.html',
  styleUrls: ['./add-files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFilesUploadComponent implements OnChanges {
  @Input() label: string = '';
  @Input() multiple: boolean = true;
  @Output() upload = new EventEmitter();
  @Input() selectFiles: Event = null;

  @Input() accept: string = '';//EAttachmentFile.ACCEPT_FILE_TYPE;
  @Input() limitFileSize: number = EAttachmentFile.FILE_SIZE_EXCEEDS_LIMIT * 1024 * 1024;
  @Input() limitFileSizeMessage: string = EAttachmentFile.FILE_SIZE_EXCEEDS_LIMIT_MESSAGE;
  @Input() limitFileNumber: number = EAttachmentFile.FILE_NUMBER_EXCEEDS_LIMIT;
  @Input() limitFileNumberMessage: string = EAttachmentFile.FILE_NUMBER_EXCEEDS_LIMIT_MESSAGE;
  @Input() isShowSummary: boolean = false;
  @Input() isShowOverviewSummary: boolean = true;

  public files = [];
  public size: number = 0;
  public fileSizeExceedsLimit: boolean = false;
  public totalFileSize: number = 0;
  public readonly appData = {
    faEye: faEye,
    faTimes: faTimes,
    faPlus: faPlus
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectFiles"]) {
      if (this.selectFiles) {
        this.onSelectFile(this.selectFiles);
      } else {
        this.files = [];
        this.totalFileSize = 0;
        this.processEmitFileUpload();
      }
    }
  }

  public onRemoveFile(file: any): void {
    this.files = this.files.filter(item => item.id !== file['id']);
    this.totalFileSize = this.calcFileSize(this.files);
    this.processEmitFileUpload();
  }

  public onSelectFile(event: Event): void {
    if ((event.target as HTMLInputElement)?.files && (event?.target as HTMLInputElement)?.files[0]) {
      const attachedFs = (event?.target as HTMLInputElement)?.files;
      for (var i = 0; i < attachedFs?.length; i++) {
        //Generate id for new file upload.
        attachedFs[i]['id'] = uuidv4();
        this.files.push(attachedFs[i]);
      }
      this.totalFileSize = this.calcFileSize(this.files);
    }

    this.processEmitFileUpload();
  }

  public isExceedMaxFileNumber(): boolean {
    return this.files?.length > this.limitFileNumber;
  }

  public isExceedMaxFileSize(file: any): boolean {
    return file?.size > this.limitFileSize;
  }

  public isShowExceedMaxFileSizeMessage(file: any): boolean {
    return this.isExceedMaxFileSize(file);
  }

  private calcFileSize(files: any[]): number {
    return this.files.map(item => item?.size).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  private emitFileUpload(): void {
    this.upload.emit({
      files: this.files,
      size: this.totalFileSize,
      fileSizeExceedsLimit: this.fileSizeExceedsLimit || this.isExceedMaxFileNumber(),
    });
  }

  private processEmitFileUpload(): void {
    this.computeFileUploadChange();
    this.emitFileUpload();
  }

  private computeFileUploadChange(): void {
    this.fileSizeExceedsLimit = false;
    this.files.map(item => {
      if (this.isExceedMaxFileSize(item)) {
        this.fileSizeExceedsLimit = true;
        return;
      }
    });
  }
}
