import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFireStorageApi } from 'serverside/batch/api/interface/firestorage.api.interface';
import { FireStorageCopyRequest } from 'serverside/batch/api/model/firestorage-copy.request';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class CopyFirestorageApiClient implements IFireStorageApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }

  copyFile(fireStorageCopyRequest: FireStorageCopyRequest): Promise<any> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/firestorage/copy', fireStorageCopyRequest)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      ).toPromise();
  }
}
