import { IsDeleted } from '../../../kernel/base/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { SlackFileId } from '../../../slack/slack-file/value';
import { SharingVendorFileId, SharingVendorFileStorageLink, SharingVendorFileStoragePath } from '../value';

export class SharingVendorFile implements Entity {
  @Column()
  readonly id: SharingVendorFileId;
  @Column()
  slackFileId: SlackFileId;
  @Column()
  storageLink: SharingVendorFileStorageLink;
  @Column()
  storagePath: SharingVendorFileStoragePath;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: SharingVendorFileId) {
    this.id = id;
  }

  static get allFields(): (keyof SharingVendorFile)[] {
    return getColumns(new SharingVendorFile(SharingVendorFileId.create('')));
  }

  equals(value: SharingVendorFile): boolean {
    return this.id.equals(value.id);
  }
}
