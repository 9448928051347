import { AccountAuthority, AccountEmail, AccountId, AccountImg, AccountName, AccountPhone, AccountShortImg, AccountUid } from '../value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';

import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../../kernel/base/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class Account implements Entity {
  @Column()
  readonly id: AccountId;
  @Column()
  uid: AccountUid;
  @Column()
  email: AccountEmail;
  @Column()
  phone?: AccountPhone;
  @Column()
  name: AccountName;
  @Column()
  img: AccountImg;
  @Column()
  shortImg: AccountShortImg;
  @Column()
  authority: AccountAuthority;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: AccountId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof Account)[] {
    return getColumns(new Account(AccountId.create('')));
  }

  equals(value: Account): boolean {
    return this.id.equals(value.id);
  }
}
