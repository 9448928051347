// tslint:disable: variable-name
import { IsDeleted } from '../../../kernel/base/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { AccessToken, IntegrationEmail, IntegrationId, IntegrationStatus, TargetId, TargetType, TeamId, TeamName, UserName } from '../value';

export class Integration implements Entity {
  @Column()
  readonly id: IntegrationId;
  @Column()
  accessToken: AccessToken;
  @Column()
  accountOrganizationId: AccountOrganizationId;
  @Column()
  targetId: TargetId;
  @Column()
  targetType: TargetType;
  @Column()
  teamName: TeamName;
  @Column()
  teamId: TeamId;
  @Column()
  userName: UserName;
  @Column()
  email?: IntegrationEmail;
  @Column()
  status?: IntegrationStatus;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: IntegrationId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof Integration)[] {
    return getColumns(new Integration(IntegrationId.create('')));
  }

  equals(value: Integration): boolean {
    return this.id.equals(value.id);
  }
}
