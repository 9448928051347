<div class="board-delete-action">
  <section #panel>
    <div [id]="boardZoneId" class="icon-remove" (click)="onOpenDropdownMenu()">
      <fa-icon class="btn-trigger" [icon]="appData.faTimes"></fa-icon>
    </div>
    <ng-container *ngIf="isOpenDropdown">
      <div class="dropdown-options">
        <div class="action-menu-wrapper">
          <p class="action-menu-item" (click)="onTriggerDelete()">ボードを削除</p>
        </div>
      </div>
    </ng-container>
  </section>
</div>
