import { AccountOrganizationId } from '../../account/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { KnowledgeBody, KnowledgeCategory, KnowledgeId, KnowledgeQuotationTargetId, KnowledgeTitle, KnowledgeType } from '../value';
import { IsDeleted } from '../../base/value';

export class Knowledge implements Entity {
  @Column()
  readonly id: KnowledgeId;
  @Column()
  registeredBy: AccountOrganizationId;
  @Column()
  sendBy: AccountOrganizationId;
  @Column()
  title: KnowledgeTitle;
  @Column()
  body: KnowledgeBody;
  @Column()
  type: KnowledgeType;
  @Column()
  quotationTargetId: KnowledgeQuotationTargetId;
  @Column()
  category: KnowledgeCategory;
  @Column()
  wroteAt: Timestamp;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: KnowledgeId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof Knowledge)[] {
    return getColumns(new Knowledge(KnowledgeId.create('')));
  }

  equals(value: Knowledge): boolean {
    return this.id.equals(value.id);
  }
}
