import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Knowledge } from '../entity';
import { KnowledgeId } from '../value';
import { Observable } from 'rxjs';

export abstract class IKnowledgeRepository extends IRepository<KnowledgeId, Knowledge> {
  abstract selectAll(builder: FirestoreQueryBuilder<Knowledge>): Observable<Knowledge>;
  abstract selectForUpdateEs(id: KnowledgeId): Observable<Knowledge> | null;
}
