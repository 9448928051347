import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { SlackChannelId, SlackChannelName, SlackChannelStatus } from '../value';

import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../../kernel/base/value';
import { SlackTeamId } from '../../slack-team/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class SlackChannel implements Entity {
  @Column()
  readonly id: SlackChannelId;
  @Column()
  name: SlackChannelName;
  @Column()
  teamId: SlackTeamId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  status?: SlackChannelStatus;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: SlackChannelId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof SlackChannel)[] {
    return getColumns(new SlackChannel(SlackChannelId.create('')));
  }

  equals(value: SlackChannel): boolean {
    return this.id.equals(value.id);
  }
}
