import { filter, tap } from 'rxjs/operators';

import { BoardDomainService } from 'domain/kernel/board/service';
import { BoardDto } from 'domain/kernel/board/dto';
import { Injectable } from '@angular/core';
import { SidebarMenuDashboardStore } from './sidebar-menu-dashboard.store';

@Injectable({ providedIn: 'root' })
export class SidebarMenuDashboardService {
  constructor(
    private readonly sidebarMenuDashboardStore: SidebarMenuDashboardStore,
    private readonly boardDomainService: BoardDomainService
  ) { }

  fetch(accountOrganizationId: string) {
    return this.boardDomainService.selectByCreatedBy(accountOrganizationId)
      .pipe(
        filter(board => board !== null),
        tap(board => this.pushToBoards(board)),
      )
  }

  updateIncaseRemoveBoard(board: BoardDto) {
    const currentBoards = this.sidebarMenuDashboardStore.getValue().boards;
    const boardViews = currentBoards.filter(b => b.id !== board.id);
    this.sidebarMenuDashboardStore.update({ boards: boardViews });
  }

  updateIncaseUpdateBoard(board: BoardDto) {
    const currentBoards = this.sidebarMenuDashboardStore.getValue().boards;
    const updateBoards = currentBoards.map(item => {
      if (board.id === item.id) {
        item = board;
      }
      return item;
    });
    this.sidebarMenuDashboardStore.update({ boards: updateBoards });
  }

  pushToBoards(board: BoardDto) {
    let boards = this.sidebarMenuDashboardStore.getValue().boards;
    boards = board.isDefault ? [board, ...boards.filter(item => !item.isDefault)] : [...boards, board];
    this.sidebarMenuDashboardStore.update({ boards });
    this.sidebarMenuDashboardStore.update({ totalBoardCount: Object.keys(boards).length });
  }

  sortBoardItemsByCreatedAt(boards: BoardDto[]) {
    const boardDefault = boards?.find(b => b.isDefault);
    boardDefault ? (boardDefault.createdAt = new Date()) : null;
    return boards.sort((a, b) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }


  updateSelectedBoard(board: BoardDto) {
    this.sidebarMenuDashboardStore.update({ selectedBoard: board });
  }

  resetStore() {
    this.sidebarMenuDashboardStore.reset();
  }
}
