import { TargetId, TargetType } from '../../integration/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { SharingVendorId } from '../../sharing-vendor/value';
import { SharingVendorIntegrationId } from '../value';
import { IsDeleted } from '../../../kernel/base/value';

export class SharingVendorIntegration implements Entity {
  @Column()
  readonly id: SharingVendorIntegrationId;
  @Column()
  sharingVendorId: SharingVendorId;
  @Column()
  targetId: TargetId;
  @Column()
  targetType: TargetType;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted?: IsDeleted;

  constructor(id: SharingVendorIntegrationId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof SharingVendorIntegration)[] {
    return getColumns(new SharingVendorIntegration(SharingVendorIntegrationId.create('')));
  }

  equals(value: SharingVendorIntegration): boolean {
    return this.id.equals(value.id);
  }
}
