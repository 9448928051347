import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { Integration } from 'frontend/lib/model/integration.model';

export interface SendFeedbackModalState {
  sending: boolean;
  accounts: AccountMember[];
  integrations: Integration[];
}

export function createInitialState(): SendFeedbackModalState {
  return {
    sending: false,
    accounts: [],
    integrations: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'send-feedback-modal' })
export class SendFeedbackModalStore extends Store<SendFeedbackModalState> {
  constructor() {
    super(createInitialState());
  }
}
