import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { TimelineCardAttachmentComponent } from './timeline-card-attachment.component';
import { KnowledgeFileModule } from '../knowledge-file/knowledge-file.module';

const components: Type<any>[] = [TimelineCardAttachmentComponent];

@NgModule({
  imports: [CommonModule, KnowledgeFileModule],
  declarations: [components],
  exports: [components],
})
export class TimelineCardAttachmentModule { }
