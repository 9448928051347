import { EMPTY, Observable } from 'rxjs';
import { ReactionChosen, ReactionId, ReactionTargetType, ReactionType } from '../../value';
import { count, distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IReactionRepository } from '../reaction.repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { PostId } from '../../../../kernel/post';
import { Reaction } from '../../entity';
import { ReactionNotFoundError } from '../../exception';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class ReactionFirestoreRepository implements IReactionRepository {
  private static readonly collectionId = 'reaction';
  constructor(private readonly firestoreService: IFirestoreService) { }

  selectAll(builder: FirestoreQueryBuilder<Reaction>): Observable<Reaction> {
    return this.firestoreService.getCollection(ReactionFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(ReactionFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item)),
    );
  }

  countAll(builder: FirestoreQueryBuilder<Reaction>) {
    return this.firestoreService.getCollection(ReactionFirestoreRepository.collectionId, builder.limit(10000)).pipe(
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
    );
  }

  selectIfExists(builder: FirestoreQueryBuilder<Reaction>): Observable<any> {
    return this.firestoreService.getCollection(ReactionFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items[0] : null),
      map(item => item ? this.convertToEntity(item) : null),
    );
  }

  select(id: ReactionId): Observable<Reaction> {
    return this.firestoreService.getDocument(ReactionFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new ReactionNotFoundError(`reaction id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: Reaction): Observable<Reaction> {
    item.createdAt = Timestamp.createByMillsec(+new Date());
    item.updatedAt = Timestamp.createByMillsec(+new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(ReactionFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(reaction: Reaction): Observable<Reaction> {
    reaction.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(ReactionFirestoreRepository.collectionId, reaction.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new ReactionNotFoundError(`reaction id「${reaction.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(ReactionFirestoreRepository.collectionId, this.convertToMap(reaction));
      }),
      map(_ => reaction)
    );
  }

  delete(id: ReactionId): Observable<any> {
    const proposal = new Reaction(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(ReactionFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): ReactionId {
    return ReactionId.create(this.firestoreService.generateId());
  }

  private convertToMap(reaction: Reaction): object {
    return Reaction.allFields.reduce((p, key) => {
      if (reaction[key] === undefined) {
        return p;
      }
      const value = reaction[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const reaction = new Reaction(ReactionId.create(item.id));
    reaction.postId = PostId.create(item.postId);
    reaction.target = ReactionTargetType.create(item.target);
    reaction.chosen = ReactionChosen.create(item.chosen);
    reaction.type = ReactionType.create(item.type);
    reaction.reactedBy = AccountOrganizationId.create(item.reactedBy);
    reaction.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    reaction.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    reaction.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return reaction;
  }
}
