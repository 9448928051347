import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { ELASTIC_APP_SEARCH_ENDPOINT, ELASTIC_APP_SEARCH_SEARCH_KEY } from 'frontend/lib/token';
import * as ElasticAppSearch from '@elastic/app-search-javascript';
import { Observable, from } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { SearchESResult, PagingES } from '../interfaces/search-result-es.interface';

@Injectable({
  providedIn: 'root'
})
export class MyKnowledgeElasticsearchService {
  private readonly elasticClient: any;
  constructor(
    @Inject(ELASTIC_APP_SEARCH_SEARCH_KEY) searchKey: string,
    @Inject(ELASTIC_APP_SEARCH_ENDPOINT) endpointBase: string,
  ) {
    this.elasticClient = new ElasticAppSearch.createClient({
      searchKey,
      endpointBase,
      engineName: 'my-knowledge',
      cacheResponses: false
    });
  }

  public search(query: string, options: any): Observable<SearchESResult> {
    return from(this.elasticClient.search(query, options))
      .pipe(
        map(search => ({
          rawResults: (search as any).rawResults as any[],
          page: (search as any).info.meta.page as PagingES
        } as SearchESResult))
      );
  }

  public multiSearch(queries: any[]): Observable<SearchESResult[]> {
    return from(this.elasticClient.multiSearch(queries))
      .pipe(
        map(search => search as any[]),
        mergeMap(search => search),
        map(search => ({
          rawResults: (search as any).rawResults as any[],
          page: (search as any).info.meta.page as PagingES
        } as SearchESResult)),
        toArray()
      );
  }
}
