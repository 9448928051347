import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';

export class AssetStoragePermalink extends PrimitiveValueObject<string> {
  static create(value: string): AssetStoragePermalink {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new AssetStoragePermalink(value);
  }
}
