<section class="create-tag">
  <div class="create-tag__label">
    <p>ナレッジタグ</p>
    <p *ngIf="isValidate" class="has--error">タグは10個以上追加できません。</p>
  </div>
  <div class="create-tag__wrapper">
    <div class="create-tag__action">
      <app-search-tag-autocomplete [data]="dataSource" [initValue]="selectedTags" (selectItem)="onSelectedTags($event)">
      </app-search-tag-autocomplete>
    </div>
  </div>
</section>
