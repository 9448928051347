<section class="feedback-search-knowledge-result">
  <div class="feedback-search-knowledge-result__wrapper">
    <div class="feedback-search-knowledge-result__header">
      <div class="header--container">
        <div class="header--title">
          <span>{{ knowledgeSummary?.title }}</span>
        </div>
        <div class="header--attached">
          <ng-container *ngIf="linksDetected?.length > 0 && files.length > 0; else option">
            <app-card-view-header-attachment-count [count]="files.length + linksDetected.length"></app-card-view-header-attachment-count>
          </ng-container>

          <ng-template #option>
            <ng-container *ngIf="linksDetected?.length > 0; then linkTmpl; else fileTmpl"> </ng-container>
            <ng-template #linkTmpl>
              <app-card-view-header-link [links]="linksDetected"></app-card-view-header-link>
            </ng-template>
            <ng-template #fileTmpl>
              <app-card-view-header-attachment [files]="files"></app-card-view-header-attachment>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="feedback-search-knowledge-result__body">
        <p #knowledgeContent class="content">{{ knowledgeSummary?.body }}</p>
      </div>
    </div>
  </div>
</section>
