import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { JitComponent } from './jit.component';

const components: Type<any>[] = [JitComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class JitModule { }
