<div class="feed-notification">
  <ng-container *ngIf="initLoad">
    <div class="update-user">
      <fa-icon [icon]="appData.faStar" class="me-sm-25"></fa-icon>
      <ng-container *ngIf="userReactions.displayNameReactions; else userReactionNotExist">
        <span>{{ userReactions.displayNameReactions }}</span>
      </ng-container>
      <ng-template #userReactionNotExist>
        <span>削除済みユーザーによって投稿がシェアされました</span>
      </ng-template>
    </div>
    <!-- <div class="update-time">
      <app-jit [shortenTimestamp]="appendString('更新', knowledgeSummary.wroteAtShortStr)"></app-jit>
      <app-jit [shortenTimestamp]="appendString('更新', userReactions.updateAtShortStr)"></app-jit>
    </div> -->
  </ng-container>
</div>
