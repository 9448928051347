import { Store, StoreConfig } from '@datorama/akita';

import { Account } from '../../../lib/model/account.model';
import { EAccountAuthority } from 'domain/kernel/account/enum';
import { EAccountAuthorityLabel } from '../enum/account-authority.enum';
import { IOption } from 'frontend/lib/model/option.model';
import { Injectable } from '@angular/core';

export interface AppState {
  authorityOptions: IOption<EAccountAuthority, EAccountAuthorityLabel>[];
  loading: boolean;
  currentAccount: Account;
  workspaces: [];
}

export function createInitialState(): AppState {
  return {
    authorityOptions: [
      { value: EAccountAuthority.MEMBER, label: EAccountAuthorityLabel.member },
      { value: EAccountAuthority.ADMIN, label: EAccountAuthorityLabel.admin }
    ],
    loading: false,
    currentAccount: null,
    workspaces: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}
