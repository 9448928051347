import { Store, StoreConfig } from '@datorama/akita';

import { EAccountOrganizationRole } from 'domain/kernel/account/enum';
import { Injectable } from '@angular/core';

export interface AccountOrganizationView {
  id: string;
  name: string;
  displayName: string;
  shortImg: string;
  role: EAccountOrganizationRole;
  createdAt: Date;
  updatedAt: Date;
}
export interface RootWorkspaceState {
  organizations: AccountOrganizationView[];
}

export function createInitialState(): RootWorkspaceState {
  return {
    organizations: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'root-workspace' })
export class RootWorkspaceStore extends Store<RootWorkspaceState> {
  constructor() {
    super(createInitialState());
  }
}
