<div class="add-files-upload">
  <div class="overview">
    <ng-container *ngIf="files && files.length > 0">
      <div *ngFor="let file of files">
        <div *ngIf="isShowExceedMaxFileSizeMessage(file)" class="error-msg">{{ limitFileSizeMessage }}</div>
        <div class="attached-file" [class.over-limit-size]="isShowExceedMaxFileSizeMessage(file)">
          <div class="attached-file__content">
            <div class="attached-file__content__name"> {{ file.name }} </div>
            <ng-container *ngIf="isShowOverviewSummary">
              <div class="attached-file__content__size">
                {{ file.size | toBytesSize
                }}<!-- {{ file.type.toUpperCase() }} --></div
              >
            </ng-container>
          </div>
          <div class="attached-file__icon" *ngIf="file">
            <app-icon-attachment [type]="file?.type" [width]="18" [height]="18"></app-icon-attachment>
          </div>
          <div class="attached-file__actions">
            <fa-icon [icon]="appData.faTimes" (click)="onRemoveFile(file)"></fa-icon>
          </div>
        </div>
      </div>
      <div *ngIf="isExceedMaxFileNumber()" class="error-msg">
        <p>{{ limitFileNumberMessage }}</p>
      </div>
    </ng-container>
  </div>
  <form class="form form-upload">
    <dl class="form-group">
      <input id="file" #file type="file" id="myfile" style="display: none" (change)="onSelectFile($event)" multiple="true" />
      <div>
        <ng-content select="[content]" (click)="file.click()"></ng-content>
      </div>
    </dl>
  </form>
</div>
