import { TimelineCardFooterModule } from './../../molecules/timeline-card-footer/timeline-card-footer.module';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { TimelineCardComponent } from './timeline-card.component';
import { TimelineCardHeaderModule } from '../../molecules/timeline-card-header/timeline-card-header.module';
import { TimelineCardBodyModule } from '../../molecules/timeline-card-body/timeline-card-body.module';
import { KnowledgeReplyModule } from '../../molecules/knowledge-reply/knowledge-reply.module';

const components: Type<any>[] = [TimelineCardComponent];

@NgModule({
  imports: [
    CommonModule,
    TimelineCardHeaderModule,
    TimelineCardBodyModule,
    TimelineCardFooterModule,
    KnowledgeReplyModule
  ],
  declarations: [components],
  exports: [components],
})
export class TimelineCardModule { }
