import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EAttachmentFile } from 'frontend/admin/app/enum/attachment-file.enum';
import { FirestorageService } from 'frontend/admin/app/service/firestorage.service';
import { Subscription } from 'rxjs';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-attachment-file-view-card',
  templateUrl: './attachment-file-view-card.component.html',
  styleUrls: ['./attachment-file-view-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentFileViewCardComponent {

  @Input() file: any = null;
  @Input() limitFileSize: number = EAttachmentFile.FILE_SIZE_EXCEEDS_LIMIT * 1024 * 1024;
  @Input() isReadonly: boolean = false;
  @Input() isShowOverviewSummary: boolean = true;
  @Output() removeFile = new EventEmitter();

  public readonly appData = {
    faTimes: faTimes,
  }

  constructor(private readonly firestorageService: FirestorageService) { }

  public openFile(file: any): Subscription {
    if (!this.isReadonly) {
      return;
    }

    if (!file.storagePath) {
      return;
    }
    return this.firestorageService.getDownloadUrl$(file.storagePath)
      .subscribe(res => {
        window.open(res, '_blank').focus();
      });
  }

  public isExceedMaxFileSize(file: any): boolean {
    return file?.size > this.limitFileSize;
  }

  public isShowExceedMaxFileSizeMessage(file: any): boolean {
    return this.isExceedMaxFileSize(file) && !this.isReadonly;
  }

  public onRemoveInputFile(file: any): void {
    this.removeFile.emit(file);
  }
}
