import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TimelineCardEditorComponent } from './timeline-card-editor.component';

const components: Type<any>[] = [TimelineCardEditorComponent];

@NgModule({
  imports: [
    CommonModule,
    NzDropDownModule,
    FontAwesomeModule
  ],
  declarations: [components],
  exports: [components],
})
export class TimelineCardEditorModule { }
