import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactionComponent } from './reaction.component';

const components: Type<any>[] = [ReactionComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class ReactionModule { }
