import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef, Inject } from '@angular/core';
import { faArrowAltRight, faCheck, faChevronLeft, faEnvelopeSquare } from '@fortawesome/fontawesome-pro-light';
import { faEnvelope, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';

import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { SendEmailService } from 'frontend/lib/service/send-email.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { EMPTY } from 'rxjs';
import { SendFeedbackModalQuery } from './state/send-feedback-modal.query';
import { SendFeedbackModalService } from './state/send-feedback-modal.service';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { DOCUMENT } from '@angular/common';
import { FeedbackFireStoreService } from 'frontend/admin/app/shared/services/feedback-firestore.service';
import { AppRoutingConstant } from 'frontend/admin/app/shared/constants/app-routing.constant';
import { tap } from 'rxjs/operators';
import { LoadingService } from 'frontend/admin/app/shared/services/loading.service';

@Component({
  selector: 'app-send-feedback-modal',
  templateUrl: './send-feedback-modal.component.html',
  styleUrls: ['./send-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendFeedbackModalComponent extends BaseComponent {
  public faSending = faSpinner;
  public faArrowAltRight = faArrowAltRight;
  public faEnvelopeSquare = faEnvelopeSquare;
  public faChevronLeft = faChevronLeft;
  public faEnvelope = faEnvelope;
  public faCheck = faCheck;
  public selectedUsers: AccountMember[] = [];
  public inProcess: boolean = true;
  public initLoad: boolean = false;

  @Input() files: KnowledgeFileSummary[] = [];
  @Input() links: string[] = [];
  @Input() knowledgeSummary: KnowledgeSummary;
  @Output() closed = new EventEmitter();

  constructor(
    public readonly sendFeedbackModalQuery: SendFeedbackModalQuery,
    public readonly workspaceQuery: WorkspaceQuery,
    public readonly sendEmailService: SendEmailService,
    private readonly sendFeedbackModalService: SendFeedbackModalService,
    private readonly notificationService: NotificationService,
    private readonly nzModalRef: NzModalRef<SendFeedbackModalComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly feedbackFireStoreService: FeedbackFireStoreService,
    private readonly _loadingService: LoadingService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
  }

  protected override onDestroy(): void {
    this.sendFeedbackModalService.resetStore();
  }

  protected override onInit(): void {
    const { currentAccountOrganization } = this.workspaceQuery.getValue();
    if (currentAccountOrganization != null && currentAccountOrganization.organizationId != null) {
      this.subscribe(this.sendFeedbackModalService.fetchActiveMembers(currentAccountOrganization.organizationId), _ => {
        this.initLoad = true;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  public close() {
    this.nzModalRef.close();
    this.closed.emit();
  }

  public onSelectUser(account: AccountMember) {
    if (this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email)) {
      this.selectedUsers = this.selectedUsers.filter(u => u.email !== account.email);
    } else {
      this.selectedUsers.push(account);
    }

    if (this.selectedUsers.length > 0) {
      this.inProcess = false;
    } else {
      this.inProcess = true;
    }
  }

  public isSelectedUSer(account: AccountMember) {
    return this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email);
  }

  public backToQuote(): void {
    this.close();
  }

  public onSendFeedback(): void {
    this.inProcess = true;

    const subscription$ = this.processSendFeedback().subscribe({
      complete: () => {
        this.close();
        this.inProcess = false;
        this.notificationService.open('送信が完了しました');
      },
      error: err => {
        this.inProcess = false;
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private processSendFeedback() {
    if (this.selectedUsers == null || this.selectedUsers.length == 0) {
      return EMPTY;
    } else {
      this._loadingService.setStateLoading(true);
      const currentAccountOrganization = this.workspaceQuery.getValue()?.currentAccountOrganization;
      const knowledgeLink = this.document.location.origin + '/' + currentAccountOrganization?.organizationId + AppRoutingConstant.POST[0] + this.knowledgeSummary.id
      return this.feedbackFireStoreService.sendFeedback(
        currentAccountOrganization?.id,
        this.selectedUsers,
        this.knowledgeSummary,
        knowledgeLink,
        currentAccountOrganization?.organizationId,
        this.knowledgeSummary?.title,
        this.knowledgeSummary?.body
      ).pipe(
        tap(feedbackSummaries => {
          this._loadingService.setStateLoading(false);
        })
      )
    }
  }
}
