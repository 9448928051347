<div class="create-feedback-modal">
  <ng-container modal-content>
    <div class="create-feedback-modal__content">
      <div class="header-card">
        <div class="header-card__container">
          <div class="header-card__identity">
            <div class="feedback-to-lable">To: </div>
            <ng-container *ngIf="selectedUsers.length === 0">
              <div class="feedback-to-selection" (click)="onToggleOpenSelectReceiver()">送信先を選択</div>
            </ng-container>
            <div class="feedback-to-receivers" (click)="onToggleOpenSelectReceiver()">
              <ng-container *ngFor="let item of selectedUsers?.slice(0, EMyFeedbackConstant.DEFAULT_RECEIVER_DISPLAY_NUMBER)">
                <!-- <div class="receiver-avatar">
                  <app-avatar [altText]="item.email" [source]="item.shortImg" [width]="33" [height]="33"></app-avatar>
                </div> -->
                <div class="receiver-name">
                  <p> {{ item?.name && item.name?.first }} {{ item.name && item.name?.last }}</p>
                </div>
              </ng-container>
            </div>
            <div class="feedback-to-receiver-total" *ngIf="totalReceiverSelected > 0"> +{{ totalReceiverSelected }}名</div>
            <ng-template #noHasReceivers>
              <div class="feedback-to-selection" (click)="onToggleOpenSelectReceiver()">送信先を選択</div>
            </ng-template>
          </div>
          <div class="header-card-action">
            <app-button [disabled]="inProcess || formGroup.invalid" (clickButton)="onUpsertRequest()">フィードバックを送信 </app-button>
          </div>
        </div>
      </div>
      <div class="create-feedback-modal__attached-knowledge" [class.-disabled]="isOpenSelectKnowledge">
        <ng-container *ngIf="selectedKnowledgeSummaries.length === 0">
          <app-knowledge-select
            [dataSource]="ngSelectTags.tags"
            [selectedTags]="ngSelectTags.selectedTags"
            [knowledgeSummaries]="ngSelectTags.knowledgeSummaries"
            [isViewExtra]="ngSelectTags.isViewExtra"
            hideSelected="{{ ngSelectTags.hideSelected }}"
            multiple="{{ ngSelectTags.multiple }}"
            (selected)="onSelectedTags($event)"
            (toggle)="onToggleOpenSelectKnowledge()"
          ></app-knowledge-select>
        </ng-container>
        <ng-container *ngIf="selectedKnowledgeSummaries.length > 0">
          <div class="knowledge-select__review">
            <p>knowledge: </p>
            <ng-container *ngFor="let knowledge of selectedKnowledgeSummaries">
              <div class="knowledge-select__review__summary">
                <div class="content -disabled">
                  <app-feedback-search-knowledge-results [knowledgeSummary]="knowledge"></app-feedback-search-knowledge-results>
                </div>
                <div class="overlay" (click)="onRemoveSelectedKnowledge(knowledge)">
                  <fa-icon [icon]="appData.faTimes"></fa-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="separate">
        <div class="separate--line"></div>
      </div>
      <div class="create-feedback-modal__body">
        <div class="flash" *ngIf="createFeedbackModalQuery.selectFailed() | async as state">
          <div class="flash__wrapper" *ngIf="createFeedbackModalQuery.selectErrMsgs() | async as errMsgs">
            <div>
              <p *ngFor="let errMsg of errMsgs">{{ errMsg }}</p>
            </div>
            <div class="flash__button" (click)="closeValidate()">
              <fa-icon [icon]="appData.faTimes"></fa-icon>
            </div>
          </div>
        </div>
        <form class="form" [formGroup]="formGroup">
          <div class="form__body">
            <div class="title d-flex">
              <div class="feedback-title-lable">title: </div>
              <app-text type="text" formControlName="title" placeholder="ビジネススキルについてのフィードバック(必須)"></app-text>
            </div>
            <div
              *ngIf="formGroup.controls['title'].invalid && (formGroup.controls['title'].dirty || formGroup.controls['title'].touched)"
              class="group__error"
            >
              <p *ngIf="formGroup.controls['title'].errors.required" class="error"> {{ MessagesUtils.required('タイトル') }} </p>
              <p *ngIf="formGroup.controls['title'].errors.maxlength" class="error"> {{ MessagesUtils.max(50) }} </p>
            </div>
            <div class="body">
              <app-textarea [minRows]="14" formControlName="body" placeholder="その情報を共有しましょう(必須)"> </app-textarea>
              <div
                *ngIf="formGroup.controls['body'].invalid && (formGroup.controls['body'].dirty || formGroup.controls['body'].touched)"
                class="group__error"
              >
                <p *ngIf="formGroup.controls['body'].errors.required" class="error">
                  {{ MessagesUtils.required('本文') }}
                </p>
                <p *ngIf="formGroup.controls['body'].errors.maxlength" class="error">
                  {{ MessagesUtils.max(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH) }}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Select receivers section-->
    <ng-container *ngIf="isOpenReceiverEditor">
      <div class="select-receivers">
        <div class="select-receivers__wrapper">
          <div class="select-receivers__header">
            <div class="select-receivers__title">
              <div class="select-receivers__title--label">To: </div>
              <div class="select-receivers__title--placeholder">
                <div *ngIf="selectedUsers.length > 0; else noSelectUSer">
                  <div class="selected-receivers">
                    <ng-container *ngFor="let user of selectedUsers">
                      <div class="selected-receivers__item">
                        <span> {{ user.displayName }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <ng-template #noSelectUSer>
                  <span>送信先を選択</span>
                </ng-template>
              </div>
            </div>
            <div class="divider"></div>
            <div class="select-receivers__select-receiver">
              <div id="receivers" class="receivers">
                <app-feedback-select-receiver
                  [initSelectedUsers]="selectedUsers"
                  (onSelectedUsers)="onSelectUser($event)"
                ></app-feedback-select-receiver>
              </div>
            </div>
            <div class="action">
              <app-button (clickButton)="onToggleOpenSelectReceiver()">送信先を決定</app-button>
            </div>
          </div>
          <div class="select-receivers__spotlight"></div>
        </div>
      </div>
    </ng-container>

    <!-- Select knowledge section-->
    <ng-container *ngIf="isOpenSelectKnowledge">
      <div id="knowledge-attachments">
        <app-feedback-select-knowledge
          [initSelectedKnowledgeSummaries]="selectedKnowledgeSummaries"
          (close)="onToggleOpenSelectKnowledge()"
          (handleSelectKnowledge)="onSelectKnowledgeSummary($event)"
        ></app-feedback-select-knowledge>
      </div>
    </ng-container>
  </ng-container>
</div>
