import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';
import { SlackMessage } from '../entity';
import { SlackMessageId } from '../value';

export abstract class ISlackMessageRepository extends IRepository<SlackMessageId, SlackMessage> {
  abstract selectAll(builder: FirestoreQueryBuilder<SlackMessage>): Observable<SlackMessage>;
  abstract selectAllV1(builder: FirestoreQueryBuilder<SlackMessage>): Observable<SlackMessage>;
}
