import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
// tslint:disable:no-any
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent extends BaseComponent implements ControlValueAccessor {
  @Input() type = 'default';
  @Input() label = null;
  @Input() placeholder = '';
  @Input() preventEnterKeyCreateNewLine: boolean = false;
  @Input() maxLength = ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH;
  @Input() minRows = ELimitCharacters.TEXTAREA_DEFAULT_MAX_ROWS;
  @Input() maxRows = ELimitCharacters.TEXTAREA_DEFAULT_MIN_ROWS;
  @Output() submit = new EventEmitter();

  @ViewChild('textarea') element: ElementRef;

  required = false;

  onChange: (value: any) => void;
  onTouched: (value: any) => void;

  constructor(@Self() public control: NgControl) {
    super();
    this.control.valueAccessor = this;
  }

  protected override onInit(): void {
    const validator = this.control.control.validator;
    this.required = validator ? validator({} as AbstractControl)?.required || false : false;
  }

  // ControlValueAccessor
  writeValue(value: any) {
    if (this.element) {
      this.element.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onKeyupEnter() {
    this.submit.emit();
  }
}
