<div class="suggestible-text">
  <p class="suggestible-text__label" *ngIf="label">{{ label }}</p>
  <fa-icon class="suggestible-text__icon" [icon]="faSearch"></fa-icon>
  <fa-icon class="suggestible-text__loading" [icon]="faSpinnerThird" [class]="{ '-active': loading }"></fa-icon>
  <input #text [placeholder]="placeholder" (input)="onChange(text.value)" (blur)="onTouched(text.value)" />

  <ng-container *ngIf="data && isSearch">
    <div class="suggestible-text__results" *ngIf="data.length > 0; else empty">
      <ng-container *ngFor="let item of data">
        <div class="suggestible-text__results-item" [class.-disabled]="item.inProcess" (click)="onSelect(item)">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
        </div>
      </ng-container>
    </div>
    <ng-template #empty>
      <div class="suggestible-text__results">
        <div class="suggestible-text__results-item">
          <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
