import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';
import { QuoteKnowledgeModalComponent } from '../../modal/quote-knowledge-modal/quote-knowledge-modal.component';
import { faShare } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteComponent {
  faShare = faShare;

  @Input() knowledgeSummary: KnowledgeSummary;

  constructor(private readonly modalService: ModalService,
  ) { }

  public openQuotation($event: Event): void {
    this.modalService.open(QuoteKnowledgeModalComponent, {
      nzComponentParams: {
        knowledgeSummary: this.knowledgeSummary
      }
    });
    $event.preventDefault();
    $event.stopPropagation();
  }
}
