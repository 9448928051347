import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class FeedbackKnowledgeId extends PrimitiveValueObject<string> {
  static create(value: string): FeedbackKnowledgeId {
    if (value === null) {
      return new FeedbackKnowledgeId('');
    }
    return new FeedbackKnowledgeId(value);
  }
}
