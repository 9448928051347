import { NgModule, Type } from '@angular/core';

import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { CommonModule } from '@angular/common';
import { FeedbackSelectReceiverComponent } from './feedback-select-receiver.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JitModule } from '../../atoms/jit/jit.module';

const components: Type<any>[] = [FeedbackSelectReceiverComponent];

@NgModule({
  imports: [CommonModule, AvatarModule, FontAwesomeModule, JitModule],
  declarations: [components],
  exports: [components],
})
export class FeedbackSelectReceiverModule { }
