import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeCategory extends PrimitiveValueObject<string> {
  static create(value: string): KnowledgeCategory {
    if (value === null) {
      return new KnowledgeCategory('');
    }
    return new KnowledgeCategory(value);
  }
}
