<section class="invite-account-modal__section">
  <div class="modal-header" *ngIf="workspaceQuery.selectOrganization() | async as organization">
    <app-avatar *ngIf="organization" [altText]="organization?.email" [source]="organization.img" [width]="56" [height]="56"></app-avatar>
    <p>{{ organization.displayName }}</p>
    <p>にアカウントを招待します</p>
  </div>
  <ng-container *ngIf="inviteAccountModalQuery.selectSelectedAccount() | async as selectedAccount; else nonSelected">
    <div class="item-selected">
      <div class="item-selected__wrapper">
        <app-avatar
          *ngIf="selectedAccount"
          [altText]="selectedAccount?.email"
          [source]="selectedAccount.shortImg"
          [width]="30"
          [height]="30"
        ></app-avatar>
        <div class="item-selected__body">
          <p class="item-selected__body-name">{{ selectedAccount.name?.first }} {{ selectedAccount.name?.last }}</p>
        </div>
        <fa-icon class="item-selected__cancel clicked" [icon]="faTimes" (click)="cancelAccount()"></fa-icon>
      </div>
      <app-button [disabled]="inProcess" (clickButton)="addWorkspace()">ワークスペースに追加します</app-button>
    </div>
  </ng-container>
</section>

<ng-template #nonSelected>
  <form [formGroup]="formGroup" class="invitation__modal-search">
    <app-suggestible-text
      formControlName="email"
      [itemTemplate]="item"
      [emptyTemplate]="empty"
      [isSearch]="isSearch"
      [data]="inviteAccountModalQuery.selectAccounts() | async"
      [loading]="inviteAccountModalQuery.selectLoading() | async"
      (select)="updateSelectedAccount($event)"
    >
      <ng-template #item let-data>
        <div *ngIf="data" class="item">
          <app-avatar [altText]="data?.email" [source]="data?.shortImg" [width]="30" [height]="30"></app-avatar>
          <div class="item__body">
            <span>{{ data.name?.first }} {{ data.name?.last }}</span>
          </div>
        </div>
      </ng-template>
      <ng-template #empty>
        <div class="item">
          <div class="item__body">
            <span>ユーザが見つかりません, 招待よろしでしょうか。</span>
          </div>
          <div class="button-invite">
            <app-button [disabled]="inProcess" (clickButton)="sendEmailInvitation()">招待</app-button>
          </div>
        </div>
      </ng-template>
    </app-suggestible-text>
  </form>
</ng-template>
