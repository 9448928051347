import {
  AssetStorageDownloadUrl,
  AssetStorageId,
  AssetStorageMimeType,
  AssetStorageName,
  AssetStoragePermalink,
  AssetStorageSize,
  AssetStorageSlackFileId,
  AssetStorageType
} from '../value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';

import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../../kernel/base/value';
import { PostId } from '../../post';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class AssetStorage implements Entity {
  @Column()
  readonly id: AssetStorageId;
  @Column()
  postId: PostId;
  @Column()
  downloadUrl: AssetStorageDownloadUrl;
  @Column()
  mimeType: AssetStorageMimeType;
  @Column()
  name: AssetStorageName;
  @Column()
  permalink: AssetStoragePermalink;
  @Column()
  size: AssetStorageSize;
  @Column()
  type: AssetStorageType;
  @Column()
  slackFileId?: AssetStorageSlackFileId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: AssetStorageId) {
    this.id = id;
  }

  static get allFields(): (keyof AssetStorage)[] {
    return getColumns(new AssetStorage(AssetStorageId.create('')));
  }

  equals(value: AssetStorage): boolean {
    return this.id.equals(value.id);
  }
}
