<div class="body-knowledge-detail">
  <div class="body-knowledge-detail__wrapper">
    <div class="body-knowledge-detail__body">
      <ng-content select="[body]"> </ng-content>
    </div>
    <div class="body-knowledge-detail__attachments">
      <ng-content select="[attachment]"> </ng-content>
    </div>
  </div>
</div>
