import { NgModule, Type } from '@angular/core';

import { AvatarModule } from './../../atoms/avatar/avatar.module';
import { CommonModule } from '@angular/common';
import { DeletedUserModule } from '../../atoms/deleted-user/deleted-user.module';
import { JitModule } from './../../atoms/jit/jit.module';
import { TimelineCardHeaderComponent } from './timeline-card-header.component';

const components: Type<any>[] = [TimelineCardHeaderComponent];

@NgModule({
  imports: [CommonModule, AvatarModule, JitModule, DeletedUserModule],
  declarations: [components],
  exports: [components],
})
export class TimelineCardHeaderModule { }
