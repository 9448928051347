import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AddFilesUploadModule } from '../add-files-upload/add-files-upload.module';
import { CommentFormComponent } from './comment-form.component';
import { CommonModule } from '@angular/common';
import { FilesUploadModule } from '../files-upload/files-upload.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IMEEventDirectiveModule } from '../../atoms/ime-event-directive/ime-event.module';
import { TextareaModule } from '../../atoms/textarea/textarea.module';

const components: Type<any>[] = [CommentFormComponent];

@NgModule({
  imports: [
    CommonModule,
    AddFilesUploadModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    IMEEventDirectiveModule,
    TextareaModule,
    FilesUploadModule
  ],
  declarations: [components],
  exports: [components],
})
export class CommentFormModule { }
