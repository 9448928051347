import { AssetStorage } from '../entity';
import { AssetStorageId } from '../value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class IAssetStorageRepository extends IRepository<AssetStorageId, AssetStorage> {
  abstract selectAll(builder: FirestoreQueryBuilder<AssetStorage>): Observable<AssetStorage>;
  abstract select(AssetStorageId: AssetStorageId): Observable<AssetStorage>;
  abstract selectIfExists(builder: FirestoreQueryBuilder<AssetStorage>): Observable<any>;
}
