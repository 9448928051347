import { CreateQuotationModalStore } from './create-quotation-modal.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CreateQuotationModalService {
  constructor(
    private readonly createQuotationModalStore: CreateQuotationModalStore,
  ) { }

  // Akita
  updateLoading(loading: boolean) {
    this.createQuotationModalStore.update({ loading });
  }

  updateFailed(failed: boolean, errMsgs: string[]) {
    this.createQuotationModalStore.update({ failed, errMsgs });
  }

  resetStore() {
    this.createQuotationModalStore.reset();
  }
}
