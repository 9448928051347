import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class AssetStorageSlackFileId extends PrimitiveValueObject<string> {
  static create(value: string): AssetStorageSlackFileId {
    if (value === null) {
      return new AssetStorageSlackFileId('');
    }
    return new AssetStorageSlackFileId(value);
  }
}
