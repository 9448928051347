import { ActivityId, ActivityType, TargetId } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { Activity } from '../../entity';
import { ActivityNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IActivityRepository } from '../../repository';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IsDeleted } from '../../../../kernel/base/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class ActivityFirestoreRepository implements IActivityRepository {
  private static readonly collectionId = 'activity';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: ActivityId): Observable<Activity> {
    return this.firestoreService.getDocument(ActivityFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new ActivityNotFoundError(`activity id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<Activity>): Observable<Activity> {
    return this.firestoreService.getCollection(ActivityFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(ActivityFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: Activity): Observable<Activity> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    return this.firestoreService.setDocument(ActivityFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(activity: Activity): Observable<Activity> {
    activity.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(ActivityFirestoreRepository.collectionId, activity.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new ActivityNotFoundError(`activity id「${activity.id}」 is not found`);
        }
        return this.firestoreService.setDocument(ActivityFirestoreRepository.collectionId, this.convertToMap(activity));
      }),
      map(_ => activity)
    );
  }

  delete(id: ActivityId): Observable<void> {
    const proposal = new Activity(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(ActivityFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): ActivityId {
    return ActivityId.create(this.firestoreService.generateId());
  }

  private convertToMap(activity: Activity): object {
    return Activity.allFields.reduce((p, key) => {
      if (activity[key] === undefined) {
        return p;
      }
      const value = activity[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const activity = new Activity(ActivityId.create(item.id));
    activity.targetId = TargetId.create(item.targetId);
    activity.createdBy = AccountOrganizationId.create(item.createdBy);
    activity.type = ActivityType.create(item.type);
    activity.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    activity.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    activity.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return activity;
  }
}
