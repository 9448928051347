import { Observable, from } from 'rxjs';
import { catchError, map, takeLast } from 'rxjs/operators';

import { Account } from 'frontend/lib/model/account.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import { Organization } from 'frontend/lib/model/organization.model';

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {
  constructor(private storage: AngularFireStorage) { }
  upload(file: File, account: Account) {
    return from(this.storage.upload(`/account/${account.id}/${file.name}`, file)).pipe(
      map(snapshot => snapshot.ref.fullPath),
      catchError(err => {
        console.log('upload', err)
        throw err
      })
    );
  }

  uploadOrganization(file: File, organization: Organization) {
    return from(this.storage.upload(`/organization/${organization.id}/${file.name}`, file)).pipe(
      map(snapshot => snapshot.ref.fullPath),
      catchError(err => {
        console.log('uploadOrganization', err)
        throw err
      })
    );
  }

  getDownloadUrl$(path: string): Observable<string> {
    return from(
      this.storage.ref(path).getDownloadURL()).pipe(
        takeLast(1),
        catchError(err => {
          console.log('getDownloadUrl$', err)
          throw err
        })
      );
  }

  getDownloadRefFromURL(path: string): Observable<string> {
    return from(
      this.storage.refFromURL(path).getDownloadURL()).pipe(
        takeLast(1),
        catchError(err => {
          console.log('getDownloadRefFromURL', err)
          throw err
        })
      );
  }

  uploadAttachmentFile(file: File, postId: string) {
    return from(this.storage.upload(`/asset/${postId}/${file.name}`, file)).pipe(
      map(snapshot => snapshot.ref.fullPath),
      catchError(err => {
        console.log('uploadAttachmentFile', err)
        throw err
      })
    );
  }
}
