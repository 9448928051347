<section class="search-tag-autocomplete__section">
  <div class="list-value">
    <div *ngFor="let item of choseItems" class="item--box">
      <div class="value">
        <p>{{ item.label }}</p>
        <img [src]="AssetConstant.ICON_CLOSE_BLACK_URL" (click)="onRemove($event, item)" />
      </div>
    </div>
    <div
      nz-dropdown
      nzTrigger="click"
      nzOverlayClassName="search-tag-autocomplete__cdk"
      nzPlacement="bottomLeft"
      [nzDropdownMenu]="menu"
      (nzVisibleChange)="onVisibleChange($event)"
    >
      <div class="add">
        <img [src]="AssetConstant.ICON_ADD_SMALL_URL" />
        <p>追加する</p>
      </div>
    </div>
  </div>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <input nz-input [placeholder]="'タグを入力'" [formControl]="inputSearchForm" />
    <div class="list">
      <p class="default-text" *ngIf="!isSearch || filteredOptions.length === 0">検索してナレッジタグを付与します</p>
      <ng-container *ngIf="isSearch">
        <div *ngFor="let option of filteredOptions" class="item" (click)="onSelect(option)" [class.hidden]="option?.isHidden">
          <p>{{ option.label }}</p>
        </div>
      </ng-container>
    </div>
  </nz-dropdown-menu>
</section>
