import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { FeedbackKnowledgeLink, FeedbackToSlackAccessToken, FeedbackToSlackEmail, FeedbackToSlackId, FeedbackToSlackStatus, FeedbackToSlackText, FeedbackToSlackTitle, TargetId } from '../value';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../base/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class FeedbackToSlack implements Entity {
  @Column()
  readonly id: FeedbackToSlackId;
  @Column()
  sendBy: AccountOrganizationId;
  @Column()
  receivedBy: AccountOrganizationId;
  @Column()
  receiverEmail: FeedbackToSlackEmail;
  @Column()
  targetId: TargetId;
  @Column()
  slackStatus: FeedbackToSlackStatus;
  @Column()
  accessToken: FeedbackToSlackAccessToken;
  @Column()
  text: FeedbackToSlackText;
  @Column()
  title: FeedbackToSlackTitle;
  @Column()
  knowledgeLink: FeedbackKnowledgeLink;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: FeedbackToSlackId) {
    this.id = id;
  }

  static get allFields(): (keyof FeedbackToSlack)[] {
    return getColumns(new FeedbackToSlack(FeedbackToSlackId.create('')));
  }

  equals(value: FeedbackToSlack): boolean {
    return this.id.equals(value.id);
  }
}
