import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WindowRef {
  private supportsPassive = false;
  private wheelOpt: Object | boolean;
  private wheelEvent: string;

  public get nativeWindow(): Window & typeof globalThis {
    return window;
  }

  constructor(@Inject(DOCUMENT) private readonly document: Document,) { }

  public initEventScroll(): void {
    // modern Chrome requires { passive: false } when adding event
    try {
      this.nativeWindow.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get: () => { this.supportsPassive = true; }
      }));
    } catch (e) { }

    this.wheelOpt = this.supportsPassive ? { passive: false } : false;
    this.wheelEvent = 'onwheel' in this.document.createElement('div') ? 'wheel' : 'mousewheel';
  }

  private preventDefault($event: Event): void {
    $event.preventDefault();
  }

  public disableScroll(): void {
    this.nativeWindow.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    this.nativeWindow.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
    this.nativeWindow.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
  }

  public enableScroll(): void {
    this.nativeWindow.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    this.nativeWindow.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
    this.nativeWindow.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
  }
}
