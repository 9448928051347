<div class="feedback-select-knowledges">
  <div class="feedback-select-knowledges__wrapper">
    <div class="feedback-select-knowledges__remainder" (click)="onClose()"> </div>
    <div class="feedback-select-knowledges__board">
      <div class="feedback-select-knowledges__title">
        <div class="feedback-select-knowledges__title--label">
          <fa-icon class="back-icon" [icon]="appData.faChevronLeft" (click)="onClose()"></fa-icon>
          <span> ナレッジを検索 </span>
        </div>
        <div class="feedback-select-knowledges__title__search">
          <div class="feedback-select-knowledges__title__search__wrapper">
            <app-search-box
              placeholder="ボードを横断して検索"
              type="text"
              (onKeyUpPress)="onSearch($event)"
              (onChangeKeywords)="onChangeKeywords($event)"
            ></app-search-box>
          </div>
        </div>
      </div>
      <div class="feedback-select-knowledges__select-knowledge" *ngIf="isSearching">
        <ng-container *ngIf="knowledgeSearchResults.summaries && knowledgeSearchResults.summaries.length > 0; else empty">
          <div class="search-results">
            <div
              class="knowledge-results"
              infinite-scroll
              [infiniteScrollDistance]="pageSetting.scrollDistance"
              [infiniteScrollThrottle]="pageSetting.throttle"
              [scrollWindow]="pageSetting.scrollWindow"
              (scrolled)="onScrollDown()"
            >
              <div class="results__list">
                <ng-container *ngFor="let knowledge of knowledgeSearchResults.summaries">
                  <div class="search-results__item" (click)="onSelectKnowledge(knowledge)">
                    <app-feedback-search-knowledge-results [knowledgeSummary]="knowledge"></app-feedback-search-knowledge-results>
                    <ng-container *ngIf="isSelectedKnowledge(knowledge)">
                      <div class="knowledge-selected">
                        <fa-icon [icon]="appData.faCheck"></fa-icon>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #empty>
          <div class="knowledge-search__body-knowledge-not-found">
            <p class="knowledge-not-found__notation">データなし</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
