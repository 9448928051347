import { CategoryDescription, CategoryId, CategoryLabel } from '../value';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountId } from '../../../kernel/account/value';
import { Category } from '../entity';
import { CategoryDto } from '../dto';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { ICategoryRepository } from '../repository';
import { OrganizationId } from '../../../kernel/organization/value';

export class CategoryDomainService {
  constructor(private readonly categoryRepository: ICategoryRepository) { }

  select(categoryId: string) {
    return this.categoryRepository.select(CategoryId.create(categoryId)).pipe(
      map(item => this.convertDto(item)),
      catchError(err => { console.log(err); return of(null) })
    );
  }

  selectAllByOrganizationId(organizationId: string) {
    return this.categoryRepository
      .selectAll(new FirestoreQueryBuilder<Category>().equalWhere('organizationId', organizationId))
      .pipe(
        map(item => this.convertDto(item)),
        catchError(err => { console.log(err); return of(null) })
      );
  }

  selectByIds(ids: string[], organizationId: string) {
    return this.categoryRepository
      .selectAll(new FirestoreQueryBuilder<Category>()
        .equalWhere('organizationId', organizationId)
      )
      .pipe(
        filter(category => ids.includes(category.id.value)),
        map(item => this.convertDto(item)),
        catchError(err => { console.log(err); return of(null) })
      );
  }

  insert(organizationId: string, label: string, description: string, createdBy: string) {
    const newCategory = new Category(this.categoryRepository.generateId());
    newCategory.organizationId = OrganizationId.create(organizationId);
    newCategory.label = CategoryLabel.create(label);
    newCategory.description = CategoryDescription.create(description);
    newCategory.createdBy = AccountId.create(createdBy);

    return this.categoryRepository.insert(newCategory).pipe(map(item => this.convertDto(item)));
  }

  update(category: CategoryDto) {
    return this.categoryRepository.select(CategoryId.create(category.id)).pipe(
      take(1),
      map(item => {
        const newCategory = new Category(CategoryId.create(category.id));
        newCategory.organizationId = OrganizationId.create(category.organizationId);
        newCategory.label = CategoryLabel.create(category.label);
        newCategory.description = CategoryDescription.create(category.description);
        newCategory.createdBy = AccountId.create(category.createdBy);
        newCategory.createdAt = item.createdAt;
        return newCategory;
      }),
      mergeMap(newCategory => this.categoryRepository.update(newCategory)),
      map(item => this.convertDto(item))
    );
  }

  delete(categoryId: string) {
    return this.categoryRepository.delete(CategoryId.create(categoryId));
  }

  isExists(organizationId: string, label: string): Observable<boolean> {
    return this.categoryRepository.selectIfExists(new FirestoreQueryBuilder<Category>()
      .equalWhere('organizationId', organizationId)
      .equalWhere('label', label)
    ).pipe(
      map(item => item !== null),
    )
  }

  selectIfExists(organizationId: string, label: string) {
    return this.categoryRepository.selectIfExists(new FirestoreQueryBuilder<Category>()
      .equalWhere('organizationId', organizationId)
      .equalWhere('label', label)
    ).pipe(
      map(item => item ? this.convertDto(item) : null)
    )
  }

  private convertDto(category: Category): CategoryDto {
    return Category.allFields.reduce((p, key) => {
      if (category[key] === undefined) {
        return p;
      }
      const value = category[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as CategoryDto);
  }
}
