import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { ESlackOAuthTokenType } from '../enum';

export class SlackOAuthTokenType extends PrimitiveValueObject<ESlackOAuthTokenType> {
  static create(value: ESlackOAuthTokenType): SlackOAuthTokenType {
    if (value === null) {
      return new SlackOAuthTokenType(ESlackOAuthTokenType.BOT);
    }
    return new SlackOAuthTokenType(value);
  }
}
