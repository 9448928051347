import { EMPTY, Observable } from 'rxjs';
import { SlackChannelId, SlackChannelName } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { ESlackChannelStatus } from '../../enum';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISlackChannelRepository } from '../../repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { SlackChannel } from '../../entity';
import { SlackChannelNotFoundError } from '../../exception';
import { SlackChannelStatus } from '../../value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SlackChannelFirestoreRepository implements ISlackChannelRepository {
  private static readonly collectionId = 'slack_channel';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SlackChannelId): Observable<SlackChannel> {
    return this.firestoreService.getDocument(SlackChannelFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true || item && item.status === ESlackChannelStatus.SUSPEND) {
          throw new SlackChannelNotFoundError(`slack_channel id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectIfExists(id: SlackChannelId): Observable<SlackChannel> | null {
    return this.firestoreService.getDocument(SlackChannelFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          return null;
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<SlackChannel>): Observable<SlackChannel> {
    return this.firestoreService.getCollection(SlackChannelFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(SlackChannelFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: SlackChannel): Observable<SlackChannel> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SlackChannelFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(channel: SlackChannel): Observable<SlackChannel> {
    channel.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(SlackChannelFirestoreRepository.collectionId, channel.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SlackChannelNotFoundError(`slack_channel id「${channel.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SlackChannelFirestoreRepository.collectionId, this.convertToMap(channel));
      }),
      map(_ => channel)
    );
  }

  delete(id: SlackChannelId): Observable<void> {
    const proposal = new SlackChannel(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SlackChannelFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SlackChannelId {
    return SlackChannelId.create(this.firestoreService.generateId());
  }

  private convertToMap(reaction: SlackChannel): object {
    return SlackChannel.allFields.reduce((p, key) => {
      if (reaction[key] === undefined) {
        return p;
      }
      const value = reaction[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const slackChannel = new SlackChannel(SlackChannelId.create(item.id));
    slackChannel.name = SlackChannelName.create(item.name);
    slackChannel.teamId = SlackChannelName.create(item.teamId);
    slackChannel.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    slackChannel.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    slackChannel.status = SlackChannelStatus.create(item.status);
    slackChannel.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return slackChannel;
  }
}
