import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { FileService } from 'frontend/admin/app/shared/services/file.service';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-knowledge-attachment-view',
  templateUrl: './knowledge-attachment-view.component.html',
  styleUrls: ['./knowledge-attachment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeAttachmentViewComponent extends BaseComponent implements OnChanges {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() isReadonly: boolean = true;

  @Output() upload = new EventEmitter();
  @Output() filesEmitter = new EventEmitter<KnowledgeFileSummary[]>();

  files: KnowledgeFileSummary[] = [];


  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fileService: FileService
  ) { super(); }

  protected override onInit(): void {
    this.subscribe(this.loadFiles(), res => {
      this.files = res;
      this.filesEmitter.next(this.files);
      this.changeDetectorRef.detectChanges();
    });
  }

  // incase a posting has been updated
  // func will check it by updatedAt and reload data for component
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']
      && changes?.['knowledgeSummary']?.currentValue
      && changes?.['knowledgeSummary']?.previousValue) {

      if (moment(changes?.['knowledgeSummary'].currentValue.updatedAt) > moment(changes?.['knowledgeSummary'].previousValue.updatedAt)) {
        this.subscribe(this.loadFiles(), res => {
          this.files = res;
          this.filesEmitter.next(this.files);
          this.changeDetectorRef.detectChanges();
        });
      }
    }
  }

  public loadFiles() {
    if (this.knowledgeSummary) {
      return this.fileService.getKnowledgeFiles(this.knowledgeSummary.id);
    } else {
      return EMPTY;
    }
  }

  public emitUploadFiles(event: any) {
    this.upload.emit(event);
  }
}
