import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeDocumentConverted extends PrimitiveValueObject<boolean> {
  static create(value: boolean): KnowledgeDocumentConverted {
    if (value === null || value === undefined) {
      return new KnowledgeDocumentConverted(false);
    }
    return new KnowledgeDocumentConverted(value);
  }
}
