import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';
import { SharingVendor } from '../entity';
import { SharingVendorId } from '../value';

export abstract class ISharingVendorRepository extends IRepository<SharingVendorId, SharingVendor> {
  abstract selectAll(builder: FirestoreQueryBuilder<SharingVendor>): Observable<SharingVendor>;
  abstract selectForUpdateEs(id: SharingVendorId): Observable<SharingVendor> | null;
}
