import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubscriptionCollection } from './subscription-collection';

@Directive()
export abstract class Subscribable implements OnDestroy {
  protected subscriptionCollection = new SubscriptionCollection();
  protected destroy$: Subject<void> = new Subject();

  public ngOnDestroy(): void {
    // Subject if using for takeUntil
    this.destroy$.next();
    this.destroy$.complete();

    this.subscriptionCollection.clear();
    this.onUnsubscribe();
  }

  protected subscribe<T>(observable: Observable<T>, handler: (data: T) => void): void {
    this.subscriptionCollection.add(observable, handler);
  }

  protected subscribeOne<T>(observable: Observable<T>, handler: (data: T) => void): void {
    observable.pipe(take(1)).subscribe(data => handler(data));
  }

  protected addSubscription(...subscriptions: Array<Subscription>): void {
    this.subscriptionCollection.addItems(subscriptions);

    subscriptions.forEach(sub => {
      const subscriptionRef = sub;
      sub.add(() => {
        this.subscriptionCollection.removeItem(subscriptionRef);
      });
    });
  }

  protected onUnsubscribe(): void {
    // virtual method
  }
}
