import { CreateQuotationModalState, CreateQuotationModalStore } from './create-quotation-modal.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class CreateQuotationModalQuery extends Query<CreateQuotationModalState> {
  constructor(protected store: CreateQuotationModalStore) {
    super(store);
  }
  selectLoading() {
    return this.select('loading');
  }

  selectFailed() {
    return this.select('failed');
  }

  selectErrMsgs() {
    return this.select('errMsgs');
  }
}
