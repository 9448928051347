import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class FeedbackToSlackEmail extends PrimitiveValueObject<string> {
  static create(value: string): FeedbackToSlackEmail {
    // if (value === null) {
    //   throw new ArgumentNullException();
    // }
    return new FeedbackToSlackEmail(value);
  }
}
