import { AccountId, AccountOrganizationId, AccountOrganizationRole, AccountOrganizationStatus } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganization } from '../../entity';
import { AccountOrganizationNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IAccountOrganizationRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { OrganizationId } from '../../../organization/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class AccountOrganizationFirestoreRepository implements IAccountOrganizationRepository {
  private static readonly collectionId = 'account_organization';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: AccountOrganizationId): Observable<AccountOrganization> {
    return this.firestoreService.getDocument(AccountOrganizationFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new AccountOrganizationNotFoundError(`account_organization id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<AccountOrganization>): Observable<AccountOrganization> {
    return this.firestoreService.getCollection(AccountOrganizationFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(AccountOrganizationFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: AccountOrganization): Observable<AccountOrganization> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService
      .setDocument(AccountOrganizationFirestoreRepository.collectionId, this.convertToMap(item))
      .pipe(map(() => item));
  }

  update(accountOrganization: AccountOrganization): Observable<AccountOrganization> {
    accountOrganization.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(AccountOrganizationFirestoreRepository.collectionId, accountOrganization.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new AccountOrganizationNotFoundError(`account_organization id「${accountOrganization.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(
          AccountOrganizationFirestoreRepository.collectionId,
          this.convertToMap(accountOrganization)
        );
      }),
      map(_ => accountOrganization)
    );
  }

  delete(id: AccountOrganizationId): Observable<void> {
    const proposal = new AccountOrganization(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(AccountOrganizationFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): AccountOrganizationId {
    return AccountOrganizationId.create(this.firestoreService.generateId());
  }

  private convertToMap(accountOrganization: AccountOrganization): object {
    return AccountOrganization.allFields.reduce((p, key) => {
      if (accountOrganization[key] === undefined) {
        return p;
      }
      const value = accountOrganization[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const accountOrganization = new AccountOrganization(AccountOrganizationId.create(item.id));
    accountOrganization.accountId = AccountId.create(item.accountId);
    accountOrganization.organizationId = OrganizationId.create(item.organizationId);
    accountOrganization.role = AccountOrganizationRole.create(item.role);
    accountOrganization.status = AccountOrganizationStatus.create(item.status);
    accountOrganization.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    accountOrganization.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    accountOrganization.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return accountOrganization;
  }
}
