<section *ngIf="data" class="confirm-modal__section">
  <div class="confirm-modal__body">
    <span *ngIf="data?.message" [innerHTML]="data?.message"></span>
  </div>

  <div class="confirm-modal__footer">
    <app-button
      [type]="StateType.TERTIARY"
      [version]="Version.TWO"
      [label]="data?.secondaryBtnLabel"
      (clickEmitter)="onSecondaryBtnClick()"
    ></app-button>
    <app-button
      [type]="StateType.SECONDARY"
      [version]="Version.TWO"
      [label]="data?.primaryBtnLabel"
      (clickEmitter)="onPrimaryBtnClick()"
    ></app-button>
  </div>
</section>
