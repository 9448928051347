import { ActivityId, ActivityType, TargetId } from '../value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../base/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class Activity implements Entity {
  @Column()
  readonly id: ActivityId;
  @Column()
  targetId: TargetId;
  @Column()
  createdBy: AccountOrganizationId;
  @Column()
  type: ActivityType;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: ActivityId) {
    this.id = id;
  }

  static get allFields(): (keyof Activity)[] {
    return getColumns(new Activity(ActivityId.create('')));
  }

  equals(value: Activity): boolean {
    return this.id.equals(value.id);
  }
}
