import { ArgumentNullException } from 'utility/exception/argument-null.exception';
import { IdValueObject } from 'utility/model/id-value-object.model';

export class MyKnowledgeDocumentId extends IdValueObject {
  static create(value: string): MyKnowledgeDocumentId {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new MyKnowledgeDocumentId(value);
  }
}
