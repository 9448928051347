import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCheck, faChevronLeft, faTimes } from '@fortawesome/fontawesome-pro-light';
import { EMyFeedbackConstant } from 'frontend/admin/app/enum/my-feedback-constant.enum';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { EMPTY, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CreateFeedbackModalQuery } from './state/create-feedback-modal.query';
import { CreateFeedbackModalService } from './state/create-feedback-modal.service';
import { LoadingService } from 'frontend/admin/app/shared/services/loading.service';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { FeedbackFireStoreService } from 'frontend/admin/app/shared/services/feedback-firestore.service';
import { DOCUMENT } from '@angular/common';
import { AppRoutingConstant } from 'frontend/admin/app/shared/constants/app-routing.constant';

@Component({
  selector: 'app-create-feedback-modal',
  templateUrl: './create-feedback-modal.component.html',
  styleUrls: ['./create-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFeedbackModalComponent extends BaseComponent {
  public EMyFeedbackConstant = EMyFeedbackConstant;
  readonly appData = {
    faTimes: faTimes,
    faCheck: faCheck,
    faChevronLeft: faChevronLeft
  }
  // for ngSelect knowledge
  ngSelectTags = {
    tags: [],// KnowledgeSummary[] all KnowledgeSummary belong to workspace
    selectedTags: [],// string[] all KnowledgeSummary have selected
    // initTags: [], // from ES
    knowledgeSummaries: [],
    hideSelected: true,
    // addTag: this.addTagFn,
    multiple: true,
    isViewExtra: true,
    // isOpen: false
  };

  formGroup: FormGroup;
  subscription: Subscription;
  avatarPostOwner: string = null;
  namePostOwner: string = null;
  inProcess: boolean = true;
  knowledgeSummary: any;
  selectedUsers: AccountMember[] = [];
  selectedKnowledgeSummaries: KnowledgeSummary[] = [];
  AccountMembers: AccountMember[] = [];
  isOpenReceiverEditor: boolean = false;
  isOpenSelectKnowledge: boolean = false;

  public readonly ELimitCharacters = ELimitCharacters;

  constructor(
    readonly appQuery: AppQuery,
    readonly createFeedbackModalQuery: CreateFeedbackModalQuery,
    private readonly formBuilder: FormBuilder,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly createFeedbackModalService: CreateFeedbackModalService,
    private readonly nzModalRef: NzModalRef<CreateFeedbackModalComponent>,
    private readonly notificationService: NotificationService,
    private readonly _loadingService: LoadingService,
    private readonly feedbackFireStoreService: FeedbackFireStoreService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      body: ['', [Validators.required, Validators.maxLength(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH)]],
    });
  }

  protected override onInit(): void { }

  protected override onDestroy(): void {
    this.createFeedbackModalService.resetStore();
  }

  onUpsertRequest() {
    const validForm = this.validateCreateFeedback();
    if (!validForm.isValid) {
      this.createFeedbackModalService.updateFailed(true, validForm.errMsgs);
      return;
    }
    return this.onSendFeedback()
  }

  onSelectedTags(event: any) {
    this.ngSelectTags.selectedTags = event;

    if (this.ngSelectTags.selectedTags.length > 0) {
      this.inProcess = false;
    }
  }

  onSelectUser($event: AccountMember[]) {
    this.selectedUsers = $event;
    if (this.selectedUsers.length > 0) {
      this.inProcess = false;
    }
  }

  isSelectedUSer(account: AccountMember) {
    return this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email);
  }

  closeValidate() {
    this.createFeedbackModalService.updateFailed(false, []);
  }

  onToggleOpenSelectReceiver() {
    this.isOpenReceiverEditor = !this.isOpenReceiverEditor;
  }

  onToggleOpenSelectKnowledge() {
    this.isOpenSelectKnowledge = !this.isOpenSelectKnowledge;
  }

  onSelectKnowledgeSummary($event: KnowledgeSummary[]) {
    this.selectedKnowledgeSummaries = $event;
    if (this.selectedKnowledgeSummaries.length > 0) {
      this.inProcess = false;
    }
  }

  onRemoveSelectedKnowledge(knowledge: KnowledgeSummary) {
    this.selectedKnowledgeSummaries = this.selectedKnowledgeSummaries.filter(item => item.id !== knowledge.id);
  }

  public get totalReceiverSelected() {
    return this.selectedUsers?.length - EMyFeedbackConstant.DEFAULT_RECEIVER_DISPLAY_NUMBER;
  }

  onSendFeedback() {
    this.inProcess = true;
    const subscription$ = this.processSendFeedback().subscribe({
      complete: () => {
        this.inProcess = false;
        this.notificationService.open('フィードバックを送信しました。');
      },
      error: err => {
        this.inProcess = false;
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private processSendFeedback() {
    if (!this.validateCreateFeedback()) {
      return EMPTY;
    } else {
      this._loadingService.setStateLoading(true);
      const currentAccountOrganization = this.workspaceQuery.getValue()?.currentAccountOrganization;
      const knowledgeLink = this.document.location.origin + '/' + currentAccountOrganization?.organizationId + AppRoutingConstant.POST[0] + this.selectedKnowledgeSummaries[0].id
      return this.feedbackFireStoreService.sendFeedback(
        currentAccountOrganization?.id,
        this.selectedUsers,
        this.selectedKnowledgeSummaries[0],
        knowledgeLink,
        currentAccountOrganization?.organizationId,
        this.formGroup.value['title'].trim(),
        this.formGroup.value['body'].trim()
      ).pipe(
        tap(feedbackSummaries => {
          this.nzModalRef.close(feedbackSummaries)
          this._loadingService.setStateLoading(false);
        })
      )
    }
  }

  private validateCreateFeedback() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.selectedUsers.length <= 0) {
      rs.isValid = false;
      rs.errMsgs.push('受信機を選択してください!');
    }
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }
    if (this.selectedKnowledgeSummaries.length == 0) {
      rs.isValid = false;
      rs.errMsgs.push('ナレッジを選択してください!');
    }

    return rs;
  }
}
