import { NgModule, Type } from '@angular/core';

import { AttachmentFileViewCardModule } from '../attachment-file-view-card/attachment-file-view-card.module';
import { CommentAttachmentViewComponent } from './comment-attachment-view.component';
import { CommonModule } from '@angular/common';

const components: Type<any>[] = [CommentAttachmentViewComponent];

@NgModule({
  imports: [
    CommonModule,
    AttachmentFileViewCardModule
  ],
  declarations: [components],
  exports: [components],
})
export class CommentAttachmentViewModule { }
