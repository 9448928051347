<div class="attachment-file-view-card">
  <div
    class="attached-file"
    [class.-event-pointer]="isReadonly"
    (click)="openFile(file)"
    [class.over-limit-size]="isShowExceedMaxFileSizeMessage(file)"
  >
    <div class="attached-file__content">
      <div class="attached-file__content__name"> {{ file.name || file.fileName }} </div>
      <ng-container *ngIf="isShowOverviewSummary">
        <div class="attached-file__content__size">
          {{ file.size | toBytesSize
          }}<!--{{ file.type.toUpperCase() }} --></div
        >
      </ng-container>
    </div>

    <div [ngClass]="{ 'attached-file__icon': !isReadonly, 'attached-file__icon--readonly': isReadonly }" *ngIf="file">
      <app-icon-attachment [type]="file?.type" [width]="18" [height]="18"></app-icon-attachment>
    </div>
    <div class="attached-file__actions" *ngIf="!isReadonly">
      <fa-icon [icon]="appData.faTimes" (click)="onRemoveInputFile(file)"></fa-icon>
    </div>
  </div>
</div>
