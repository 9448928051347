import { from, Observable, of } from 'rxjs';
import { mergeMap, tap, toArray } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MyKnowledgeSharingVendorSidebarStore } from './my-knowledge-sharing-vendor-sidebar.store';
import { SharingVendorView } from 'frontend/admin/app/model/sharing-vendor-view.model';
import { GetDistinctArray, MergeArray } from 'frontend/utl/builder/array-transform.builder';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { EPostType } from 'domain/kernel/post';
import { ESortOptions } from 'frontend/admin/app/enum/sort-options.enum';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';

@Injectable({
  providedIn: 'root'
})
export class MyKnowledgeSharingVendorSidebarService {
  constructor(
    private readonly myKnowledgeSharingVendorSidebarStore: MyKnowledgeSharingVendorSidebarStore,
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
  ) { }

  public fetch(accountOrganization: AccountOrganization, currentPageIndex: number, pageSize: number): Observable<KnowledgeSummary[]> {
    this.updateCurrent(currentPageIndex);
    return this.fetchSharingVendors(accountOrganization, currentPageIndex, pageSize).pipe(
      tap(sharingVendors => this.updateSharingVendors(sharingVendors))
    );
  }

  private fetchSharingVendors(accountOrganization: AccountOrganization, currentPageIndex: number, pageSize: number): Observable<KnowledgeSummary[]> {
    const opt = {
      filters: { all: [{ organization_id: accountOrganization.organizationId }, { knowledge_converted: "false" }, { post_type: EPostType.SHARING_VENDOR }, { is_deleted: "false" }] },
      sort: [{ created_at: ESortOptions.DESC }],
      page: {
        size: pageSize,
        current: currentPageIndex
      }
    };
    return this.knowledgeElasticsearchService.search('', opt).pipe(
      tap(search => {
        this.updateMax(search.page.total_pages);
        this.updateTotal(search.page.total_results);
      }),
      mergeMap(search => search.rawResults),
      mergeMap(rs => of(convertToKnowledgeSummary(rs, accountOrganization))),
      toArray(),
    );
  }

  public updateSharingVendors(newSharingVendors: KnowledgeSummary[]): void {
    const { sharingVendors } = this.myKnowledgeSharingVendorSidebarStore.getValue();
    const _sharingVendors = [...sharingVendors, ...newSharingVendors];
    this.myKnowledgeSharingVendorSidebarStore.update({ sharingVendors: _sharingVendors });
  }

  public appendSharingVendors(newSharingVendors: SharingVendorView[]): void {
    const { sharingVendors, current } = this.myKnowledgeSharingVendorSidebarStore.getValue();
    // this.myKnowledgeSharingVendorSidebarStore.update({ sharingVendors: sharingVendors.concat(newSharingVendors) });
    const updateSharingVendors = GetDistinctArray(MergeArray(sharingVendors, newSharingVendors), 'id');
    if (updateSharingVendors.length > sharingVendors.length) {
      this.myKnowledgeSharingVendorSidebarStore.update({ sharingVendors: updateSharingVendors });
      this.myKnowledgeSharingVendorSidebarStore.update({ current: current + 1 });
    }
  }

  // Akita
  public resetStore(): void {
    this.myKnowledgeSharingVendorSidebarStore.reset();
  }

  public updateTotal(total: number): void {
    this.myKnowledgeSharingVendorSidebarStore.update({ total });
  }

  public updateCurrent(current: number): void {
    this.myKnowledgeSharingVendorSidebarStore.update({ current });
  }

  public updateMax(max: number): void {
    this.myKnowledgeSharingVendorSidebarStore.update({ max });
  }

  public popSharingVendor(sharingVendorId: string): void {
    const { sharingVendors, total } = this.myKnowledgeSharingVendorSidebarStore.getValue();
    if (sharingVendors?.length > 0) {
      const updatedCurrent = sharingVendors.filter(sv => sv.id !== sharingVendorId);
      this.myKnowledgeSharingVendorSidebarStore.update({ sharingVendors: [...updatedCurrent] });
      this.myKnowledgeSharingVendorSidebarStore.update({ total: total - 1 });
    }
  }
}
