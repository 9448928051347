import { BucketResultQuery, KnowledgeSearchHeaderStore } from './knowledge-search-header.store';
import { GetDistinctArray, MergeArray } from 'frontend/utl/builder/array-transform.builder';
import { combineLatest, of } from 'rxjs';
import { mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { CategoryDomainService } from 'domain/kernel/category/service';
import { CategoryDto } from 'domain/kernel/category/dto';
import { ELuceneLogic } from 'domain/kernel/board/enum';
import { EPostType } from 'domain/kernel/post';
import { EScrollPaging } from 'frontend/admin/app/enum/scroll-paging.enum';
import { ESortOptions } from 'frontend/admin/app/enum/sort-options.enum';
import { Injectable } from '@angular/core';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { SearchESResult } from 'frontend/admin/app/shared/interfaces/search-result-es.interface';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeSearchHeaderService {
  public isLoading = true;
  constructor(
    private readonly knowledgeSearchHeaderStore: KnowledgeSearchHeaderStore,
    private readonly categoryDomainService: CategoryDomainService,
    private readonly workspaceService: WorkspaceService,
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
  ) { }

  fetch(
    keyword: string,
    accountOrganization: AccountOrganization,
    pageSize: number,
    scrollId: number,
    scrollLimitPageSize: number,
    postTypes: EPostType[] = null
  ) {
    this.isLoading = true;

    return this.searchKnowledgeES(keyword, accountOrganization, scrollId, scrollLimitPageSize, postTypes)
      .pipe(
        mergeMap(searchResults => searchResults),
        tap(bucket => this.pushBucket(bucket, accountOrganization, scrollId, scrollLimitPageSize, pageSize)),
        toArray(),
        tap(_ => {
          this.updateSummaries(pageSize);
          this.isLoading = false;
        })
      )
  }

  searchKnowledgeES(
    keyword: string,
    accountOrganization: AccountOrganization,
    scrollId: number,
    scrollLimitPageSize: number,
    postTypes: EPostType[] = null
  ) {
    const query = keyword.trim() || '';
    let sendBys: AccountMember[] = [];
    let categories: CategoryDto[] = [];

    return combineLatest([
      this.workspaceService.fetchSuspendedAndActiveMembers(accountOrganization.organizationId),
      this.loadCategories(accountOrganization.organizationId)
    ]).pipe(
      tap(rs => { sendBys = rs[0]; categories = rs[1]; }),
      take(1),
      mergeMap(_ => this.multiSearchES(accountOrganization, query, sendBys, categories, scrollId, scrollLimitPageSize, postTypes)),
    );
  }

  // akita
  updateKeyword(keyword: string) {
    this.knowledgeSearchHeaderStore.update({ keyword });
  }

  refreshKnowledgeSummary(proposal: KnowledgeSummary) {
    const currentSummaries = this.knowledgeSearchHeaderStore.getValue().summaries;
    if (currentSummaries?.length > 0) {
      const index = currentSummaries.findIndex(item => item.id === proposal.id);
      if (index >= 0) {
        currentSummaries[index] = proposal;
        this.knowledgeSearchHeaderStore.update({ summaries: [...currentSummaries] });
      }
    }
  }

  updateKnowledgeSummary(proposal: KnowledgeSummary, props: any) {
    const currentSummaries = this.knowledgeSearchHeaderStore.getValue().summaries;
    if (currentSummaries?.length > 0) {
      const index = currentSummaries.findIndex(item => item.id === proposal.id);
      if (index >= 0) {
        let summary = currentSummaries[index];
        Object.keys(props).map(key => {
          summary = { ...summary, [key]: props[key] };
        });

        currentSummaries[index] = { ...summary };
        this.knowledgeSearchHeaderStore.update({ summaries: [...currentSummaries] });
      }
    }
  }

  /**
   * enhance search
   * update buckets result query
   * update scrollId, maxScrollId
   */
  pushBucket(bucket: SearchESResult, accountOrganization: AccountOrganization, scrollId: number, scrollLimitPageSize: number, pageSize: number) {
    const bucketResultQuery = ({
      summaries: [],// bucket.results && bucket.results.length > 0 && bucket.results.map(item => convertToKnowledgeSummary(item.data, accountOrganization)),
      current: scrollId,
      max: bucket && bucket.page && bucket.page.total_pages,
      total: bucket && bucket.page && bucket.page.total_results,
      pageSize: scrollLimitPageSize
    } as BucketResultQuery);
    // const { buckets, maxScrollId, vault } = this.knowledgeSearchHeaderStore.getValue();
    const { maxScrollId, vault } = this.knowledgeSearchHeaderStore.getValue();

    // update buckets result query
    // update scrollId
    // this.knowledgeSearchHeaderStore.update({ buckets: buckets.concat([bucketResultQuery]), scrollId });
    this.knowledgeSearchHeaderStore.update({ scrollId });

    // scan all to set maxScrollId in buckets[]
    if (bucketResultQuery.max > maxScrollId) {
      this.knowledgeSearchHeaderStore.update({ maxScrollId: bucketResultQuery.max });
    }

    // update vault, max, total
    const bucketData = bucket.rawResults && bucket.rawResults.length > 0 && bucket.rawResults.map(item => convertToKnowledgeSummary(item, accountOrganization));
    const updatedVault = GetDistinctArray(MergeArray(vault, bucketData), 'id');

    this.knowledgeSearchHeaderStore.update({
      vault: updatedVault,
      total: updatedVault.length,
      max: pageSize > 0 ? Math.ceil(updatedVault.length / pageSize) : Math.ceil(updatedVault.length / EScrollPaging.DEFAULT_PAGE_SIZE)
    });
  }

  /**
  * enhance search
  * depend on vault data
  * update summaries for render
  */
  updateSummaries(pageSize: number) {
    const { vault, summaries, current, total } = this.knowledgeSearchHeaderStore.getValue();
    const fromIndex = summaries && summaries.length ? summaries.length : 0;
    const toIndex = pageSize * (current - 1) + pageSize <= total ? pageSize * (current - 1) + pageSize : total;
    const updateSummaries = summaries.concat(vault.slice(fromIndex, toIndex));
    this.knowledgeSearchHeaderStore.update({ summaries: GetDistinctArray(updateSummaries, 'id') });
  }

  /**
  * enhance search
  * incase infinite scroll search result area
  * update CurrentPageIndex
  * update summaries for render
  */
  updatePagingSearchResult(currentAccountOrganization: AccountOrganization, current: number, pageSize: number) {
    const { vault, summaries, scrollId, maxScrollId, total, keyword, max } = this.knowledgeSearchHeaderStore.getValue();
    if (current <= max) {
      this.knowledgeSearchHeaderStore.update({ current });

      const fromIndex = summaries && summaries.length ? summaries.length : 0;
      const toIndex = pageSize * (current - 1) + pageSize <= total ? pageSize * (current - 1) + pageSize : total;
      const updateSummaries = summaries.concat(vault.slice(fromIndex, toIndex));

      this.knowledgeSearchHeaderStore.update({ summaries: updateSummaries });
    }

    // TODO
    // Need to check limit pagesize before query to ES
    if (summaries.length >= total && scrollId < maxScrollId) {
      return this.fetch(keyword, currentAccountOrganization, pageSize, scrollId + 1, EScrollPaging.DEFAULT_MAXIMUM_SIZE);
    }

    return of(null);
  }

  resetStore() {
    this.knowledgeSearchHeaderStore.reset();
  }

  private multiSearchES(accountOrganization: AccountOrganization, query: string, sendBys: AccountMember[], categories: CategoryDto[],
    scrollId: number, pageSize: number, postTypes: EPostType[]) {
    // const matchFilterOptions = this.genFilterQueryES(accountOrganization.organizationId, query, sendBys, categories);
    // default query: search keyword match with title/body
    const optionsDefault = {
      search_fields: { title: {}, body: {}, },
      query,
      filters: postTypes && postTypes.length > 0 ?
        { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }, { post_type: postTypes }] }
        : { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }] },
      sort: [{ updated_at: ESortOptions.DESC }],
      page: { size: pageSize, current: scrollId }
    };
    const queries = [{ query, options: optionsDefault }];

    // old version with Lucene query syntax  
    // bind queries to multiSearch Elastic search
    // const genQueryCategories = this.genQueryCategories(query, categories);    
    // if (genQueryCategories) {
    //   queries.push({
    //     query: genQueryCategories, options: {
    //       query: genQueryCategories,
    //       search_fields: { category: {} },
    //       filters: postTypes && postTypes.length > 0 ?
    //         { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }, { post_type: postTypes }] }
    //         : { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }] },
    //       sort: [{ updated_at: ESortOptions.DESC }],
    //       page: { size: pageSize, current: scrollId }
    //     } as any
    //   });
    // }

    // switch new version => select limit 10 queries
    const genQueryCategories = this.genQueryCategories_V2(query, categories);
    const matchingCategories = genQueryCategories.length < 10 ? genQueryCategories : genQueryCategories.splice(0, 9);
    if (matchingCategories?.length > 0) {
      matchingCategories.forEach(item => {
        queries.push({
          query: item,
          options: {
            query: item,
            search_fields: { category: {} },
            filters: postTypes && postTypes.length > 0 ?
              { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }, { post_type: postTypes }] }
              : { all: [{ organization_id: accountOrganization.organizationId }, { is_deleted: 'false' }] },
            sort: [{ updated_at: ESortOptions.DESC }],
            page: { size: pageSize, current: scrollId }
          }
        } as any)
      })
    }

    return this.knowledgeElasticsearchService.multiSearch(queries);
  }

  // private genFilterQueryES(organizationId: string, query: string, sendBys: AccountMember[], categories: CategoryDto[]) {
  //   const filters = { any: [] };
  //   const matchingCategories = categories.filter(item => query.toLowerCase().includes(`${item['label']}`.toLowerCase()));

  //   // const matchingFirstNameSendBys = sendBys.filter(item => query.toLowerCase().includes(`${item.name && item.name.first}`.toLowerCase()));
  //   // const matchingLastNameSendBys = sendBys.filter(item => query.toLowerCase().includes(`${item.name && item.name.last}`.toLowerCase()));
  //   // const matchingSendBys = GetDistinctArray(MergeArray(matchingFirstNameSendBys, matchingLastNameSendBys), 'id');

  //   // match with tags
  //   if (matchingCategories.length > 0) {
  //     filters.any.push({ all: [{ organization_id: organizationId }, { is_deleted: 'false' }, { category: matchingCategories.map(item => item.id) } as any] });
  //   }

  //   // match with createdBy
  //   // if (matchingSendBys.length > 0) {
  //   //   filters.any.push({ all: [{ organization_id: organizationId }, { is_deleted: 'false' }, { send_by: matchingSendBys.map(item => item.accountOrganizationId) } as any] });
  //   // }

  //   return filters.any.length == 0 ? null : filters;
  // }

  private genQueryCategories(query: string, categories: CategoryDto[]) {
    const matchingCategories = categories.filter(item => query.toLowerCase().includes(`${item['label']}`.toLowerCase()));
    let rs = '';
    if (matchingCategories.length > 0) {
      rs += `${ELuceneLogic.INCLUDE}${matchingCategories.map(item => item.id).join(`${ELuceneLogic.INCLUDE}`)}`;
    }
    return rs;
  }

  private genQueryCategories_V2(query: string, categories: CategoryDto[]) {
    const matchingCategories = categories.filter(item => query.toLowerCase().includes(`${item['label']}`.toLowerCase()));
    return matchingCategories?.length > 0 ? matchingCategories.map(item => item.id) : [];
  }

  private loadCategories(organizationId: string) {
    return this.categoryDomainService.selectAllByOrganizationId(organizationId)
      .pipe(toArray());
  }
}
