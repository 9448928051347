import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FeedbackSearchKnowledgeResultsModule } from '../../molecules/feedback-search-knowledge-results/feedback-search-knowledge-results.module';
import { FeedbackSelectKnowledgeModule } from '../../molecules/feedback-select-knowledge/feedback-select-knowledge.module';
import { FeedbackSelectReceiverModule } from '../../molecules/feedback-select-receiver/feedback-select-receiver.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ForwardFeedbackModalComponent } from './forward-feedback-modal.component';
import { KnowledgeSelectModule } from '../../molecules/knowledge-select/knowledge-select.module';
import { TextModule } from '../../atoms/text/text.module';
import { TextareaModule } from '../../atoms/textarea/textarea.module';

const components: Type<any>[] = [ForwardFeedbackModalComponent];

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    TextModule,
    TextareaModule,
    KnowledgeSelectModule,
    FeedbackSelectReceiverModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    FeedbackSelectKnowledgeModule,
    FeedbackSearchKnowledgeResultsModule
  ],
  declarations: [components],
  exports: [components],
})
export class ForwardFeedbackModalModule { }
