import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { IsDeleted } from '../../base/value';
import { KnowledgeId } from '../../knowledge/value';
import { MyKnowledgeId } from '../value';

export class MyKnowledge implements Entity {
  @Column()
  readonly id: MyKnowledgeId;
  @Column()
  knowledgeId: KnowledgeId;
  @Column()
  createdBy: AccountOrganizationId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: MyKnowledgeId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof MyKnowledge)[] {
    return getColumns(new MyKnowledge(MyKnowledgeId.create('')));
  }

  equals(value: MyKnowledge): boolean {
    return this.id.equals(value.id);
  }
}
