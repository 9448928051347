import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class SharingVendorKnowledgeConverted extends PrimitiveValueObject<boolean> {
  static create(value: boolean): SharingVendorKnowledgeConverted {
    if (value === null || value === undefined) {
      return new SharingVendorKnowledgeConverted(false);
    }
    return new SharingVendorKnowledgeConverted(value);
  }
}
