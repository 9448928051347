import { ArgumentException } from '../../../../utility/exception/argument.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class AccountPhone extends PrimitiveValueObject<string> {
  static reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

  static create(value: string): AccountPhone {
    if (value === null) {
      return new AccountPhone(null);
    }
    else if (value && !AccountPhone.reg.test(value)) {
      throw new ArgumentException();
    }

    return new AccountPhone(value);
  }
}
