import * as moment from 'moment';

import { EMPTY, Observable, combineLatest, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountMember } from '../../model/account-member-model';
import { AppQuery } from '../../state/app.query';
import { EDateFormat } from '../../enum/date-format.enum';
import { EFeedbackToSlackStatus } from 'domain/kernel/feedback-to-slack/enum';
import { EFeedbackType } from 'domain/kernel/feedback/enum';
import { EIntegrationStatus } from 'domain/kernel/integration/enum';
import { ESlackOAuthStatus } from 'domain/slack/slack-oauth/enum';
import { FeedbackDeliveryDomainService } from 'domain/kernel/feedback-delivery/service';
import { FeedbackDeliveryDto } from 'domain/kernel/feedback-delivery/dto';
import { FeedbackDocumentDto } from 'domain/kernel/feedback-document/dto';
import { FeedbackDocumentService } from 'frontend/lib/service/feedback-document.service';
import { FeedbackDomainService } from 'domain/kernel/feedback/service';
import { FeedbackDto } from 'domain/kernel/feedback/dto';
import { FeedbackSummary } from '../../model/feedback-summary.model';
import { FeedbackToSlackDomainService } from 'domain/kernel/feedback-to-slack/service';
import { FeedbackToSlackDto } from 'domain/kernel/feedback-to-slack/dto';
import { Injectable } from '@angular/core';
import { IntegrationDomainService } from 'domain/kernel/integration/service';
import { IntegrationDto } from 'domain/kernel/integration/dto';
import { IntegrationNotFoundError } from 'domain/kernel/integration/exception';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { SlackOAuthDomainService } from 'domain/slack/slack-oauth/service';
import { SlackOAuthNotFoundError } from 'domain/slack/slack-oauth/exception';
import { Timestamp } from 'utility/model/timestamp.value';
import { WorkspaceQuery } from '../../pages/workspace/components/workspace/state/workspace.query';
import { environment } from 'frontend/admin/environments/environment';

@Injectable({ providedIn: 'root' })
export class FeedbackFireStoreService {
  constructor(
    readonly appQuery: AppQuery,
    private readonly feedbackDomainService: FeedbackDomainService,
    private readonly feedbackDeliveryDomainService: FeedbackDeliveryDomainService,
    private readonly integrationDomainService: IntegrationDomainService,
    private readonly slackOAuthDomainService: SlackOAuthDomainService,
    private readonly feedbackToSlackDomainService: FeedbackToSlackDomainService,
    readonly workspaceQuery: WorkspaceQuery,
    private readonly feedbackDocumentService: FeedbackDocumentService,
  ) { }

  public sendFeedback(
    sendBy: string,
    receivers: AccountMember[],
    knowledgeSummary: KnowledgeSummary,
    knowledgeLink: string,
    organizationId: string,
    title: string,
    body: string
  ): Observable<FeedbackSummary[]> {

    const feedbackProp = {
      sendBy: sendBy,
      title: title || '',
      body: body || '',
      knowledgeId: knowledgeSummary && knowledgeSummary.id ? knowledgeSummary.id : '',
      type: EFeedbackType.INTERNAL
    } as FeedbackDto;

    return combineLatest([
      this.feedbackDomainService.insertFeedback(feedbackProp).pipe(
        take(1),
        mergeMap(feedback =>
          combineLatest([
            this.insertFeedbackDelivery(sendBy, feedback.id, receivers, organizationId, feedbackProp),
          ])
        ),
        map(result => ({ deliveries: result[0], feedbackDto: feedbackProp })),
        mergeMap(result => this.mapToFeedbackSummary(result.deliveries, result.feedbackDto, knowledgeSummary)),
      ),
      this.insertFeedbackToSlack(sendBy, receivers, feedbackProp, knowledgeLink)
    ]).pipe(
      map(result => result[0])
    )
  }

  private insertFeedbackToSlack(sendBy: string, selectedUsers: AccountMember[], feedbackDto: FeedbackDto, knowledgeLink: string) {
    return from(selectedUsers).pipe(
      map(receiver => receiver),
      mergeMap(receiver =>
        combineLatest([
          this.getIntegrationByAccountOrganizationId(receiver.accountOrganizationId),
          this.getOauthTokenByAccountOrganizationId(receiver.accountOrganizationId),
          this.getIntegrationByAccountOrganizationId(sendBy),
          of(receiver)
        ]).pipe(
          mergeMap(result => {
            if (result[0] !== null && result[1] !== null) {
              return this.feedbackToSlackDomainService.insert({
                accessToken: result[1].accessToken,
                sendBy: sendBy,
                targetId: result[2] ? result[2].targetId : '',
                receivedBy: result[3]?.accountOrganizationId,
                receiverEmail: result[3]?.email,
                text: feedbackDto?.body,
                title: feedbackDto?.title,
                knowledgeLink: knowledgeLink,
                slackStatus: EFeedbackToSlackStatus.WAITING,
              } as FeedbackToSlackDto)
            } else {
              return of(null)
            }
          })
        )
      )
    )
  }

  private getOauthTokenByAccountOrganizationId(accountOrganizationId: string) {
    return this.getIntegrationByAccountOrganizationId(accountOrganizationId).pipe(
      mergeMap(integration =>
        this.slackOAuthDomainService.selectByTeamIdAndAppId(integration.teamId, environment.slack.gfbAppId).pipe(
          take(1),
          mergeMap(slackOAuth => {
            if (slackOAuth && slackOAuth.status === ESlackOAuthStatus.ACTIVE) {
              return of(slackOAuth)
            } else {
              return of(null as IntegrationDto)
            }
          }),
          catchError(err => {
            if (err instanceof SlackOAuthNotFoundError) {
              console.log('getOauthTokenByReceiver-catchError', err)
            }
            return of(null);
          })
        )
      ),
      catchError(err => {
        if (err instanceof IntegrationNotFoundError) {
          // console.log(err)
        }
        return of(null);
      }),
    )
  }

  private getIntegrationByAccountOrganizationId(accountOrganizationId: string): Observable<IntegrationDto> {
    return this.integrationDomainService.checkByAccountOrganizationId(accountOrganizationId).pipe(
      take(1),
      mergeMap(integrationDto => {
        if (integrationDto && integrationDto.status === EIntegrationStatus.ACTIVE) {
          return of(integrationDto)
        } else {
          return of(null as IntegrationDto)
        }
      }),
      catchError(err => {
        if (err instanceof IntegrationNotFoundError) {
          // console.log('getIntegrationByAccountOrganizationId-catchError', err)
        }
        return of(null as IntegrationDto);
      }),
    )
  }

  private mapToFeedbackSummary(deliveries: FeedbackDeliveryDto[], feedbackDto: FeedbackDto, knowledgeSummary: KnowledgeSummary): Observable<FeedbackSummary[]> {
    return from(deliveries).pipe(
      map(feedbackDelivery => ({
        id: feedbackDelivery?.id,
        title: feedbackDto.title,
        body: feedbackDto.body,
        type: feedbackDto.type,
        organizationId: feedbackDelivery?.organizationId,
        feedbackId: feedbackDelivery?.feedbackId,
        receivedBy: feedbackDelivery?.receivedBy,
        unread: feedbackDelivery?.unread,
        sendBy: feedbackDelivery.sendBy,
        receiverArchived: feedbackDelivery.receiverArchived,
        senderArchived: feedbackDelivery.senderArchived,
        createdAt: feedbackDelivery.createdAt,
        createdAtDateStr: feedbackDelivery?.createdAt ? moment(Timestamp.createByDate(feedbackDelivery.createdAt)?.value).format(EDateFormat.DAY_FORMAT_LL) : null,
        createdAtDetailStr: feedbackDelivery?.createdAt ? moment(Timestamp.createByDate(feedbackDelivery.createdAt)?.value).format(EDateFormat.DAYTIME_FORMAT_LLLL) : null,
        updatedAt: feedbackDelivery.updatedAt,
        knowledgeId: knowledgeSummary && knowledgeSummary.id ? knowledgeSummary.id : '',
      } as FeedbackSummary)),
      toArray()
    );
  }

  private insertFeedbackDelivery(sendBy: string, feedbackId: string, receivers: AccountMember[], organizationId: string, feedbackProp: FeedbackDto) {
    return from(receivers).pipe(
      map(receiver => receiver),
      concatMap(receiver =>
        this.feedbackDeliveryDomainService.insert({
          feedbackId: feedbackId,
          receivedBy: receiver.accountOrganizationId,
          sendBy: sendBy,
          organizationId: organizationId
        } as FeedbackDeliveryDto)
          .pipe(
            filter(feedbackDelivery => feedbackDelivery !== null),
            take(1),
            concatMap(feedbackDelivery => {
              this.feedbackDocumentService.createFeedbackDocument(({
                id: feedbackDelivery.id,
                feedbackId: feedbackDelivery.feedbackId,
                organizationId: feedbackDelivery.organizationId,
                sendBy: feedbackDelivery.sendBy,
                receivedBy: feedbackDelivery.receivedBy,
                knowledgeId: feedbackProp.knowledgeId,
                title: feedbackProp.title,
                body: feedbackProp.body,
                type: EFeedbackType.INTERNAL,
                unread: feedbackDelivery.unread,
                receiverArchived: feedbackDelivery.receiverArchived,
                senderArchived: feedbackDelivery.senderArchived,
                createdAt: feedbackDelivery.createdAt,
                updatedAt: feedbackDelivery.updatedAt,
                isDeleted: false
              } as FeedbackDocumentDto))
              return of(feedbackDelivery)
            })
          )
      ),
      toArray()
    );
  }

  //es
  public updateFeedbackDocument(id: string) {
    return this.feedbackDeliveryDomainService.selectForUpdateEs(id).pipe(
      take(1),
      filter(feedbackDelivery => feedbackDelivery !== null),
      mergeMap(feedbackDelivery => this.feedbackDomainService.select(feedbackDelivery.feedbackId).pipe(
        take(1),
        filter(feedback => feedback !== null),
        mergeMap(feedback => this.feedbackDocumentService.updateFeedbackDocument(({
          id: feedbackDelivery.id,
          feedbackId: feedbackDelivery.feedbackId,
          organizationId: feedbackDelivery.organizationId,
          sendBy: feedbackDelivery.sendBy,
          receivedBy: feedbackDelivery.receivedBy,
          knowledgeId: feedback?.knowledgeId,
          title: feedback?.title,
          body: feedback?.body,
          type: feedback?.type,
          unread: feedbackDelivery.unread,
          receiverArchived: feedbackDelivery.receiverArchived,
          senderArchived: feedbackDelivery.senderArchived,
          isDeleted: feedbackDelivery.isDeleted,
          createdAt: feedbackDelivery.createdAt,
          updatedAt: feedbackDelivery.updatedAt,
        } as FeedbackDocumentDto))),
      )),
      catchError(err => {
        // console.log('updateFeedbackDocument-catchError', err)
        return EMPTY;
      })
    )
  }
}
