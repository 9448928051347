import { NgModule, Type } from '@angular/core';

import { AnonymousModule } from '../../atoms/anonymous/anonymous.module';
import { CommonModule } from '@angular/common';
import { DeletedUserModule } from '../../atoms/deleted-user/deleted-user.module';
import { HeaderCardComponent } from './header-card.component';

const components: Type<any>[] = [HeaderCardComponent];

@NgModule({
  imports: [CommonModule, AnonymousModule, DeletedUserModule],
  declarations: [components],
  exports: [components],
})
export class HeaderCardModule { }
