// tslint:disable:no-any
import { OrganizationId } from '../../../kernel/organization/value';
import { of, concat, EMPTY } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { SharingVendorDto } from '../dto';
import { SharingVendor } from '../entity';
import { SharingVendorNotFoundError } from '../exception';
import { ISharingVendorRepository } from '../repository';
import { SharingVendorBody, SharingVendorId, SharingVendorKnowledgeConverted, SharingVendorTs } from '../value';

export class SharingVendorDomainService {
  constructor(private readonly sharingVendorRepository: ISharingVendorRepository) { }

  select(sharingVendorId: string) {
    return this.sharingVendorRepository.select(SharingVendorId.create(sharingVendorId))
      .pipe(
        map(item => this.convertDto(item)),
        catchError(_ => of(null))
      );
  }

  selectForUpdateEs(sharingVendorId: string) {
    return this.sharingVendorRepository.selectForUpdateEs(SharingVendorId.create(sharingVendorId)).pipe(map(item => item ? this.convertDto(item) : null));
  }

  insertSharingVendor(sendBy: string, registeredBy: string, body: string, text: string, ts: string, organizationId: string, wroteAt: Date) {
    const sharingVendor = new SharingVendor(this.sharingVendorRepository.generateId());
    sharingVendor.sendBy = AccountOrganizationId.create(sendBy);
    sharingVendor.registeredBy = AccountOrganizationId.create(registeredBy);
    sharingVendor.body = SharingVendorBody.create(body);
    sharingVendor.text = SharingVendorBody.create(text);
    sharingVendor.wroteAt = Timestamp.createByDate(wroteAt);
    sharingVendor.organizationId = OrganizationId.create(organizationId);
    sharingVendor.ts = SharingVendorTs.create(ts);
    sharingVendor.createdAt = Timestamp.createByDate(wroteAt);
    sharingVendor.updatedAt = Timestamp.createByDate(wroteAt);
    sharingVendor.knowledgeConverted = SharingVendorKnowledgeConverted.create(false);
    return this.sharingVendorRepository.insert(sharingVendor).pipe(map(item => this.convertDto(item)));
  }

  selectByOrganizationIdAndOriginTs(ts: string, organizationId: string) {
    return concat(
      this.sharingVendorRepository.selectAll(new FirestoreQueryBuilder<SharingVendor>().equalWhere('ts', ts).equalWhere('organizationId', organizationId)),
      of(null)
    ).pipe(
      take(1),
      tap(sharingVendor => {
        if (sharingVendor === null) {
          throw new SharingVendorNotFoundError('SharingVendor is not found');
        } else {
          return EMPTY
        }
      }),
    );
  }

  selectByOrganizationId(organizationId: string) {
    return concat(
      this.sharingVendorRepository.selectAll(new FirestoreQueryBuilder<SharingVendor>().equalWhere('organizationId', organizationId)),
      of(null)
    ).pipe(
      filter(sharingVendor => sharingVendor !== null),
      map(item => this.convertDto(item)),
    );
  }

  selectByOrganizationIdDesc(organizationId: string) {
    const builder = new FirestoreQueryBuilder<SharingVendor>()
      .orderBy('createdAt', 'desc')
      .limit(1000)
      .equalWhere('organizationId', organizationId);
    return this.sharingVendorRepository.selectAll(builder)
      .pipe(
        filter(sharingVendor => sharingVendor !== null),
        map(item => this.convertDto(item)),
      );
  }

  selectAllSharingVendor() {
    return this.sharingVendorRepository.selectAll(new FirestoreQueryBuilder<SharingVendor>()).pipe(map(item => this.convertDto(item)));
  }

  updateSharingVendor(sharingVendorDto: SharingVendorDto) {
    const sharingVendor = new SharingVendor(SharingVendorId.create(sharingVendorDto.id));
    sharingVendor.sendBy = AccountOrganizationId.create(sharingVendorDto.sendBy);
    sharingVendor.registeredBy = AccountOrganizationId.create(sharingVendorDto.registeredBy);
    sharingVendor.text = SharingVendorBody.create(sharingVendorDto.text);
    sharingVendor.body = SharingVendorBody.create(sharingVendorDto.body);
    sharingVendor.wroteAt = Timestamp.createByDate(sharingVendorDto.wroteAt);
    sharingVendor.createdAt = Timestamp.createByDate(sharingVendorDto.createdAt);
    sharingVendor.organizationId = OrganizationId.create(sharingVendorDto.organizationId);
    sharingVendor.ts = SharingVendorTs.create(sharingVendorDto.ts);
    sharingVendor.knowledgeConverted = sharingVendorDto.knowledgeConverted ? SharingVendorKnowledgeConverted.create(sharingVendorDto.knowledgeConverted) : SharingVendorKnowledgeConverted.create(false);

    return this.sharingVendorRepository.update(sharingVendor).pipe(map(item => this.convertDto(item)));
  }

  deleteSharingVendor(sharingVendorId: string) {
    return this.sharingVendorRepository.delete(SharingVendorId.create(sharingVendorId));
  }

  updateKnowledgeConverted(sharingVendorId: string) {
    const sharingVendor = new SharingVendor(SharingVendorId.create(sharingVendorId));
    sharingVendor.knowledgeConverted = SharingVendorKnowledgeConverted.create(true);
    return this.sharingVendorRepository.update(sharingVendor).pipe(map(item => this.convertDto(item)));
  }

  private convertDto(sharingVendor: SharingVendor): SharingVendorDto {
    return SharingVendor.allFields.reduce((p, key) => {
      if (sharingVendor[key] === undefined) {
        return p;
      }
      const value = sharingVendor[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as SharingVendorDto);
  }
}

