import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AndOrConditionModule } from '../../atoms/and-or-condition/and-or-condition.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BoardComponent } from './board.component';
import { BoardDeleteActionModule } from '../../molecules/board-delete-action/board-delete-action.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CollapseModule } from '../../atoms/collapse/collapse.module';
import { CommonModule } from '@angular/common';
import { DatepickerModule } from '../../atoms/datepicker/datepicker.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchTagAutocompleteModule } from '../../atoms/search-tag-autocomplete/search-tag-autocomplete.module';
import { SearchUserAutocompleteModule } from '../../atoms/search-user-autocomplete/search-user-autocomplete.module';
import { SelectCheckboxModule } from '../../atoms/select-checkbox/select-checkbox.module';
import { TextModule } from '../../atoms/text/text.module';

const components: Type<any>[] = [BoardComponent];

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CollapseModule,
    SelectCheckboxModule,
    TextModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    // TODO: why not load image
    AngularSvgIconModule,
    FormsModule,
    DatepickerModule,
    BoardDeleteActionModule,
    SearchUserAutocompleteModule,
    SearchTagAutocompleteModule,
    AndOrConditionModule
  ],
  declarations: [components],
  exports: [components],
})
export class BoardModule { }
