import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Subscription } from 'rxjs';

import { AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';
import { CreateQuotationModalComponent } from '../../modal/create-quotation-modal/create-quotation-modal.component';
import { MyKnowledgeSharingVendorSidebarService } from 'frontend/admin/app/pages/my-knowledge/organisms/my-knowledge-sharing-vendor-sidebar/state/my-knowledge-sharing-vendor-sidebar.service';
import { MyKnowledgeSharingVendorSidebarQuery } from 'frontend/admin/app/pages/my-knowledge/organisms/my-knowledge-sharing-vendor-sidebar/state/my-knowledge-sharing-vendor-sidebar.query';
import { EPostType } from 'domain/kernel/post';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { DashboardQuery } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.query';
import { ObjectUtils } from 'frontend/admin/app/shared/utils/objects.utils';

@Component({
  selector: 'app-knowledge-quotation',
  templateUrl: './knowledge-quotation.component.html',
  styleUrls: ['./knowledge-quotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeQuotationComponent extends BaseComponent {
  subscription: Subscription;
  isKnowledgeHasBeenQuoted: boolean = true;

  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() label: string;
  @Output() showList = new EventEmitter();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly modalService: ModalService,
    private readonly myKnowledgeSharingVendorSidebarQuery: MyKnowledgeSharingVendorSidebarQuery,
    private readonly myKnowledgeSharingVendorSidebarService: MyKnowledgeSharingVendorSidebarService,
    private readonly dashboardService: DashboardService,
    private readonly dashboardQuery: DashboardQuery,
  ) {
    super();
  }

  protected override onDestroy(): void { }

  protected override onInit(): void { }

  onCreateQuotation($event: any) {
    this.modalService.open(CreateQuotationModalComponent, {
      nzComponentParams: {
        knowledgeSummary: this.knowledgeSummary,
        showList: this.showList,
        isCreateKnowledge: true
      }
    }, (data) => {
      if (data) {
        this.knowledgeSummary.knowledgeConverted = true;
        data.knowledgeConverted = true;
        this.detectSyncToState(data);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onShowList($event: any) {
    this.showList.emit($event);
  }

  private detectSyncToState(summary: KnowledgeSummary) {
    const { sharingVendors } = this.myKnowledgeSharingVendorSidebarQuery.getValue();
    const { boards } = this.dashboardQuery.getValue();

    //  for myknowledge sharing vendor sidebar
    if (sharingVendors?.length > 0 && this.knowledgeSummary?.postType === EPostType.SHARING_VENDOR) {
      this.myKnowledgeSharingVendorSidebarService.popSharingVendor(this.knowledgeSummary.id)
    }

    // for dashboard
    if (Object.keys(boards)?.length > 0) {
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary, { knowledgeConverted: this.knowledgeSummary.knowledgeConverted });
      this.dashboardService.pushKnowledgeSummary(summary);
    }
  }
}
