import { AccountId, AccountOrganizationId, AccountOrganizationRole, AccountOrganizationStatus } from '../value';
import { AccountOrganizationAlreadyExistsError, AccountOrganizationNotFoundError } from '../exception';
import { EAccountOrganizationRole, EAccountOrganizationStatus } from '../enum';
import { Observable, concat, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountOrganization } from '../entity';
import { AccountOrganizationDto } from '../dto';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IAccountOrganizationRepository } from '../repository';
import { OrganizationId } from '../../organization/value';

export class AccountOrganizationDomainService {
  constructor(private readonly accountOrganizationRepository: IAccountOrganizationRepository) { }

  select(id: string) {
    return this.accountOrganizationRepository.select(AccountOrganizationId.create(id))
      .pipe(
        filter(item => item.status.value !== EAccountOrganizationStatus.SUSPEND),
        map(item => this.convertDto(item)),
      );
  }

  selectByIdAndActive(id: string): Observable<AccountOrganizationDto | null> {
    return concat(
      this.accountOrganizationRepository.select(AccountOrganizationId.create(id)).pipe(
        take(1),
        filter(item => item.status.value !== EAccountOrganizationStatus.SUSPEND),
        map(item => this.convertDto(item)),
      ),
      of(null)
    )
  }

  selectById(id: string) {
    return concat(
      this.accountOrganizationRepository.select(AccountOrganizationId.create(id)).pipe(
        take(1),
        map(item => this.convertDto(item)),
      ),
      of(null)
    )
  }

  selectAll() {
    return this.accountOrganizationRepository
      .selectAll(new FirestoreQueryBuilder<AccountOrganization>())
      .pipe(
        map(item => this.convertDto(item))
      );
  }

  selectAllAccountOrganizationByAccountId(accountId: string) {
    const builder = new FirestoreQueryBuilder<AccountOrganization>()
      .orderBy('createdAt', 'asc')
      .limit(1000);

    return this.accountOrganizationRepository
      .selectAll(builder)
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.accountId === accountId && item.status === EAccountOrganizationStatus.ACTIVE),
      );
  }

  selectAllAccountOrganizationByAccountIdAndStatus(accountId: string, status: EAccountOrganizationStatus) {
    return this.accountOrganizationRepository
      .selectAll(
        new FirestoreQueryBuilder<AccountOrganization>()
          .equalWhere('accountId', accountId)
          .equalWhere('status', status)
      )
      .pipe(map(item => this.convertDto(item)));
  }

  selectAllAccountOrganizationByOrganizationId(organizationId: string) {
    const builder = new FirestoreQueryBuilder<AccountOrganization>()
      .orderBy('createdAt', 'asc')
      .limit(1000);

    return this.accountOrganizationRepository
      .selectAll(builder)
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.organizationId === organizationId && item.status === EAccountOrganizationStatus.ACTIVE),
      );
  }

  selectAllSuspendedAndActiveByOrganizationId(organizationId: string) {
    const builder = new FirestoreQueryBuilder<AccountOrganization>()
      .orderBy('createdAt', 'asc')
      .limit(1000);

    return this.accountOrganizationRepository
      .selectAll(builder)
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.organizationId === organizationId),
      );
  }

  selectAllByOrganizationId(organizationId: string) {
    const builder = new FirestoreQueryBuilder<AccountOrganization>()
      .equalWhere('organizationId', organizationId)
      .equalWhere('status', EAccountOrganizationStatus.ACTIVE);

    return this.accountOrganizationRepository
      .selectAll(builder)
      .pipe(
        map(item => this.convertDto(item)),
      );
  }

  selectAllAccountOrganizationByAccountIdAndOrganizationId(accountId: string, organizationId: string) {
    return this.accountOrganizationRepository
      .selectAll(
        new FirestoreQueryBuilder<AccountOrganization>()
          .equalWhere('accountId', accountId)
          .equalWhere('organizationId', organizationId)
          .equalWhere('status', EAccountOrganizationStatus.ACTIVE)
      )
      .pipe(
        map(item => this.convertDto(item))
      );
  }

  selectIfExistAccountOrganization(accountId: string, organizationId: string) {
    return this.accountOrganizationRepository
      .selectAll(
        new FirestoreQueryBuilder<AccountOrganization>()
          .equalWhere('accountId', accountId)
          .equalWhere('organizationId', organizationId)
      )
      .pipe(
        map(item => this.convertDto(item))
      );
  }

  selectByAccountIdAndOrganizationIdAndStatusIsActive(accountId: string, organizationId: string) {
    return concat(
      this.accountOrganizationRepository.selectAll(
        new FirestoreQueryBuilder<AccountOrganization>()
          .equalWhere('accountId', accountId)
          .equalWhere('organizationId', organizationId)
          .equalWhere('status', EAccountOrganizationStatus.ACTIVE)
      ),
      of(null as AccountOrganization)
    ).pipe(
      take(1),
      tap(item => {
        if (item === null) {
          throw new AccountOrganizationNotFoundError('Account organization is not found');
        }
      }),
      map(item => this.convertDto(item))
    );
  }

  insertAccountOrganization(accountId: string, organizationId: string, role: EAccountOrganizationRole) {
    return concat(
      this.accountOrganizationRepository.selectAll(
        new FirestoreQueryBuilder<AccountOrganization>().equalWhere('accountId', accountId).equalWhere('organizationId', organizationId)
      ),
      of(null as AccountOrganization)
    ).pipe(
      take(1),
      tap(item => {
        if (item !== null) {
          throw new AccountOrganizationAlreadyExistsError(
            'this organization name is already exists',
            item.accountId.value,
            item.organizationId.value
          );
        }
      }),
      map(_ => {
        const accountOrganization = new AccountOrganization(this.accountOrganizationRepository.generateId());
        accountOrganization.accountId = AccountId.create(accountId);
        accountOrganization.organizationId = OrganizationId.create(organizationId);
        accountOrganization.role = AccountOrganizationRole.create(role);
        accountOrganization.status = AccountOrganizationStatus.create(EAccountOrganizationStatus.ACTIVE);
        return accountOrganization;
      }),
      mergeMap(accountOrganization => this.accountOrganizationRepository.insert(accountOrganization)),
      map(item => this.convertDto(item))
    );
  }

  suspendAccountOrganization(accountOrganization: AccountOrganizationDto) {
    return this.accountOrganizationRepository.select(AccountOrganizationId.create(accountOrganization.id)).pipe(
      take(1),
      map(item => {
        const newAccountOrganization = new AccountOrganization(AccountOrganizationId.create(accountOrganization.id));
        newAccountOrganization.accountId = AccountId.create(accountOrganization.accountId);
        newAccountOrganization.organizationId = OrganizationId.create(accountOrganization.organizationId);
        newAccountOrganization.role = AccountOrganizationRole.create(accountOrganization.role);
        newAccountOrganization.status = AccountOrganizationStatus.create(EAccountOrganizationStatus.SUSPEND);
        newAccountOrganization.createdAt = item.createdAt;
        return newAccountOrganization;
      }),
      mergeMap(newAccountOrganization => this.accountOrganizationRepository.update(newAccountOrganization)),
      map(item => this.convertDto(item))
    );
  }

  activeAccountOrganization(accountOrganization: AccountOrganizationDto) {
    return this.accountOrganizationRepository.select(AccountOrganizationId.create(accountOrganization.id)).pipe(
      take(1),
      map(item => {
        const newAccountOrganization = new AccountOrganization(AccountOrganizationId.create(accountOrganization.id));
        newAccountOrganization.accountId = AccountId.create(accountOrganization.accountId);
        newAccountOrganization.organizationId = OrganizationId.create(accountOrganization.organizationId);
        newAccountOrganization.role = AccountOrganizationRole.create(accountOrganization.role);
        newAccountOrganization.status = AccountOrganizationStatus.create(EAccountOrganizationStatus.ACTIVE);
        newAccountOrganization.createdAt = item.createdAt;
        return newAccountOrganization;
      }),
      mergeMap(newAccountOrganization => this.accountOrganizationRepository.update(newAccountOrganization)),
      map(item => this.convertDto(item))
    );
  }

  private convertDto(accountOrganization: AccountOrganization): AccountOrganizationDto {
    return AccountOrganization.allFields.reduce((p, key) => {
      if (accountOrganization[key] === undefined) {
        return p;
      }
      const value = accountOrganization[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as AccountOrganizationDto);
  }
}
