import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { BoardCriteria } from 'frontend/admin/app/model/board-criteria.model';
import { BoardDomainService } from 'domain/kernel/board/service';
import { BoardDto } from 'domain/kernel/board/dto';
import { CategoryDomainService } from 'domain/kernel/category/service';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { Injectable } from '@angular/core';
import { SidebarMenuDashboardService } from '../../sidebar/sidebar-menu-dashboard/state/sidebar-menu-dashboard.service';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';

@Injectable({ providedIn: 'root' })
export class BoardService {
  constructor(
    readonly categoryDomainService: CategoryDomainService,
    readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    readonly accountDomainService: AccountDomainService,
    readonly boardDomainService: BoardDomainService,
    private readonly sidebarMenuDashboardService: SidebarMenuDashboardService,
    private readonly dashboardService: DashboardService,
    private readonly workspaceService: WorkspaceService,
  ) { }

  fetch(organizationId: string) {
    return combineLatest([
      this.loadCategories(organizationId),
      this.workspaceService.fetchSuspendedAndActiveMembers(organizationId)
    ]).pipe(
      map(result => ({ categories: result[0], accountOrganizations: result[1] } as any))
    )
  }

  createBoard(boardName: string, criteria: BoardCriteria, currentAccountOrganization: AccountOrganization) {
    const knowledgeView = {
      createdBy: currentAccountOrganization.id,
      title: boardName,
      criteria: JSON.stringify(criteria)
    } as BoardDto;
    return this.boardDomainService.insert(knowledgeView).pipe(
      take(1),
      tap(board => {
        this.dashboardService.pushNewBoard(board, currentAccountOrganization);
        this.sidebarMenuDashboardService.pushToBoards(board);
      }),
    );
  }

  updateBoard(boardName: string, criteria: BoardCriteria, currentAccountOrganization: AccountOrganization, proposal: BoardDto) {
    const updateBoard = proposal;
    updateBoard.title = boardName;
    updateBoard.createdBy = currentAccountOrganization.id;
    updateBoard.criteria = JSON.stringify(criteria);
    return this.boardDomainService.update(proposal).pipe(
      take(1),
      tap(updatedBoard => {
        this.sidebarMenuDashboardService.updateIncaseUpdateBoard(updatedBoard);
      }),
      mergeMap(updatedBoard => this.dashboardService.updateExistingBoard(updatedBoard, currentAccountOrganization)),
    )
  }

  deleteBoard(id: any) {
    return this.boardDomainService.delete(id).pipe(
      take(1),
      tap(board => this.dashboardService.popSelectedBoard(board)),
      tap(board => this.sidebarMenuDashboardService.updateIncaseRemoveBoard(board)),
    )
  }

  isExistsBoard(boardName: string, createdBy: string): Observable<boolean> {
    return this.boardDomainService.selectByTitleAndCreatedBy(boardName, createdBy).pipe(
      take(1),
      map(board => board !== null)
    );
  }

  selectedIfExists(boardName: string, createdBy: string): Observable<BoardDto> {
    return this.boardDomainService.selectByTitleAndCreatedBy(boardName, createdBy).pipe(
      take(1),
    );
  }

  private loadCategories(organizationId: string) {
    return this.categoryDomainService.selectAllByOrganizationId(organizationId)
      .pipe(toArray());
  }
}
