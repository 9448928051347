import { Account } from '../entity';
import { AccountId } from '../value';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';

export abstract class IAccountRepository extends IRepository<AccountId, Account> {
  abstract selectAll(builder: FirestoreQueryBuilder<Account>): Observable<Account>;
  abstract sendEmail(receiver: string, subject: string, html: string);
}
