import { AccountAuthority, AccountEmail, AccountId, AccountImg, AccountName, AccountPhone, AccountShortImg, AccountUid } from '../value';
// tslint:disable:no-any
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { Account } from '../entity';
import { AccountDto } from '../dto';
import { AccountNameProps } from '../props/account-name.props';
import { EAccountAuthority } from '../enum';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IAccountRepository } from '../repository';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class AccountDomainService {
  constructor(private readonly accountRepository: IAccountRepository) { }

  insertAccount(
    email: string,
    name: AccountNameProps,
    img: string,
    shortImg: string,
    authority: EAccountAuthority
  ): Observable<AccountDto> {
    const account = new Account(this.accountRepository.generateId());
    account.email = AccountEmail.create(email.toLowerCase());
    account.name = AccountName.create(name);
    account.img = AccountImg.create(img);
    account.shortImg = AccountShortImg.create(shortImg);
    account.authority = AccountAuthority.create(authority);
    return this.accountRepository.insert(account).pipe(map(item => this.convertDto(item)));
  }

  selectAccount(id: string) {
    return this.accountRepository.select(AccountId.create(id)).pipe(
      map(item => this.convertDto(item)),
      catchError(_ => of(null))
    );
  }

  selectAccountByUid(uid: string) {
    return this.accountRepository.selectAll(new FirestoreQueryBuilder<Account>().equalWhere('uid', uid)).pipe(
      take(1),
      map(item => this.convertDto(item))
    );
  }

  checkEmailExist(email: string) {
    return this.accountRepository.select(AccountEmail.create(email)).pipe(
      map(item => item !== null)
    );
  }

  selectAllAccountByEmail(email: string) {
    return this.accountRepository
      .selectAll(new FirestoreQueryBuilder<Account>().equalWhere('email', email))
      .pipe(map(item => (item !== null ? this.convertDto(item) : null)));
  }

  checkExistAccountByEmail(email: string) {
    return this.accountRepository
      .selectAll(new FirestoreQueryBuilder<Account>().equalWhere('email', email))
      .pipe(map(item => item !== null));
  }

  selectAllAccount(builder = new FirestoreQueryBuilder<Account>(), limit = 10) {
    return this.accountRepository.selectAll(builder.limit(limit)).pipe(map(item => this.convertDto(item)));
  }

  updateAccount(accountDto: AccountDto) {
    const account = new Account(AccountId.create(accountDto.id));
    account.uid = AccountUid.create(accountDto.uid);
    account.email = AccountEmail.create(accountDto.email);
    account.name = AccountName.create(accountDto.name);
    account.authority = AccountAuthority.create(accountDto.authority);
    account.createdAt = Timestamp.createByDate(accountDto.createdAt);
    account.img = AccountImg.create(accountDto.img);
    account.shortImg = AccountImg.create(accountDto.shortImg);
    return this.accountRepository.update(account).pipe(map(item => this.convertDto(item)));
  }

  updateAccountName(accountDto: AccountDto) {
    const account = new Account(AccountId.create(accountDto.id));
    account.name = AccountName.create(accountDto.name);
    return this.accountRepository.update(account).pipe(map(item => this.convertDto(item)));
  }

  updateAccountPhone(accountDto: AccountDto) {
    const account = new Account(AccountId.create(accountDto.id));
    account.phone = AccountPhone.create(accountDto.phone);
    return this.accountRepository.update(account).pipe(map(item => this.convertDto(item)));
  }

  updateAccountAvatar(accountDto: AccountDto) {
    const account = new Account(AccountId.create(accountDto.id));
    account.img = AccountImg.create(accountDto.img);
    account.shortImg = AccountShortImg.create(accountDto.shortImg);
    return this.accountRepository.update(account).pipe(map(item => this.convertDto(item)));
  }

  updateAccountEmail(accountDto: AccountDto) {
    const account = new Account(AccountId.create(accountDto.id));
    account.email = AccountEmail.create(accountDto.email);
    return this.accountRepository.update(account).pipe(map(item => this.convertDto(item)));
  }

  deleteAccount(accountId: string) {
    return this.accountRepository.delete(AccountId.create(accountId));
  }

  sendEmail(receiver: string, subject: string, html: string) {
    return this.accountRepository.sendEmail(receiver, subject, html);
  }

  private convertDto(account: Account): AccountDto {
    return Account.allFields.reduce((p, key) => {
      if (account[key] === undefined) {
        return p;
      }
      const value = account[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as AccountDto);
  }
}
