import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppRoutingConstant } from './shared/constants/app-routing.constant';
import { AuthGuard } from './shared/guard/auth/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: AppRoutingConstant.ROOT[0],
    loadChildren: () => import('./pages/root/root.module').then(m => m.RootModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppRoutingConstant.AUTH[0],
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: `${AppRoutingConstant.INVITE[0]}/:organization`,
    loadChildren: () => import('./pages/invitation/invitation.module').then(m => m.InvitationModule),
  },
  {
    path: AppRoutingConstant.SLACK_CALLBACK[0],
    loadChildren: () => import('./pages/slack-callback/slack-callback.module').then(m => m.SlackCallbackModule),
    canActivate: [AuthGuard]
  },
  {
    path: `${AppRoutingConstant.WORKSPACE[0]}/new`,
    loadChildren: () => import('./pages/workspace-new/workspace-new.module').then(m => m.WorkspaceNewModule),
    canActivate: [AuthGuard]
  },
  {
    path: AppRoutingConstant.ERROR[0],
    loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: `${AppRoutingConstant.SETTING[0]}`,
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: AppRoutingConstant.HELP[0],
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule),
  },
  {
    path: ':id',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspaceModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
