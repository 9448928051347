import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
export interface CreateFeedbackModalState {
  loading: boolean;
  errMsgs: string[];
  failed: boolean;
}

export function createInitialState(): CreateFeedbackModalState {
  return {
    loading: false,
    failed: false,
    errMsgs: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'create-feedback-modal' })
export class CreateFeedbackModalStore extends Store<CreateFeedbackModalState> {
  constructor() {
    super(createInitialState());
  }
}
