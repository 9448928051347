import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { ESlackChannelStatus } from '../enum';

export class SlackChannelStatus extends PrimitiveValueObject<ESlackChannelStatus> {
  static create(value: ESlackChannelStatus): SlackChannelStatus {
    if (value === null) {
      return new SlackChannelStatus(ESlackChannelStatus.ACTIVE);
    }
    return new SlackChannelStatus(value);
  }
}
