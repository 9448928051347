import { EPostType } from "domain/kernel/post";

function genPostType() {
  return [
    { value: EPostType.KNOWLEDGE, label: 'ナレッジ' },
    { value: EPostType.SHARING_VENDOR, label: '投稿シェア' },
    { value: EPostType.KNOWLEDGE_REQUEST, label: 'リクエスト' },
  ]
}

function genDateRangeOptions() {
  return [
    { value: 0, label: '指定なし' },
    { value: 1, label: '過去1日' }, // 1 day
    { value: 7, label: '過去1週間' }, // 1 week
    { value: 30, label: '過去1ヶ月' }, // 1 month
    { value: 365, label: '過去1年' }, // 1 year
    { value: 1000, label: '' }, // date range
  ];
}

export { genPostType, genDateRangeOptions };
