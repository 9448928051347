<div class="files-upload">
  <div class="files-upload__overview">
    <ng-container *ngIf="inputFiles && inputFiles.length > 0">
      <div *ngFor="let file of inputFiles">
        <div *ngIf="isShowExceedMaxFileSizeMessage(file)" class="error-msg">{{ limitFileSizeMessage }}</div>
        <div
          class="attached-file"
          [class.-event-pointer]="isReadonly"
          (click)="openFile(file)"
          [class.over-limit-size]="isShowExceedMaxFileSizeMessage(file)"
        >
          <div class="attached-file__content">
            <div class="attached-file__content__name"> {{ file.name || file.fileName }} </div>
            <ng-container *ngIf="isShowOverviewSummary">
              <div class="attached-file__content__size">
                {{ file.size | toBytesSize
                }}<!--{{ file.type.toUpperCase() }} --></div
              >
            </ng-container>
          </div>

          <div [ngClass]="{ 'attached-file__icon': !isReadonly, 'attached-file__icon--readonly': isReadonly }" *ngIf="file">
            <app-icon-attachment [type]="file?.type" [width]="18" [height]="18"></app-icon-attachment>
          </div>
          <div class="attached-file__actions" *ngIf="!isReadonly">
            <fa-icon [icon]="appData.faTimes" (click)="onRemoveInputFile(file)"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="files && files.length > 0">
      <div *ngFor="let file of files">
        <div *ngIf="isShowExceedMaxFileSizeMessage(file)" class="error-msg">{{ limitFileSizeMessage }}</div>
        <div class="attached-file" [class.over-limit-size]="isShowExceedMaxFileSizeMessage(file)">
          <div class="attached-file__content">
            <div class="attached-file__content__name"> {{ file.name }} </div>
            <ng-container *ngIf="isShowOverviewSummary">
              <div class="attached-file__content__size">
                {{ file.size | toBytesSize
                }}<!-- {{ file.type.toUpperCase() }} --></div
              >
            </ng-container>
          </div>
          <div class="attached-file__icon" *ngIf="file">
            <app-icon-attachment [type]="file?.type" [width]="18" [height]="18"></app-icon-attachment>
          </div>
          <ng-container *ngIf="!isReadonly">
            <div class="attached-file__actions">
              <fa-icon [icon]="appData.faTimes" (click)="onRemoveFile(file)"></fa-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!isReadonly">
    <div class="files-upload__action">
      <form class="form form-upload">
        <dl class="form-group">
          <div *ngIf="isExceedMaxFileNumber() && !isReadonly" class="error-msg">
            <p>{{ limitFileNumberMessage }}</p>
          </div>
          <ng-container *ngIf="!isExceedMaxFileNumber(); else exceedMaxFileNumber">
            <input #file type="file" id="myfile" style="display: none" (change)="onSelectFile($event)" multiple="true" [accept]="accept" />
            <div>
              <div class="add-attachments" (click)="file.click()">
                <div class="add-attachments__container">
                  <div class="icon"><fa-icon [icon]="appData.faPlus"></fa-icon></div>
                  <span>{{ label ? label : 'ファイルを添付' }}</span>
                </div>
              </div>
              <span class="summary" *ngIf="isShowSummary && totalFileSize > 0"
                >ファイル: <strong>{{ files.length + inputFiles.length }}</strong
                >, サイズ: <strong>{{ totalFileSize | toBytesSize }}</strong>
              </span>
            </div>
          </ng-container>
        </dl>
      </form>
    </div>
    <ng-template #exceedMaxFileNumber>
      <div>
        <div class="add-attachments">
          <div class="add-attachments__container">
            <div class="icon"><fa-icon [icon]="appData.faPlus"></fa-icon></div>
            <span>{{ label ? label : 'ファイルを添付' }}</span>
          </div>
        </div>
        <span class="summary" *ngIf="isShowSummary && totalFileSize > 0"
          >ファイル: <strong>{{ files.length + inputFiles.length }}</strong
          >, サイズ: <strong>{{ totalFileSize | toBytesSize }}</strong>
        </span>
      </div>
    </ng-template>
  </ng-container>
</div>
