import { AccessToken, IntegrationEmail, IntegrationId, IntegrationStatus, TargetId, TargetType, TeamId, TeamName, UserName } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountId } from '../../../account/value';
import { EIntegrationStatus } from '../../enum';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IIntegrationRepository } from '../../repository';
import { Integration } from '../../entity';
import { IntegrationNotFoundError } from '../../exception';
import { IsDeleted } from '../../../../kernel/base/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class IntegrationFirestoreRepository implements IIntegrationRepository {
  private static readonly collectionId = 'integration';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: IntegrationId): Observable<Integration> {
    return this.firestoreService.getDocument(IntegrationFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new IntegrationNotFoundError(`integration id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: Integration): Observable<Integration> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(IntegrationFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(integration: Integration): Observable<Integration> {
    integration.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(IntegrationFirestoreRepository.collectionId, integration.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new IntegrationNotFoundError(`integration id「${integration.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(IntegrationFirestoreRepository.collectionId, this.convertToMap(integration));
      }),
      map(_ => integration)
    );
  }

  delete(id: IntegrationId): Observable<void> {
    const proposal = new Integration(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(IntegrationFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): IntegrationId {
    return IntegrationId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<Integration>) {
    return this.firestoreService.getCollection(IntegrationFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(IntegrationFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  private convertToMap(integration: Integration): object {
    return Integration.allFields.reduce((p, key) => {
      if (integration[key] === undefined) {
        return p;
      }
      const value = integration[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const integration = new Integration(IntegrationId.create(item.id));
    integration.accountOrganizationId = AccountId.create(item.accountOrganizationId);
    integration.accessToken = AccessToken.create(item.accessToken);
    integration.email = IntegrationEmail.create(item.email);
    integration.targetId = TargetId.create(item.targetId);
    integration.targetType = TargetType.create(item.targetType);
    integration.teamName = TeamName.create(item.teamName);
    integration.teamId = TeamId.create(item.teamId);
    integration.userName = UserName.create(item.userName);
    integration.status = item && item.status !== null ? IntegrationStatus.create(item.status) : IntegrationStatus.create(EIntegrationStatus.ACTIVE);
    integration.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    integration.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    integration.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return integration;
  }
}
