import { NgModule, Type } from '@angular/core';

import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KnowledgeSelectComponent } from './knowledge-select.component';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { TimelineCardModule } from '../../organisms/timeline-card/timeline-card.module';

const components: Type<any>[] = [KnowledgeSelectComponent];

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TimelineCardModule,
    FontAwesomeModule,
    PipesModule
  ],
  declarations: [components],
  exports: [components],
})
export class KnowledgeSelectModule { }
