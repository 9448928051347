<div class="comment" *ngIf="!loading">
  <div class="comment-image-container" *ngIf="comment">
    <app-avatar [source]="comment.avatarPostOwner" [altText]="comment?.emailPostOwner" [width]="22" [height]="22"></app-avatar>
    <div class="comment-content">
      <div class="comment-author">{{ comment.displayName }}</div>
    </div>
  </div>
  <!-- <ng-container *ngIf="comment && comment.deletedUser === true">
    <app-deleted-user></app-deleted-user>
  </ng-container> -->
  <div class="comment-right-part">
    <div class="comment-text" [class.-hidden]="isOpenEditor" *ngIf="comment.body" [innerHTML]="comment.body | slackMarked"></div>
    <ng-container *ngIf="commentAttachment">
      <div class="mt-md-50" [class.-hidden]="isOpenEditor">
        <app-comment-attachment-view [knowledgeSummary]="commentAttachment" [isReadonly]="true"></app-comment-attachment-view>
      </div>
    </ng-container>

    <app-comment-form
      *ngIf="isEditing()"
      submitLabel="Update"
      [commentId]="comment.id"
      [hasCancelButton]="true"
      [initialText]="comment.body"
      (handleSubmit)="updateComment.emit({ text: $event, commentId: comment.id })"
      (handleCancel)="cancelEditComment()"
    ></app-comment-form>
  </div>
</div>
