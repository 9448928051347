import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface FeedbackSelectReceiverState {
  loading: boolean;
  errMsgs: string[];
  failed: boolean;
}

export function createInitialState(): FeedbackSelectReceiverState {
  return {
    loading: false,
    failed: false,
    errMsgs: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'feedback-select-receiver' })
export class FeedbackSelectReceiverStore extends Store<FeedbackSelectReceiverState> {
  constructor() {
    super(createInitialState());
  }
}
