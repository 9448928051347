<div class="textarea">
  <p class="textarea__label" [class.-required]="required" *ngIf="label">{{ label }}</p>
  <textarea
    #textarea
    appTextareaAutosize
    appIMEEvent
    [class.-error]="control.touched && control.invalid"
    [placeholder]="placeholder"
    (input)="onChange(textarea.value)"
    (blur)="onTouched(textarea.value)"
    (onEnterKey)="onKeyupEnter()"
    [minRows]="minRows"
    [maxRows]="maxRows"
    [preventEnterKeyCreateNewLine]="preventEnterKeyCreateNewLine"
  ></textarea>
</div>
<!-- #needhelp [minRows]="minRows"
    [maxRows]="maxRows" -->
