import { NgModule, Type } from '@angular/core';

import { BoardModule } from '../../organisms/board/board.module';
import { CommonModule } from '@angular/common';
import { CreateBoardFolderModalComponent } from './create-board-folder-modal.component';
import { FormsModule } from '@angular/forms';

const components: Type<any>[] = [CreateBoardFolderModalComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BoardModule
  ],
  declarations: [components],
  exports: [components],
})
export class CreateBoardFolderModalModule { }
