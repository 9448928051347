import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeDocumentReactionTotal extends PrimitiveValueObject<number> {
  static create(value: number): KnowledgeDocumentReactionTotal {
    if (value === null) {
      return new KnowledgeDocumentReactionTotal(0);
    }
    return new KnowledgeDocumentReactionTotal(value);
  }
}
