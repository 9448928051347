
export class MessagesUtils {
  /**
   * @param imgMaxSizeUpload Bytes (in binary)
   * @returns string
   */
  public static imageUploadMaximumFileSize(imgMaxSizeUpload: number): string {
    const imgMaxSizeUpload_MiB = imgMaxSizeUpload / Math.pow(1024, 2);
    return `このファイルはアップロード容量を超えています（${imgMaxSizeUpload_MiB}まで）`;
  }

  public static required(key: string): string {
    return `${key}は必須です。`;
  }

  public static incorrect(key: string): string {
    return `${key}が正しくありません。`;
  }

  public static min(number: number): string {
    return `${number}文字以上`;
  }

  public static max(number: number): string {
    return `${number}文字以内で入力してください。`;
  }
}
