import { CategoryDescription, CategoryId, CategoryLabel } from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountId } from '../../../account/value';
import { Category } from '../../entity';
import { CategoryNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { ICategoryRepository } from '../category.repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
// tslint:disable:no-any
import { IsDeleted } from '../../../../kernel/base/value';
import { OrganizationId } from '../../../organization/value';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class CategoryFirestoreRepository implements ICategoryRepository {
  private static readonly collectionId = 'category';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: CategoryId): Observable<Category> {
    return this.firestoreService.getDocument(CategoryFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new CategoryNotFoundError(`category id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: Category): Observable<Category> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(CategoryFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(category: Category): Observable<Category> {
    category.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(CategoryFirestoreRepository.collectionId, category.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new CategoryNotFoundError(`category id「${category.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(CategoryFirestoreRepository.collectionId, this.convertToMap(category));
      }),
      map(_ => category)
    );
  }

  delete(id: CategoryId): Observable<void> {
    const proposal = new Category(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(CategoryFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): CategoryId {
    return CategoryId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<Category>) {
    return this.firestoreService.getCollection(CategoryFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(CategoryFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  selectIfExists(builder: FirestoreQueryBuilder<Category>): Observable<any> {
    return this.firestoreService.getCollection(CategoryFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? this.convertToEntity(items[0]) : null),
    );
  }

  private convertToMap(category: Category): object {
    return Category.allFields.reduce((p, key) => {
      if (category[key] === undefined) {
        return p;
      }
      const value = category[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const category = new Category(CategoryId.create(item.id));
    category.organizationId = OrganizationId.create(item.organizationId);
    category.label = CategoryLabel.create(item.label);
    category.description = CategoryDescription.create(item.description);
    category.createdBy = AccountId.create(item.createdBy);
    category.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    category.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    category.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return category;
  }
}
