import { WorkspaceState, WorkspaceStore } from './workspace.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class WorkspaceQuery extends Query<WorkspaceState> {
  constructor(protected store: WorkspaceStore) {
    super(store);
  }

  selectOrganization() {
    return this.select('organization');
  }

  selectCurrentAccountOrganization() {
    return this.select('currentAccountOrganization');
  }

  selectIntegrated() {
    return this.select('integrated');
  }

  selectMembers() {
    return this.select('members');
  }
}
