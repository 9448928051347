import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { CategoriesAnchorComponent } from './categories-anchor.component';

const components: Type<any>[] = [CategoriesAnchorComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class CategoriesAnchorModule { }
