<div class="knowledge-card-footer">
  <div class="action">
    <div class="action__item">
      <app-reaction [knowledgeSummary]="knowledgeSummary"> </app-reaction>
    </div>
    <div class="action__item comment" [class.activated]="isCommentActivated">
      <strong> <fa-icon class="" [icon]="appData.faComment" (click)="showDetail.emit(knowledgeSummary)"></fa-icon> </strong
      ><span *ngIf="totalComment > 0">{{ totalComment }}</span>
    </div>
    <ng-container *ngIf="isShowQuote === true">
      <div class="action__item">
        <app-quote [knowledgeSummary]="knowledgeSummary"></app-quote>
      </div>
    </ng-container>
  </div>
</div>
