import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EKnowledgeType } from '../enum';

export class KnowledgeType extends PrimitiveValueObject<EKnowledgeType> {
  static create(value: EKnowledgeType): KnowledgeType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new KnowledgeType(value);
  }
}
