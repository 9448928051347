import { Observable, from } from 'rxjs';

import { CopyFirestorageApiClient } from '../api/copy-firestorage.api.client';
import { FireStorageCopyRequest } from 'serverside/batch/api/model/firestorage-copy.request';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CopyFirestorageService {
  constructor(private readonly copyFirestorageApiClient: CopyFirestorageApiClient) { }

  copyFile(fireStorageCopyRequest: FireStorageCopyRequest): Observable<string> {
    return from(this.copyFirestorageApiClient.copyFile(fireStorageCopyRequest));
  }
}
