import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { AccountOrganizationDto } from 'domain/kernel/account/dto';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { AvatarService } from 'frontend/admin/app/shared/services/avatar.service';
import { Injectable } from '@angular/core';
import { Organization } from 'frontend/lib/model/organization.model';
import { OrganizationDomainService } from 'domain/kernel/organization/service';
import { WorkspaceStore } from './workspace.store';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
  constructor(
    private readonly workspaceStore: WorkspaceStore,
    private readonly appQuery: AppQuery,
    private readonly organizationDomainService: OrganizationDomainService,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly accountDomainService: AccountDomainService,
    private readonly avatarService: AvatarService
  ) { }

  checkAuthorizeByOrganizationId(organizationId: string) {
    const currentAccount = this.appQuery.getValue().currentAccount;
    return combineLatest([
      this.organizationDomainService.selectOrganization(organizationId).pipe(
        take(1),
        mergeMap(organization => organization !== null ? this.updateOrganization(organization) : this.resetOrganization()),
        filter(organization => organization !== null),
        mergeMap(organization => this.accountOrganizationDomainService.selectByAccountIdAndOrganizationIdAndStatusIsActive(currentAccount.id, organization.id)),
        take(1),
        tap(accountOrganization => this.updateCurrentAccountOrganization(accountOrganization)),
      ),
      this.fetchActiveMembers(organizationId)
    ]).pipe(
      map(rs => rs[0])
    )
  }

  // Akita
  updateOrganization(organization: Organization) {
    return this.avatarService.getViewUrl(organization.displayName, organization.shortImg)
      .pipe(
        map(result => {
          this.workspaceStore.update({ organization });
          return organization;
        })
      );
  }

  updateOrganizationDisplayName(displayName: string) {
    const currentOrganization = this.workspaceStore.getValue().organization;
    currentOrganization.displayName = displayName;
    this.workspaceStore.update({ organization: { ...currentOrganization } });
  }

  updateOrganizationAvatar(organization: Organization) {
    const currentOrganization = this.workspaceStore.getValue().organization;
    if (currentOrganization.id === organization.id) {
      currentOrganization.img = organization.img;
      currentOrganization.shortImg = organization.shortImg;
      currentOrganization.updatedAt = organization.updatedAt;
      this.workspaceStore.update({ organization: { ...currentOrganization } });
    }
  }

  resetOrganization() {
    this.workspaceStore.update({ organization: null });
    return of(null);
  }

  updateCurrentAccountOrganization(currentAccountOrganization: AccountOrganization) {
    this.workspaceStore.update({ currentAccountOrganization });
  }

  updateIntegrated(integrated: boolean) {
    this.workspaceStore.update({ integrated });
  }

  resetStore() {
    this.workspaceStore.reset();
  }

  // zone for get member account
  fetchActiveMembers(organizationId: string) {
    const currentAccount = this.appQuery.getValue().currentAccount;
    return this.accountOrganizationDomainService.selectAllAccountOrganizationByOrganizationId(organizationId)
      .pipe(
        mergeMap(accountOrganization => this.genMemberAccount(accountOrganization)),
        toArray(),
        map(account => this.sortMemberAccount(account, currentAccount.id)),
        tap(account => this.updateMemberAccount(account)),
      );
  }

  fetchSuspendedAndActiveMembers(organizationId: string) {
    const currentAccount = this.appQuery.getValue().currentAccount;
    return this.accountOrganizationDomainService.selectAllSuspendedAndActiveByOrganizationId(organizationId)
      .pipe(
        mergeMap(accountOrganization => this.genMemberAccount(accountOrganization)),
        toArray(),
        map(account => this.sortMemberAccount(account, currentAccount.id)),
      );
  }

  sortMemberAccount(memberAccounts: AccountMember[], accountId: string): AccountMember[] {
    const currentAccount = memberAccounts.find(acc => acc.id == accountId);
    const accounts = memberAccounts.filter(acc => acc.id !== accountId);
    return currentAccount ? [currentAccount].concat(accounts) : accounts;
  }

  private genMemberAccount(accountOrganization: AccountOrganizationDto) {
    return this.accountDomainService.selectAccount(accountOrganization.accountId).pipe(
      take(1),
      filter(account => account !== null),
      map(account => ({
        id: account.id,
        name: account.name,
        shortImg: account.shortImg,
        role: accountOrganization.role,
        email: account.email,
        accountOrganization: accountOrganization,
        displayName: `${account?.name?.first} ${account?.name?.last}`,
        accountOrganizationId: accountOrganization.id
      } as AccountMember)));
  }

  updateMemberAccount(memberAccounts: AccountMember[],) {
    this.workspaceStore.update({ members: memberAccounts })
  }

  removeMemberAccount(accountId: string) {
    const currentAccounts = this.workspaceStore.getValue().members;
    const updateAccounts = currentAccounts && currentAccounts.length > 0 ? currentAccounts.filter(account => account.id !== accountId) : [];
    this.workspaceStore.update({ members: updateAccounts });
  }
}
