import { SlackOAuthAccessToken, SlackOAuthAppId, SlackOAuthAuthedUser, SlackOAuthBotId, SlackOAuthId, SlackOAuthScope, SlackOAuthStatus, SlackOAuthTokenType } from '../value';
import { concat, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { ESlackOAuthStatus } from '../enum';
import { FirestoreQueryBuilder } from './../../../../lib/gcp/builder/firestore-query.builder';
import { ISlackOAuthRepository } from '../repository';
import { SlackOAuth } from '../entity';
import { SlackOAuthDto } from '../dto';
import { SlackOAuthNotFoundError } from '../exception';
import { SlackTeamId } from '../../slack-team/value';

export class SlackOAuthDomainService {
  constructor(private readonly slackOauthRepository: ISlackOAuthRepository) { }

  generateSlackOAuthId(): string {
    return this.slackOauthRepository.generateId().value;
  }

  select(id: string) {
    return this.slackOauthRepository.select(SlackOAuthId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectByTeamIdAndAppId(teamId: string, appId: string) {
    return concat(
      this.slackOauthRepository.selectAll(
        new FirestoreQueryBuilder<SlackOAuth>().equalWhere('teamId', teamId).equalWhere('appId', appId)
      ),
      of(null)
    ).pipe(
      take(1),
      tap(team => {
        if (team === null) {
          throw new SlackOAuthNotFoundError(`team ${teamId} is not found`);
        }
      }),
      map(item => this.convertDto(item))
    );
  }

  insertSlackOAuth(proposal: SlackOAuthDto) {
    const slackOAuth = new SlackOAuth(SlackOAuthId.create(proposal.id));

    slackOAuth.authedUser = SlackOAuthAuthedUser.create(proposal.authedUser);
    slackOAuth.scope = SlackOAuthScope.create(proposal.scope);
    slackOAuth.tokenType = SlackOAuthTokenType.create(proposal.tokenType);
    slackOAuth.accessToken = SlackOAuthAccessToken.create(proposal.accessToken);
    slackOAuth.botUserId = SlackOAuthBotId.create(proposal.botUserId);

    // slackOAuth.userToken = SlackOAuthUserToken.create(proposal.userToken);
    // slackOAuth.botToken = SlackOAuthBotToken.create(proposal.botToken);
    // slackOAuth.userScope = SlackOAuthUserScope.create(proposal.userScope);
    // slackOAuth.botScope = SlackOAuthBotScope.create(proposal.botScope);

    slackOAuth.appId = SlackOAuthAppId.create(proposal.appId);
    slackOAuth.teamId = SlackTeamId.create(proposal.teamId);
    slackOAuth.status = SlackOAuthStatus.create(ESlackOAuthStatus.ACTIVE);

    return this.slackOauthRepository.insert(slackOAuth).pipe(map(item => this.convertDto(item)));
  }

  syncSlackOAuth(slackOAuthDto: SlackOAuthDto) {
    return this.slackOauthRepository.select(SlackOAuthId.create(slackOAuthDto.id)).pipe(
      take(1),
      map(item => {
        const slackOAuth = new SlackOAuth(SlackOAuthId.create(slackOAuthDto.id));

        slackOAuth.scope = SlackOAuthScope.create(slackOAuthDto.scope);
        slackOAuth.accessToken = SlackOAuthAccessToken.create(slackOAuthDto.accessToken);
        slackOAuth.status = SlackOAuthStatus.create(ESlackOAuthStatus.ACTIVE);

        slackOAuth.createdAt = item.createdAt;
        return slackOAuth;
      }),
      mergeMap(SlackOAuth => this.slackOauthRepository.update(SlackOAuth)),
      map(item => this.convertDto(item))
    );
  }

  suspendSlackOAuth(slackOAuthDto: SlackOAuthDto) {
    return this.slackOauthRepository.select(SlackOAuthId.create(slackOAuthDto.id)).pipe(
      take(1),
      filter(item => item !== null),
      map(item => {
        const slackOAuth = new SlackOAuth(SlackOAuthId.create(slackOAuthDto.id));
        slackOAuth.status = SlackOAuthStatus.create(ESlackOAuthStatus.SUSPEND);
        slackOAuth.createdAt = item.createdAt;
        return slackOAuth;
      }),
      mergeMap(SlackOAuth => this.slackOauthRepository.update(SlackOAuth)),
      map(item => this.convertDto(item))
    );
  }

  activeSlackOAuth(slackOAuthDto: SlackOAuthDto) {
    return this.slackOauthRepository.select(SlackOAuthId.create(slackOAuthDto.id)).pipe(
      take(1),
      map(item => {
        const slackOAuth = new SlackOAuth(SlackOAuthId.create(slackOAuthDto.id));
        slackOAuth.status = SlackOAuthStatus.create(ESlackOAuthStatus.ACTIVE);
        slackOAuth.createdAt = item.createdAt;
        return slackOAuth;
      }),
      mergeMap(slackOAuth => this.slackOauthRepository.update(slackOAuth)),
      map(item => this.convertDto(item))
    );
  }

  private convertDto(oauth: SlackOAuth): SlackOAuthDto {
    return SlackOAuth.allFields.reduce((p, key) => {
      if (oauth[key] === undefined) {
        return p;
      }
      const value = oauth[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as SlackOAuthDto);
  }
}
