import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-knowledge-body-title',
  templateUrl: './knowledge-body-title.component.html',
  styleUrls: ['./knowledge-body-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeBodyTitleComponent {
  @Input() isShowUpdatedAtJIT: boolean = false;
}
