import { EPostType } from '../../post';
import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class ReactionTargetType extends PrimitiveValueObject<EPostType> {
  static create(value: EPostType): ReactionTargetType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new ReactionTargetType(value);
  }
}
