import { ActivityId, ActivityType, TargetId } from '../value';
import { EMPTY, Observable, concat, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { Activity } from '../entity';
import { ActivityDto } from '../dto';
import { ActivityNotFoundError } from '../exception';
import { EActivityType } from '../enum';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IActivityRepository } from '../repository';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class ActivityDomainService {
  constructor(private readonly activityRepository: IActivityRepository) { }

  select(activityId: string) {
    return this.activityRepository.select(ActivityId.create(activityId)).pipe(map(item => this.convertDto(item)));
  }

  selectAll() {
    return this.activityRepository.selectAll(new FirestoreQueryBuilder<Activity>()).pipe(map(item => this.convertDto(item)));
  }

  selectActivityByCreatedUserAndTargetIdAndType(createdBy: string, targetId: string, type: EActivityType) {
    return concat(
      this.activityRepository.selectAll(
        new FirestoreQueryBuilder<Activity>().equalWhere('createdBy', createdBy).equalWhere('targetId', targetId).equalWhere('type', type)
      ),
      of(null)
    ).pipe(
      take(1),
      tap(activity => {
        if (activity === null) {
          throw new ActivityNotFoundError('Activity is not found');
        }
      }),
      map(item => this.convertDto(item))
    );
  }

  synchronizeActivity(createdBy: string, targetId: string, activityType: EActivityType) {
    return this.selectActivityByCreatedUserAndTargetIdAndType(createdBy, targetId, activityType).pipe(
      catchError(err => {
        if (err instanceof ActivityNotFoundError) {
          return this.insert(createdBy, targetId, activityType)
        }
        return EMPTY;
      }),
      mergeMap(activity => this.update(activity))
    );
  }

  insert(createdBy: string, targetId: string, activityType: EActivityType): Observable<ActivityDto> {
    const activity = new Activity(this.activityRepository.generateId());
    activity.targetId = TargetId.create(targetId);
    activity.createdBy = AccountOrganizationId.create(createdBy);
    activity.type = ActivityType.create(activityType);
    return this.activityRepository.insert(activity).pipe(map(item => this.convertDto(item)));
  }

  update(activityDto: ActivityDto) {
    const activity = new Activity(ActivityId.create(activityDto.id));
    activity.targetId = TargetId.create(activityDto.targetId);
    activity.createdBy = AccountOrganizationId.create(activityDto.createdBy);
    activity.type = ActivityType.create(activityDto.type);
    activity.createdAt = Timestamp.createByDate(activityDto.createdAt);
    return this.activityRepository.update(activity).pipe(map(item => this.convertDto(item)));
  }

  delete(activityId: string) {
    return this.activityRepository.delete(ActivityId.create(activityId));
  }

  private convertDto(activity: Activity): ActivityDto {
    return Activity.allFields.reduce((p, key) => {
      if (activity[key] === undefined) {
        return p;
      }
      const value = activity[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as ActivityDto);
  }
}
