import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/fontawesome-pro-light';
import { AccountOrganizationAlreadyExistsError } from 'domain/kernel/account/exception';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { Account } from 'frontend/lib/model/account.model';
import { SendInvitationEmailService } from 'frontend/lib/service/send-invitation-email.service';
import { UserTokenService } from 'frontend/lib/service/user-token.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { debounceTime, distinctUntilChanged, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { InviteAccountModalQuery } from './state/invite-account-modal.query';
import { InviteAccountModalService } from './state/invite-account-modal.service';

@Component({
  selector: 'app-invite-account-modal',
  templateUrl: './invite-account-modal.component.html',
  styleUrls: ['./invite-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteAccountModalComponent extends BaseComponent {
  faSearch = faSearch;
  faTimes = faTimes;
  formGroup: FormGroup;
  onSubmitted: ((accountOrganization: AccountOrganization) => void)[] = [];
  inProcess: boolean = false;

  public isSearch = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    readonly inviteAccountModalQuery: InviteAccountModalQuery,
    readonly workspaceQuery: WorkspaceQuery,
    readonly inviteAccountModalService: InviteAccountModalService,
    readonly appQuery: AppQuery,
    readonly sendInvitationEmailService: SendInvitationEmailService,
    private readonly formBuilder: FormBuilder,
    private readonly userTokenService: UserTokenService,
    private readonly nzModalRef: NzModalRef<InviteAccountModalComponent>,
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  protected override onInit(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(_ => this.inviteAccountModalService.updateLoading(true)),
        debounceTime(CommonConstant.INPUT_DEBOUNCE_INTERVAL),
        distinctUntilChanged(),
        switchMap(value => {
          this.isSearch = !!value?.email;
          return this.inviteAccountModalService.searchAccounts(value.email.trim() || '').pipe(toArray());
        }),
        tap(_ => this.inviteAccountModalService.updateLoading(false)),
        tap(accounts => {
          this.inviteAccountModalService.updateAccounts(accounts);
        })
      )
      .subscribe();
  }

  protected override onDestroy(): void {
    this.inviteAccountModalService.resetStore();
  }

  addEventListener(fn: (account: AccountOrganization) => void) {
    this.onSubmitted.push(fn);
  }

  updateSelectedAccount(account: Account) {
    if (account instanceof Event) {
      return;
    }
    this.inviteAccountModalService.updateAccounts(null);
    this.inviteAccountModalService.updateSelectedAccount(account);
  }

  cancelAccount() {
    this.inviteAccountModalService.updateSelectedAccount(null);
  }

  addWorkspace() {
    const { selectedAccount } = this.inviteAccountModalQuery.getValue();
    const { organization } = this.workspaceQuery.getValue();

    if (selectedAccount == null || organization == null || selectedAccount.id === null || organization.id === null) {
      return;
    }

    if (this.inProcess) {
      return;
    }

    this.inProcess = true;

    this.inviteAccountModalService.addAccountOrganization(selectedAccount.id, organization.id).pipe(
      tap(accountOrganization => this.onSubmitted.forEach(fn => fn(accountOrganization))),
      tap(accountOrganization => this.close(accountOrganization))
    ).subscribe({
      complete: () => {
        this.inProcess = false;
        // this.close();
      },
      error: err => {
        this.inProcess = false;
        if (err instanceof AccountOrganizationAlreadyExistsError) {
        }
        console.error(err);
        this.close();
      }
    });
  }

  sendEmailInvitation() {
    const { email } = this.formGroup.getRawValue();
    if (email == null || email == '' || Validators.email(email) !== null) {
      return;
    }
    const { organization } = this.workspaceQuery.getValue();
    if (organization == null || organization.id == '') {
      return;
    }

    this.appQuery.selectCurrentAccount().pipe(
      take(1),
      mergeMap(account =>
        this.userTokenService.generateToken({ organizationId: organization.id, invitedEmail: email, accountEmail: account.email }).pipe(
          take(1),
          map(token =>
            this.sendInvitationEmailService.sendInvitation({
              email: email,
              body: '',
              account: '',
              lastName: account.name?.last,
              firstName: account.name?.first,
              token: token.token,
              flag: 1,
              siteUrl: this.document.location.origin,
              organizationName: organization.displayName
            })
          ),
        )
      ),
      tap(_ => this.close())
    ).subscribe(res => {
    });
  }

  close(data?: any) {
    this.nzModalRef.close(data);
  }
}
