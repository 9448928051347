import { Account } from '../../../lib/model/account.model';
import { AppStore } from './app.store';
import { AuthService } from 'frontend/lib/service/auth.service';
import { Injectable } from '@angular/core';
import { RootWorkspaceService } from '../pages/root/state/root.service';
import { Router } from '@angular/router';
import { WorkspaceService } from '../pages/workspace/components/workspace/state/workspace.service';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private readonly appStore: AppStore,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly rootWorkspaceService: RootWorkspaceService,
    private readonly workspaceService: WorkspaceService,
  ) { }

  logout(isUserInput = true) {
    this.authService.logout(isUserInput).subscribe(() => {
      this.rootWorkspaceService.resetStore();
      this.workspaceService.resetStore();
      this.authService.resetAccount();
      this.router.navigateByUrl('/auth/login');
    });
  }

  registerWithInvitation() {
    this.authService.logout().subscribe(() => {
      this.rootWorkspaceService.resetStore();
      this.workspaceService.resetStore();
    });
  }

  // Akita
  updateLoading(loading: boolean) {
    this.appStore.update({ loading });
  }

  updateCurrentAccount(currentAccount: Account) {
    this.appStore.update({ currentAccount });
  }

  resetStore() {
    this.appStore.reset();
  }
}
