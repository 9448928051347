import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KnowledgeDocumentDto } from 'domain/kernel/knowledge-document/dto';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IKnowledgeDocumentApi } from 'serverside/batch/api/interface/knowledge-document.api.interface';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeDocumentApiClient implements IKnowledgeDocumentApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }

  createKnowledge(knowledgeDocumentDto: KnowledgeDocumentDto): Promise<KnowledgeDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/knowledge-document/create-knowledge', knowledgeDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

  updateKnowledge(knowledgeDocumentDto: KnowledgeDocumentDto): Promise<KnowledgeDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/knowledge-document/update-knowledge', knowledgeDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

}
