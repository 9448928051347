import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MyKnowledgeDocumentDto } from 'domain/kernel/my-knowledge-document/dto';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IMyKnowledgeDocumentApi } from 'serverside/batch/api/interface/my-knowledge-document.api.interface';
import { BATCH_API_ENDPOINT } from '../token';

@Injectable({
  providedIn: 'root'
})
export class MyKnowledgeDocumentApiClient implements IMyKnowledgeDocumentApi {
  constructor(private readonly http: HttpClient, @Inject(BATCH_API_ENDPOINT) private readonly batchApiEndpoint: string) { }

  createMyKnowledge(myKnowledgeDocumentDto: MyKnowledgeDocumentDto): Promise<MyKnowledgeDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/my-knowledge-document/create-my-knowledge', myKnowledgeDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }

  updateMyKnowledge(myKnowledgeDocumentDto: MyKnowledgeDocumentDto): Promise<MyKnowledgeDocumentDto> {
    return this.http
      .post(this.batchApiEndpoint + '/v1/my-knowledge-document/update-my-knowledge', myKnowledgeDocumentDto)
      .pipe(
        map(response => response as any),
        catchError(_ => EMPTY)
      )
      .toPromise();
  }
}
