import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';
import { IOption } from 'frontend/lib/model/option.model';

@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectCheckboxComponent {
  @Input() dataSource: IOption<any, any>[] = [];
  @Output() selected = new EventEmitter();
}
