import { Pipe, PipeTransform } from '@angular/core';

import { CommonConstant } from '../constants/common.constant';

@Pipe({
  name: 'toBadgeLabel'
})
export class ToBadgeLabelPipe implements PipeTransform {
  transform(value: any): any {
    let size = Math.abs(value);
    if (Number.isNaN(size)) {
      return 'Unknown' + size;
    }

    if (size <= CommonConstant.MAX_COUNT_BADGE) {
      return size.toString();
    } else if (size > CommonConstant.MAX_COUNT_BADGE) {
      return '9+';
    } else {
      return '0';
    }
  }
}
