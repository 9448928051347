import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { EFeedbackToSlackStatus } from '../enum';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class FeedbackToSlackStatus extends PrimitiveValueObject<EFeedbackToSlackStatus> {
  static create(value: EFeedbackToSlackStatus): FeedbackToSlackStatus {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new FeedbackToSlackStatus(value);
  }
}
