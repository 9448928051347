import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FeedbackSearchKnowledgeResultsModule } from '../feedback-search-knowledge-results/feedback-search-knowledge-results.module';
import { FeedbackSelectKnowledgeComponent } from './feedback-select-knowledge.component';
import { FeedbackSelectReceiverModule } from '../feedback-select-receiver/feedback-select-receiver.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { KnowledgeSelectModule } from '../knowledge-select/knowledge-select.module';
import { SearchBoxModule } from '../../atoms/search-box/search-box.module';
import { TextModule } from '../../atoms/text/text.module';
import { TextareaModule } from '../../atoms/textarea/textarea.module';

const components: Type<any>[] = [FeedbackSelectKnowledgeComponent];

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    TextModule,
    TextareaModule,
    KnowledgeSelectModule,
    FeedbackSelectReceiverModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    SearchBoxModule,

    // add knowledge search service
    FeedbackSearchKnowledgeResultsModule,
    InfiniteScrollModule
    // KnowledgeSearchModule,
    // KnowledgeSearchResultModule,
    // KnowledgeCreateRequestModule,
  ],
  declarations: [components],
  exports: [components],
})
export class FeedbackSelectKnowledgeModule { }
