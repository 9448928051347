import { Store, StoreConfig } from '@datorama/akita';

import { BoardDto } from 'domain/kernel/board/dto';
import { EDashboardVisualization } from 'frontend/admin/app/enum/dashboard-visualization.enum';
import { EScrollPaging } from 'frontend/admin/app/enum/scroll-paging.enum';
import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';

export interface BoardSummary {
  id: string;
  name: string;
  board: BoardDto,
  summaries: KnowledgeSummary[];
  current: number, // current page index
  total: number, // total records
  max: number, // total pages

  vault: KnowledgeSummary[], // paging server side
  scrollId: number,// paging server side
  maxScrollId: number,// paging server side
  scrollPageSize: number// paging server side
}

export interface BoardView {
  [id: string]: {
    id: string;
    name: string;
    board: BoardDto,
    summaries: KnowledgeSummary[];
    current: number, // current page index // paging client side
    total: number, // total records // paging client side
    max: number, // total pages // paging client side

    vault: KnowledgeSummary[], // paging server side
    scrollId: number,// paging server side
    maxScrollId: number,// paging server side
    scrollPageSize: number// paging server side
  };
}

export interface DashboardState {
  modeView: EDashboardVisualization;
  boards: BoardView;
  isOpenBoard: boolean;
  selectedBoard: BoardView;
  limitBoardViewSize: number;
}

export function createInitialState(): DashboardState {
  return {
    modeView: EDashboardVisualization.TIMELINE_VALUE,
    boards: {},
    isOpenBoard: false,
    selectedBoard: {},
    limitBoardViewSize: EScrollPaging.LIMIT_BOARD_VIEW_SIZE
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboard' })
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(createInitialState());
  }
}
