<div class="timeline-card-header">
  <div class="d-flex justify-content-between align-items-center">
    <div class="feed-identity">
      <ng-container *ngIf="writer && knowledgeSummary.postType !== postType.KNOWLEDGE_REQUEST">
        <div *ngIf="writer" class="feed-identity__avatar">
          <ng-container *ngIf="isQuotation; else defaultAvatar">
            <app-avatar
              [altText]="writer.email"
              [source]="writer.shortImg"
              [inputUrl]="writerUrl"
              [width]="17"
              [height]="17"
              (handledLoaded)="onStorageAvatar($event)"
            ></app-avatar>
          </ng-container>
          <ng-template #defaultAvatar>
            <app-avatar
              [altText]="writer.email"
              [source]="writer.shortImg"
              [inputUrl]="writerUrl"
              [width]="25"
              [height]="25"
              (handledLoaded)="onStorageAvatar($event)"
            ></app-avatar>
          </ng-template>
        </div>
        <div class="feed-identity__name">
          <span class="">{{ writer.name?.first }} {{ writer.name?.last }}</span>
          <ng-container *ngIf="knowledgeSummary.postType === postType.SHARING_VENDOR && knowledgeSummary.wroteAt; else knowledgeJit">
            <app-jit [shortenTimestamp]="knowledgeSummary.wroteAtShortStr"></app-jit>
          </ng-container>
          <ng-template #knowledgeJit>
            <app-jit [shortenTimestamp]="knowledgeSummary.updatedAtShortStr"></app-jit>
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="writer && knowledgeSummary.postType === postType.KNOWLEDGE_REQUEST">
        <div class="feed-identity-request">
          <span class="feed-identity-request__text">リクエスト</span>
          <app-jit [shortenTimestamp]="knowledgeSummary.updatedAtShortStr"></app-jit>
        </div>
      </ng-container>
      <!-- <ng-container *ngIf="deletedUser">
          <app-deleted-user></app-deleted-user>
        </ng-container> -->
    </div>
  </div>
</div>
