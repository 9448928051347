<ng-container [ngSwitch]="type">
  <div *ngIf="label">
    <label>{{ label ? label : '' }}</label>
  </div>
  <ng-container *ngSwitchCase="'date'">
    <nz-date-picker [(ngModel)]="date" [nzSize]="size" (ngModelChange)="onChange.emit($event)"></nz-date-picker>
  </ng-container>
  <ng-container *ngSwitchCase="'dateRange'">
    <nz-range-picker [(ngModel)]="dateRange" [nzSize]="size" (ngModelChange)="onChange.emit($event)"></nz-range-picker>
    <!-- <ng-container *ngIf="start || end; else noInputDateRange">
      <nz-range-picker [(ngModel)]="dateRange" [nzSize]="size" (ngModelChange)="onChange.emit($event)"></nz-range-picker>
    </ng-container>
    <ng-template #noInputDateRange>
      <nz-range-picker [nzSize]="size" (ngModelChange)="onChange.emit($event)"></nz-range-picker>
    </ng-template> -->
  </ng-container>
  <ng-container *ngSwitchCase="'dateTimeRange'">
    <nz-range-picker [(ngModel)]="dateRange" nzShowTime [nzSize]="size" (ngModelChange)="onChange.emit($event)"></nz-range-picker>
  </ng-container>
</ng-container>
