import { FeedbackKnowledgeLink, FeedbackToSlackAccessToken, FeedbackToSlackEmail, FeedbackToSlackId, FeedbackToSlackStatus, FeedbackToSlackText, FeedbackToSlackTitle, TargetId } from '../value';

import { AccountOrganizationId } from '../../account/value';
import { FeedbackToSlack } from '../entity';
import { FeedbackToSlackDto } from '../dto';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFeedbackToSlackRepository } from '../repository';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class FeedbackToSlackDomainService {
  constructor(private readonly feedbackToSlackRepository: IFeedbackToSlackRepository) { }

  select(id: string) {
    return this.feedbackToSlackRepository.select(FeedbackToSlackId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectAllFeedback(builder = new FirestoreQueryBuilder<FeedbackToSlack>()) {
    return this.feedbackToSlackRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  insert(feedbackToSlackDto: FeedbackToSlackDto): Observable<FeedbackToSlackDto> {
    const feedbackToSlack = new FeedbackToSlack(this.feedbackToSlackRepository.generateId());
    feedbackToSlack.sendBy = AccountOrganizationId.create(feedbackToSlackDto.sendBy);
    feedbackToSlack.receivedBy = AccountOrganizationId.create(feedbackToSlackDto.receivedBy);
    feedbackToSlack.receiverEmail = FeedbackToSlackEmail.create(feedbackToSlackDto.receiverEmail);
    feedbackToSlack.targetId = TargetId.create(feedbackToSlackDto?.targetId);
    feedbackToSlack.slackStatus = FeedbackToSlackStatus.create(feedbackToSlackDto.slackStatus);
    feedbackToSlack.accessToken = FeedbackToSlackAccessToken.create(feedbackToSlackDto.accessToken);
    feedbackToSlack.text = FeedbackToSlackText.create(feedbackToSlackDto.text);
    feedbackToSlack.title = FeedbackToSlackTitle.create(feedbackToSlackDto.title);
    feedbackToSlack.knowledgeLink = FeedbackKnowledgeLink.create(feedbackToSlackDto.knowledgeLink);
    return this.feedbackToSlackRepository.insert(feedbackToSlack).pipe(map(item => this.convertDto(item)));
  }

  update(feedbackToSlackDto: FeedbackToSlackDto) {
    const feedbackToSlack = new FeedbackToSlack(FeedbackToSlackId.create(feedbackToSlackDto.id));
    feedbackToSlack.sendBy = AccountOrganizationId.create(feedbackToSlackDto.sendBy);
    feedbackToSlack.receivedBy = AccountOrganizationId.create(feedbackToSlackDto.receivedBy);
    feedbackToSlack.receiverEmail = FeedbackToSlackEmail.create(feedbackToSlackDto.receiverEmail);
    feedbackToSlack.targetId = TargetId.create(feedbackToSlackDto?.targetId);
    feedbackToSlack.slackStatus = FeedbackToSlackStatus.create(feedbackToSlackDto.slackStatus);
    feedbackToSlack.accessToken = FeedbackToSlackAccessToken.create(feedbackToSlackDto.accessToken);
    feedbackToSlack.text = FeedbackToSlackText.create(feedbackToSlackDto.text);
    feedbackToSlack.title = FeedbackToSlackTitle.create(feedbackToSlackDto.title);
    feedbackToSlack.knowledgeLink = FeedbackKnowledgeLink.create(feedbackToSlackDto.knowledgeLink);
    return this.feedbackToSlackRepository.update(feedbackToSlack).pipe(map(item => this.convertDto(item)));
  }

  deleteFeedback(id: string) {
    return this.feedbackToSlackRepository.delete(FeedbackToSlackId.create(id));
  }

  private convertDto(feedbackToSlack: FeedbackToSlack): FeedbackToSlackDto {
    return FeedbackToSlack.allFields.reduce((p, key) => {
      if (feedbackToSlack[key] === undefined) {
        return p;
      }
      const value = feedbackToSlack[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as FeedbackToSlackDto);
  }
}
