import { ArgumentNullException } from 'utility/exception/argument-null.exception';
import { PrimitiveValueObject } from 'utility/model/primitive-value-object.model';

export class BoardCriteria extends PrimitiveValueObject<string> {
  static create(value: string): BoardCriteria {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new BoardCriteria(value);
  }
}
