import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { BodyKnowledgeDetailModule } from '../../molecules/body-knowledge-detail/body-knowledge-detail.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CategoriesAnchorModule } from '../../molecules/categories-anchor/categories-anchor.module';
import { CommentGroupModule } from '../../molecules/comment-group/comment-group.module';
import { CommonModule } from '@angular/common';
import { DeletedUserModule } from '../../atoms/deleted-user/deleted-user.module';
import { DetailPostModalComponent } from './detail-post-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderCardModule } from '../header-card/header-card.module';
import { KnowledgeAttachmentViewModule } from '../../molecules/knowledge-attachment-view/knowledge-attachment-view.module';
import { KnowledgeBodyTitleModule } from '../../molecules/knowledge-body-title/knowledge-body-title.module';
import { KnowledgeCardFooterModule } from '../../molecules/knowledge-card-footer/knowledge-card-footer.module';
import { KnowledgeQuotationModule } from '../../molecules/knowledge-quotation/knowledge-quotation.module';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';
import { TextModule } from '../../atoms/text/text.module';
import { TextareaModule } from '../../atoms/textarea/textarea.module';

const components: Type<any>[] = [DetailPostModalComponent];

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    HeaderCardModule,
    KnowledgeQuotationModule,
    ButtonModule,
    KnowledgeBodyTitleModule,
    BodyKnowledgeDetailModule,
    KnowledgeAttachmentViewModule,
    CategoriesAnchorModule,
    KnowledgeCardFooterModule,
    CommentGroupModule,
    DeletedUserModule,
    TextModule,
    TextareaModule,
    ReactiveFormsModule,
    FormsModule,
    //fa-icon
    FontAwesomeModule,
    AngularSvgIconModule,
    PipesModule
  ],
  declarations: [components],
  exports: [components],
})
export class DetailPostModalModule { }
