export enum ELimitCharacters {
  ACCOUNT_DISPLAY_FIRST_NAME_LENGTH = 10,
  ACCOUNT_DISPLAY_LAST_NAME_LENGTH = 10,
  ACCOUNT_DISPLAY_PHONE_LENGTH = 15,
  TEXTAREA_DEFAULT_MAX_LENGTH = 524288,
  TEXTAREA_DEFAULT_MAX_ROWS = 2,
  TEXTAREA_DEFAULT_MIN_ROWS = 2,
  INPUT_TEXT_DEFAULT_MAX_LENGTH = 524288,
  KNOWLEDGE_CONTENT_OFFSET_HEIGHT = 110,
}
