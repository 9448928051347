import {
  AssetStorageDownloadUrl,
  AssetStorageId,
  AssetStorageMimeType,
  AssetStorageName,
  AssetStoragePermalink,
  AssetStorageSize,
  AssetStorageSlackFileId,
  AssetStorageType
} from '../../value';
import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AssetStorage } from '../../entity';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IAssetStorageRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { PostId } from '../../../post';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class AssetStorageFirestoreRepository implements IAssetStorageRepository {
  private static readonly collectionId = 'asset_storage';
  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: AssetStorageId): Observable<AssetStorage> {
    return this.firestoreService.getDocument(AssetStorageFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          return null as AssetStorage;
        } else {
          return this.convertToEntity(item);
        }
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<AssetStorage>): Observable<AssetStorage> {
    return this.firestoreService.getCollection(AssetStorageFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(AssetStorageFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item)),
    );
  }

  insert(item: AssetStorage): Observable<AssetStorage> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(AssetStorageFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(assetStorage: AssetStorage): Observable<AssetStorage> {
    assetStorage.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(AssetStorageFirestoreRepository.collectionId, assetStorage.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new Error('asset storage is not found');
        }
        return this.firestoreService.setDocument(AssetStorageFirestoreRepository.collectionId, this.convertToMap(assetStorage));
      }),
      map(_ => assetStorage)
    );
  }

  delete(id: AssetStorageId) {
    const proposal = new AssetStorage(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(AssetStorageFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): AssetStorageId {
    return AssetStorageId.create(this.firestoreService.generateId());
  }

  selectIfExists(builder: FirestoreQueryBuilder<AssetStorage>): Observable<any> {
    return this.firestoreService.getCollection(AssetStorageFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items : null),
    );
  }

  private convertToMap(assetStorage: AssetStorage): object {
    return AssetStorage.allFields.reduce((p, key) => {
      if (assetStorage[key] === undefined) {
        return p;
      }
      const value = assetStorage[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const assetStorage = new AssetStorage(AssetStorageId.create(item.id));
    assetStorage.postId = PostId.create(item.postId);
    assetStorage.downloadUrl = AssetStorageDownloadUrl.create(item.downloadUrl);
    assetStorage.mimeType = AssetStorageMimeType.create(item.mimeType);
    assetStorage.name = AssetStorageName.create(item.name);
    assetStorage.permalink = AssetStoragePermalink.create(item.permalink);
    assetStorage.size = AssetStorageSize.create(item.size);
    assetStorage.type = AssetStorageType.create(item.type);
    assetStorage.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    assetStorage.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    assetStorage.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    assetStorage.slackFileId = item.slackFileId !== null ? AssetStorageSlackFileId.create(item.slackFileId) : undefined;
    return assetStorage;
  }
}
