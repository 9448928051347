import { NgModule, Type } from '@angular/core';

import { ButtonModule } from './../../atoms/button/button.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KnowledgeCardFooterComponent } from './knowledge-card-footer.component';
import { KnowledgeDeleteActionModule } from '../knowledge-delete-action/knowledge-delete-action.module';
import { QuoteModule } from '../quote/quote.module';
import { ReactionModule } from '../reaction/reaction.module';

const components: Type<any>[] = [KnowledgeCardFooterComponent];

@NgModule({
  imports: [CommonModule, ReactionModule, KnowledgeDeleteActionModule, QuoteModule, ButtonModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeCardFooterModule { }
