import { take, tap } from 'rxjs/operators';

import { BoardDomainService } from 'domain/kernel/board/service';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { Injectable } from '@angular/core';
import { SidebarMenuDashboardService } from '../../../organisms/sidebar/sidebar-menu-dashboard/state/sidebar-menu-dashboard.service';

@Injectable({ providedIn: 'root' })
export class BoardDeleteActionService {
  constructor(
    readonly boardDomainService: BoardDomainService,
    private readonly sidebarMenuDashboardService: SidebarMenuDashboardService,
    private readonly dashboardService: DashboardService,
  ) { }

  deleteBoard(id: any) {
    return this.boardDomainService.delete(id).pipe(
      take(1),
      tap(board => this.dashboardService.popSelectedBoard(board)),
      tap(board => this.sidebarMenuDashboardService.updateIncaseRemoveBoard(board)),
    )
  }
}
