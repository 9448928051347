import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';
import { Reaction } from '../entity';
import { ReactionId } from '../value';

export abstract class IReactionRepository extends IRepository<ReactionId, Reaction> {
  abstract selectAll(builder: FirestoreQueryBuilder<Reaction>): Observable<Reaction>;
  abstract countAll(builder: FirestoreQueryBuilder<Reaction>): Observable<Reaction>;
  abstract selectIfExists(builder: FirestoreQueryBuilder<Reaction>): Observable<Reaction>;
}
