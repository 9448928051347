import { NgModule, Type } from '@angular/core';

import { AndOrConditionComponent } from './and-or-condition.component';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

const components: Type<any>[] = [AndOrConditionComponent];

@NgModule({
  imports: [CommonModule, NzDropDownModule],
  declarations: [components],
  exports: [components],
})
export class AndOrConditionModule { }
