import { EMPTY, Observable, concat, of } from 'rxjs';
import { EPostType, PostId } from '../../../kernel/post';
import { KnowledgeActivityId, KnowledgeActivityTargetType, KnowledgeActivityType } from '../value';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { EKnowledgeActivityType } from '../enum';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IKnowledgeActivityRepository } from '../repository';
import { KnowledgeActivity } from '../entity';
import { KnowledgeActivityDto } from '../dto';
import { KnowledgeActivityNotFoundError } from '../exception';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class KnowledgeActivityDomainService {
  constructor(private readonly knowledgeActivityRepository: IKnowledgeActivityRepository) { }

  select(id: string) {
    return this.knowledgeActivityRepository.select(KnowledgeActivityId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectAll() {
    return this.knowledgeActivityRepository.selectAll(new FirestoreQueryBuilder<KnowledgeActivity>()).pipe(map(item => this.convertDto(item)));
  }

  selectLastRecentlyByOrganizationId(organizationId: string, type: EKnowledgeActivityType) {
    const builder = new FirestoreQueryBuilder<KnowledgeActivity>()
      //.equalWhere('type', type)
      .orderBy('createdAt', 'desc')
      .limit(1000);

    return this.knowledgeActivityRepository.selectAll(builder).pipe(
      map(item => this.convertDto(item)),
      filter(item => item && item.type === type),
    );
  }

  selectActivityByCreatedUserAndPostIdAndType(actionBy: string, postId: string, targetType: string, type: EKnowledgeActivityType) {
    return concat(
      this.knowledgeActivityRepository.selectAll(
        new FirestoreQueryBuilder<KnowledgeActivity>()
          .equalWhere('actionBy', actionBy)
          .equalWhere('postId', postId)
          .equalWhere('targetType', targetType)
          .equalWhere('type', type)
      ),
      of(null)
    ).pipe(
      take(1),
      tap(activity => {
        if (activity === null) {
          throw new KnowledgeActivityNotFoundError('KnowledgeActivityNotFoundError');
        }
      }),
      map(item => this.convertDto(item))
    );
  }

  synchronizeActivity(actionBy: string, postId: string, targetType: EPostType, type: EKnowledgeActivityType) {
    return this.selectActivityByCreatedUserAndPostIdAndType(actionBy, postId, targetType, type).pipe(
      catchError(err => {
        if (err instanceof KnowledgeActivityNotFoundError) {
          return this.insert(actionBy, postId, targetType, type)
        }
        return EMPTY;
      }),
    );
  }

  insert(actionBy: string, postId: string, targetType: EPostType, type: EKnowledgeActivityType): Observable<KnowledgeActivityDto> {
    const knowledgeActivity = new KnowledgeActivity(this.knowledgeActivityRepository.generateId());
    knowledgeActivity.postId = PostId.create(postId);
    knowledgeActivity.targetType = KnowledgeActivityTargetType.create(targetType);
    knowledgeActivity.type = KnowledgeActivityType.create(type);
    knowledgeActivity.actionBy = AccountOrganizationId.create(actionBy);
    return this.knowledgeActivityRepository.insert(knowledgeActivity).pipe(map(item => this.convertDto(item)));
  }

  update(knowledgeActivityDto: KnowledgeActivityDto) {
    const knowledgeActivity = new KnowledgeActivity(KnowledgeActivityId.create(knowledgeActivityDto.id));
    knowledgeActivity.postId = PostId.create(knowledgeActivityDto.postId);
    knowledgeActivity.targetType = KnowledgeActivityTargetType.create(knowledgeActivityDto.targetType);
    knowledgeActivity.type = KnowledgeActivityType.create(knowledgeActivityDto.type);
    knowledgeActivity.actionBy = AccountOrganizationId.create(knowledgeActivityDto.actionBy);
    knowledgeActivity.createdAt = Timestamp.createByDate(knowledgeActivityDto.createdAt);
    return this.knowledgeActivityRepository.update(knowledgeActivity).pipe(map(item => this.convertDto(item)));
  }

  delete(id: string) {
    return this.knowledgeActivityRepository.delete(KnowledgeActivityId.create(id));
  }

  private convertDto(knowledgeActivity: KnowledgeActivity): KnowledgeActivityDto {
    return KnowledgeActivity.allFields.reduce((p, key) => {
      if (knowledgeActivity[key] === undefined) {
        return p;
      }
      const value = knowledgeActivity[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as KnowledgeActivityDto);
  }
}
