<section class="search-user-autocomplete__section">
  <div
    nz-dropdown
    nzTrigger="click"
    nzOverlayClassName="search-user-autocomplete__cdk"
    nzPlacement="bottomLeft"
    [nzDropdownMenu]="menu"
    (nzVisibleChange)="onVisibleChange($event)"
  >
    <p *ngIf="choseItems.length === 0" class="clicked input">{{ nameKey }}</p>
    <div class="list-value">
      <div *ngFor="let item of choseItems; last as isLast" class="item--box">
        <div class="value">
          <p>{{ item.label }}</p>
          <img [src]="AssetConstant.ICON_CLOSE_BLACK_URL" (click)="onRemove($event, item)" />
        </div>
      </div>
    </div>
  </div>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <input nz-input [placeholder]="placeholderInput" [formControl]="inputSearchForm" />
    <div class="list">
      <div *ngFor="let option of filteredOptions" class="item" (click)="onSelect(option)" [class.hidden]="option?.isHidden">
        <app-avatar [source]="option.shortImg" [altText]="option.label" [width]="16" [height]="16"></app-avatar>
        <p>{{ option.label }}</p>
      </div>
      <p class="empty" *ngIf="filteredOptions?.length === 0">データがありません。</p>
    </div>
  </nz-dropdown-menu>
</section>
