import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { InviteAccountModalState, InviteAccountModalStore } from './invite-account-modal.store';

@Injectable({ providedIn: 'root' })
export class InviteAccountModalQuery extends Query<InviteAccountModalState> {
  constructor(protected store: InviteAccountModalStore) {
    super(store);
  }

  selectAccounts() {
    return this.select('accounts');
  }

  selectSelectedAccount() {
    return this.select('selectedAccount');
  }

  selectLoading() {
    return this.select('loading');
  }
}
