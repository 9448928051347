import { EMPTY, Observable } from 'rxjs';
import { SharingVendorBody, SharingVendorId, SharingVendorKnowledgeConverted, SharingVendorText, SharingVendorTs } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { ISharingVendorRepository } from '../../repository';
import { IsDeleted } from '../../../../kernel/base/value';
import { OrganizationId } from '../../../../kernel/organization/value';
import { SharingVendor } from '../../entity';
import { SharingVendorNotFoundError } from '../../exception';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class SharingVendorFirestoreRepository implements ISharingVendorRepository {
  private static readonly collectionId = 'sharing_vendor';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: SharingVendorId): Observable<SharingVendor> {
    return this.firestoreService.getDocument(SharingVendorFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SharingVendorNotFoundError(`sharing_vendor id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectForUpdateEs(id: SharingVendorId): Observable<SharingVendor> | null {
    return this.firestoreService.getDocument(SharingVendorFirestoreRepository.collectionId, id).pipe(
      map(item => item ? this.convertToEntity(item) : null)
    );
  }

  selectAll(builder: FirestoreQueryBuilder<SharingVendor>): Observable<SharingVendor> {
    return this.firestoreService.getCollection(SharingVendorFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(SharingVendorFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: SharingVendor): Observable<SharingVendor> {
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(SharingVendorFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(sharingVendor: SharingVendor): Observable<SharingVendor> {
    return this.firestoreService.getDocument(SharingVendorFirestoreRepository.collectionId, sharingVendor.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new SharingVendorNotFoundError(`sharing_vendor id「${sharingVendor.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(SharingVendorFirestoreRepository.collectionId, this.convertToMap(sharingVendor));
      }),
      map(_ => sharingVendor)
    );
  }

  delete(id: SharingVendorId): Observable<void> {
    const proposal = new SharingVendor(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(SharingVendorFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): SharingVendorId {
    return SharingVendorId.create(this.firestoreService.generateId());
  }

  private convertToMap(sharingVendor: SharingVendor): object {
    return SharingVendor.allFields.reduce((p, key) => {
      if (sharingVendor[key] === undefined) {
        return p;
      }
      const value = sharingVendor[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const sharingVendor = new SharingVendor(SharingVendorId.create(item.id));
    sharingVendor.body = SharingVendorBody.create(item.body);
    sharingVendor.text = SharingVendorText.create(item.text);
    sharingVendor.sendBy = item.sendBy ? AccountOrganizationId.create(item.sendBy) : undefined;
    sharingVendor.registeredBy = item.registeredBy ? AccountOrganizationId.create(item.registeredBy) : undefined;
    sharingVendor.ts = item.ts ? SharingVendorTs.create(item.ts) : undefined;
    sharingVendor.organizationId = item.organizationId ? OrganizationId.create(item.organizationId) : undefined;
    sharingVendor.wroteAt = Timestamp.createByMillsec(item.wroteAt.seconds * 1000);
    sharingVendor.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    sharingVendor.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    sharingVendor.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    sharingVendor.knowledgeConverted = item.knowledgeConverted ? SharingVendorKnowledgeConverted.create(item.knowledgeConverted) : SharingVendorKnowledgeConverted.create(false);
    return sharingVendor;
  }
}
