import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { KnowledgeDocumentApiClient } from '../api/knowledge-document.api.client';
import { KnowledgeDocumentDto } from 'domain/kernel/knowledge-document/dto';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeDocumentService {
  constructor(private readonly knowledgeDocumentApiClient: KnowledgeDocumentApiClient) { }

  createKnowledgeDocument(knowledgeDocumentDto: KnowledgeDocumentDto): Observable<KnowledgeDocumentDto> {
    return from(this.knowledgeDocumentApiClient.createKnowledge(knowledgeDocumentDto));
  }

  updateKnowledgeDocument(knowledgeDocumentDto: KnowledgeDocumentDto): Observable<KnowledgeDocumentDto> {
    return from(this.knowledgeDocumentApiClient.updateKnowledge(knowledgeDocumentDto));
  }
}
