import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/fontawesome-pro-light';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { AppQuery } from 'frontend/admin/app/state/app.query';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription, of } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { CreateRequestModalQuery } from './state/create-request-modal.query';
import { CreateRequestModalService } from './state/create-request-modal.service';
import { LoadingService } from 'frontend/admin/app/shared/services/loading.service';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { KnowledgeFirestoreService } from 'frontend/admin/app/shared/services/knowledge-firestore.service';
import { EKnowledgeType } from 'domain/kernel/knowledge/enum';

@Component({
  selector: 'app-create-request-modal',
  templateUrl: './create-request-modal.component.html',
  styleUrls: ['./create-request-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRequestModalComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;

  readonly appData = {
    faTimes: faTimes,
    createBtn: 'リクエストを作成',
    updateBtn: 'リクエスト編集を保存'
  }

  ngSelectTags = {
    tags: [],// CategoryDto[] all categories belong to workspace
    selectedTags: [],// string[] all categoryIds have selected
    categoryKnowledge: [],
    hideSelected: true,
    // addTag: this.addTagFn,
    multiple: true,
    // isOpen: false
  };

  attachmentFiles = {
    inputFiles: [],
    remainFiles: [],
    files: [],
    fileSizeExceedsLimit: false
  };

  formGroup: FormGroup;
  subscription: Subscription;
  avatarPostOwner: string = null;
  namePostOwner: string = null;
  start: Date = new Date();
  end: Date = new Date();
  inProcess: boolean = false;
  @Input() content: string;
  @Output() handleShowBoard = new EventEmitter();

  public readonly ELimitCharacters = ELimitCharacters;

  constructor(
    readonly appQuery: AppQuery,
    readonly createRequestModalQuery: CreateRequestModalQuery,
    private readonly formBuilder: FormBuilder,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly createRequestModalService: CreateRequestModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly nzModalRef: NzModalRef<CreateRequestModalComponent>,
    private readonly notificationService: NotificationService,
    private readonly _loadingService: LoadingService,
    private readonly knowledgeFirestoreService: KnowledgeFirestoreService
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      title: [this.content, [Validators.required, Validators.maxLength(50)]],
      body: ['', [Validators.required, Validators.maxLength(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH)]],
    });
  }

  protected override onDestroy(): void {
    this.createRequestModalService.resetStore();
  }

  protected override onInit(): void {
    const currentUserName = this.appQuery.getValue().currentAccount.name;
    this.avatarPostOwner = this.appQuery.getValue().currentAccount?.shortImg;
    this.namePostOwner = currentUserName ? `${currentUserName.first} ${currentUserName.last}` : null;

    this.subscription = this.init().subscribe(rs => {
      if (rs && rs.length > 0) {
        this.attachmentFiles.inputFiles = this.attachmentFiles.remainFiles = rs;
      }

      if (this.knowledgeSummary) {
        this.formGroup.setValue({
          title: this.knowledgeSummary.title || '',
          body: this.knowledgeSummary.body || '',
        });

        const tempArr = this.knowledgeSummary.categories ? this.knowledgeSummary.categories.split(',') : [];
        const initTags = this.ngSelectTags.tags.filter(tag => tempArr.includes(tag.value));
        this.ngSelectTags.selectedTags = initTags ? initTags.map(tag => tag.value) : []
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onUploadFiles($event: any): void {
    this.attachmentFiles.files = $event && $event.files;
    this.attachmentFiles.remainFiles = $event && $event.inputFiles;
    this.attachmentFiles.fileSizeExceedsLimit = $event && $event.fileSizeExceedsLimit;
  }

  close(data?: any) {
    this.nzModalRef.close(data);
  }

  closeValidate() {
    this.createRequestModalService.updateFailed(false, []);
  }

  onSelectedTags(event: any) {
    this.ngSelectTags.selectedTags = event;
  }

  onChangeRange($event: any) {
    this.end = $event;
  }

  onUpsertRequest() {
    const validForm = this.validateCreateKnowledge();
    if (!validForm.isValid) {
      this.createRequestModalService.updateFailed(true, validForm.errMsgs);
      return;
    }
    if (this.ngSelectTags.selectedTags.length > CommonConstant.ITEM_TAG_MAXIMUM) {
      return;
    }

    this.inProcess = true;
    this._loadingService.setStateLoading(true);
    this.knowledgeSummary ? this.update() : this.insert();
  }

  private insert() {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    const subscription$ = this.knowledgeFirestoreService.create(
      currentAccountOrganization.id,
      EKnowledgeType.REQUEST,
      this.formGroup.value,
      this.ngSelectTags.selectedTags,
      this.attachmentFiles.files,
    ).pipe(
      take(1),
      tap(request => this.close(request)),
      tap(_ => {
        this.inProcess = false;
        this.handleShowBoard.emit(null);
        this._loadingService.setStateLoading(false);
      })
    ).subscribe({
      next: (rs) => {
        this.notificationService.open('リクエストを作成しました。')
      },
      error: err => {
        this.inProcess = false;
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private update() {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    const inputFileIds = this.attachmentFiles.inputFiles.map(file => file.id);
    const remainFileIds = this.attachmentFiles.remainFiles.map(file => file.id)
    const deleteFiles = inputFileIds.filter(n => !remainFileIds.includes(n));

    const subscription$ = this.knowledgeFirestoreService.update(
      currentAccountOrganization.id,
      EKnowledgeType.REQUEST,
      this.knowledgeSummary.id,
      this.formGroup.value,
      this.ngSelectTags.selectedTags,
      deleteFiles,
      this.attachmentFiles.files
    ).pipe(
      take(1),
      tap(request => this.close(request)),
      tap(_ => {
        this.inProcess = false;
        this.handleShowBoard.emit(null);
        this._loadingService.setStateLoading(false);
      }),
    ).subscribe({
      next: (rs) => {
        this.notificationService.open('リクエストを編集しました。')
      },
      error: err => {
        console.error(err);
      }
    });
    this.addSubscription(subscription$);
  }

  private init() {
    //  load categories
    return this.workspaceQuery.selectOrganization().pipe(
      filter(organization => organization !== null),
      mergeMap(organization => this.knowledgeFirestoreService.getCategoryByOrganizationId(organization.id)),
      tap(result => this.ngSelectTags.tags = result.map(x => ({ value: x.id, label: x.label } as any))),
      mergeMap(_ => this.knowledgeSummary ? this.knowledgeFirestoreService.getFilesByKnowledgeId(this.knowledgeSummary.id) : of(null))
    );
  }

  private validateCreateKnowledge() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }
    if (this.start == null) {
      rs.isValid = false;
      rs.errMsgs.push('開始日をお選びください。');
    }
    if (this.end == null) {
      rs.isValid = false;
      rs.errMsgs.push('終了日をお選びください。');
    }

    return rs;
  }
}
