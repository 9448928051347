import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-jit',
  templateUrl: './jit.component.html',
  styleUrls: ['./jit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitComponent {
  @Input() shortenTimestamp: string = null;
  @Input() DetailTimestamp: string = null;
}
