import * as moment from 'moment';

import { Observable, forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { AccountOrganizationDto } from 'domain/kernel/account/dto';
import { AppQuery } from '../../state/app.query';
import { AssetStorageDomainService } from 'domain/kernel/asset-storage/service';
import { AssetStorageDto } from 'domain/kernel/asset-storage/dto';
import { CategoryDomainService } from 'domain/kernel/category/service';
import { CopyFirestorageService } from 'frontend/lib/service/copy-firestorage.service';
import { EDateFormat } from 'frontend/admin/app/enum/date-format.enum';
import { EKnowledgeType } from 'domain/kernel/knowledge/enum';
import { EPostType } from 'domain/kernel/post';
import { FireStorageCopyRequest } from 'serverside/batch/api/model/firestorage-copy.request';
import { FirestorageService } from 'frontend/admin/app/service/firestorage.service';
import { Injectable } from '@angular/core';
import { KnowledgeDocumentDto } from 'domain/kernel/knowledge-document/dto';
import { KnowledgeDocumentService } from 'frontend/lib/service/knowledge-document.service';
import { KnowledgeDomainService } from 'domain/kernel/knowledge/service';
import { KnowledgeDto } from 'domain/kernel/knowledge/dto';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { SharingVendorDomainService } from 'domain/kernel/sharing-vendor/service';
import { Timestamp } from 'utility/model/timestamp.value';

@Injectable({ providedIn: 'root' })
export class KnowledgeFirestoreService {
  constructor(
    readonly appQuery: AppQuery,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly assetStorageDomainService: AssetStorageDomainService,
    private readonly categoryDomainService: CategoryDomainService,
    private readonly copyFirestorageService: CopyFirestorageService,
    private readonly firestorageService: FirestorageService,
    private readonly knowledgeDomainService: KnowledgeDomainService,
    private readonly sharingVendorDomainService: SharingVendorDomainService,
    private readonly knowledgeDocumentService: KnowledgeDocumentService,
  ) { }

  create(createdBy: string, knowledgeType: EKnowledgeType, formValue: any, selectedTags: string[], addedFiles: File[], refFiles: KnowledgeFileSummary[] = null, refKnowledgeId: string = null): Observable<KnowledgeSummary> {
    const knowledgeDto = {
      sendBy: createdBy,
      registeredBy: '',
      title: formValue['title'].trim() || '',
      body: formValue['body'].trim() || '',
      category: selectedTags ? selectedTags.join(',') : '',
      type: knowledgeType,
      quotationTargetId: refKnowledgeId !== null ? refKnowledgeId : ''
    } as KnowledgeDto;
    return this.accountOrganizationDomainService.selectByIdAndActive(createdBy).pipe(
      filter(accountOrganization => accountOrganization !== null),
      take(1),
      mergeMap(accountOrganization => this.knowledgeDomainService.insertKnowledge(knowledgeDto).pipe(
        filter(response => response !== null && response.id !== null),
        mergeMap(knowledge => forkJoin({
          refKnowledgeId: this.emitKnowledgeConverted(refKnowledgeId),
          addedFiles: this.uploadAttachments(knowledge.id, addedFiles),
          refFiles: this.cloneFilesFromFirestorage(knowledge.id, refFiles),
          doc: this.insertKnowledgeDocument(knowledge, accountOrganization),
          knowledge: of(knowledge)
        })),
        mergeMap(result => this.genKnowledgeViewModel(result.knowledge.id, accountOrganization, result.knowledge)),
      ))
    );
  }

  update(createdBy: string, knowledgeType: EKnowledgeType, knowledgeId: string, formValue: any, selectedTags: string[], removedFiles: string[], addedFiles: File[]) {
    const knowledgeDto = {
      id: knowledgeId,
      sendBy: createdBy,
      registeredBy: '',
      title: formValue['title'].trim() || '',
      body: formValue['body'].trim() || '',
      category: selectedTags ? selectedTags.join(',') : '',
      type: knowledgeType,
    } as KnowledgeDto;
    return this.accountOrganizationDomainService.selectByIdAndActive(createdBy).pipe(
      filter(accountOrganization => accountOrganization !== null),
      take(1),
      mergeMap(accountOrganization => this.knowledgeDomainService.updateKnowledge(knowledgeDto).pipe(
        filter(response => response !== null && response.id !== null),
        mergeMap(knowledge => forkJoin({
          removedFiles: this.deleteAttachments(removedFiles),
          addedFiles: this.uploadAttachments(knowledge.id, addedFiles),
          doc: this.updateKnowledgeDocument(knowledge, accountOrganization),
          knowledge: of(knowledge)
        })),
        mergeMap(result => this.genKnowledgeViewModel(result.knowledge.id, accountOrganization, result.knowledge))
      ))
    );
  }

  getFilesByKnowledgeId(knowledgeId: string): Observable<AssetStorageDto[]> {
    return this.assetStorageDomainService.selectAllByKnowledgeId(knowledgeId).pipe(
      toArray()
    );
  }

  getCategoryByOrganizationId(organizationId: string) {
    return this.categoryDomainService.selectAllByOrganizationId(organizationId)
      .pipe(toArray());
  }

  private genKnowledgeViewModel(knowledgeId: string, accountOrganization: AccountOrganizationDto, knowledgeDto: KnowledgeDto) {
    return of(
      {
        id: knowledgeId,
        organizationId: accountOrganization.organizationId,
        registeredBy: knowledgeDto.registeredBy,
        sendBy: knowledgeDto.sendBy,
        title: knowledgeDto.title,
        body: knowledgeDto.body,
        categories: knowledgeDto.category,
        createdAt: new Date(),
        updatedAt: new Date(),
        updatedAtShortStr: moment(Timestamp.createByDate(new Date()).value).fromNow(),
        updatedAtDetailStr: moment(Timestamp.createByDate(new Date()).value).format(EDateFormat.DAYTIME_FORMAT_LLLL),
        postType: EPostType.KNOWLEDGE,
        knowledgeType: knowledgeDto.type,
        loggedUser: accountOrganization,
      } as KnowledgeSummary
    )
  }

  private emitKnowledgeConverted(refKnowledgeId: string) {
    if (refKnowledgeId && refKnowledgeId !== '') {
      return this.sharingVendorDomainService.updateKnowledgeConverted(refKnowledgeId)
    } else {
      return of(null)
    }
  }

  private deleteAttachments(assetIds: string[]) {
    if (assetIds && assetIds.length > 0) {
      return of(assetIds).pipe(
        mergeMap(assetIds => assetIds),
        mergeMap(assetId => this.assetStorageDomainService.delete(assetId))
      )
    } else {
      return of(null)
    }
  }

  private uploadAttachments(postId: string, addedFiles: File[]) {
    if (addedFiles && addedFiles.length > 0) {
      return of(addedFiles).pipe(
        mergeMap(files => files),
        mergeMap(file => this.firestorageService.uploadAttachmentFile(file, postId).pipe(
          take(1),
          map(url => ({
            postId: postId,
            downloadUrl: url,
            mimeType: '',
            name: file?.name,
            permalink: '',
            size: file?.size,
            type: file?.type
          } as AssetStorageDto)),
          // tap(rs => console.log(rs)),
          mergeMap(asset => this.assetStorageDomainService.insert(asset))
        ))
      )
    } else {
      return of(null)
    }
  }

  private cloneFilesFromFirestorage(postId: string, sharingVendorFiles: KnowledgeFileSummary[]) {
    if (sharingVendorFiles && sharingVendorFiles?.length > 0) {
      return of(sharingVendorFiles).pipe(
        mergeMap(files => files),
        mergeMap(file => this.copyFirestorageService.copyFile({ srcFileName: file.storagePath, destFileName: `asset/${postId}/${file.name}` } as FireStorageCopyRequest)
          .pipe(
            take(1),
            map(_ => ({
              postId: postId,
              downloadUrl: `asset/${postId}/${file.name}`,
              mimeType: file?.mimetype,
              name: file?.name,
              permalink: '',
              size: file?.size,
              type: file?.type
            } as AssetStorageDto)),
            // tap(rs => console.log(rs)),
            mergeMap(asset => this.assetStorageDomainService.insert(asset))
          ))
      )
    } else {
      return of(null)
    }
  }

  //start zone document ES
  private insertKnowledgeDocument(knowledge: KnowledgeDto, accountOrganization: AccountOrganizationDto) {
    const currentAccount = this.appQuery.getValue()?.currentAccount;
    return this.knowledgeDocumentService.createKnowledgeDocument({
      id: knowledge.id,
      organizationId: accountOrganization.organizationId,
      sendBy: knowledge.sendBy,
      sharedUserName: '',
      registeredBy: '',
      createdUserName: currentAccount ? `${currentAccount?.name?.first} ${currentAccount?.name?.last}` : '',
      title: knowledge.title,
      body: knowledge.body,
      category: knowledge?.category ? knowledge.category : '',
      wroteAt: knowledge.wroteAt,
      createdAt: knowledge.createdAt,
      updatedAt: knowledge.updatedAt,
      concerns: [],
      postType: knowledge && knowledge.type === EKnowledgeType.REQUEST ? EPostType.KNOWLEDGE_REQUEST : EPostType.KNOWLEDGE,
      createdBy: accountOrganization.accountId,
      knowledgeType: knowledge.type,
      knowledgeConverted: false,
      quotationTargetId: knowledge?.quotationTargetId,
      commentTotal: 0,
      reactionTotal: 0
    } as KnowledgeDocumentDto)
  }

  private updateKnowledgeDocument(knowledge: KnowledgeDto, accountOrganization: AccountOrganizationDto) {
    const currentAccount = this.appQuery.getValue()?.currentAccount;
    return this.knowledgeDomainService.select(knowledge.id).pipe(
      take(1),
      tap(rs => console.log(rs)),
      mergeMap(knowFired => this.knowledgeDocumentService.updateKnowledgeDocument(({
        id: knowFired.id,
        organizationId: accountOrganization.organizationId,
        sendBy: knowFired.sendBy,
        sharedUserName: '',
        registeredBy: '',
        createdUserName: currentAccount ? `${currentAccount?.name?.first} ${currentAccount?.name?.last}` : '',
        title: knowFired.title,
        body: knowFired.body,
        category: knowFired?.category ? knowFired.category : '',
        createdAt: knowFired.createdAt,
        postType: knowFired && knowFired.type === EKnowledgeType.REQUEST ? EPostType.KNOWLEDGE_REQUEST : EPostType.KNOWLEDGE,
        wroteAt: knowFired.wroteAt,
        updatedAt: knowFired.updatedAt,
        concerns: [],
        createdBy: accountOrganization.accountId,
        knowledgeType: knowFired.type,
        knowledgeConverted: false,
        quotationTargetId: knowFired?.quotationTargetId,
        reactionTotal: 0,
        commentTotal: 0,
      } as KnowledgeDocumentDto))
      )
    )
  }
  //end zone document ES
}
