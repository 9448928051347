import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NotfoundRecordTextComponent } from './notfound-record-text.component';

const components: Type<any>[] = [NotfoundRecordTextComponent];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [components],
  exports: [components],
})
export class NotfoundRecordTextModule { }
