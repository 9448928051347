import { NgModule, Type } from '@angular/core';

import { AvatarComponent } from './avatar.component';
import { CommonModule } from '@angular/common';

const components: Type<any>[] = [AvatarComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class AvatarModule { }
