import { Component, Input } from '@angular/core';
import { faAngleDown, faAngleRight } from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent {
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;

  @Input() hasSubMenu: boolean;
  @Input() isCollapsed: boolean = true;
  @Input() disable = false;

  constructor() {
    this.isCollapsed = true;
  }

  displayContextMenu(event) {
    if (this.disable) {
      return;
    }
    event.preventDefault();
    this.isCollapsed = !this.isCollapsed;
  }
}
