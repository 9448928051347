import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderCardComponent {
  @Input() anonymous: boolean = false;
  @Input() deletedUser: boolean = false;
  @Input() hasUser: boolean = false;
  constructor() { }
}
