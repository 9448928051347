import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { MyKnowledge } from '../entity';
import { MyKnowledgeId } from '../value';
import { Observable } from 'rxjs';

export abstract class IMyKnowledgeRepository extends IRepository<MyKnowledgeId, MyKnowledge> {
  abstract selectAll(builder: FirestoreQueryBuilder<MyKnowledge>): Observable<MyKnowledge>;
  abstract selectForUpdateEs(id: MyKnowledgeId): Observable<MyKnowledge> | null;
  abstract selectIfExists(builder: FirestoreQueryBuilder<MyKnowledge>): Observable<MyKnowledge>;
  abstract reInsert(myKnowledge: MyKnowledge): Observable<MyKnowledge>;
}
