import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CreateKnowledgeModalState, CreateKnowledgeModalStore } from './create-knowledge-modal.store';

@Injectable({ providedIn: 'root' })
export class CreateKnowledgeModalQuery extends Query<CreateKnowledgeModalState> {
  constructor(protected store: CreateKnowledgeModalStore) {
    super(store);
  }
  selectLoading() {
    return this.select('loading');
  }

  selectFailed() {
    return this.select('failed');
  }

  selectErrMsgs() {
    return this.select('errMsgs');
  }
}
