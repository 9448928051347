//repository
import {
  AccountFirestoreRepository,
  AccountOrganizationFirestoreRepository,
  IAccountOrganizationRepository,
  IAccountRepository
} from "domain/kernel/account/repository";
import {
  ActivityFirestoreRepository,
  IActivityRepository
} from "domain/kernel/activity/repository";
import {
  AssetStorageFirestoreRepository,
  IAssetStorageRepository,
} from "domain/kernel/asset-storage/repository";
import {
  BoardFirestoreRepository,
  IBoardRepository,
} from "domain/kernel/board/repository";
import {
  CategoryFirestoreRepository,
  ICategoryRepository,
} from "domain/kernel/category/repository";
import {
  CommentFirestoreRepository,
  ICommentRepository,
} from "domain/kernel/comment/repository";
import {
  FeedbackDeliveryFirestoreRepository,
  IFeedbackDeliveryRepository,
} from "domain/kernel/feedback-delivery/repository";
import {
  FeedbackFirestoreRepository,
  IFeedbackRepository
} from "domain/kernel/feedback/repository";
import {
  FeedbackToSlackFirestoreRepository,
  IFeedbackToSlackRepository,
} from "domain/kernel/feedback-to-slack/repository";
import {
  IIntegrationRepository,
  IntegrationFirestoreRepository
} from "domain/kernel/integration/repository";
import {
  IKnowledgeActivityRepository,
  KnowledgeActivityFirestoreRepository
} from "domain/kernel/knowledge-activity/repository";
import {
  IKnowledgeRepository,
  KnowledgeFirestoreRepository
} from "domain/kernel/knowledge/repository";
import {
  IMyKnowledgeRepository,
  MyKnowledgeFirestoreRepository,
} from "domain/kernel/my-knowledge/repository";
import {
  IOrganizationRepository,
  OrganizationFirestoreRepository
} from "domain/kernel/organization/repository";
import {
  IReactionRepository,
  ReactionFirestoreRepository,
} from "domain/kernel/reaction/repository";
import {
  ISharingVendorFileRepository,
  ISharingVendorIntegrationRepository,
  ISharingVendorRepository,
  SharingVendorFileFirestoreRepository,
  SharingVendorFirestoreRepository,
  SharingVendorIntegrationFirestoreRepository,
} from "domain/kernel/sharing-vendor/repository";
import {
  ISlackChannelRepository,
  SlackChannelFirestoreRepository,
} from "domain/slack/slack-channel/repository";
import {
  ISlackFileRepository,
  SlackFileFirestoreRepository,
} from "domain/slack/slack-file/repository";
import {
  ISlackMessageRepository,
  SlackMessageFirestoreRepository
} from "domain/slack/slack-message/repository";
import {
  ISlackOAuthRepository,
  SlackOAuthFirestoreRepository,
} from "domain/slack/slack-oauth/repository";
import {
  ISlackReactionRepository,
  SlackReactionFirestoreRepository,
} from "domain/slack/slack-reaction/repository";
import {
  ISlackUserRepository,
  SlackUserFirestoreRepository,
} from "domain/slack/slack-user/repository";

import { IFirestoreService } from "lib/gcp/service/firestore.service";

export const APP_REPOSITORY_PROVIDERS = [
  {
    provide: ISlackMessageRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackMessageFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IAccountRepository,
    useFactory: (firestoreService: IFirestoreService) => new AccountFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IAccountOrganizationRepository,
    useFactory: (firestoreService: IFirestoreService) => new AccountOrganizationFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IOrganizationRepository,
    useFactory: (firestoreService: IFirestoreService) => new OrganizationFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IKnowledgeRepository,
    useFactory: (firestoreService: IFirestoreService) => new KnowledgeFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ICategoryRepository,
    useFactory: (firestoreService: IFirestoreService) => new CategoryFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IIntegrationRepository,
    useFactory: (firestoreService: IFirestoreService) => new IntegrationFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IActivityRepository,
    useFactory: (firestoreService: IFirestoreService) => new ActivityFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IFeedbackRepository,
    useFactory: (firestoreService: IFirestoreService) => new FeedbackFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IKnowledgeActivityRepository,
    useFactory: (firestoreService: IFirestoreService) => new KnowledgeActivityFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IFeedbackToSlackRepository,
    useFactory: (firestoreService: IFirestoreService) => new FeedbackToSlackFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IFeedbackDeliveryRepository,
    useFactory: (firestoreService: IFirestoreService) => new FeedbackDeliveryFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISlackReactionRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackReactionFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISlackOAuthRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackOAuthFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISlackFileRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackFileFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISlackUserRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackUserFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISlackChannelRepository,
    useFactory: (firestoreService: IFirestoreService) => new SlackChannelFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISharingVendorRepository,
    useFactory: (firestoreService: IFirestoreService) => new SharingVendorFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IAssetStorageRepository,
    useFactory: (firestoreService: IFirestoreService) => new AssetStorageFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IReactionRepository,
    useFactory: (firestoreService: IFirestoreService) => new ReactionFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISharingVendorIntegrationRepository,
    useFactory: (firestoreService: IFirestoreService) => new SharingVendorIntegrationFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ISharingVendorFileRepository,
    useFactory: (firestoreService: IFirestoreService) => new SharingVendorFileFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IBoardRepository,
    useFactory: (firestoreService: IFirestoreService) => new BoardFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: ICommentRepository,
    useFactory: (firestoreService: IFirestoreService) => new CommentFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
  {
    provide: IMyKnowledgeRepository,
    useFactory: (firestoreService: IFirestoreService) => new MyKnowledgeFirestoreRepository(firestoreService),
    deps: [IFirestoreService]
  },
];
