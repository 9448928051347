import { CategoryAnchorId, CategoryId } from '../../value';
import { EMPTY, Observable, of } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { CategoryAnchor } from '../../entity';
import { CategoryNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from '../../../../../lib/gcp/builder/firestore-query.builder';
import { ICategoryAnchorRepository } from '../../repository';
import { IFirestoreService } from '../../../../../lib/gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { PostId } from '../../../post';
import { Timestamp } from '../../../../../utility/model/timestamp.value';

export class CategoryAnchorFirestoreRepository implements ICategoryAnchorRepository {
  private static readonly collectionId = 'category_anchor';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: CategoryAnchorId): Observable<CategoryAnchor> {
    return this.firestoreService.getDocument(CategoryAnchorFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new CategoryNotFoundError(`category_anchor id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: CategoryAnchor): Observable<CategoryAnchor> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService
      .setDocument(CategoryAnchorFirestoreRepository.collectionId, this.convertToMap(item))
      .pipe(map(() => item));
  }

  update(category: CategoryAnchor): Observable<CategoryAnchor> {
    category.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(CategoryAnchorFirestoreRepository.collectionId, category.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new CategoryNotFoundError(`category_anchor id「${category.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(CategoryAnchorFirestoreRepository.collectionId, this.convertToMap(category));
      }),
      map(_ => category)
    );
  }

  delete(id: CategoryAnchorId) {
    const proposal = new CategoryAnchor(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(CategoryAnchorFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): CategoryAnchorId {
    return CategoryAnchorId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<CategoryAnchor>) {
    return this.firestoreService.getCollection(CategoryAnchorFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(CategoryAnchorFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  selectIfExists(builder: FirestoreQueryBuilder<CategoryAnchor>): Observable<any> {
    return this.firestoreService.getCollection(CategoryAnchorFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items : null),
    );
  }

  private convertToMap(category: CategoryAnchor): object {
    return CategoryAnchor.allFields.reduce((p, key) => {
      if (category[key] === undefined) {
        return p;
      }
      const value = category[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const categoryAnchor = new CategoryAnchor(CategoryAnchorId.create(item.id));
    categoryAnchor.postId = PostId.create(item.postId);
    categoryAnchor.categoryId = CategoryId.create(item.categoryId);
    categoryAnchor.createdBy = item.createdBy ? AccountOrganizationId.create(item.createdBy) : undefined;
    categoryAnchor.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    categoryAnchor.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    categoryAnchor.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return categoryAnchor;
  }
}
