import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
// tslint:disable:no-any
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { IconDefinition, faEye, faEyeSlash } from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent extends BaseComponent implements ControlValueAccessor {
  @Input() type = 'default';
  @Input() value: string = '';
  @Input() maxLength = ELimitCharacters.INPUT_TEXT_DEFAULT_MAX_LENGTH;
  @Input() label = null;
  @Input() hint = null;
  @Input() placeholder = '';
  @Input() readOnly = false;

  @Input() isPassword = false;

  @Output() submit = new EventEmitter();

  @ViewChild('text') element: ElementRef;

  required = false;

  onChange: (value: any) => void;
  onTouched: (value: any) => void;

  constructor(@Self() public control: NgControl) {
    super();
    this.control.valueAccessor = this;
  }

  public get icon(): IconDefinition {
    return this.type === 'password' ? faEye : faEyeSlash;
  }

  public onChangeMode(): void {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password'
    }
  }

  protected override onInit(): void {
    const validator = this.control.control.validator;
    this.required = validator ? validator({} as AbstractControl)?.required || false : false;
  }

  // ControlValueAccessor
  writeValue(value: any) {
    if (this.element) {
      this.element.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onKeyupEnter() {
    this.submit.emit();
  }
}
