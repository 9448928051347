export class AppRoutingConstant {
  public static readonly ROOT = [''];

  /* ----------------------------------------- */
  /*                  AUTH                     */
  /* ----------------------------------------- */
  public static readonly AUTH = ['auth'];
  public static readonly AUTH_LOGIN = AppRoutingConstant.AUTH.concat('login');

  /* ----------------------------------------- */
  /*                 Setting                   */
  /* ----------------------------------------- */
  public static readonly SETTING = ['setting'];
  public static readonly SETTING_INTEGRATION = AppRoutingConstant.SETTING.concat('integration');
  public static readonly SETTING_ACCOUNT = AppRoutingConstant.SETTING.concat('account');
  public static readonly SETTING_WORKSPACE = ['setting/workspace']

  /* ----------------------------------------- */
  /*              MY FEEDBACK                  */
  /* ----------------------------------------- */
  public static readonly MY_FEEDBACK = ['feedback'];

  /* ----------------------------------------- */
  /*              MY KNOWLEDGE                 */
  /* ----------------------------------------- */
  public static readonly MY_KNOWLEDGE = ['knowledge'];

  /* ----------------------------------------- */
  /*              WORKSPACE                    */
  /* ----------------------------------------- */
  public static readonly WORKSPACE = ['workspace'];

  /* ----------------------------------------- */
  /*              SLACK CALLBACK               */
  /* ----------------------------------------- */
  public static readonly SLACK_CALLBACK = ['slack-callback'];

  /* ----------------------------------------- */
  /*              POST                      */
  /* ----------------------------------------- */
  public static readonly POST = ['?p='];

  /* ----------------------------------------- */
  /*              INVITE                       */
  /* ----------------------------------------- */
  public static readonly INVITE = ['invite'];

  /* ----------------------------------------- */
  /*              SEARCH                       */
  /* ----------------------------------------- */
  public static readonly SEARCH = ['search'];

  /* ----------------------------------------- */
  /*              ERROR                       */
  /* ----------------------------------------- */
  public static readonly ERROR = ['error'];

  /* ----------------------------------------- */
  /*              HELP                      */
  /* ----------------------------------------- */
  public static readonly HELP = ['help'];
}
