import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { faLongArrowAltLeft } from '@fortawesome/pro-solid-svg-icons';
import { AccountDomainService, AccountOrganizationDomainService } from 'domain/kernel/account/service';
import { AccountDto } from 'domain/kernel/account/dto';
import { CategoryDto } from 'domain/kernel/category/dto';
import { EKnowledgeType } from 'domain/kernel/knowledge/enum';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { DashboardQuery } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.query';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { KnowledgeSearchHeaderQuery } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.query';
import { KnowledgeSearchHeaderService } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.service';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription, combineLatest, concat, of, Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { CreateKnowledgeModalComponent } from '../create-knowledge-modal/create-knowledge-modal.component';
import { CreateQuotationModalComponent } from '../create-quotation-modal/create-quotation-modal.component';
import { CreateRequestModalComponent } from '../create-request-modal/create-request-modal.component';
import { QuoteKnowledgeModalComponent } from '../quote-knowledge-modal/quote-knowledge-modal.component';
import { DetailPostModalService } from './state/detail-post-modal.service';

@Component({
  selector: 'app-detail-post-modal',
  templateUrl: './detail-post-modal.component.html',
  styleUrls: ['./detail-post-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPostModalComponent extends BaseComponent {
  subscription: Subscription;
  faLongArrowAltLeft = faLongArrowAltLeft;
  isKnowledgeWriter: boolean = false;
  isRequestWriter: boolean = false;
  isExpanded: boolean = false;
  deletedUser: boolean = false;
  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }
  user: AccountDto = null;
  totalComment: number = 0;
  @Input() knowledgeSummary: KnowledgeSummary = null;

  public isCommentActivated = false;
  public tags: CategoryDto[] = [];
  public files: KnowledgeFileSummary[] = [];
  public initLoad = false;

  constructor(
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly modalService: ModalService,
    private readonly accountOrganizationDomainService: AccountOrganizationDomainService,
    private readonly accountDomainService: AccountDomainService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly nzModalRef: NzModalRef<DetailPostModalComponent>,
    private readonly dashboardService: DashboardService,
    private readonly knowledgeSearchHeaderService: KnowledgeSearchHeaderService,
    private readonly knowledgeSearchHeaderQuery: KnowledgeSearchHeaderQuery,
    private readonly detailPostModalService: DetailPostModalService,
    private readonly dashboardQuery: DashboardQuery,
  ) {
    super();
  }

  public getTags($event: CategoryDto[]): void {
    this.tags = $event;
  }

  public getFiles($event: KnowledgeFileSummary[]): void {
    this.files = $event;
  }

  protected override onDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected override onInit(): void {
    if (this.knowledgeSummary.postType === EPostType.KNOWLEDGE) {
      const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
      this.isKnowledgeWriter = this.knowledgeSummary.sendBy === currentAccountOrganization.id;
    }

    if (this.knowledgeSummary.postType === EPostType.KNOWLEDGE_REQUEST) {
      const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
      this.isRequestWriter = this.knowledgeSummary.sendBy === currentAccountOrganization.id;
    }

    this.subscription = combineLatest([
      this.getAccountOrganization().pipe(
        take(1),
        tap(rs => {
          if (rs !== null) {
            this.user = rs;
          } else {
            this.deletedUser = true;
          }
        })),
      // this.saveKnowledgeActivity()
      this.getPostingDetail()
    ]).pipe(
      map(rs => rs[1]),
      tap(rs => {
        if (rs) {
          this.knowledgeSummary.title = rs?.title;
          this.knowledgeSummary.body = rs?.body;
          this.knowledgeSummary.categories = rs?.categories;
          this.knowledgeSummary.createdAt = rs?.createdAt;
          this.knowledgeSummary.updatedAt = rs?.updatedAt;
          this.knowledgeSummary.updatedAtShortStr = rs?.updatedAtShortStr;
          this.knowledgeSummary.updatedAtDetailStr = rs?.updatedAtDetailStr;

          const stateUpdate = {
            title: rs?.title,
            body: rs?.body,
            categories: rs?.categories,
            createdAt: rs?.createdAt,
            updatedAt: rs?.updatedAt,
            updatedAtShortStr: rs?.updatedAtShortStr,
            updatedAtDetailStr: rs?.updatedAtDetailStr,
          }
          this.syncPropsToState(stateUpdate);
        }
      })
    ).subscribe(_ => {
      this.initLoad = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  onEditKnowledge() {
    if (this.knowledgeSummary.postType === EPostType.KNOWLEDGE && this.knowledgeSummary.knowledgeType === EKnowledgeType.NORMAL) {
      this.modalService.open(CreateKnowledgeModalComponent,
        { nzComponentParams: { knowledgeSummary: this.knowledgeSummary } },
        (data) => {
          // update modal detail when a posting have been updated.
          if (data) {
            this.knowledgeSummary = { ...data } as KnowledgeSummary;
            const stateUpdate = {
              title: data?.title,
              body: data?.body,
              categories: data?.categories,
              createdAt: data?.createdAt,
              updatedAt: data?.updatedAt,
              updatedAtShortStr: data?.updatedAtShortStr,
              updatedAtDetailStr: data?.updatedAtDetailStr,
            }
            this.syncPropsToState(stateUpdate);
            this.changeDetectorRef.detectChanges();
          }
        }
      );
      return;
    } else if (this.knowledgeSummary.postType === EPostType.KNOWLEDGE && this.knowledgeSummary.knowledgeType === EKnowledgeType.QUOTATION) {
      return this.showModalExtractToKnowledge();
    }
  }

  onEditRequest() {
    if (this.knowledgeSummary.postType === EPostType.KNOWLEDGE_REQUEST) {
      this.modalService.open(CreateRequestModalComponent,
        { nzComponentParams: { knowledgeSummary: this.knowledgeSummary } },
        (data) => {
          // update modal detail when a posting have been updated.
          if (data) {
            this.knowledgeSummary = { ...data } as KnowledgeSummary;
            const stateUpdate = {
              title: data?.title,
              body: data?.body,
              categories: data?.categories,
              createdAt: data?.createdAt,
              updatedAt: data?.updatedAt,
              updatedAtShortStr: data?.updatedAtShortStr,
              updatedAtDetailStr: data?.updatedAtDetailStr,
            }
            this.syncPropsToState(stateUpdate);
            this.changeDetectorRef.detectChanges();
          }
        }
      );
    }
  }

  onOpenQuotation() {
    this.modalService.open(QuoteKnowledgeModalComponent,
      { nzComponentParams: { knowledgeSummary: this.knowledgeSummary } },
      (data) => {
        // update modal detail when a posting have been created.
        if (data) {
          this.knowledgeSummary = { ...data } as KnowledgeSummary;
          this.dashboardService.pushKnowledgeSummary(data);
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }

  close() {
    this.nzModalRef.close();
  }

  public onTotalCommentChange($event): void {
    this.totalComment = $event?.totalComment || 0;
    this.isCommentActivated = $event?.isCommentActivated || false;

    // sync comment state
    this.syncPropsToState({ commentTotal: this.totalComment, isCommentActivated: this.isCommentActivated });
    this.changeDetectorRef.detectChanges();
  }

  private getAccountOrganization() {
    // const accountOrganizationId = this.knowledgeSummary && this.knowledgeSummary.postType === EPostType.SHARING_VENDOR ? this.knowledgeSummary.registeredBy : this.knowledgeSummary.sendBy;
    return concat(
      this.accountOrganizationDomainService.selectById(this.knowledgeSummary.sendBy).pipe(
        take(1),
        filter(accountOrganization => accountOrganization !== null),
        mergeMap(accountOrganization => this.accountDomainService.selectAccount(accountOrganization.accountId)),
      ),
      of(null)
    )
  }

  // create/edit knowledge from a sharing vendor
  private showModalExtractToKnowledge() {
    // const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    this.modalService.open(
      CreateQuotationModalComponent,
      {
        nzComponentParams: {
          knowledgeSummaryEditor: this.knowledgeSummary,
          knowledgeSummary: this.knowledgeSummary
        }
      },
      (data) => {
        // update modal detail when a posting have been updated.
        if (data) {
          this.knowledgeSummary = { ...data } as KnowledgeSummary;
          // this.detectSyncToState();
          const stateUpdate = {
            title: data?.title,
            body: data?.body,
            categories: data?.categories,
            createdAt: data?.createdAt,
            updatedAt: data?.updatedAt,
            updatedAtShortStr: data?.updatedAtShortStr,
            updatedAtDetailStr: data?.updatedAtDetailStr,
          }
          this.syncPropsToState(stateUpdate);
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }

  private getPostingDetail(): Observable<KnowledgeSummary> {
    const { currentAccountOrganization } = this.workspaceQuery.getValue();
    return concat(this.detailPostModalService.fetchKnowledgeById(this.knowledgeSummary.id, currentAccountOrganization), of(null))
  }

  private syncPropsToState(props: any) {
    const { boards } = this.dashboardQuery.getValue();
    const { summaries } = this.knowledgeSearchHeaderQuery.getValue();
    if (Object.keys(boards)?.length > 0) {
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary, props);
    }
    // for dashboard search
    if (summaries.length > 0) {
      this.knowledgeSearchHeaderService.updateKnowledgeSummary(this.knowledgeSummary, props);
    }
  }
}
