import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { UserTokenApiClient } from '../api/user-token.api.client';
import { Observable } from 'rxjs';
import { GenerateTokenStateRequest } from 'serverside/slackbot/api/model/generate-token-state.request.model';
import { GenerateTokenStateResponse } from 'serverside/slackbot/api/model/generate-token-state.response.model';
import { DecodeTokenStateRequest } from 'serverside/slackbot/api/model/decode-token-state.request.model';
import { DecodeTokenStateReponse } from 'serverside/slackbot/api/model/decode-token-state.reponse.model';

@Injectable({
  providedIn: 'root'
})
export class UserTokenService {
  constructor(private readonly userTokenApiClient: UserTokenApiClient) { }

  generateToken(generateTokenStateRequest: GenerateTokenStateRequest): Observable<GenerateTokenStateResponse> {
    return from(this.userTokenApiClient.generateToken(generateTokenStateRequest));
  }

  decodeToken(decodeTokenStateRequest: DecodeTokenStateRequest): Observable<DecodeTokenStateReponse> {
    return from(this.userTokenApiClient.decodeToken(decodeTokenStateRequest));
  }
}
