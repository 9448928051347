export class ObjectUtils {
  public static isComparisonObject(obj1: unknown, obj2: unknown): boolean {
    if (this.isNullOrUndefined(obj1) || this.isNullOrUndefined(obj2)) {
      return false;
    }
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public static isNullOrUndefined(obj: unknown): boolean {
    return typeof obj === 'undefined' || obj === null;
  }

  public static filterDuplicate<T>(array: T[]): T[] {
    const uniqueValues: T[] = [];
    const value = array.filter((element: T) => {
      if (element['value']) {
        const isDuplicate = uniqueValues.includes(element['value']);
        if (!isDuplicate) {
          uniqueValues.push(element['value']);
          return true;
        }
      }
      return false;
    });
    return value;
  }

  /**
   * Sort time updatedAt for firebase
   *
   * @param array
   * @returns
   */
  public static sortTime<T>(array: T[]): T[] {
    return array.sort((a, b) => b?.['updatedAt']?.getTime() - a?.['updatedAt']?.getTime()) || [];
  }
}
