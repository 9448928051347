import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/fontawesome-pro-light';
import { faChevronUp, faExchange } from '@fortawesome/pro-regular-svg-icons';
import { EDashboardVisualization } from 'frontend/admin/app/enum/dashboard-visualization.enum';
import { ELogicOperators } from 'domain/kernel/board/enum';
import { EDateFormat } from 'frontend/admin/app/enum/date-format.enum';
import { BoardCriteria } from 'frontend/admin/app/model/board-criteria.model';
import { BoardSummary } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.store';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { genPostType } from 'frontend/utl/generate/combobox.generate';
import * as moment from 'moment';
import { Subscription, of } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { UserOption } from '../../atoms/search-user-autocomplete/search-user-autocomplete.component';
import { BoardService } from './state/board.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardComponent extends BaseComponent {
  @Input() isOpenBoard: boolean = false;
  @Input() board: BoardSummary = null;
  @Input() dashboardModeView: EDashboardVisualization = EDashboardVisualization.TIMELINE_VALUE;
  @Output() close = new EventEmitter();

  public readonly EDashboardVisualization = EDashboardVisualization;

  readonly appData = {
    faTimes: faTimes,
    faExchange: faExchange,
    faChevronUp: faChevronUp,
    createBtn: 'フィルターを作成',
    updateBtn: 'フィルターを更新'
  }

  ngSelectTags = {
    tags: [],// CategoryDto[] all categories belong to workspace
    selectedTags: [],// string[] all categoryIds have selected
    categoryKnowledge: [],
    hideSelected: true,
    multiple: true,
    notFoundText: '検索タグ'
  };

  ngSelectTagsNotInclude = {
    tags: [],// CategoryDto[] all categories belong to workspace
    selectedTags: [],// string[] all categoryIds have selected
    hideSelected: true,
    multiple: true,
    notFoundText: '検索タグ'
  };

  ngSelectMemberInclude = {
    tags: [],// sendby/createdby
    selectedTags: [], // string[] all accountOrganizationId have selected
    hideSelected: true,
    multiple: true,
  };

  ngSelectMemberNotInclude = {
    tags: [],// sendby/createdby
    selectedTags: [],// string[] all accountOrganizationId have selected
    hideSelected: true,
    multiple: true,
  };

  ngSelectPostType = {
    tags: [],// PostType[]
    selectedTags: [],// string[] all post type have selected
    hideSelected: true,
    multiple: true,
  };

  // date range
  start: Date = null;
  end: Date = null;
  keywordLogicOperator = null;
  categoriesLogicOperator = null;

  // logic operator
  readonly logicOperatorOptions: any[] = [
    { value: ELogicOperators.AND, label: 'and検索' },
    { value: ELogicOperators.OR, label: 'or検索' },
  ];

  formGroup: FormGroup;
  subscription: Subscription;
  isFailed: boolean = false;
  errMsgs: string[] = [];
  inProcess: boolean = false;
  criteria: BoardCriteria = null;
  isEditForm: boolean = false;
  isDetailFilter: boolean = false;
  isShowDatePicker: boolean = false;

  constructor(
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly formBuilder: FormBuilder,
    private readonly boardService: BoardService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      boardName: ['', [Validators.required, Validators.maxLength(50)]],
      keyword: [''],
      keywordNotInclude: [''],
    });
  }

  protected override onInit(): void {
    this.criteria = this.board && this.board.board && this.board.board.criteria ?
      (JSON.parse(this.board.board.criteria) as BoardCriteria) : null;
    if (this.board) {
      this.isEditForm = true;
      this.formGroup.setValue({
        boardName: this.board && this.board.board.title || '',
        keyword: this.criteria && this.criteria.keyword || '',
        keywordNotInclude: this.criteria && this.criteria.keywordNotInclude || '',
      });
    }
    this.ngSelectPostType.tags = genPostType();


    this.subscription = this.init().subscribe(_ => {
      if (this.criteria) {
        this.ngSelectPostType.selectedTags = this.criteria.postTypes;
        this.ngSelectPostType.tags = this.ngSelectPostType.tags.map(tag => ({ ...tag, checked: this.criteria.postTypes.indexOf(tag.value) !== -1 }));

        this.ngSelectTags.selectedTags = this.criteria.categories;
        this.ngSelectMemberInclude.selectedTags = this.criteria.origins;

        this.ngSelectTagsNotInclude.selectedTags = this.criteria.categoriesNotInclude || [];
        this.ngSelectMemberNotInclude.selectedTags = this.criteria.sendbyNotInclude || [];

        this.categoriesLogicOperator = this.criteria.categoriesLogicOperator;
        this.keywordLogicOperator = this.criteria.keywordLogicOperator;
        this.start = this.criteria.dateFrom || null;
        this.end = this.criteria.dateTo || null;
        this.isShowDatePicker = (this.criteria.dateFrom !== null || this.criteria.dateTo !== null);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  closeValidate() {
    this.isFailed = false;
    this.errMsgs = [];
  }

  onCloseModal() {
    // close modal
    this.close.emit();
  }

  onSelectedTags($event: any): void {
    const input = $event?.map(x => x.value);
    this.ngSelectTags.selectedTags = input;
  }

  onSelectedTagsNotInclude(event: any) {
    this.ngSelectTagsNotInclude.selectedTags = event;
  }

  public onSelectedWriters($event: UserOption[]): void {
    const input = $event?.map(x => x.value);
    this.ngSelectMemberInclude.selectedTags = input;
  }

  // public onSelectMemberNotInclude(event: any) {
  //   this.ngSelectMemberNotInclude.selectedTags = event;
  // }

  public onSelectMemberNotInclude($event: UserOption[]): void {
    const input = $event?.map(x => x.value);
    this.ngSelectMemberNotInclude.selectedTags = input;
  }

  onSelectedPostType(event: any) {
    this.ngSelectPostType.selectedTags = event;
    if (event && event.length > 0) {
      this.ngSelectPostType.selectedTags = event.filter(item => item.checked)?.map(item => item.value);
    }
  }

  // date range
  onChangeRange($event: any) {
    this.start = Array.isArray($event) && $event.length > 0 ? $event[0] : null;
    this.end = Array.isArray($event) && $event.length > 1 ? $event[1] : null;
  }

  onChangeKeywordLogicOption($event: any) {
    this.keywordLogicOperator = $event;
  }
  onChangeTagLogicOption($event: any) {
    this.categoriesLogicOperator = $event;
  }

  onUpsertBoard() {
    this.isFailed = false;
    this.errMsgs = [];
    const validForm = this.validateCreateKnowledge();
    if (!validForm.isValid) {
      this.isFailed = true
      this.errMsgs = validForm.errMsgs;
      return;
    }

    if (this.ngSelectTags.selectedTags.length > CommonConstant.ITEM_TAG_MAXIMUM) {
      return;
    }

    this.inProcess = true;
    return this.isEditForm ? this.updateBoard() : this.insertBoard();
  }

  private getEndOfDay(date: Date): Date {
    const d = moment(date).endOf("day")?.format(EDateFormat.DATETIME_FORMAT);
    return new Date(d);
  }

  private getStartOfDay(date: Date): Date {
    const d = moment(date).startOf("day")?.format(EDateFormat.DATETIME_FORMAT);
    return new Date(d);
  }

  private init() {
    return this.workspaceQuery.selectOrganization().pipe(
      filter(organization => organization !== null),
      mergeMap(organization => this.boardService.fetch(organization.id)),
      tap(result => (this.ngSelectTagsNotInclude.tags = this.ngSelectTags.tags = result.categories.map(
        (tag: { id: any; label: any; }) => ({ value: tag.id, label: tag.label } as any)))),
      tap(result => (
        this.ngSelectMemberNotInclude.tags = this.ngSelectMemberInclude.tags = result.accountOrganizations.map((user) => {
          return ({
            value: user?.accountOrganizationId,
            label: user?.displayName,
            shortImg: user?.shortImg,
          })
        }
        )))
    );
  }

  private validateCreateKnowledge() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.formGroup.invalid || this.formGroup.value['boardName'].trim() === '') {
      this.formGroup.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }

    if (this.formGroup.value['boardName'].trim() && this.formGroup.value['boardName'].trim().toUpperCase() === 'ALL') {
      rs.isValid = false;
      rs.errMsgs.push('ボードフィルター名が存在しました');
    }

    return rs;
  }

  private insertBoard() {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    return this.boardService.isExistsBoard(this.formGroup.value['boardName'].trim(), currentAccountOrganization.id)
      .pipe(
        take(1),
        mergeMap(isExists => {
          if (isExists) {
            this.isFailed = true;
            this.errMsgs.push('ボードフィルター名が存在しました');
            this.inProcess = false;
            return of(null);
          } else {
            const criteria = ({
              postTypes: this.ngSelectPostType.selectedTags || [],
              origins: this.ngSelectMemberInclude.selectedTags || [],
              sendbyNotInclude: this.ngSelectMemberNotInclude.selectedTags || [],
              keyword: this.formGroup.value['keyword'].trim() || '',
              keywordNotInclude: this.formGroup.value['keywordNotInclude'].trim() || '',
              keywordLogicOperator: this.keywordLogicOperator || '', //
              categories: this.ngSelectTags.selectedTags || [],
              categoriesNotInclude: this.ngSelectTagsNotInclude.selectedTags || [],
              categoriesLogicOperator: this.categoriesLogicOperator || '', //
              dateFrom: this.getStartOfDay(this.start), //
              dateTo: this.getEndOfDay(this.end), //
            } as BoardCriteria);
            return this.boardService.createBoard(this.formGroup.value['boardName'].trim(), criteria, currentAccountOrganization);
          }
        })).subscribe({
          complete: () => {
            this.inProcess = false;
            this.changeDetectorRef.detectChanges();
            if (!this.isFailed) {
              this.onCloseModal();
            }
          },
          error: err => {
            this.inProcess = false;
            console.error(err);
            this.onCloseModal();
          }
        });
  }

  private updateBoard() {
    const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
    return this.boardService.selectedIfExists(this.formGroup.value['boardName'].trim(), currentAccountOrganization.id)
      .pipe(
        take(1),
        mergeMap(board => {
          if (board == null || board.id == this.board.board.id) {
            const criteria = ({
              postTypes: this.ngSelectPostType.selectedTags || [],
              origins: this.ngSelectMemberInclude.selectedTags || [],
              sendbyNotInclude: this.ngSelectMemberNotInclude.selectedTags || [],
              keyword: this.formGroup.value['keyword'].trim() || '',
              keywordNotInclude: this.formGroup.value['keywordNotInclude'].trim() || '',
              keywordLogicOperator: this.keywordLogicOperator || '', //
              categories: this.ngSelectTags.selectedTags || [],
              categoriesNotInclude: this.ngSelectTagsNotInclude.selectedTags || [],
              categoriesLogicOperator: this.categoriesLogicOperator || '', //
              dateFrom: this.getStartOfDay(this.start), //
              dateTo: this.getEndOfDay(this.end), //
            } as BoardCriteria);
            return this.boardService.updateBoard(this.formGroup.value['boardName'].trim(), criteria, currentAccountOrganization, this.board.board);
          } else {
            this.isFailed = true;
            this.errMsgs.push('ボードフィルター名が存在しました');
            this.inProcess = false;
            return of(null);
          }
        })
      ).subscribe({
        complete: () => {
          this.inProcess = false;
          this.changeDetectorRef.detectChanges();
          if (!this.isFailed) {
            this.onCloseModal();
          }
        },
        error: err => {
          this.inProcess = false;
          console.error(err);
          this.onCloseModal();
        }
      });;
  }

  onSwitchToBoardDetail() {
    this.isDetailFilter = !this.isDetailFilter;
  }

  onToggleDatePicker() {
    this.isShowDatePicker = !this.isShowDatePicker;
  }
}
