<div class="create-quotation-modal" (showList)="showList.emit($event)">
  <ng-container modal-content>
    <app-header-card [hasUser]="true">
      <ng-container avatar *ngIf="appQuery.selectCurrentAccount() | async as currentAccount">
        <app-avatar
          *ngIf="currentAccount"
          [altText]="currentAccount.email"
          [source]="currentAccount.shortImg"
          [width]="25"
          [height]="25"
        ></app-avatar>
      </ng-container>
      <ng-container displayName>
        <div *ngIf="namePostOwner">{{ namePostOwner }}</div>
      </ng-container>
      <ng-container action>
        <app-button *ngIf="!attachmentFiles.fileSizeExceedsLimit" [disabled]="inProcess" (clickButton)="onUpsertPost()">
          {{ knowledgeSummary ? appData.updateBtn : appData.createBtn }}
        </app-button>
        <app-button *ngIf="attachmentFiles.fileSizeExceedsLimit" [disabled]="attachmentFiles.fileSizeExceedsLimit">
          {{ knowledgeSummary ? appData.updateBtn : appData.createBtn }}
        </app-button>
      </ng-container>
    </app-header-card>
    <div class="create-quotation-modal__body">
      <div class="flash" *ngIf="createQuotationModalQuery.selectFailed() | async as state">
        <div class="flash__wrapper" *ngIf="createQuotationModalQuery.selectErrMsgs() | async as errMsgs">
          <div>
            <p *ngFor="let errMsg of errMsgs">{{ errMsg }}</p>
          </div>
          <div class="flash__button" (click)="closeValidate()">
            <fa-icon [icon]="appData.faTimes"></fa-icon>
          </div>
        </div>
      </div>
      <form class="form" [formGroup]="formGroup">
        <div class="form-body">
          <div class="form-body__title">
            <app-knowledge-body-title [isShowUpdatedAtJIT]="false">
              <ng-container title>
                <app-text type="text" formControlName="title" placeholder="タイトルナレッジ" [readOnly]="false"></app-text>
                <div
                  *ngIf="formGroup.controls['title'].invalid && (formGroup.controls['title'].dirty || formGroup.controls['title'].touched)"
                  class="group__error"
                >
                  <p *ngIf="formGroup.controls['title'].errors.required" class="error"> {{ MessagesUtils.required('タイトル') }} </p>
                  <p *ngIf="formGroup.controls['title'].errors.maxlength" class="error"> {{ MessagesUtils.max(50) }} </p>
                </div>
              </ng-container>
            </app-knowledge-body-title>
          </div>
          <div class="form-body__content">
            <app-body-knowledge-detail [knowledgeSummary]="knowledgeSummary" [isReadonly]="false">
              <ng-container body>
                <app-textarea [minRows]="12" formControlName="body" placeholder="ナレッジ詳細を入力してください(必須)"> </app-textarea>
                <div
                  *ngIf="formGroup.controls['body'].invalid && (formGroup.controls['body'].dirty || formGroup.controls['body'].touched)"
                  class="group__error"
                >
                  <p *ngIf="formGroup.controls['body'].errors.required" class="error">
                    {{ MessagesUtils.required('本文') }}
                  </p>
                  <p *ngIf="formGroup.controls['body'].errors.maxlength" class="error">
                    {{ MessagesUtils.max(ELimitCharacters.TEXTAREA_DEFAULT_MAX_LENGTH) }}
                  </p>
                </div>
                <div class="form-tags">
                  <app-create-tag
                    [dataSource]="ngSelectTags.tags"
                    [selectedTags]="ngSelectTags.selectedTags"
                    (selected)="onSelectedTags($event)"
                  ></app-create-tag>
                </div>
              </ng-container>
              <ng-container attachment>
                <app-knowledge-attachment-view
                  [knowledgeSummary]="isCreateKnowledge ? knowledgeSummary : knowledgeSummaryEditor"
                  [isReadonly]="false"
                  (upload)="onUploadFiles($event)"
                ></app-knowledge-attachment-view>
              </ng-container>
            </app-body-knowledge-detail>
          </div>
          <div class="quotation" *ngIf="!hiddenQuotation">
            <div class="timeline-card">
              <div class="timeline-card__wrapper">
                <div>
                  <div class="px-md-50">
                    <app-timeline-card-header [knowledgeSummary]="knowledgeSummary" isQuotation="true"> </app-timeline-card-header>
                  </div>
                  <div class="px-md-1">
                    <app-timeline-card-body [knowledgeSummary]="knowledgeSummary" [isQuotation]="true"> </app-timeline-card-body>
                  </div>
                  <div class="px-md-1">
                    <app-categories-anchor [knowledgeSummary]="knowledgeSummary"></app-categories-anchor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- add tags -->
      </form>
    </div>
  </ng-container>
</div>
