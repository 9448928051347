import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Type } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { BadgeModule } from '../../atoms/badge/badge.module';
import { ButtonModule } from '../../atoms/button/button.module';
import { CardViewHeaderAttachmentCountModule } from '../../molecules/card-view-header-attachment-count/card-view-header-attachment-count.module';
import { CardViewHeaderAttachmentModule } from '../../molecules/card-view-header-attachment/card-view-header-attachment.module';
import { CardViewHeaderLinkModule } from '../../molecules/card-view-header-link/card-view-header-link.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotfoundRecordTextModule } from '../../molecules/notfound-record-text/notfound-record-text.module';
import { SendFeedbackModalComponent } from './send-feedback-modal.component';

const components: Type<any>[] = [SendFeedbackModalComponent];

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    BadgeModule,
    ButtonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    FormsModule,
    NotfoundRecordTextModule,
    CardViewHeaderAttachmentModule,
    CardViewHeaderLinkModule,
    CardViewHeaderAttachmentCountModule
  ],
  declarations: [components],
  exports: [components],
})
export class SendFeedbackModalModule { }
