import { NzNotificationDataOptions } from "ng-zorro-antd/notification";

export class CommonConstant {
  // Language
  public static readonly LANGUAGE_DEFAULT = {
    SHORT: 'ja',
    LONG: 'ja-JP',
  };

  public static readonly MAX_COUNT_BADGE = 99;
  public static readonly AUTO_SAVE_DRAFT_TIME = 400;

  // Notification config
  public static readonly DEFAULT_NOTIFICATION_CONFIG_DURATION = 3 * 1000;
  public static readonly DEFAULT_NOTIFICATION_CONFIG: NzNotificationDataOptions = {
    nzDuration: CommonConstant.DEFAULT_NOTIFICATION_CONFIG_DURATION,
    nzClass: 'notification__custom',
  };

  // Input
  public static readonly INPUT_DEBOUNCE_INTERVAL = 300;
  public static readonly INPUT_DEBOUNCE_THRESH_HOLD = 1;
  public static readonly DEFAULT_MAX_LENGTH = 100;

  public static readonly ITEM_TAG_MAXIMUM = 10;

  public static readonly FIREBASE_ERROR_CODES = {
    EXITS_EMAIL: 'auth/email-already-in-use',
  }
}
