import { EMPTY, Observable } from 'rxjs';
import { FeedbackDeliveryId, FeedbackDeliveryReceiverArchived, FeedbackDeliverySenderArchived, FeedbackDeliveryUnread } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FeedbackDelivery } from '../../entity';
import { FeedbackDeliveryNotFoundError } from '../../exception';
import { FeedbackId } from '../../../feedback/value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFeedbackDeliveryRepository } from '../feedback-delivery.repository';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { OrganizationId } from '../../../organization/value';
import { Timestamp } from 'utility/model/timestamp.value';

export class FeedbackDeliveryFirestoreRepository implements IFeedbackDeliveryRepository {
  private static readonly collectionId = 'feedback_delivery';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: FeedbackDeliveryId): Observable<FeedbackDelivery> {
    return this.firestoreService.getDocument(FeedbackDeliveryFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new FeedbackDeliveryNotFoundError(`feedback_delivery id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectForUpdateEs(id: FeedbackDeliveryId): Observable<FeedbackDelivery> | null {
    return this.firestoreService.getDocument(FeedbackDeliveryFirestoreRepository.collectionId, id).pipe(
      map(item => item ? this.convertToEntity(item) : null)
    );
  }

  insert(item: FeedbackDelivery): Observable<FeedbackDelivery> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date())
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(FeedbackDeliveryFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(feedbackDelivery: FeedbackDelivery): Observable<FeedbackDelivery> {
    feedbackDelivery.updatedAt = Timestamp.createByMillsec(+new Date());
    feedbackDelivery.isDeleted = IsDeleted.create(false);
    return this.firestoreService.getDocument(FeedbackDeliveryFirestoreRepository.collectionId, feedbackDelivery.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new FeedbackDeliveryNotFoundError(`feedback_delivery id「${feedbackDelivery.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(FeedbackDeliveryFirestoreRepository.collectionId, this.convertToMap(feedbackDelivery));
      }),
      map(_ => feedbackDelivery)
    );
  }

  delete(id: FeedbackDeliveryId) {
    const proposal = new FeedbackDelivery(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.setDocument(FeedbackDeliveryFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): FeedbackDeliveryId {
    return FeedbackDeliveryId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<FeedbackDelivery>) {
    return this.firestoreService.getCollection(FeedbackDeliveryFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(FeedbackDeliveryFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  private convertToMap(feedbackDelivery: FeedbackDelivery): object {
    return FeedbackDelivery.allFields.reduce((p, key) => {
      if (feedbackDelivery[key] === undefined) {
        return p;
      }
      const value = feedbackDelivery[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const feedbackDelivery = new FeedbackDelivery(FeedbackDeliveryId.create(item.id));
    feedbackDelivery.feedbackId = FeedbackId.create(item.feedbackId);
    feedbackDelivery.sendBy = AccountOrganizationId.create(item.sendBy);
    feedbackDelivery.receivedBy = AccountOrganizationId.create(item.receivedBy);
    feedbackDelivery.organizationId = OrganizationId.create(item.organizationId);
    feedbackDelivery.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    feedbackDelivery.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    feedbackDelivery.unread = FeedbackDeliveryUnread.create(item.unread);
    feedbackDelivery.receiverArchived = FeedbackDeliveryReceiverArchived.create(item.receiverArchived);
    feedbackDelivery.senderArchived = FeedbackDeliverySenderArchived.create(item.senderArchived);
    feedbackDelivery.isDeleted = IsDeleted.create(item.isDeleted);
    return feedbackDelivery;
  }
}
