import { AfterViewInit, Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AssetConstant } from '../../constants/asset.constant';
import { MessagesUtils } from '../../utils/messages.util';
import { Subscribable } from './subscribable';

@Directive()
export abstract class BaseComponent extends Subscribable implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.display') display = 'block';

  public readonly AssetConstant = AssetConstant;
  public readonly MessagesUtils = MessagesUtils;

  public ngOnInit(): void {
    this.onInit();
  }

  public ngAfterViewInit(): void {
    this.onAfterViewInit();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy();
  }

  protected onInit(): void {
    // virtual method
  }

  protected onAfterViewInit(): void {
    // virtual method
  }

  protected onDestroy(): void {
    // virtual method
  }
}
