import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeDocumentCommentTotal extends PrimitiveValueObject<number> {
  static create(value: number): KnowledgeDocumentCommentTotal {
    if (value === null) {
      return new KnowledgeDocumentCommentTotal(0);
    }
    return new KnowledgeDocumentCommentTotal(value);
  }
}
