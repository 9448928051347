export const FILE_NAME_TYPE = {
  audio: ['mp3', 'wav', 'm4a', 'flac', 'wma', 'aac'],
  video: ['mp4', 'mov', 'avi', 'wmv', 'flv', 'f4v', 'swf', 'mkv', 'webm', 'mpeg', 'mpeg-2', 'mkv'],
  image: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'webp'],
  compress: ['zip', 'rar'],
  pdf: ['application/pdf', 'pdf'],
  word: ['doc', 'application/docx', 'application/doc', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['csv', 'xls', 'xlsx', 'application/xlsx', 'application/xls', 'application/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
  powerPoint: ['pptx', 'ppt', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint'],
};

export const ICON_ATTACHMENT_TYPE = {
  // 'audio': FILE_NAME_TYPE.audio,
  // 'video': FILE_NAME_TYPE.video,
  // 'img': FILE_NAME_TYPE.image,
  // 'compress': FILE_NAME_TYPE.compress,
  'pdf': FILE_NAME_TYPE.pdf,
  'word': FILE_NAME_TYPE.word,
  'excel': FILE_NAME_TYPE.excel,
  'powerpoint': FILE_NAME_TYPE.powerPoint,
  'url': ['url']
};

export const DEFAULT_FILE_ICON = 'default';

function IsAudio(name: string): boolean {
  return FILE_NAME_TYPE.audio.includes(name);
}
function IsVideo(name: string): boolean {
  return FILE_NAME_TYPE.video.includes(name);
}
function IsImage(name: string): boolean {
  return FILE_NAME_TYPE.image.includes(name);
}
function IsCompress(name: string): boolean {
  return FILE_NAME_TYPE.compress.includes(name);
}
function IsPdf(name: string): boolean {
  return FILE_NAME_TYPE.pdf.includes(name);
}
function IsWord(name: string): boolean {
  return FILE_NAME_TYPE.word.includes(name);
}
function IsExcel(name: string): boolean {
  return FILE_NAME_TYPE.excel.includes(name);
}
function IsPowerPoint(name: string): boolean {
  return FILE_NAME_TYPE.powerPoint.includes(name);
}

function getAttachmentIcon(type: string) {
  if (type) {
    for (var key in ICON_ATTACHMENT_TYPE) {
      if (ICON_ATTACHMENT_TYPE[key]?.includes(type)) {
        return key;
      }
    }
  }

  return DEFAULT_FILE_ICON;
}

export { IsAudio, IsVideo, IsImage, IsCompress, IsPdf, IsWord, IsExcel, IsPowerPoint, getAttachmentIcon };
