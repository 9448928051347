import { EMPTY, Observable } from 'rxjs';
import { KnowledgeActivityId, KnowledgeActivityTargetType, KnowledgeActivityType } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IKnowledgeActivityRepository } from '../knowledge-activity.repository';
import { IsDeleted } from '../../../base/value';
import { KnowledgeActivity } from '../../entity';
import { KnowledgeActivityNotFoundError } from '../../exception';
import { PostId } from '../../../../kernel/post';
import { Timestamp } from 'utility/model/timestamp.value';

export class KnowledgeActivityFirestoreRepository implements IKnowledgeActivityRepository {
  private static readonly collectionId = 'knowledge_activity';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: KnowledgeActivityId): Observable<KnowledgeActivity> {
    return this.firestoreService.getDocument(KnowledgeActivityFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new KnowledgeActivityNotFoundError(`KnowledgeActivity id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectAll(builder: FirestoreQueryBuilder<KnowledgeActivity>): Observable<KnowledgeActivity> {
    return this.firestoreService.getCollection(KnowledgeActivityFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(KnowledgeActivityFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  insert(item: KnowledgeActivity): Observable<KnowledgeActivity> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(KnowledgeActivityFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(KnowledgeActivity: KnowledgeActivity): Observable<KnowledgeActivity> {
    KnowledgeActivity.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(KnowledgeActivityFirestoreRepository.collectionId, KnowledgeActivity.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new KnowledgeActivityNotFoundError(`KnowledgeActivity id「${KnowledgeActivity.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(KnowledgeActivityFirestoreRepository.collectionId, this.convertToMap(KnowledgeActivity));
      }),
      map(_ => KnowledgeActivity)
    );
  }

  delete(id: KnowledgeActivityId): Observable<void> {
    const proposal = new KnowledgeActivity(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(KnowledgeActivityFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): KnowledgeActivityId {
    return KnowledgeActivityId.create(this.firestoreService.generateId());
  }

  private convertToMap(knowledgeActivity: KnowledgeActivity): object {
    return KnowledgeActivity.allFields.reduce((p, key) => {
      if (knowledgeActivity[key] === undefined) {
        return p;
      }
      const value = knowledgeActivity[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const knowledgeActivity = new KnowledgeActivity(KnowledgeActivityId.create(item.id));
    knowledgeActivity.postId = PostId.create(item.postId);
    knowledgeActivity.targetType = KnowledgeActivityTargetType.create(item.targetType);
    knowledgeActivity.type = KnowledgeActivityType.create(item.type);
    knowledgeActivity.actionBy = AccountOrganizationId.create(item.actionBy);
    knowledgeActivity.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    knowledgeActivity.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    knowledgeActivity.isDeleted = IsDeleted.create(item.isDeleted);
    return knowledgeActivity;
  }
}
