import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ECommentType } from 'domain/kernel/comment/enum';
import { EPostType } from 'domain/kernel/post';
import { ActiveComment } from 'frontend/admin/app/model/active-comment.model';
import { CommentModel } from 'frontend/admin/app/model/comment.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { DashboardQuery } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.query';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { KnowledgeSearchHeaderQuery } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.query';
import { KnowledgeSearchHeaderService } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.service';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { combineLatest, of, Subscription } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { CommentService } from './state/comment.service';

@Component({
  selector: 'app-comment-group',
  templateUrl: './comment-group.component.html',
  styleUrls: ['./comment-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentGroupComponent extends BaseComponent {
  subscription: Subscription;
  @Input() currentUserId!: string;
  @Input() postId!: string;
  @Input() knowledgeSummary!: KnowledgeSummary;
  @Output() totalCommentRef = new EventEmitter();

  comments: CommentModel[] = [];
  totalComment: number = null;
  totalAnswer: number = null;
  noComment: boolean = false;
  activeComment: ActiveComment | null = null;
  isLoading: boolean = false;
  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  private isCommentActivated = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private commentService: CommentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardService: DashboardService,
    private readonly dashboardQuery: DashboardQuery,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly knowledgeSearchHeaderQuery: KnowledgeSearchHeaderQuery,
    private readonly knowledgeSearchHeaderService: KnowledgeSearchHeaderService,
  ) {
    super();
  }

  protected override onDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected override onInit(): void {
    const { currentAccountOrganization } = this.workspaceQuery.getValue();
    this.isLoading = true;
    if (this.knowledgeSummary && this.knowledgeSummary?.commentTotal) {
      this.noComment = this.knowledgeSummary && this.knowledgeSummary?.commentTotal > 0 ? false : true;
      this.totalComment = this.knowledgeSummary?.commentTotal;
      this.totalAnswer = this.knowledgeSummary?.commentTotal;
    }

    this.subscription = combineLatest([
      this.commentService.getCommentByUser(this.knowledgeSummary, currentAccountOrganization.id)
        .pipe(
          take(1),
          tap(item => {
            this.isCommentActivated = item !== null;
            this.emitTotalComment();
          })),
      of(this.noComment).pipe(
        mergeMap(noComment =>
          !noComment ? this.commentService.getCommentsOnlyByPost(this.knowledgeSummary).pipe(tap(comments => this.comments = comments))
            : of(null)
        )
      )
    ]).subscribe({
      complete: () => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getRootComments() {
    return this.comments;
    // return this.comments.filter((comment) => comment.parentId === null || comment.parentId === undefined);
  }

  updateComment($event: any): void {
    if (($event.comment && $event.comment.title && $event.comment.title.trim() !== '')
      || ($event.comment && $event.comment.files && $event.comment.files.length > 0)) {

      const files = $event.comment.files as File[];
      this.commentService.update($event.commentId, $event.comment.title && $event.comment.title.trim() || '', this.knowledgeSummary.id, this.currentUserId, this.knowledgeSummary.postType, files, $event.parentId)
        .subscribe((updatedComment) => {

          // this.comments = this.comments.map((comment) => {
          //   if (comment.id === $event.commentId) {
          //     comment = updatedComment;
          //   }
          //   return comment;
          // });

          this.comments.forEach((comment) => {
            if (comment.id === $event.commentId) {
              comment = updatedComment;
              return comment;
            }
          });

          this.activeComment = null;
          this.totalComment = this.comments.filter(it => it.type !== ECommentType.ANSWER_REQUEST).length;
          this.comments?.forEach(x => { this.isCommentActivated = x.commentedBy === this.knowledgeSummary?.loggedUser?.id });
          this.emitTotalComment();
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  deleteComment($event: any): void {
    this.commentService.delete($event).subscribe(() => {
      this.comments = this.comments.filter(
        (comment) => comment.id !== $event
      );
      this.totalComment = this.comments.filter(it => it.type !== ECommentType.ANSWER_REQUEST).length;
      this.comments?.forEach(x => { this.isCommentActivated = x.commentedBy === this.knowledgeSummary?.loggedUser?.id });
      this.emitTotalComment();
      this.changeDetectorRef.detectChanges();
    });
  }

  setActiveComment(activeComment: ActiveComment | null): void {
    this.activeComment = activeComment;
  }

  addComment($event: any): void {
    if (($event.comment && $event.comment.title && $event.comment.title.trim() !== '')
      || ($event.comment && $event.comment.files && $event.comment.files.length > 0)) {
      const files = $event.comment.files as File[];

      this.commentService.insert(this.knowledgeSummary.id,
        this.currentUserId,
        this.knowledgeSummary.postType,
        $event.comment.title && $event.comment.title.trim() || '',
        $event?.parentId,
        ECommentType.NORMAL,
        files,
      )
        .subscribe((createdComment) => {
          this.comments = [...this.comments, createdComment];
          this.totalComment = this.totalComment + 1;
          this.isCommentActivated = true;
          this.activeComment = null;
          this.emitTotalComment();
          this.scrollToFile();
          // update total comment for all post in dashboard
          this.detectSyncToState();
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  getReplies(commentId: string): CommentModel[] {
    return this.comments
      .filter((comment) => comment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }

  emitTotalComment() {
    this.totalCommentRef.emit({
      totalComment: this.totalComment,
      isCommentActivated: this.isCommentActivated
    });
  }

  private scrollToFile(): void {
    const element = this.document.getElementById('commentBoxId');
    if (element) {
      try {
        setTimeout(() => {
          element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
        });
      } catch (err) {
        console.log(err)
      }
    }
  }

  private detectSyncToState() {
    const { boards } = this.dashboardQuery.getValue();
    const { summaries } = this.knowledgeSearchHeaderQuery.getValue();

    // for dashboard
    if (Object.keys(boards)?.length > 0) {
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary, { totalComments: this.totalComment });
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary, { isCommentActivated: true });
    }

    // for dashboard search
    if (summaries?.length > 0) {
      this.knowledgeSearchHeaderService.updateKnowledgeSummary(this.knowledgeSummary, { totalComments: this.totalComment, isCommentActivated: true });
    }
  }
}
