import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectCheckboxComponent } from './select-checkbox.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

const components: Type<any>[] = [SelectCheckboxComponent];

@NgModule({
  imports: [CommonModule, NzCheckboxModule, FormsModule],
  declarations: [components],
  exports: [components],
})
export class SelectCheckboxModule { }
