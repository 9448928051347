import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SlackMarkedPipe } from './slack-marked.pipe';
import { ToBadgeLabelPipe } from './to-badge-label.pipe';
import { ToBytesSizePipe } from './to-bytes-size.pipe';

const components: Type<any>[] = [
  ToBytesSizePipe,
  SlackMarkedPipe,
  ToBadgeLabelPipe,
  FilterPipe,
  SafeHtmlPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class PipesModule { }
