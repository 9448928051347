import { CommentBody, CommentId, CommentParentId, CommentType } from '../value';
import { EPostType, PostId, PostType } from '../../post';
import { count, filter, map, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { Comment } from '../entity';
import { CommentDto } from '../dto';
import { CommentNotFoundError } from '../exception';
import { ECommentType } from '../enum';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { ICommentRepository } from '../repository';
import { Observable } from 'rxjs';

export class CommentDomainService {
  constructor(private readonly commentRepository: ICommentRepository) { }

  select(commentId: string) {
    return this.commentRepository.select(CommentId.create(commentId))
      .pipe(map(item => this.convertDto(item)));
  }

  countTotalByPostId(postId: string) {
    return this.commentRepository.countAll(new FirestoreQueryBuilder<Comment>()
      .equalWhere('postId', postId))
      .pipe(count());
  }

  getTotalByPostId(postId: string) {
    return this.commentRepository.selectAll(new FirestoreQueryBuilder<Comment>().equalWhere('postId', postId))
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.isDeleted !== true)
      );
  }

  selectByPostId(postId: string) {
    return this.commentRepository
      .selectAll(new FirestoreQueryBuilder<Comment>()
        .orderBy('createdAt', 'asc')
        .limit(1000)
      )
      .pipe(
        map(item => this.convertDto(item)),
        filter(item => item && item.postId === postId && item.isDeleted !== true)
      );
  }

  selectByPostIdAndCommentedBy(postId: string, commentedBy: string) {
    const builder = new FirestoreQueryBuilder<Comment>()
      .equalWhere('postId', postId).equalWhere('commentedBy', commentedBy);

    return this.commentRepository.selectIfExist(builder)
      .pipe(
        take(1),
        tap(comment => {
          if (comment === null || comment?.['isDeleted.value']) {
            throw new CommentNotFoundError('Comment is not found');
          }
        }),
        map(item => this.convertDto(item)),
      );
  }

  selectAll(builder = new FirestoreQueryBuilder<Comment>()) {
    return this.commentRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  insert(postId: string, commentedBy: string, postType: EPostType, body: string, type: ECommentType, parentId?: string): Observable<CommentDto> {
    const comment = new Comment(this.commentRepository.generateId());
    comment.postId = PostId.create(postId);
    comment.postType = PostType.create(postType);
    comment.type = CommentType.create(type);
    comment.body = CommentBody.create(body);
    comment.commentedBy = AccountOrganizationId.create(commentedBy);
    comment.parentId = parentId ? CommentParentId.create(parentId) : undefined;
    return this.commentRepository.insert(comment).pipe(map(item => this.convertDto(item)));
  }

  update(commentId: string, body: string, postId: string, commentedBy: string, postType: EPostType, type: ECommentType, parentId?: string) {
    const comment = new Comment(CommentId.create(commentId));
    comment.body = CommentBody.create(body);
    comment.type = CommentType.create(type);
    comment.postId = PostId.create(postId);
    comment.postType = PostType.create(postType);
    comment.commentedBy = AccountOrganizationId.create(commentedBy);
    comment.parentId = parentId ? CommentParentId.create(parentId) : undefined;
    return this.commentRepository.update(comment).pipe(map(item => this.convertDto(item)));
  }

  delete(commentId: string) {
    return this.commentRepository.delete(CommentId.create(commentId));
  }

  private convertDto(comment: Comment): CommentDto {
    return Comment.allFields.reduce((p, key) => {
      if (comment[key] === undefined) {
        return p;
      }
      const value = comment[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as CommentDto);
  }
}
