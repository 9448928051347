<div class="quote-knowledge-modal">
  <ng-container modal-content>
    <div class="quote-knowledge-modal__header">
      <div class="quote-knowledge-modal__header-group">
        <a (click)="back()">
          <fa-icon class="back-icon" [icon]="faChevronLeft"></fa-icon>
          <span>ナレッジの引用方法を選択</span>
        </a>
      </div>
    </div>
    <div class="quote-knowledge-modal__body">
      <div class="quote-knowledge-modal__body-title">
        <span>{{ knowledgeSummary.title }}</span>
        <div class="attachment">
          <ng-container *ngIf="linksDetected?.length > 0 && files.length > 0; else option">
            <app-card-view-header-attachment-count [count]="files.length + linksDetected.length"></app-card-view-header-attachment-count>
          </ng-container>

          <ng-template #option>
            <ng-container *ngIf="linksDetected?.length > 0; then linkTmpl; else fileTmpl"> </ng-container>
            <ng-template #linkTmpl>
              <app-card-view-header-link [links]="linksDetected"></app-card-view-header-link>
            </ng-template>
            <ng-template #fileTmpl>
              <app-card-view-header-attachment [files]="files"></app-card-view-header-attachment>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="quote-knowledge-modal__body-actions">
        <div class="action action-send">
          <a class="action-group" (click)="onSendFeedback()">
            <div class="action-icon-send"><fa-icon class="" [icon]="faEnvelope"></fa-icon></div>
            <span>フィードバックとして送信する</span>
          </a>
        </div>
        <div class="action action-copy">
          <a class="action-group" (click)="onCopyUrl()">
            <div class="action-icon-copy"><fa-icon class="" [icon]="faCopy"></fa-icon></div>
            <span>URLをコピーする </span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="isCopying">
  <div class="copy__notification">
    <p>URLをコピーしました'</p>
  </div>
</ng-container>
