import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';

@Component({
  selector: 'app-anonymous',
  templateUrl: './anonymous.component.html',
  styleUrls: ['./anonymous.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnonymousComponent extends BaseComponent {
  @Input() text: string = 'A';
  @Input() width: number = 25;
  @Input() height: number = 25;

  widthStyle: string = null;
  heightStyle: string = null;

  protected override onInit(): void {
    this.widthStyle = this.width.toString() + "px"
    this.heightStyle = this.height.toString() + "px"
  }
}
