<div class="app-collapse" [ngClass]="{ open: !isCollapsed }">
  <div class="app-collapse__header" (click)="displayContextMenu($event)">
    <div class="app-collapse__header-content">
      <ng-container *ngIf="!disable">
        <span class="icon-angle" *ngIf="!isCollapsed">
          <fa-icon [icon]="faAngleDown"></fa-icon>
        </span>
        <span class="icon-angle" *ngIf="isCollapsed">
          <fa-icon [icon]="faAngleRight"></fa-icon>
        </span>
      </ng-container>
      <ng-content select="[header]"></ng-content>
    </div>
  </div>
  <div class="app-collapse__body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
