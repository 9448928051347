import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscribable } from 'frontend/admin/app/shared/components/base-component/subscribable';
import { LocalStorageConstant } from 'frontend/admin/app/shared/constants/local-storage.constant';
import { LocalStorageService } from 'frontend/admin/app/shared/services/local-storage.service';
import { CollectionUtils } from 'frontend/admin/app/shared/utils/collection.utils';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class FireAuthService extends Subscribable {
  public user: any;
  protected token: string;
  protected refreshToken: string;
  protected logged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected _firebaseUserCredential: any;

  constructor(
    private readonly angularFireAuth: AngularFireAuth,
    private readonly localStorageService: LocalStorageService
  ) {
    super();
    this.subscribe(this.angularFireAuth.authState, async user => {
      if (user) {
        this.angularFireAuth.updateCurrentUser(user);
        this.token = await user.getIdToken(true);
        const refreshToken = user.refreshToken;
        const userToken = { token: this.token, refreshToken };
        this.localStorageService.setObject(LocalStorageConstant.USER_TOKEN, userToken);
        this.refreshUserToken();
        this.refreshFirebaseUserCredential();
      } else {
        this.localStorageService.setObject(LocalStorageConstant.USER_TOKEN, null);
      }
    })
  }

  public getToken(): string {
    return this.token;
  }

  protected refreshUserToken(): void {
    const userToken = this.localStorageService.getObject(LocalStorageConstant.USER_TOKEN);
    if (userToken) {
      this.setUserToken(userToken as { token: string; refreshToken: string });
    }
  }

  protected refreshFirebaseUserCredential() {
    const firebaseUserCredential = this.localStorageService.getItem(LocalStorageConstant.FIREBASE_USER_CREDENTIAL);
    if (firebaseUserCredential) {
      this._firebaseUserCredential = firebaseUserCredential;
      this.localStorageService.setItem(
        LocalStorageConstant.FIREBASE_USER_CREDENTIAL, this._firebaseUserCredential
      );
    }
  }

  protected clearUserToken() {
    this.localStorageService.removeItem(LocalStorageConstant.USER_TOKEN);
    this.localStorageService.removeItem(LocalStorageConstant.FIREBASE_USER_CREDENTIAL);
    this.token = undefined;
    this.refreshToken = undefined;
    this.user = undefined;
    this._firebaseUserCredential = undefined;
    this.setLogged(false);
  }

  public setUserToken(userToken: { token: string; refreshToken: string }) {
    this.localStorageService.setObject(LocalStorageConstant.USER_TOKEN, userToken);
    this.token = userToken.token;
    this.refreshToken = userToken.refreshToken;
    this.user = jwt_decode(this.token);
    this.setLogged(true);
  }

  public setLogged(status: boolean): void {
    this.logged$.next(status);
  }

  public getLogged(): Observable<boolean> {
    return this.logged$.asObservable();
  }

  login(email: string, password: string) {
    return from(this.angularFireAuth.signInWithEmailAndPassword(email, password));
  }

  public logout(isUserInput = true): Observable<void> {
    this.clearUserToken();
    if (isUserInput) {
      this.localStorageService.setItem(LocalStorageConstant.LOGOUT_EVENT, `logout-${CollectionUtils.uuid()}`);
    }
    return from(this.angularFireAuth.signOut());
  }

  getAuthState() {
    return this.angularFireAuth.authState;
  }

  createAccount(email: string, password: string) {
    return from(this.angularFireAuth.createUserWithEmailAndPassword(email, password)).pipe(map(item => item.user.uid));
  }

  sendPasswordResetEmail(email: string) {
    return from(this.angularFireAuth.sendPasswordResetEmail(email));
  }

  async changePassword(newPwd: string) {
    const user = await this.angularFireAuth.currentUser;
    return user.updatePassword(newPwd).then(() => {
      return user;;
    }).catch((error) => {
      // console.log(error);
      throw new Error('change password failed');
    });
  }

  public confirmPasswordReset(code: string, password: string): Observable<void> {
    return from(this.angularFireAuth.confirmPasswordReset(code, password));
  }

  public verifyPasswordResetCode(code: string): Observable<string> {
    return from(this.angularFireAuth.verifyPasswordResetCode(code));
  }

  public updateEmail(email: string, password: string, newEmail: string): Observable<void> {
    return this.login(email, password).pipe(
      mergeMap(userCredential => userCredential.user.updateEmail(newEmail))
    );
  }

}
