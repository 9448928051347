import { KnowledgeSearchHeaderState, KnowledgeSearchHeaderStore } from './knowledge-search-header.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class KnowledgeSearchHeaderQuery extends Query<KnowledgeSearchHeaderState> {
  constructor(protected store: KnowledgeSearchHeaderStore) {
    super(store);
  }

  selectSummaries() {
    return this.select('summaries');
  }

  selectKeyword() {
    return this.select('keyword');
  }
}
