import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconAttachmentComponent } from './icon-attachment.component';

const components: Type<any>[] = [IconAttachmentComponent];

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [components]
})
export class IconAttachmentModule { }
