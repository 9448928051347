import { CreateRequestModalStore } from './create-request-modal.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CreateRequestModalService {
  constructor(
    private readonly createRequestModalStore: CreateRequestModalStore,
  ) { }

  // Akita
  updateLoading(loading: boolean) {
    this.createRequestModalStore.update({ loading });
  }

  updateFailed(failed: boolean, errMsgs: string[]) {
    this.createRequestModalStore.update({ failed, errMsgs });
  }

  resetStore() {
    this.createRequestModalStore.reset();
  }
}
