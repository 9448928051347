import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBytesSize'
})
export class ToBytesSizePipe implements PipeTransform {
  transform(value: any): any {
    let size = Math.abs(value);
    if (Number.isNaN(size)) {
      return 'Unknown' + size;
    }

    if (size === 0) {
      return '0 bytes';
    }

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let quotient = Math.floor(Math.log10(size) / 3);
    quotient = quotient < units.length ? quotient : units.length - 1;
    size /= 1000 ** quotient;

    return `${+size.toFixed(2)} ${units[quotient]}`;
  }
}
