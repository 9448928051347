import { NgModule, Type } from '@angular/core';

import { BadgeModule } from '../../atoms/badge/badge.module';
import { CardViewHeaderAttachmentComponent } from './card-view-header-attachment.component';
import { CardViewHeaderAttachmentCountModule } from '../card-view-header-attachment-count/card-view-header-attachment-count.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconAttachmentModule } from '../../atoms/icon-attachment/icon-attachment.module';

const components: Type<any>[] = [CardViewHeaderAttachmentComponent];

@NgModule({
  imports: [
    CommonModule,
    BadgeModule,
    FontAwesomeModule,
    IconAttachmentModule,
    CardViewHeaderAttachmentCountModule
  ],
  declarations: [components],
  exports: [components],
})
export class CardViewHeaderAttachmentModule { }
