import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { CommentBody, CommentId, CommentParentId, CommentType } from '../value';
import { PostId, PostType } from '../../../kernel/post';

import { AccountOrganizationId } from '../../account/value';
import { Entity } from '../../../../utility/model/entity.model';
import { IsDeleted } from '../../base/value';
import { Timestamp } from '../../../../utility/model/timestamp.value';

export class Comment implements Entity {
  @Column()
  readonly id: CommentId;
  @Column()
  postId: PostId;
  @Column()
  postType: PostType;
  @Column()
  type: CommentType;
  @Column()
  body: CommentBody;
  @Column()
  commentedBy: AccountOrganizationId;
  @Column()
  parentId?: CommentParentId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: CommentId) {
    this.id = id;
  }

  static get allFields(): (keyof Comment)[] {
    return getColumns(new Comment(CommentId.create('')));
  }

  equals(value: Comment): boolean {
    return this.id.equals(value.id);
  }
}
