import { Injectable } from '@angular/core';
import { EPostType } from 'domain/kernel/post';
import { map, toArray } from 'rxjs/operators';
import { CreateFeedbackModalStore } from './create-feedback-modal.store';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { Observable } from 'rxjs';
import { ESortOptions } from 'frontend/admin/app/enum/sort-options.enum';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';
import { EScrollPaging } from 'frontend/admin/app/enum/scroll-paging.enum';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';

@Injectable({ providedIn: 'root' })
export class CreateFeedbackModalService {
  constructor(
    private readonly createFeedbackModalStore: CreateFeedbackModalStore,
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
  ) { }

  loadKnowledgesInWorkspace(accountOrganization: AccountOrganization, keyword: string): Observable<KnowledgeSummary[]> {
    const query = keyword || '';
    const opt = {
      search_fields: {
        title: {},
        body: {},
      },
      query: query,
      filters: { all: [{ organization_id: accountOrganization.organizationId }, { post_type: EPostType.KNOWLEDGE }, { is_deleted: 'false' }] },
      sort: [{ updated_at: ESortOptions.DESC }],
      page: {
        size: EScrollPaging.DEFAULT_MAXIMUM_SIZE,
        current: EScrollPaging.INITIAL_PAGE_INDEX
      }
    };

    return this.knowledgeElasticsearchService.search(query, opt).pipe(
      map(rs => convertToKnowledgeSummary(rs.rawResults, accountOrganization)),
      toArray(),
    );
  }

  // Akita
  updateLoading(loading: boolean) {
    this.createFeedbackModalStore.update({ loading });
  }

  updateFailed(failed: boolean, errMsgs: string[]) {
    this.createFeedbackModalStore.update({ failed, errMsgs });
  }

  resetStore() {
    this.createFeedbackModalStore.reset();
  }
}
