import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faPaperPlane, faPlus, faTimes } from '@fortawesome/fontawesome-pro-light';

import { AssetStorageDomainService } from 'domain/kernel/asset-storage/service';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentFormComponent extends BaseComponent {

  @Input() submitLabel!: string;
  @Input() hasCancelButton: boolean = false;
  @Input() initialText: string = '';
  @Input() commentId: string = null;

  @Output() handleSubmit = new EventEmitter<string>();
  @Output() handleCancel = new EventEmitter<void>();

  form!: FormGroup;
  faPaperPlane = faPaperPlane;
  faPlus = faPlus;
  faTimes = faTimes;

  // for attached files
  attachmentFiles = {
    inputFiles: [], // init input File[]
    files: [], // File[]
    size: 0,
    fileSizeExceedsLimit: false,
  };

  filesAttachment: Event = null;
  isFailed: boolean = false;
  errMsgs: string[] = [];
  inProcess: boolean = false;

  constructor(
    private fb: FormBuilder,
    private readonly assetStorageDomainService: AssetStorageDomainService,
  ) {
    super();
  }

  protected override onInit(): void {
    this.form = this.fb.group({
      title: [this.initialText],
    });
    if (this.commentId) {
      this.loadCommentFiles().subscribe(rs => {
        this.attachmentFiles.inputFiles = rs && rs.length > 0 ? rs : null;
      });
    }
  }

  onUploadFiles($event: any): void {
    this.attachmentFiles.files = $event && $event?.files;
    this.attachmentFiles.size = $event && $event?.size;
    this.attachmentFiles.fileSizeExceedsLimit = $event && $event?.fileSizeExceedsLimit;
  }

  closeValidate() {
    this.isFailed = false;
    this.errMsgs = [];
  }

  onSubmit(): void {
    this.isFailed = false;
    this.errMsgs = [];
    const validForm = this.validateCreateKnowledge();
    if (!validForm.isValid) {
      this.isFailed = true;
      this.errMsgs = validForm.errMsgs;
      return;
    }
    this.inProcess = true;

    //
    this.handleSubmit.emit(
      ({
        title: this.form.value.title,
        files: this.attachmentFiles.files,
        size: this.attachmentFiles.size
      } as any)
    );

    this.form.reset();
    this.onSelectFile(null);
  }

  onSelectFile(event: Event): void {
    this.filesAttachment = event;
  }

  onCancel() {
    this.handleCancel.emit();
  }

  private validateCreateKnowledge() {
    const rs = { isValid: true, errMsgs: [] };
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      rs.isValid = false;
      rs.errMsgs.push('必要項目に入力してください!');
    }
    // if (this.attachmentFiles.size > this.attachmentFiles.maxSize) {
    //   rs.isValid = false;
    //   rs.errMsgs.push('添付ファイルは 25 MB 未満にする必要があります。');
    // }
    return rs;
  }

  private loadCommentFiles() {
    return this.assetStorageDomainService.selectAllByKnowledgeId(this.commentId).pipe(
      toArray(),
    )
  }
}
