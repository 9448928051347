import { CreateKnowledgeModalStore } from './create-knowledge-modal.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CreateKnowledgeModalService {
  constructor(
    private readonly createKnowledgeModalStore: CreateKnowledgeModalStore
  ) { }

  // Akita
  updateLoading(loading: boolean) {
    this.createKnowledgeModalStore.update({ loading });
  }

  updateFailed(failed: boolean, errMsgs: string[]) {
    this.createKnowledgeModalStore.update({ failed, errMsgs });
  }

  resetStore() {
    this.createKnowledgeModalStore.reset();
  }
}
