import { Injectable, TemplateRef } from '@angular/core';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { ComponentType } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private _modalService: NzModalService) { }

  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: ModalOptions<D>,
    afterClosed?: (data?: any) => any
  ): NzModalRef<T, R> {
    // TODO: add config default
    const options: ModalOptions = {
      nzWrapClassName: 'backdrop--modal__container',
      nzCentered: true,
      nzClosable: false,
      nzFooter: null,
      nzWidth: 'auto',
      nzContent: componentOrTemplateRef,
      ...config,
    };
    const ref = this._modalService.create(options);
    ref.afterClose.pipe(take(1)).subscribe((data) => {
      if (!afterClosed) {
        return;
      }
      afterClosed(data);
    });
    return ref;
  }

  public closeAll(): void {
    this._modalService.closeAll();
  }
}
