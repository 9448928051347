import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IRepository } from '../../../../utility/repository/repository';
import { Observable } from 'rxjs';
import { SharingVendorFile } from '../entity';
import { SharingVendorFileId } from '../value';

export abstract class ISharingVendorFileRepository extends IRepository<SharingVendorFileId, SharingVendorFile> {
  abstract selectAll(builder: FirestoreQueryBuilder<SharingVendorFile>): Observable<SharingVendorFile>;
  abstract select(sharingVendorFileId: SharingVendorFileId): Observable<SharingVendorFile>;
}
