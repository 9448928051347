// tslint:disable: variable-name
import { IsDeleted } from '../../../kernel/base/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { SharingVendorBody, SharingVendorId, SharingVendorKnowledgeConverted, SharingVendorText, SharingVendorTs } from '../value';
import { OrganizationId } from '../../../kernel/organization/value';

export class SharingVendor implements Entity {
  @Column()
  readonly id: SharingVendorId;
  @Column()
  body: SharingVendorBody;
  @Column()
  sendBy?: AccountOrganizationId;
  @Column()
  registeredBy?: AccountOrganizationId;
  @Column()
  wroteAt: Timestamp;
  @Column()
  ts: SharingVendorTs;
  @Column()
  organizationId: OrganizationId;
  @Column()
  text: SharingVendorText;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;
  @Column()
  knowledgeConverted?: SharingVendorKnowledgeConverted;

  constructor(id: SharingVendorId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof SharingVendor)[] {
    return getColumns(new SharingVendor(SharingVendorId.create('')));
  }

  equals(value: SharingVendor): boolean {
    return this.id.equals(value.id);
  }
}
