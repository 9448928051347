import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';
@Injectable({
  providedIn: 'root'
})
export class DetailPostModalService {
  constructor(
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
  ) { }

  fetchKnowledgeById(id: string, accountOrganization: AccountOrganization): Observable<KnowledgeSummary | null> {
    const query = id.trim() || '';
    const opt = { filters: { all: [{ id: query }, { is_deleted: 'false' }] } };
    return this.knowledgeElasticsearchService.search(query, opt).pipe(
      map(rs => rs && rs.rawResults && rs.rawResults.length > 0 ? convertToKnowledgeSummary(rs.rawResults[0], accountOrganization) : null),
    );
  }
}
