import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IMEEventDirective } from './ime-event.directive';

const components: Type<any>[] = [IMEEventDirective];
@NgModule({
  declarations: [components],
  imports: [
    CommonModule
  ],
  exports: [components]
})
export class IMEEventDirectiveModule { }
