import { Observable, Subscription, timer } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { LoadingService } from 'frontend/admin/app/shared/services/loading.service';

@Component({
  // loading page -> if want snipper -> to use scss ".loading"
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent extends BaseComponent {
  public isLoading = false;

  private time$: Subscription;

  private readonly MAXIMUM_WAITING_TIME = 30 * 1000 * 60;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  protected override onInit(): void {
    this.subscribe(this.loadingService.getLoadingObservable(), (state: boolean) => {
      this.isLoading = state;
      if (this.isLoading) {
        this.handleWaitingTime();
      } else {
        if (this.time$) {
          this.time$.unsubscribe();
        }
      }
      this.cdr.detectChanges();
    });
  }

  private handleWaitingTime(): void {
    this.time$ = timer(this.MAXIMUM_WAITING_TIME).subscribe({
      complete: () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

}
