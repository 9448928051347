import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconAttachmentModule } from '../../atoms/icon-attachment/icon-attachment.module';
import { KnowledgeFileComponent } from './knowledge-file.component';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';

const components: Type<any>[] = [KnowledgeFileComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule, PipesModule, IconAttachmentModule],
  declarations: [components],
  exports: [components],
})
export class KnowledgeFileModule { }
