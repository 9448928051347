import { MyKnowledgeSharingVendorSidebarState, MyKnowledgeSharingVendorSidebarStore } from './my-knowledge-sharing-vendor-sidebar.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class MyKnowledgeSharingVendorSidebarQuery extends Query<MyKnowledgeSharingVendorSidebarState> {
  constructor(protected store: MyKnowledgeSharingVendorSidebarStore) {
    super(store);
  }

  selectSharingVendors() {
    return this.select('sharingVendors');
  }

  selectTotal() {
    return this.select('total');
  }
}
