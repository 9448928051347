import * as Sentry from '@sentry/angular';

import { ErrorHandler, Injectable } from '@angular/core';

import { FIREBASE_AUTH_ERROR_CODES } from 'frontend/lib/enum/firebase-auth-error-codes.enum';

@Injectable({ providedIn: 'root' })
export class SentryService implements ErrorHandler {

  errors: string[] = [];
  timer: any;

  handleError(error: any): void {
    /** Detect new version => reload page */
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
      return;
    }

    const capturedError = error.error || error.message || error.originalError || error;
    const eventId = Sentry.captureException(capturedError);

    if (error.code !== null && error.code !== undefined && FIREBASE_AUTH_ERROR_CODES.includes(error.code)) {
      //skip show dialog sentry, we must handle catch ex
      return null;

    } else {
      // Clear existing timeout so that its does not fire and open a dialog
      if (this.timer) {
        clearTimeout(this.timer);
      }

      // Register a new timeout to open the dialog in 500ms
      this.timer = setTimeout(() => {
        Sentry.showReportDialog({
          eventId: eventId,
        });
      }, 500);

      this.errors.push(capturedError.toString());
      console.error('SentryErrorHandler', error);
    }
  }
}
