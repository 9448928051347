import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { DetailPostModalComponent } from '../../modal/detail-post-modal/detail-post-modal.component';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';
import { DetailPostModalService } from '../../modal/detail-post-modal/state/detail-post-modal.service';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { filter, take, tap } from 'rxjs/operators';
import { DashboardQuery } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.query';
import { KnowledgeSearchHeaderQuery } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.query';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { KnowledgeSearchHeaderService } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.service';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineCardComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() boardId: string;

  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }
  constructor(
    private readonly modalService: ModalService,
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly dashboardQuery: DashboardQuery,
    private readonly dashboardService: DashboardService,
    private readonly knowledgeSearchHeaderQuery: KnowledgeSearchHeaderQuery,
    private readonly knowledgeSearchHeaderService: KnowledgeSearchHeaderService,
    private readonly detailPostModalService: DetailPostModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  onShowDetail(): void {
    const { currentAccountOrganization } = this.workspaceQuery.getValue();
    if (this.knowledgeSummary?.id) {
      this.subscribe(this.detailPostModalService.fetchKnowledgeById(this.knowledgeSummary.id, currentAccountOrganization).pipe(
        take(1),
        filter(knowledge => knowledge !== null),
        tap(knowledge => {
          this.modalService.open(DetailPostModalComponent, {
            nzComponentParams: {
              knowledgeSummary: knowledge
            }
          });
          this.syncPropsToState(knowledge);
        }
        ),
      ), _ => {
        this.changeDetectorRef.detectChanges();
      })
    }
  }

  private syncPropsToState(props: any) {
    const { boards } = this.dashboardQuery.getValue();
    const { summaries } = this.knowledgeSearchHeaderQuery.getValue();
    if (Object.keys(boards)?.length > 0) {
      this.dashboardService.updateKnowledgeState(this.knowledgeSummary, props);
    }
    // for dashboard search
    if (summaries.length > 0) {
      this.knowledgeSearchHeaderService.updateKnowledgeSummary(this.knowledgeSummary, props);
    }
  }
}
