import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ForwardFeedbackModalStore } from './forward-feedback-modal.store';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { Observable } from 'rxjs';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';

@Injectable({
  providedIn: 'root'
})
export class ForwardFeedbackModalService {
  constructor(
    private readonly createFeedbackModalStore: ForwardFeedbackModalStore,
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
    readonly workspaceQuery: WorkspaceQuery,
  ) { }

  fetchKnowledgeById(knowledgeId: string, accountOrganization: AccountOrganization): Observable<KnowledgeSummary> {
    const opt = { filters: { all: [{ id: knowledgeId }, { is_deleted: 'false' }] }, };
    return this.knowledgeElasticsearchService.search('', opt).pipe(
      map(rs => rs && rs.rawResults && rs.rawResults.length > 0 ? convertToKnowledgeSummary(rs.rawResults[0], accountOrganization) : null),
    );
  }

  // Akita
  updateLoading(loading: boolean) {
    this.createFeedbackModalStore.update({ loading });
  }

  updateFailed(failed: boolean, errMsgs: string[]) {
    this.createFeedbackModalStore.update({ failed, errMsgs });
  }

  updateAccounts(accounts: AccountMember[]) {
    this.createFeedbackModalStore.update({ accounts });
  }

  resetStore() {
    this.createFeedbackModalStore.reset();
  }
}
