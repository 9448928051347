import { EImageSize } from "frontend/admin/app/enum/image-size.enum";

export const ImageSizeGenerate = (fileName: string, size = EImageSize.ORIGIN) => {
  const compressTypes = ['.png', '.gif', '.jpeg', '.tiff', '.webp', '.avif']
  if (fileName && fileName.length > 0) {
    const indexType = fileName?.lastIndexOf('.')
    const fileType = fileName?.substring(indexType, fileName.length);
    if (compressTypes.includes(fileType.trim().toLocaleLowerCase())) {
      return fileName?.substring(0, indexType) + size + fileType
    } else {
      return fileName;
    }
  } else {
    return null;
  }
};
