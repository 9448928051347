import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';

@Component({
  selector: 'app-body-knowledge-detail',
  templateUrl: './body-knowledge-detail.component.html',
  styleUrls: ['./body-knowledge-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyKnowledgeDetailComponent {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() isReadonly: boolean = true;

}
