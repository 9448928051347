import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class IsDeleted extends PrimitiveValueObject<boolean> {
  static create(value: boolean): IsDeleted {
    if (value === null || value === undefined) {
      return new IsDeleted(false);
    }
    return new IsDeleted(value);
  }
}
