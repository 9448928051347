import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { ESlackOAuthStatus } from '../enum/slack-oauth-status.enum';

export class SlackOAuthStatus extends PrimitiveValueObject<ESlackOAuthStatus> {
  static create(value: ESlackOAuthStatus): SlackOAuthStatus {
    if (value === null) {
      return new SlackOAuthStatus(ESlackOAuthStatus.ACTIVE);
    }
    return new SlackOAuthStatus(value);
  }
}
