import { Injectable } from '@angular/core';
import { AppService } from 'frontend/admin/app/state/app.service';
import { AuthService as LibAuthService } from 'frontend/lib/service/auth.service';
import { take, tap } from 'rxjs/operators';
import { AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private readonly store: AuthStore, private readonly authService: LibAuthService, private readonly appService: AppService) {}

  selectAccount() {
    return this.getAuthState().pipe(tap(user => this.appService.updateCurrentAccount(user)));
  }

  // Akita
  updateRedirect(redirect: string) {
    this.store.update({ redirect });
  }

  private getAuthState() {
    return this.authService.selectAuthState().pipe(take(1));
  }
}
