import { ModalOptions, NzModalRef } from 'ng-zorro-antd/modal';

import { ConfirmModalComponent } from '../confirm-modal.component';
import { ConfirmModalData } from './../models/confirm-dialog.interface';
import { Injectable } from '@angular/core';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';

@Injectable({ providedIn: 'root' })
export class ConfirmModalService {
  constructor(private readonly modalService: ModalService) { }

  public open<D>(
    data: ConfirmModalData,
    config?: ModalOptions<D>,
    afterClosed?: () => any
  ): NzModalRef<any> {
    const options: ModalOptions = {
      nzWrapClassName: 'confirm--modal__container',
      nzComponentParams: {
        data: data
      },
      ...config,
    };

    return this.modalService.open(ConfirmModalComponent, options, afterClosed);
  }
}
