import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EFeedbackType } from '../enum';

export class FeedbackType extends PrimitiveValueObject<EFeedbackType> {
  static create(value: EFeedbackType): FeedbackType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new FeedbackType(value);
  }
}
