import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { EMPTY } from 'rxjs';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import * as moment from 'moment';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { FileService } from 'frontend/admin/app/shared/services/file.service';

@Component({
  selector: 'app-comment-attachment-view',
  templateUrl: './comment-attachment-view.component.html',
  styleUrls: ['./comment-attachment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentAttachmentViewComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() isReadonly: boolean = false;
  @Output() upload = new EventEmitter();
  files: KnowledgeFileSummary[] = [];
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fileService: FileService
  ) { super(); }

  protected override onInit(): void {
    this.subscribe(this.loadFiles(), res => {
      this.files = res;
      this.changeDetectorRef.detectChanges()
    });
  }

  // incase a posting has been updated
  // func will check it by updatedAt and reload data for component
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']
      && changes?.['knowledgeSummary']?.currentValue
      && changes?.['knowledgeSummary']?.previousValue) {

      if (moment(changes?.['knowledgeSummary'].currentValue.updatedAt) > moment(changes?.['knowledgeSummary'].previousValue.updatedAt)) {
        this.subscribe(this.loadFiles(), res => {
          this.files = res;
          this.changeDetectorRef.detectChanges();
        });
      }
    }
  }

  public loadFiles() {
    if (this.knowledgeSummary) {
      return this.fileService.getKnowledgeFiles(this.knowledgeSummary.id);
    } else {
      return EMPTY;
    }
  }

  public emitUploadFiles(event: any) {
    this.upload.emit(event);
  }
}
