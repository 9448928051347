export const GetIntersectionArray = (a1: any[], a2: any[]) => {
  return a1.filter(n => a2.some(n2 => n.id == n2));
}

export const GetDistinctArray = (arr: any[], comp: string | number) => {
  return (
    arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e])
      .map(e => arr[e])
  );
}

export const MergeArray = (arr1: any[], arr2: any[]) => {
  if (arr1 === null || arr1 === undefined) arr1 = [];
  if (arr2 === null || arr2 === undefined) arr2 = [];
  return arr1.concat(arr2);
}

export const GetIntersectionArrayObject = (a1: any[], a2: any[], comp: string) => {
  if (Array.isArray(a1) && a1.length === 0 || Array.isArray(a2) && a2.length === 0) return [];
  const ids = a1.map(a => a[comp]);
  return a2.length > 0 ? a2.filter(b => ids.includes(b[comp])) : [];
} 