import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { IOption } from 'frontend/lib/model/option.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';

@Component({
  selector: 'app-knowledge-select',
  templateUrl: './knowledge-select.component.html',
  styleUrls: ['./knowledge-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeSelectComponent implements OnChanges {
  readonly appData = {
    faPlus: faPlus,
    faTimes: faTimes
  }
  isAdd: boolean = true;
  isOpenSelectKnowledge: boolean = false;
  listOfSelectedOption: IOption<any, any>[] = [];

  @Input() placeholder: string = '';
  @Input() dataSource: IOption<any, any>[] = [];
  @Input() selectedTags: IOption<any, any>[] = [];
  @Input() knowledgeSummaries: KnowledgeSummary[] = [];
  @Input() hideSelected = false;
  @Input() multiple = true;
  @Input() isViewExtra = false;
  @Output() selected = new EventEmitter();
  @Output() toggle = new EventEmitter();

  ngOnChanges() {
    this.renderSelectedTags();
  }

  renderSelectedTags() {
    this.listOfSelectedOption = this.dataSource.filter(op => this.selectedTags.includes(op.value));
  }

  onSelectedTags(event: any) {
    this.selectedTags = event;
    this.selected.emit(this.selectedTags);
    this.renderSelectedTags();
  }

  onToggle() {
    this.isAdd = !this.isAdd;
  }

  onOpenSelectKnowledge() {
    this.isOpenSelectKnowledge = true;
    this.toggle.emit();
  }

  findKnowledgeSummary(event: any) {
    return this.knowledgeSummaries.find(item => item.id === event.value)
  }

  onRemoveSelectedTag(value: any) {
    const tags = this.selectedTags.filter(val => val !== value);
    this.selectedTags = tags;
    this.selected.emit(this.selectedTags);
    this.listOfSelectedOption = this.dataSource.filter(op => this.selectedTags.includes(op.value));
  }
}
