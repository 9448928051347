import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class FeedbackDeliverySenderArchived extends PrimitiveValueObject<boolean> {
  static create(value: boolean): FeedbackDeliverySenderArchived {
    if (value === null) {
      return new FeedbackDeliverySenderArchived(false);
    }
    return new FeedbackDeliverySenderArchived(value);
  }
}
