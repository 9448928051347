import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { DeletedUserComponent } from './deleted-user.component';

const components: Type<any>[] = [DeletedUserComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [components],
  exports: [components],
})
export class DeletedUserModule { }
