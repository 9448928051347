export const FIREBASE_AUTH_ERROR_CODES = [
    'auth/expired-action-code',
    'auth/invalid-action-code',
    'auth/user-disabled',
    'auth/user-not-found',
    'auth/weak-password',
    'auth/email-already-in-use',
    'auth/invalid-email',
    'auth/operation-not-allowed',
    'auth/account-exists-with-different-credential',
    'auth/auth-domain-config-required',
    'auth/credential-already-in-use',
    'auth/operation-not-supported-in-this-environment',
    'auth/timeout',
    'auth/missing-continue-uri',
    'auth/invalid-continue-uri',
    'auth/unauthorized-continue-uri',
    'auth/argument-error',
    'auth/invalid-persistence-type',
    'auth/unsupported-persistence-type',
    'auth/invalid-credential',
    'auth/wrong-password',
    'auth/invalid-verification-code',
    'auth/invalid-verification-id',
    'auth/custom-token-mismatch',
    'auth/invalid-custom-token',
    'auth/captcha-check-failed',
    'auth/invalid-phone-number',
    'auth/missing-phone-number',
    'auth/quota-exceeded',
    'auth/unauthorized-domain',
    'auth/popup-closed-by-user',
    'auth/popup-blocked',
    'auth/invalid-user-token',
    'auth/user-token-expired',
    'auth/null-user',
    'auth/tenant-id-mismatch'
];