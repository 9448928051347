import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appIMEEvent]'
})
export class IMEEventDirective {
  // IME Composing going on
  private isComposing: boolean = false;
  private hasCompositionJustEnded: boolean = false;

  @Input() preventEnterKeyCreateNewLine: boolean = false;
  @Output() onEnterKey: EventEmitter<any> = new EventEmitter();
  @Output() onNewLineKey: EventEmitter<any> = new EventEmitter();

  constructor(public element: ElementRef) { }

  @HostListener('keyup', ['$event'])
  public onKeyup($event: any): void {

    /* this is the trigger if the user wants to enter twice to emit.
      if (this.isSelectEvent($event)) {
        this.isEventSelecting = true;
      }*/

    if (this.isComposing || this.hasCompositionJustEnded) {
      // IME composing fires keydown/keyup events
      this.hasCompositionJustEnded = false;
      return;
    }

    if ($event.which === 13 && !$event.shiftKey) {
      this.onEnterKey.emit($event);
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeydown($event): void {
    // Safari on OS X may send a keydown of 229 after compositionend
    if ($event.which !== 229) {
      this.hasCompositionJustEnded = false;
    }

    // prevent textarea from creating a new line when enter is pressed and replace it with shift + enter
    if (this.preventEnterKeyCreateNewLine && $event.which === 13 && !$event.shiftKey) {
      $event.preventDefault();
      return;
    }
  }

  @HostListener('compositionstart', ['$event'])
  public onCompositionstart($event): void {
    this.isComposing = true;
    /* this is the trigger if the user wants to enter twice to emit.
      this.isEventSelecting = false;
    */
  }

  @HostListener('compositionend', ['$event'])
  onCompositionend($event: any) {
    this.isComposing = false;

    // some browsers (Firefox, Safari) send a keyup event after
    // compositionend, some (Chrome, Edge) don't. This is to swallow
    // the next keyup event, unless a keydown event happens first
    this.hasCompositionJustEnded = true;
  }
}
