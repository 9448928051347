import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CategoryDomainService } from 'domain/kernel/category/service';
import { CategoryDto } from 'domain/kernel/category/dto';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { ObjectUtils } from 'frontend/admin/app/shared/utils/objects.utils';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'app-categories-anchor',
  templateUrl: './categories-anchor.component.html',
  styleUrls: ['./categories-anchor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesAnchorComponent extends BaseComponent implements OnChanges {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() label: string = null;
  categoriesAnchor: CategoryDto[];

  @Output() tagsEmitter = new EventEmitter<CategoryDto[]>();

  constructor(
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly categoryDomainService: CategoryDomainService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) { super(); }

  // incase a posting has been updated
  // func will check it by updatedAt and reload data for component
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']) {
      const { currentValue, previousValue, firstChange } = changes?.['knowledgeSummary'];
      if (
        (firstChange && currentValue?.['categories'])
        ||
        !ObjectUtils.isComparisonObject(previousValue?.['categories'], currentValue?.['categories'])
      ) {
        this.init(currentValue.categories);
      }
    }
  }

  protected override onDestroy(): void { }

  protected override onInit(): void { }

  private init(categories: string) {
    if (!ObjectUtils.isNullOrUndefined(categories)) {
      const spitStrs = this.knowledgeSummary.categories.split(',');
      const tagIds = spitStrs.filter(ids => ids.trim().length > 0);
      const currentAccountOrganization = this.workspaceQuery.getValue().currentAccountOrganization;
      // TODO: why history code return onInit???
      if (tagIds?.length > 0) {
        this.categoryDomainService.selectByIds(tagIds, currentAccountOrganization.organizationId).
          pipe(toArray())
          .subscribe(tags => {
            if (tags && tags.length > 0) {
              this.categoriesAnchor = tags;
              this.tagsEmitter.next(this.categoriesAnchor);
              this.changeDetectorRef.detectChanges();
            }
          })
      } else {
        this.categoriesAnchor = [];
        this.tagsEmitter.next(this.categoriesAnchor);
        this.changeDetectorRef.detectChanges();
      }
    }
  }
}
