import { AppState, AppStore } from './app.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
  constructor(protected store: AppStore) {
    super(store);
  }

  selectCurrentAccount() {
    return this.select('currentAccount');
  }
}
