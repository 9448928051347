import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { AppService } from 'frontend/admin/app/state/app.service';
import { AuthService } from '../../../pages/auth/state/auth.service';
import { Injectable } from '@angular/core';
import { AuthService as LibAuthService } from '../../../../../lib/service/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly libAuthService: LibAuthService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly appService: AppService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.libAuthService.selectAuthState().pipe(
      tap(user => this.appService.updateCurrentAccount(user)),
      map(user => user !== null),
      tap(flag => {
        if (!flag) {
          this.authService.updateRedirect(state.url);
          this.router.navigateByUrl('/auth/login');
        }
      })
    );
  }
}
