import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { faChevronLeft, faCopy } from '@fortawesome/fontawesome-pro-light';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { AppRoutingConstant } from 'frontend/admin/app/shared/constants/app-routing.constant';
import { FileService } from 'frontend/admin/app/shared/services/file.service';
import { ModalService } from 'frontend/admin/app/shared/services/modal.service';
import { NotificationService } from 'frontend/admin/app/shared/services/notification.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClipboardService } from 'ngx-clipboard';
import { tap } from 'rxjs/operators';
import { SendFeedbackModalComponent } from '../send-feedback-modal/send-feedback-modal.component';

@Component({
  selector: 'app-quote-knowledge-modal',
  templateUrl: './quote-knowledge-modal.component.html',
  styleUrls: ['./quote-knowledge-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteKnowledgeModalComponent extends BaseComponent {
  public faChevronLeft = faChevronLeft;
  public faEnvelope = faEnvelope;
  public faCopy = faCopy;
  public isCopying: boolean = false;
  public files: KnowledgeFileSummary[] = [];
  public linksDetected: string[] = [];

  @Input() knowledgeSummary: KnowledgeSummary;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly clipboardService: ClipboardService,
    readonly workspaceQuery: WorkspaceQuery,
    private readonly modalService: ModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly nzModalRef: NzModalRef<QuoteKnowledgeModalComponent>,
    private readonly fileService: FileService
  ) {
    super();
  }

  protected override onDestroy(): void {
  }

  protected override onInit(): void {
    if (this.knowledgeSummary) {
      this.subscribe(
        this.fileService.getKnowledgeFiles(this.knowledgeSummary.id).pipe(
          tap(files => this.files = files),
        ), _ => {
          this.linksDetected = this.knowledgeSummary?.body?.getUrls();
          this.changeDetectorRef.detectChanges();
        }
      );
    }
  }

  back() {
    this.nzModalRef.close();
  }

  onSendFeedback() {
    this.modalService.open(SendFeedbackModalComponent, {
      nzComponentParams: {
        knowledgeSummary: this.knowledgeSummary,
        files: this.files,
        links: this.linksDetected,
      }
    });
  }

  onCopyUrl() {
    const organizationId = this.workspaceQuery.getValue().organization.id;
    const urlCopy = this.document.location.origin + '/' + organizationId + AppRoutingConstant.POST[0] + this.knowledgeSummary.id;
    this.clipboardService.copy(urlCopy);
    this.notificationService.open('URLをコピーしました');
  }

}
