import { take, tap } from 'rxjs/operators';

import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { Injectable } from '@angular/core';
import { Integration } from 'frontend/lib/model/integration.model';
import { SendFeedbackModalStore } from './send-feedback-modal.store';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';

@Injectable({ providedIn: 'root' })
export class SendFeedbackModalService {
  constructor(
    private readonly sendFeedbackModalStore: SendFeedbackModalStore,
    private readonly workspaceService: WorkspaceService,
  ) { }

  public fetchActiveMembers(organizationId: string) {
    return this.workspaceService.fetchActiveMembers(organizationId).pipe(
      take(1),
      tap(accounts => this.updateAccounts(accounts)
      )
    )
  }

  updateSending(sending: boolean) {
    this.sendFeedbackModalStore.update({ sending });
  }

  updateAccounts(accounts: AccountMember[]) {
    this.sendFeedbackModalStore.update({ accounts });
  }

  updateAccount(account: AccountMember) {
    this.sendFeedbackModalStore.update({ accounts: [...this.sendFeedbackModalStore.getValue().accounts, account] });
  }

  updateIntegrations(integrations: Integration[]) {
    this.sendFeedbackModalStore.update({ integrations });
  }

  resetStore() {
    this.sendFeedbackModalStore.reset();
  }
}
