import { DashboardState, DashboardStore } from './dashboard.store';

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardQuery extends Query<DashboardState> {
  constructor(protected store: DashboardStore) {
    super(store);
  }

  selectModeView() {
    return this.select('modeView');
  }

  selectIsOpenBoard() {
    return this.select('isOpenBoard');
  }

  selectCurrentBoards() {
    return this.select('selectedBoard').pipe(
      map(boardView => Object.values(boardView))
    );
  }

  limitBoardViewSize() {
    return this.select("limitBoardViewSize");
  }

  selectCurrentBoard() {
    return this.select("selectedBoard");
  }

  boards() {
    return this.select("boards").pipe(
      map(board => Object.values(board))
    );
  }
}
