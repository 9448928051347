import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { AvatarModule } from '../../atoms/avatar/avatar.module';
import { SearchUserAutocompleteComponent } from './search-user-autocomplete.component';

const components: Type<any>[] = [SearchUserAutocompleteComponent];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NzAutocompleteModule, AvatarModule, NzDropDownModule],
  declarations: [components],
  exports: [components],
})
export class SearchUserAutocompleteModule { }
