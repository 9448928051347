import { BoardSummary, BoardView, DashboardStore } from './dashboard.store';
import { ELogicOperators, ELuceneLogic } from 'domain/kernel/board/enum';
import { GetDistinctArray, GetIntersectionArrayObject, MergeArray } from 'frontend/utl/builder/array-transform.builder';
import { Observable, from, of } from 'rxjs';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';

import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { BoardCriteria } from 'frontend/admin/app/model/board-criteria.model';
import { BoardDomainService } from 'domain/kernel/board/service';
import { BoardDto } from 'domain/kernel/board/dto';
import { EDashboardVisualization } from 'frontend/admin/app/enum/dashboard-visualization.enum';
import { EPostType } from 'domain/kernel/post';
import { EScrollPaging } from 'frontend/admin/app/enum/scroll-paging.enum';
import { ESortOptions } from 'frontend/admin/app/enum/sort-options.enum';
import { Injectable } from '@angular/core';
import { KnowledgeElasticsearchService } from 'frontend/admin/app/shared/services/knowledge-elastic.service';
import { KnowledgeNotFoundError } from 'domain/kernel/knowledge/exception';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { SearchESResult } from 'frontend/admin/app/shared/interfaces/search-result-es.interface';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';

@Injectable({ providedIn: 'root' })

export class DashboardService {
  public limitBoardViews: number = 0;
  private boardEmpty: BoardSummary = null;

  constructor(
    private readonly dashboardStore: DashboardStore,
    private readonly boardDomainService: BoardDomainService,
    private readonly knowledgeElasticsearchService: KnowledgeElasticsearchService,
  ) {
    this.boardEmpty = { id: 'boardEmpty', name: '', current: 1, board: { id: 'boardEmpty', updatedAt: new Date(), createdAt: new Date() } } as BoardSummary;
  }

  public getKnowledgeById(accountOrganization: AccountOrganization, postId: string): Observable<KnowledgeSummary> {
    const opt = { filters: { all: [{ id: postId }, { is_deleted: 'false' }] } };
    return this.knowledgeElasticsearchService.search('', opt).pipe(
      mergeMap(search => {
        if (search.rawResults.length > 0) {
          return of(search.rawResults[0])
        } else {
          // this.updateNotfound(true);
          throw new KnowledgeNotFoundError(`knowledge id「${postId}」 is not found`);
        }
      }),
      filter(rs => rs !== null),
      map(rs => convertToKnowledgeSummary(rs, accountOrganization)),
    )
  }

  public fetchBoardOnly(accountOrganizationId: string, initPage: number, size = EScrollPaging.DEFAULT_PAGE_SIZE, limitBoardViews: number): Observable<BoardSummary> {
    return this.fetchPaggingBoard(accountOrganizationId, initPage, size, limitBoardViews - 1);
  }

  public fetchFolderBoardOnly(accountOrganizationId: string, initPage: number): Observable<BoardSummary> {
    return this.boardDomainService.selectByCreatedBy(accountOrganizationId).pipe(
      filter(boardDto => boardDto !== null),
      map(boardDto => this.mapToBoardSummary(boardDto, initPage)),
      tap(rs => this.updateBoards(rs)),
      toArray(),
      mergeMap(_ => this.pushBoardEmpty())
    );
  }


  public fetchBoardDefault(accountOrganizationId: string, currentPageIndex: number): Observable<BoardSummary> {
    return this.boardDomainService.selectBoardDefaultByCreatedBy(accountOrganizationId)
      .pipe(
        filter(boardDto => boardDto !== null),
        map(boardDto => this.mapToBoardSummary(boardDto, currentPageIndex)),
        tap(boardSummary => this.updateBoards(boardSummary)),
      );
  }

  public getBoardEmpty(): BoardSummary {
    return this.boardEmpty;
  }

  public pushBoardEmpty(): Observable<BoardSummary> {
    return of(this.boardEmpty).pipe(
      tap(board => this.updateBoards(board))
    )
  }

  public updateBoards(boardSummary: BoardSummary): void {
    let boards = { ...this.dashboardStore.getValue()?.boards };
    boards[boardSummary?.id] = boardSummary;
    const boardDefault = Object.values(boards)?.find(item => item.board?.isDefault);
    // to make sure the board defaul is in the first place.
    if (boardDefault) {
      delete boards[boardDefault?.id];
      this.dashboardStore.update({ boards: { [boardDefault?.id]: boardDefault, ...boards } });
    } else {
      this.dashboardStore.update({ boards: { ...boards } });
    }
  }

  updateExistingBoard(board: BoardDto, accountOrganization: AccountOrganization): Observable<any> {
    return this.fetchByBoard(board, accountOrganization);
  }

  pushNewBoard(board: BoardDto, accountOrganization: AccountOrganization) {
    const newBoard = { id: board.id, name: board.title, board } as BoardSummary;
    const modeView = this.dashboardStore.getValue().modeView;
    if (modeView === EDashboardVisualization.FOLDER_VALUE) {
      // Update an empty board to the end of the list. Folder view needs an empty board to display the UI
      const boards = this.dashboardStore.getValue().boards;
      delete boards[this.boardEmpty.id];
      this.updateBoards(newBoard);
      this.updateBoards(this.boardEmpty);
    } else {
      this.updateBoards(newBoard);
    }

    return this.fetchByBoard(board, accountOrganization);
  }

  fetchPaggingBoard(accountOrganizationId: string, currentPageIndex: number, pageSize: number, limitBoardViews: number) {
    const currentBoards = this.dashboardStore.getValue().boards;
    // the length of the current board is subtracted by 1 as the element board ALL is statically assigned
    const offsetCurrentBoardCount = (Object.keys(currentBoards).length - 1);
    const _limitBoardViews = offsetCurrentBoardCount > 0 ? limitBoardViews - offsetCurrentBoardCount : limitBoardViews;
    return this.boardDomainService.selectByCreatedBy(accountOrganizationId).pipe(
      filter(boardDto => boardDto !== null),
      filter(boardDto => !Object.keys(currentBoards).find(key => key === boardDto.id)),
      take(_limitBoardViews),
      map(boardDto => this.mapToBoardSummary(boardDto, currentPageIndex)),
      tap(rs => this.updateBoards(rs)),
    );
  }

  mapToBoardSummary(board: BoardDto, currentPageIndex: number): BoardSummary {
    return { id: board.id, name: board.title, current: currentPageIndex, board: board } as BoardSummary
  }

  updateIsOpenBoard(isOpenBoard: boolean) {
    this.dashboardStore.update({ isOpenBoard });
  }

  sortBoardItemsByCreatedAt(object: Object) {
    const boardDefault = Object.keys(object).find(key => object[key].board.isDefault);
    boardDefault ? (object[boardDefault].board.createdAt = new Date()) : null;
    return Object.values(object).sort((a, b) =>
      new Date(b.board.createdAt).getTime() - new Date(a.board.createdAt).getTime()
    ).reduce((acc, value) => ({ ...acc, [value.id]: value }), {});
  }

  popSelectedBoard(BoardDto: BoardDto) {
    const selectedBoards = { ...this.dashboardStore.getValue().boards };
    delete selectedBoards[BoardDto.id];
    this.dashboardStore.update({ boards: selectedBoards });
  }

  popKnowledgeSummary(knowledgeSummary: KnowledgeSummary) {
    const boards = { ...this.dashboardStore.getValue().boards };
    const keys = Object.keys(boards);
    keys.map(function (key) {
      const arrTemp = boards[key]?.summaries?.filter(sum => sum.id !== knowledgeSummary.id);
      if (arrTemp) {
        boards[key] = {
          ...boards[key],
          summaries: arrTemp,
          total: arrTemp.length
        }
      }
    })

    this.dashboardStore.update({ boards: boards });
  }

  pushKnowledgeSummary(knowledgeSummary: KnowledgeSummary) {
    let boards = { ...this.dashboardStore.getValue().boards };

    Object.keys(boards).map(key => {
      const boardSummary = boards[key];
      const summaries = boardSummary?.summaries;
      if (summaries) {
        const isMeetCriteria = this.boardFunnel(boardSummary.board, knowledgeSummary);
        if (isMeetCriteria) {
          boardSummary.summaries = [knowledgeSummary, ...summaries];
          boardSummary.total = summaries.length;
          this.updateBoards(boardSummary);
        } else if (boardSummary.board.isDefault) {
          boardSummary.summaries = [knowledgeSummary, ...summaries];
          boardSummary.total = summaries.length;
          this.updateBoards(boardSummary);
        }
      }
    });
  }

  updateKnowledgeState(proposal: KnowledgeSummary, props: any) {
    const boards = this.dashboardStore.getValue().boards;
    Object.keys(boards).map(key => {
      const boardSummary = boards[key];
      if (boardSummary.summaries && boardSummary.summaries?.length > 0) {
        const position = boardSummary.summaries.findIndex((item => item.id === proposal.id));
        if (position >= 0) {
          // just only make state change
          // please don't touch it
          let summary = boardSummary.summaries[position];
          Object.keys(props).map(key => {
            summary = { ...summary, [key]: props[key] };
          });
          boardSummary.summaries[position] = { ...summary };
          boardSummary.summaries = [...boardSummary.summaries];
          this.updateBoards(boardSummary);
        }
      }
    });
  }

  updateKnowledgeStateInBoard(proposal: KnowledgeSummary, props: any, boardId: string) {
    const boards = this.dashboardStore.getValue().boards;
    if (boards[boardId] && boards[boardId].summaries && boards[boardId].summaries?.length > 0) {
      const position = boards[boardId].summaries.findIndex((item => item.id === proposal.id));
      if (position >= 0) {
        let summary = boards[boardId].summaries[position];
        Object.keys(props).map(key => {
          summary = { ...summary, [key]: props[key] };
        });

        boards[boardId].summaries[position] = { ...summary };
        const boardSummary = { ...boards[boardId] };
        this.updateBoards(boardSummary);
      }
    }
  }

  private boardFunnel(board: BoardDto, knowledgeSummary: KnowledgeSummary) {
    const criteria = JSON.parse(board.criteria) as BoardCriteria;

    // カテゴリー (postType)
    if (criteria.postTypes?.length > 0 && !criteria.postTypes.includes(knowledgeSummary.postType)) {
      return false;
    }

    // メンバー (members)
    if (!this.memberFunnelBoard(criteria, knowledgeSummary)) {
      return false;
    }

    // キーワード include AND/OR not include keyword
    if (!this.keywordFunnelBoard(criteria, knowledgeSummary)) {
      return false;
    }

    // タグ (tags)
    // todo check categories
    if (!this.tagFunnelBoard(criteria, knowledgeSummary)) {
      return false;
    }

    // 日時範囲
    if (criteria.dateFrom && knowledgeSummary.createdAt < criteria.dateFrom) {
      return false;
    }
    if (criteria.dateTo && knowledgeSummary.createdAt < criteria.dateTo) {
      return false;
    }

    return true;
  }

  private memberFunnelBoard(criteria: BoardCriteria, knowledgeSummary: KnowledgeSummary) {
    if (criteria.postTypes?.length === 0 ||
      (criteria.postTypes?.length > 0 && criteria.postTypes.includes(EPostType.KNOWLEDGE_REQUEST))) {
      // only check member on knowledge, sharingVendor
      if (knowledgeSummary.postType !== EPostType.KNOWLEDGE_REQUEST) {
        if (criteria.origins && criteria.origins.length > 0 && !criteria.origins.includes(knowledgeSummary.sendBy)) {
          return false;
        }
        if (criteria.sendbyNotInclude && criteria.sendbyNotInclude.length > 0 && criteria.sendbyNotInclude.includes(knowledgeSummary.sendBy)) {
          return false;
        }
      }
    }

    return true;
  }

  private keywordFunnelBoard(criteria: BoardCriteria, knowledgeSummary: KnowledgeSummary) {
    let strKeyword = [];
    let strKeywordsNotInclude = [];
    // check keyword include
    if (criteria.keyword && criteria.keyword.trim().length > 0) {
      strKeyword = criteria.keyword.split(/,|、/);
    }
    // check keyword not include
    if (criteria.keywordNotInclude && criteria.keywordNotInclude.trim().length > 0) {
      strKeywordsNotInclude = criteria.keywordNotInclude.split(/,|、/);
    }
    // incase has no input keyword/notInclude keyword
    if (strKeyword.length === 0 && strKeywordsNotInclude.length === 0) {
      return true;
    } else {

      const keywordsInclude = strKeyword.map(str => str.toLocaleLowerCase().trim());
      const keywordsNotInclude = strKeywordsNotInclude.map(str => str.toLocaleLowerCase().trim());

      // AND OPERATOR
      if (criteria.keywordLogicOperator === ELogicOperators.AND) {
        // knowledgeSummary title/body need include all keyword
        keywordsInclude.forEach(kw => {
          if (!knowledgeSummary.title.toLocaleLowerCase().trim().includes(kw)
            && !knowledgeSummary.body.toLocaleLowerCase().trim().includes(kw)) {
            return false;
          }
        });

        // knowledgeSummary title/body need exclude all keyword
        keywordsNotInclude.forEach(kw => {
          if (knowledgeSummary.title.toLocaleLowerCase().trim().includes(kw)
            || knowledgeSummary.body.toLocaleLowerCase().trim().includes(kw)) {
            return false;
          }
        });
      }

      // OR OPERATOR
      if (criteria.keywordLogicOperator === ELogicOperators.OR) {
        // knowledgeSummary title/body need include at least one keyword
        let includeAnyKeyword = false;
        for (let i = 0; i <= keywordsInclude.length; i++) {
          if (knowledgeSummary.title.toLocaleLowerCase().trim().includes(keywordsInclude[i])
            || knowledgeSummary.body.toLocaleLowerCase().trim().includes(keywordsInclude[i])) {
            includeAnyKeyword = true;
            break;
          }
        }
        if (!includeAnyKeyword) {
          return false;
        }

        // knowledgeSummary title/body need exclude all keyword
        keywordsNotInclude.forEach(kw => {
          if (knowledgeSummary.title.toLocaleLowerCase().trim().includes(kw)
            || knowledgeSummary.body.toLocaleLowerCase().trim().includes(kw)) {
            return false;
          }
        });
      }
    }

    return true;
  }

  private tagFunnelBoard(criteria: BoardCriteria, knowledgeSummary: KnowledgeSummary) {
    if (criteria.categories && criteria.categories?.length === 0 && criteria.categoriesNotInclude && criteria.categoriesNotInclude?.length === 0) {
      return true;
    } else {
      // AND OPERATOR
      if (criteria.categoriesLogicOperator === ELogicOperators.AND) {
        // knowledgeSummary category need include all tag
        if (criteria.categories && criteria.categories.length > 0) {
          criteria.categories.forEach(cate => {
            if (!knowledgeSummary.categories.includes(cate)) {
              return false;
            }
          });
        }

        // exclude all tag has been removed from bussiness
        // knowledgeSummary category need exclude all tag
        // if (criteria.categoriesNotInclude && criteria.categoriesNotInclude.length > 0) {
        //   criteria.categoriesNotInclude.forEach(cate => {
        //     if (knowledgeSummary.categories.includes(cate)) {
        //       return false;
        //     }
        //   });
        // }
      }

      // OR OPERATOR
      if (criteria.categoriesLogicOperator === ELogicOperators.OR) {

        if (criteria.categories && criteria.categories.length > 0) {
          // knowledgeSummary category need include at leat one tag
          let includeAnyTag = false;
          for (let i = 0; i <= criteria.categories.length; i++) {
            if (knowledgeSummary.categories.includes(criteria.categories[i])) {
              includeAnyTag = true;
              break;
            }
          }
          if (!includeAnyTag) {
            return false;
          }
        }

        // exclude all tag has been removed from bussiness
        // knowledgeSummary category need exclude all tag
        // if (criteria.categoriesNotInclude && criteria.categoriesNotInclude.length > 0) {
        //   criteria.categoriesNotInclude.forEach(cate => {
        //     if (knowledgeSummary.categories.includes(cate)) {
        //       return false;
        //     }
        //   });
        // }
      }
    }

    return true;
  }

  private fetchByBoard(board: BoardDto, accountOrganization: AccountOrganization) {
    const boardSummary = ({ id: board.id, name: board.title, current: EScrollPaging.INITIAL_PAGE_INDEX, board: board } as BoardSummary);
    return this.fetchSummaries(accountOrganization, boardSummary, EScrollPaging.INITIAL_PAGE_INDEX, EScrollPaging.DEFAULT_MAXIMUM_SIZE, EScrollPaging.DEFAULT_PAGE_SIZE);
  }

  updateLimitBoardViewSize(limitBoardViewSize: number) {
    this.dashboardStore.update({ limitBoardViewSize });
  }

  updateModeView(modeView: EDashboardVisualization) {
    this.dashboardStore.update({ modeView });
  }

  updateBoardViewStore(boards: BoardView) {
    this.dashboardStore.update({ boards });
  }

  resetStore() {
    this.dashboardStore.reset();
  }

  /**
  * update requirement for board filter
  * * X: a set of keyword
  * I = { x ⊆ X | keyword “include” }
  * E = { x ⊆ X | keyword “not include”}
  * S(x): search result with keywors "x"
  * I1, I2, ..., In ⊆ I
  * E1, E2, ..., En ⊆ E
  * A: search result with logic operator "AND"
  * B: search result with logic operator "OR"
  * A = (S(I1) & S(I2) & ... & S(In)) & ! (S(E1) | S(E2) | .... | S(En))
  * B = (S(I1) | S(I2) | ... | S(In)) & ! (S(E1) | S(E2) | .... | S(En))
  */
  fetchSummaries(
    accountOrganization: AccountOrganization,
    boardSummary: BoardSummary,
    scrollId: number,
    scrollPageSize: number,
    pageSize: number,
  ) {
    const criteriaFilter = (JSON.parse(boardSummary.board.criteria) as BoardCriteria);
    // generate filters
    const filtersGenerated = this.genFilterQueryES(accountOrganization.organizationId, criteriaFilter);
    const filtersDefault = {
      all: [{ organization_id: accountOrganization.organizationId },
      { is_deleted: 'false' }]
    };

    // generate queries
    const queries = this.generateQueries(
      criteriaFilter,
      filtersGenerated ? filtersGenerated : filtersDefault,
      scrollId,
      scrollPageSize);

    return this.knowledgeElasticsearchService.multiSearch(queries)
      .pipe(
        tap(buckets => this.updateBoardResult(buckets, boardSummary, accountOrganization, scrollId, scrollPageSize, pageSize)),
        take(1),
        tap(_ => this.updateSummaries(boardSummary, pageSize))
      )
  }

  /**
 * enhance search
 * update data summaries follow pagination client & server
 */
  updatePagingBoardView(id: any, accountOrganization: AccountOrganization, currentIndex: number, pageSize: number, scrollPageSize: number) {
    const boards = { ...this.dashboardStore.getValue().boards };
    // const boardSummary = { ...boards[id] } as BoardSummary;
    const boardSummary = boards[id];

    const { vault, summaries, scrollId, maxScrollId, total, max } = boardSummary;
    if (currentIndex <= max) {
      // update current
      boardSummary.current = currentIndex;

      const fromIndex = summaries && summaries.length ? summaries.length : 0;
      const toIndex = pageSize * currentIndex <= total ? pageSize * currentIndex : total;
      const updateSummaries = summaries.concat(vault.slice(fromIndex, toIndex));

      // update summaries
      boardSummary.summaries = updateSummaries;
      // update board view
      boards[id] = boardSummary;
      this.dashboardStore.update({ boards });
    }
    // condition for paging server
    else if (summaries.length >= total && scrollId < maxScrollId) {
      boardSummary.current = currentIndex;
      boards[id] = boardSummary;
      this.dashboardStore.update({ boards });

      return this.fetchSummaries(accountOrganization, boardSummary, scrollId + 1, scrollPageSize, pageSize);
    }

    return of(null);
  }


  /**
   * enhance search
   * update buckets result query
   * update vault, total, max
   * update scrollId, maxScrollId
   */
  updateBoardResult(buckets: SearchESResult[],
    board: BoardSummary,
    accountOrganization: AccountOrganization,
    scrollId: number,
    scrollPageSize: number,
    pageSize: number) {

    const totalPages = buckets.map(bucket => bucket && bucket.page && bucket.page.total_pages);
    // update scrollId
    board.scrollId = scrollId;

    // init vault if the first time
    board.vault = board.vault ? board.vault : [];

    // scan all to set maxScrollId in buckets[]
    board.maxScrollId = board.maxScrollId ? board.maxScrollId : Math.max(...totalPages);
    if (Math.max(...totalPages) > board.maxScrollId) {
      board.maxScrollId = Math.max(...totalPages);
    }

    // update vault, max, total
    const bucketDatas = buckets.map(bucket =>
      bucket.rawResults && bucket.rawResults.length > 0 ?
        bucket.rawResults.map(item => convertToKnowledgeSummary(item, accountOrganization)) : []);

    const vault = bucketDatas.length === 1 ?
      bucketDatas[0] : bucketDatas.length > 1 ?
        GetIntersectionArrayObject(bucketDatas[0], bucketDatas[1], 'id') : [];

    const updatedVault = GetDistinctArray(MergeArray(board.vault, vault), 'id');

    board.vault = updatedVault;
    board.total = updatedVault.length;
    board.max = pageSize > 0 ?
      Math.ceil(updatedVault.length / pageSize) : Math.ceil(updatedVault.length / pageSize);
    board.scrollPageSize = scrollPageSize;

    this.updateBoards(board);
  }

  /**
   * enhance search
   * update data summaries follow pagination client & server
   */
  updateSummaries(boardSummary: BoardSummary, pageSize: number) {
    let boards = { ...this.dashboardStore.getValue().boards };
    boards[boardSummary.id] = boardSummary;

    const { vault, summaries, current, total } = boards[boardSummary.id];
    const fromIndex = summaries && summaries.length ? summaries.length : 0;
    const toIndex = pageSize * current <= total ? pageSize * current : total;

    const updateSummaries = summaries && summaries.length > 0 ?
      summaries.concat(vault.slice(fromIndex, toIndex))
      : vault.slice(fromIndex, toIndex);

    boards[boardSummary.id].summaries = GetDistinctArray(updateSummaries, 'id');

    this.dashboardStore.update({ boards });
  }

  updateSummaryInBoard(summary: KnowledgeSummary, boardId: string) {
    const { boards } = this.dashboardStore.getValue();
    if (boards[boardId] && boards[boardId]?.summaries && boards[boardId].summaries?.length > 0) {
      const position = boards[boardId].summaries.findIndex((item => item.id === summary.id));
      if (position >= 0) {
        boards[boardId].summaries[position] = { ...summary };
        this.updateBoards(boards[boardId]);
      }
    }
  }

  /*
  * generate queries, change to multiSearch ES
  * @returns queries: query[]
  */
  private generateQueries(criteria: BoardCriteria, filters: any, scrollId: number, scrollPageSize: number) {
    let queries = [];

    // bind query to ES
    // keyword
    if (criteria.keyword || criteria.keywordNotInclude) {
      const query = this.bindQueryForKeyword(criteria);
      queries.push({
        query, options: {
          search_fields: { title: {}, body: {}, },
          query,
          filters,
          sort: [{ updated_at: ESortOptions.DESC }],
          page: { size: scrollPageSize, current: scrollId }
        }
      });
    }

    // categories
    if (criteria.categories && criteria.categories.length > 0 || criteria.categoriesNotInclude && criteria.categoriesNotInclude.length > 0) {
      const query = this.bindQueryForTags(criteria);

      queries.push({
        query, options: {
          search_fields: { category: {}, },
          query,
          filters,
          sort: [{ updated_at: ESortOptions.DESC }],
          page: { size: scrollPageSize, current: scrollId }
        }
      });
    }

    // incase no keyword, no tags
    if (queries.length === 0) {
      queries.push({
        query: '', options: {
          search_fields: { title: {}, body: {}, },
          query: '',
          filters,
          sort: [{ updated_at: ESortOptions.DESC }],
          page: { size: scrollPageSize, current: scrollId }
        }
      });
    }

    return queries;
  }

  /*
  * generate query with keyword include/not include, logic operator
  * @returns query: string
  */
  private bindQueryForKeyword(criteria: BoardCriteria) {
    let strKeyword = [];
    let strKeywordNotInclude = [];
    let query = '';
    if (criteria.keyword) {
      strKeyword = criteria.keyword.split(/,|、/);
      strKeyword = strKeyword.map(item => item.trim());
      strKeyword = strKeyword.filter(item => item.length !== 0);
      strKeyword = strKeyword.map(item => item.replace(/ /ig, `${ELuceneLogic.EXCLUDE}`));
    }
    if (criteria.keywordNotInclude) {
      strKeywordNotInclude = criteria.keywordNotInclude.split(/,|、/);
      strKeywordNotInclude = strKeywordNotInclude.map(item => item.trim());
      strKeywordNotInclude = strKeywordNotInclude.filter(item => item.length !== 0);
      strKeywordNotInclude = strKeywordNotInclude.map(item => item.replace(/ /ig, `${ELuceneLogic.EXCLUDE}`));
    }

    switch (criteria.keywordLogicOperator) {
      case ELogicOperators.OR:
        // include keyword
        if (strKeyword.length > 0) {
          query += `${ELuceneLogic.INCLUDE}${strKeyword.join(`${ELuceneLogic.INCLUDE}`)}`;
        }
        // not include keyword
        if (strKeywordNotInclude.length > 0) {
          query += ` ${ELuceneLogic.AND} ${ELuceneLogic.EXCLUDE}${strKeywordNotInclude.join(`${ELuceneLogic.EXCLUDE}`)}`;
        }
        break;

      case ELogicOperators.AND:
      default:
        // include keyword
        if (strKeyword.length > 0) {
          query += `${ELuceneLogic.INCLUDE}${strKeyword.join(` ${ELuceneLogic.AND} ${ELuceneLogic.INCLUDE}`)}`;
        }

        // not include keyword
        if (strKeywordNotInclude.length > 0) {
          query += ` ${ELuceneLogic.AND} ${ELuceneLogic.EXCLUDE}${strKeywordNotInclude.join(`${ELuceneLogic.EXCLUDE}`)}`;
        }
        break;
    }

    return query;
  }

  /*
  * generate query with tags include/not include, logic operator
  * @returns query: string
  */
  private bindQueryForTags(criteria: BoardCriteria) {
    let query = '';

    switch (criteria.categoriesLogicOperator) {
      case ELogicOperators.OR:
        //categories Include
        if (criteria.categories?.length === 1) {
          query = criteria.categories[0];
        } else if (criteria.categories?.length > 1) {
          query += `${ELuceneLogic.INCLUDE}${criteria.categories.join(`${ELuceneLogic.INCLUDE}`)}`;
        }
        //categories Not Include
        if (criteria.categoriesNotInclude?.length > 0) {
          query += ` ${ELuceneLogic.AND} ${ELuceneLogic.EXCLUDE}${criteria.categoriesNotInclude.join(`${ELuceneLogic.EXCLUDE}`)}`;
        }
        break;

      case ELogicOperators.AND:
      default:
        //categories Include
        if (criteria.categories?.length === 1) {
          query = criteria.categories[0];
        } else if (criteria.categories?.length > 1) {
          query += `${ELuceneLogic.INCLUDE}${criteria.categories.join(` ${ELuceneLogic.AND} ${ELuceneLogic.INCLUDE}`)}`;
        }
        //categories Not Include
        if (criteria.categoriesNotInclude?.length > 0) {
          query += ` ${ELuceneLogic.AND} ${ELuceneLogic.EXCLUDE}${criteria.categoriesNotInclude.join(`${ELuceneLogic.EXCLUDE}`)}`;
        }
        break;
    }

    return query;
  }

  /*
  * generate filters for ES  
  * @returns filters
  */
  private genFilterQueryES(organizationId: string, criteria: BoardCriteria) {
    // case 1: no postTypes
    if (!criteria.postTypes || criteria.postTypes.length === 0) {
      return this.genFilterWithNoPostType(criteria, organizationId);
    }

    // case 2: postTypes only include request type
    // {all : conditions[]}
    // cancel condition member
    else if (criteria.postTypes && criteria.postTypes.length === 1 && criteria.postTypes.includes(EPostType.KNOWLEDGE_REQUEST)) {
      return this.genFilterWithPostTypeOnlyIncludeRequest(criteria, organizationId);
    }

    // case 3: postTypes include request type and others
    // { any: [conditions for postype request, condition for other postype ]}
    // cancel condition member
    else if (criteria.postTypes && criteria.postTypes.length > 1 && criteria.postTypes.includes(EPostType.KNOWLEDGE_REQUEST)) {
      return this.genFilterWithPostTypeHaveRequestAndOthers(criteria, organizationId);
    }

    // case 4: postTypes not include request type
    else if (criteria.postTypes && criteria.postTypes.length >= 1 && !criteria.postTypes.includes(EPostType.KNOWLEDGE_REQUEST)) {
      return this.genFilterWithPostTypeNotIncludeRequest(criteria, organizationId);
    }
    else {
      return null
    }
  }

  /*
   * generate filters for ES incase no postTypes
   * @returns filters
   */
  private genFilterWithNoPostType(criteria: BoardCriteria, organizationId: string) {
    // case 1.1 have members condition
    if ((Array.isArray(criteria.origins) && criteria.origins.length > 0)
      || (Array.isArray(criteria.sendbyNotInclude) && criteria.sendbyNotInclude.length > 0)) {
      const tempCritetia = criteria;
      tempCritetia.postTypes = [
        EPostType.KNOWLEDGE,
        EPostType.KNOWLEDGE_REQUEST,
        EPostType.SHARING_VENDOR
      ];
      return this.genFilterWithPostTypeHaveRequestAndOthers(tempCritetia, organizationId);
    }
    // case 1.2 have no members condition
    else {
      let filters = {
        all: [
          { organization_id: organizationId },
          { is_deleted: 'false' },
        ]
      };

      const dateRange = this.genFilterCreatedAtDateRange(criteria);
      if (Object.keys(dateRange).length > 0) {
        filters = { all: [...filters.all, ...dateRange] };
      }

      return filters;
    }
  }

  /*
    generate filters for ES incase postTypes cotain only request
   * @returns filters
   */
  private genFilterWithPostTypeOnlyIncludeRequest(criteria: BoardCriteria, organizationId: string) {
    let filters = {
      all: [
        { organization_id: organizationId },
        { is_deleted: 'false' },
        { post_type: criteria.postTypes }
      ]
    };
    const dateRange = this.genFilterCreatedAtDateRange(criteria);

    if (Object.keys(dateRange).length > 0) {
      filters = { all: [...filters.all, ...dateRange] };
    }

    return filters;
  }

  /*
    generate filters for ES incase postTypes cotain request and others type
   * @returns filters
   */
  private genFilterWithPostTypeHaveRequestAndOthers(criteria: BoardCriteria, organizationId: string) {
    const filters = {
      any: [
        { all: [{ organization_id: organizationId }, { is_deleted: 'false' }, { post_type: criteria.postTypes.filter(type => type !== EPostType.KNOWLEDGE_REQUEST) }] },
        { all: [{ organization_id: organizationId }, { is_deleted: 'false' }, { post_type: [EPostType.KNOWLEDGE_REQUEST] }] }
      ]
    };

    const members = this.genFilterForMembers(criteria);
    const dateRange = this.genFilterCreatedAtDateRange(criteria);

    // case 3.1
    if (Object.keys(members).length > 0) {
      filters.any[0] = { all: [...filters.any[0].all, ...members] };
    }

    if (Object.keys(dateRange).length > 0) {
      filters.any[0] = { all: [...filters.any[0].all, ...dateRange] };
    }

    // case 3.2
    if (Object.keys(dateRange).length > 0) {
      filters.any[1] = { all: [...filters.any[1].all, ...dateRange] };
    }

    return filters;
  }

  /*
    generate filters for ES incase postTypes not cotain request
   * @returns filters
   */
  private genFilterWithPostTypeNotIncludeRequest(criteria: BoardCriteria, organizationId: string) {
    let filters = {
      all: [
        { organization_id: organizationId },
        { is_deleted: 'false' },
        { post_type: criteria.postTypes }
      ]
    };

    const members = this.genFilterForMembers(criteria);
    const dateRange = this.genFilterCreatedAtDateRange(criteria);

    if (Object.keys(members).length > 0) {
      filters = { all: [...filters.all, ...members] };
    }

    if (Object.keys(dateRange).length > 0) {
      filters = { all: [...filters.all, ...dateRange] };
    }

    return filters;
  }

  /* only filter with posttype = knowledge/sharing_post*/
  private genFilterForMembers(criteria: BoardCriteria) {
    let filter: any = [];
    // Noted: sharingBy maybe has been canceled.
    if (criteria.origins && criteria.origins.length > 0) {
      filter.push({ send_by: criteria.origins });
    }

    if (criteria.sendbyNotInclude && criteria.sendbyNotInclude.length > 0) {
      filter.push({ none: [{ send_by: criteria.sendbyNotInclude }] });
    }

    return filter;
  }

  private genFilterCreatedAtDateRange(criteria: BoardCriteria) {
    let filter: any = [];
    let dateRange = {};
    if (criteria.dateFrom) {
      dateRange['from'] = criteria.dateFrom;
    }
    if (criteria.dateTo) {
      dateRange['to'] = criteria.dateTo;
    }
    if (Object.keys(dateRange).length > 0) {
      filter.push({ created_at: { ...dateRange } });
    }

    return filter;
  }
}
