import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

const components: Type<any>[] = [DatepickerComponent];

@NgModule({
  imports: [CommonModule, NzDatePickerModule, FormsModule],
  declarations: [components],
  exports: [components],
})
export class DatepickerModule { }
