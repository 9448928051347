import { FeedbackBody, FeedbackId, FeedbackKnowledgeId, FeedbackTitle, FeedbackType } from '../value';
import { count, map } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { EFeedbackType } from '../enum';
import { Feedback } from '../entity';
import { FeedbackDto } from '../dto';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IFeedbackRepository } from '../repository';
import { Observable } from 'rxjs';

export class FeedbackDomainService {
  constructor(private readonly feedbackRepository: IFeedbackRepository) { }

  select(id: string) {
    return this.feedbackRepository.select(FeedbackId.create(id)).pipe(map(item => this.convertDto(item)));
  }

  selectAllFeedback(builder = new FirestoreQueryBuilder<Feedback>()) {
    return this.feedbackRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  countAllBySendBy(sendBy: string) {
    return this.feedbackRepository
      .selectAll(new FirestoreQueryBuilder<Feedback>()
        .equalWhere('sendBy', sendBy)
        .equalWhere('type', EFeedbackType.INTERNAL)
        .equalWhere('isDeleted', false)
      ).pipe(count());
  }

  selectAllBySendBy(sendBy: string) {
    return this.feedbackRepository
      .selectAll(new FirestoreQueryBuilder<Feedback>()
        .equalWhere('sendBy', sendBy)
        .equalWhere('type', EFeedbackType.INTERNAL)
        .equalWhere('isDeleted', false)
      ).pipe(map(item => this.convertDto(item)));
  }

  insertFeedback(feedbackDto: FeedbackDto): Observable<FeedbackDto> {
    const feedback = new Feedback(this.feedbackRepository.generateId());
    feedback.sendBy = AccountOrganizationId.create(feedbackDto.sendBy);
    feedback.title = FeedbackTitle.create(feedbackDto.title);
    feedback.body = FeedbackBody.create(feedbackDto.body);
    feedback.knowledgeId = FeedbackKnowledgeId.create(feedbackDto?.knowledgeId);
    feedback.type = FeedbackType.create(feedbackDto.type);
    return this.feedbackRepository.insert(feedback).pipe(map(item => this.convertDto(item)));
  }

  updateFeedback(feedbackDto: FeedbackDto) {
    const feedback = new Feedback(FeedbackId.create(feedbackDto.id));
    feedback.sendBy = AccountOrganizationId.create(feedbackDto.sendBy);
    feedback.title = FeedbackTitle.create(feedbackDto.title);
    feedback.body = FeedbackBody.create(feedbackDto.body);
    feedback.knowledgeId = FeedbackKnowledgeId.create(feedbackDto?.knowledgeId);
    return this.feedbackRepository.update(feedback).pipe(map(item => this.convertDto(item)));
  }

  deleteFeedback(id: string) {
    return this.feedbackRepository.delete(FeedbackId.create(id));
  }

  private convertDto(feedback: Feedback): FeedbackDto {
    return Feedback.allFields.reduce((p, key) => {
      if (feedback[key] === undefined) {
        return p;
      }
      const value = feedback[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as FeedbackDto);
  }
}
