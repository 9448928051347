import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { faCheck } from '@fortawesome/fontawesome-pro-light';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { FeedbackSelectReceiverService } from './state/feedback-select-receiver.service';
import { WorkspaceService } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.service';
import { AccountMember } from 'frontend/admin/app/model/account-member-model';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-select-receiver',
  templateUrl: './feedback-select-receiver.component.html',
  styleUrls: ['./feedback-select-receiver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackSelectReceiverComponent extends BaseComponent {
  @Input() initSelectedUsers: AccountMember[] = [];
  @Output() onSelectedUsers = new EventEmitter();

  faCheck = faCheck;
  initLoad: boolean = false;
  selectedUsers: AccountMember[] = [];
  subscription: Subscription;

  constructor(
    readonly workspaceQuery: WorkspaceQuery,
    readonly workspaceService: WorkspaceService,
    readonly feedbackSelectReceiverService: FeedbackSelectReceiverService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  protected override onDestroy(): void { }
  protected override onInit(): void {
    if (this.initSelectedUsers.length > 0) {
      this.selectedUsers = this.initSelectedUsers;
    }

    const organizationId = this.workspaceQuery.getValue().currentAccountOrganization.organizationId;
    if (organizationId) {
      this.workspaceService.fetchActiveMembers(organizationId).pipe(
        take(1),
        tap(_ => this.initLoad = true)
      ).subscribe(_ => { this.changeDetectorRef.detectChanges(); });
    }
  }


  onSelectUser(account: AccountMember): void {
    if (this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email)) {
      this.selectedUsers = this.selectedUsers.filter(u => u.email !== account.email);
    } else {
      this.selectedUsers.push(account);
    }

    this.onSelectedUsers.emit(this.selectedUsers);
  }

  isSelectedUSer(account: AccountMember) {
    return this.selectedUsers.length > 0 && this.selectedUsers.find(u => u.email == account.email);
  }
}
