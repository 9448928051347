import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { DashboardService } from 'frontend/admin/app/pages/dashboard/components/dashboard/state/dashboard.service';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { ObjectUtils } from 'frontend/admin/app/shared/utils/objects.utils';
import { tap } from 'rxjs/operators';
import { CommentService } from '../comment-group/state/comment.service';

@Component({
  selector: 'app-timeline-card-footer',
  templateUrl: './timeline-card-footer.component.html',
  styleUrls: ['./timeline-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineCardFooterComponent extends BaseComponent implements OnChanges {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() allowQuote: boolean = true;
  @Input() isShowEditor: boolean = true;
  @Input() boardId: string = null;
  @Output() showDetail = new EventEmitter();

  appData = {
    faComment: faComment,
  }
  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  isCommentOpened: boolean = false;
  totalComments: number = null;
  isShowQuote: boolean = false;

  public isCommentActivated = false;

  constructor(
    public readonly workspaceQuery: WorkspaceQuery,
    private readonly commentService: CommentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardService: DashboardService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['knowledgeSummary']) {
      const { currentValue, previousValue, firstChange } = changes?.['knowledgeSummary'];
      if (
        (firstChange && currentValue?.['commentTotal'])
        ||
        !ObjectUtils.isComparisonObject(previousValue?.['commentTotal'], currentValue?.['commentTotal'])
      ) {
        this.init();
      }
    }
  }

  protected override onDestroy(): void { }
  protected override onInit(): void { }

  public onDisplayCommentBox($event: any) {
    $event.preventDefault();
    this.isCommentOpened = true;
  }

  private init() {
    this.isShowQuote = this.knowledgeSummary.postType === this.postType.KNOWLEDGE && this.allowQuote === true;
    const summary = this.knowledgeSummary;
    const { currentAccountOrganization } = this.workspaceQuery.getValue();

    return this.commentService.getCommentByUser(this.knowledgeSummary, currentAccountOrganization.id)
      .pipe(
        tap(comment => {
          this.isCommentActivated = summary.isCommentActivated = comment !== null;
          // update to dashboard state
          // if (this.boardId) {
          //   this.dashboardService.updateSummaryInBoard(summary, this.boardId);
          // }
        }),
      )
      .subscribe({ complete: () => this.changeDetectorRef.detectChanges() });
  }
}
