
import { KnowledgePostType, KnowledgeType } from "domain/kernel/knowledge/value";

import { AccountOrganization } from "frontend/lib/model/account-organization.model";
import { EPostType } from "domain/kernel/post";
import { get } from "utility/validator/custom-lodash";
import { MyKnowledgeSummary } from "frontend/admin/app/model/my-knowledge-summary.model";
import { MyKnowledgeDocumentId } from "domain/kernel/my-knowledge-document/value";
import { AccountOrganizationId } from "domain/kernel/account/value";
import { Timestamp } from "utility/model/timestamp.value";
import { KnowledgeDocumentBody, KnowledgeDocumentId, KnowledgeDocumentTitle } from "domain/kernel/knowledge-document/value";
import { EKnowledgeType } from "domain/kernel/knowledge/enum";

function convertToMyKnowledgeSummary(item: any, accountOrganization: AccountOrganization): MyKnowledgeSummary {
  const postType = KnowledgePostType.create(get(item, 'post_type.raw', EPostType.KNOWLEDGE)).value;

  return {
    /** my-knowledge cluster */
    id: MyKnowledgeDocumentId.create(item.id.raw).value,
    createdBy: item.created_by && item.created_by.raw ? AccountOrganizationId.create(get(item, 'created_by.raw', '')).value : null,
    createdAt: item.created_at && item.created_at.raw ? Timestamp.createByDate(item.created_at.raw).value : null,
    updatedAt: item.updated_at && item.updated_at.raw ? Timestamp.createByDate(item.updated_at.raw).value : null,

    /** knowledge cluster */
    knowledgeId: item.knowledge_id && item.knowledge_id.raw ? KnowledgeDocumentId.create(get(item, 'knowledge_id.raw', '')).value : null,
    sendBy: item.send_by && item.send_by.raw ? AccountOrganizationId.create(get(item, 'send_by.raw', '')).value : null,
    title: item.title && item.title.raw ? KnowledgeDocumentTitle.create(get(item, 'title.raw', '')).value : null,
    body: item.body && item.body.raw ? KnowledgeDocumentBody.create(get(item, 'body.raw', '')).value : null,
    postType: postType,//KnowledgePostType.create(get(item, 'post_type.raw', EPostType.SHARING_VENDOR)).value,
    knowledgeType: postType === EPostType.KNOWLEDGE ?
      KnowledgeType.create(get(item, 'knowledge_type.raw', EKnowledgeType.NORMAL)).value : '',
    knowledgeCreatedAt: item.knowledge_created_at && item.knowledge_created_at.raw ? Timestamp.createByDate(item.knowledge_created_at.raw).value : null,
    knowledgeUpdatedAt: item.knowledge_updated_at && item.knowledge_updated_at.raw ? Timestamp.createByDate(item.knowledge_updated_at.raw).value : null,
    loggedUser: accountOrganization,

  } as MyKnowledgeSummary;
}

export { convertToMyKnowledgeSummary };
