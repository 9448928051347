<section class="and-or-condition__section">
  <div
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="menu"
    nzOverlayClassName="and-or-condition__cdk"
    nzPlacement="bottomRight"
    (nzVisibleChange)="onVisibleChange($event)"
    [class.hasOpen]="hasOpen"
    class="menu"
  >
    <p>{{ value }}</p>
    <img [src]="AssetConstant.ICON_ARROW_URL" alt="icon-arrow" />
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item class="item" [class.active]="item.label === value" *ngFor="let item of options" (click)="onSelect(item)">
        <p>{{ item.label }}</p>
      </li>
    </ul>
  </nz-dropdown-menu>
</section>
