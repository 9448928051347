<div>
  <div class="timeline-card">
    <div [class.-sharing-vendor]="knowledgeSummary.postType === postType.SHARING_VENDOR">
      <div class="timeline-card__body">
        <!-- <div class="timeline-card__body-text" (click)="showDetail.emit(knowledgeSummary)" *ngIf="knowledgeSummary.postType == postType.SHARING_VENDOR; else normal"> -->
        <div class="timeline-card__body-text" *ngIf="knowledgeSummary.postType == postType.SHARING_VENDOR; else normal">
          <div *ngIf="knowledgeSummary.title">
            <p class="timeline-card__body-title show-all" *ngIf="knowledgeSummary.title"> {{ knowledgeSummary.title }}</p>
            <span class="jit" *ngIf="isShowUpdatedAtJIT">{{ knowledgeSummary.updatedAtShortStr }}更新</span>
          </div>
          <p #knowledgeContent class="timeline-card__body-summary disable-links" [innerHTML]="knowledgeSummary.body | slackMarked"></p>
        </div>
        <ng-template #normal>
          <!-- <div class="timeline-card__body-text" (click)="showDetail.emit(knowledgeSummary)"> -->
          <div class="timeline-card__body-text">
            <div class="timeline-card__body-title" *ngIf="knowledgeSummary.title">
              <p> {{ knowledgeSummary.title }}</p>
            </div>
            <p #knowledgeContent class="timeline-card__body-summary disable-links" [innerHTML]="knowledgeSummary.body | slackMarked"></p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-container *ngIf="knowledgeSummary.postType === postType.SHARING_VENDOR">
    <div class="timeline-card__reaction">
      <app-statistic-reaction [knowledgeSummary]="knowledgeSummary"></app-statistic-reaction>
    </div>
  </ng-container>
</div>
