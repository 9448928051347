<div class="categories-anchor" *ngIf="categoriesAnchor && categoriesAnchor.length > 0">
  <div class="title" *ngIf="label">{{ label }}</div>
  <div class="container">
    <ng-container *ngFor="let category of categoriesAnchor">
      <div class="categories-anchor__item">
        <a href="javascript:void(0)">{{ category?.label }}</a>
      </div>
    </ng-container>
  </div>
</div>
