import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CreateTagComponent } from './create-tag.component';
import { SearchTagAutocompleteModule } from '../../atoms/search-tag-autocomplete/search-tag-autocomplete.module';

const components: Type<any>[] = [CreateTagComponent];

@NgModule({
  imports: [CommonModule, SearchTagAutocompleteModule],
  declarations: [components],
  exports: [components],
})
export class CreateTagModule { }
