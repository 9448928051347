import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { getAttachmentIcon } from 'frontend/utl/validator/file-extension.validator';

@Component({
  selector: 'app-icon-attachment',
  templateUrl: './icon-attachment.component.html',
  styleUrls: ['./icon-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconAttachmentComponent extends BaseComponent implements OnChanges {
  @Input() type: string;
  @Input() width = 22;
  @Input() height = 26;

  @Output() clickEmitter = new EventEmitter();

  public iconUrl = this.AssetConstant.ICON_ATTACHMENTS_URL.DEFAULT;

  public onClicked(): void {
    this.clickEmitter.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      this.iconUrl = this.AssetConstant.ICON_ATTACHMENTS_URL[getAttachmentIcon(this.type?.toLowerCase()).toUpperCase()];
    }
  }
}
