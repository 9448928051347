import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { MyKnowledgeDocumentApiClient } from '../api/my-knowledge-document.api.client';
import { MyKnowledgeDocumentDto } from 'domain/kernel/my-knowledge-document/dto';

@Injectable({
  providedIn: 'root'
})
export class MyKnowledgeDocumentService {
  constructor(private readonly knowledgeDocumentApiClient: MyKnowledgeDocumentApiClient) { }

  createMyKnowledgeDocument(myKnowledgeDocumentDto: MyKnowledgeDocumentDto): Observable<MyKnowledgeDocumentDto> {
    return from(this.knowledgeDocumentApiClient.createMyKnowledge(myKnowledgeDocumentDto));
  }

  updateMyKnowledgeDocument(myKnowledgeDocumentDto: MyKnowledgeDocumentDto): Observable<MyKnowledgeDocumentDto> {
    return from(this.knowledgeDocumentApiClient.updateMyKnowledge(myKnowledgeDocumentDto));
  }
}
