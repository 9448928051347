<div class="timeline-card" (click)="onShowDetail(); $event.stopPropagation()">
  <div [class.-request]="knowledgeSummary && knowledgeSummary.postType == postType.KNOWLEDGE_REQUEST">
    <div class="timeline-card__wrapper" [class.-rounded-10]="knowledgeSummary && knowledgeSummary.postType == postType.KNOWLEDGE_REQUEST">
      <div>
        <app-timeline-card-header [knowledgeSummary]="knowledgeSummary" [boardId]="boardId"></app-timeline-card-header>
        <app-timeline-card-body [knowledgeSummary]="knowledgeSummary"></app-timeline-card-body>
        <app-knowledge-reply
          *ngIf="knowledgeSummary.postType == postType.KNOWLEDGE_REQUEST"
          [knowledgeSummary]="knowledgeSummary"
        ></app-knowledge-reply>
      </div>
      <app-timeline-card-footer [knowledgeSummary]="knowledgeSummary" [boardId]="boardId"></app-timeline-card-footer>
    </div>
  </div>
</div>
