import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { CategoryId, CategoryAnchorId } from '../value';
import { PostType, PostId } from '../../post';
import { IsDeleted } from '../../base/value';

export class CategoryAnchor implements Entity {
  @Column()
  readonly id: CategoryAnchorId;
  @Column()
  categoryId: CategoryId;
  @Column()
  postId: PostId;
  @Column()
  postType: PostType;
  @Column()
  createdBy: AccountOrganizationId;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: CategoryAnchorId) {
    this.id = id;
  }

  static get allFields(): (keyof CategoryAnchor)[] {
    return getColumns(new CategoryAnchor(CategoryAnchorId.create('')));
  }

  equals(value: CategoryAnchor): boolean {
    return this.id.equals(value.id);
  }
}
