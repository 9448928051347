import { NgModule, Type } from '@angular/core';

import { BadgeModule } from '../../atoms/badge/badge.module';
import { CardViewHeaderAttachmentCountComponent } from './card-view-header-attachment-count.component';
import { CommonModule } from '@angular/common';
import { IconAttachmentModule } from '../../atoms/icon-attachment/icon-attachment.module';

const components: Type<any>[] = [CardViewHeaderAttachmentCountComponent];

@NgModule({
  declarations: [components],
  exports: [components],
  imports: [
    CommonModule,
    BadgeModule,
    IconAttachmentModule
  ]
})
export class CardViewHeaderAttachmentCountModule { }
