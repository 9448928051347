<div class="timeline-card-footer">
  <div class="action">
    <div class="action__wrapper">
      <div class="action">
        <app-reaction [knowledgeSummary]="knowledgeSummary"> </app-reaction>

        <div class="icon__section" [class.activated]="isCommentActivated">
          <div class="icon-action">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_107_38)">
                <path
                  d="M8.01292 0.613281C4.02692 0.613281 0.795917 3.23728 0.795917 6.47628C0.815093 7.81046 1.34975 9.08547 2.28792 10.0343C1.97488 10.793 1.53266 11.4917 0.980917 12.0993C0.890108 12.1955 0.829416 12.3161 0.806317 12.4463C0.783218 12.5766 0.798721 12.7107 0.850917 12.8323C0.903668 12.9536 0.990553 13.0569 1.10099 13.1297C1.21143 13.2025 1.34065 13.2416 1.47292 13.2423C2.88279 13.2168 4.25134 12.7619 5.39592 11.9383C6.24316 12.2055 7.12654 12.3404 8.01492 12.3383C12.0009 12.3383 15.2059 9.71328 15.2059 6.47528C15.2059 3.23728 11.9959 0.613281 8.01292 0.613281ZM8.01292 10.9873C7.26307 10.9872 6.5177 10.8719 5.80292 10.6453L5.16192 10.4453L4.61192 10.8333C4.11737 11.185 3.57257 11.4601 2.99592 11.6493C3.21668 11.2887 3.40412 10.9088 3.55592 10.5143L3.85592 9.72528L3.27492 9.10828C2.57999 8.40553 2.1791 7.46429 2.15392 6.47628C2.15392 3.98828 4.78292 1.96628 8.01692 1.96628C11.2509 1.96628 13.8799 3.98928 13.8799 6.47628C13.8799 8.96328 11.2509 10.9863 8.01692 10.9863L8.01292 10.9873Z"
                  fill="#707070"
                />
              </g>
              <defs>
                <clipPath id="clip0_107_38">
                  <rect width="14.408" height="12.629" fill="white" transform="translate(0.795898 0.613281)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p *ngIf="!!knowledgeSummary.commentTotal" class="number">{{ knowledgeSummary.commentTotal }}</p>
        </div>
        <ng-container *ngIf="isShowQuote">
          <app-quote [knowledgeSummary]="knowledgeSummary"></app-quote>
        </ng-container>
      </div>
    </div>
  </div>
</div>
