import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { AvatarService } from 'frontend/admin/app/shared/services/avatar.service';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { EImageSize } from 'frontend/admin/app/enum/image-size.enum';
import { ImageSizeGenerate } from 'frontend/utl/generate/image-size.generate';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent extends BaseComponent implements OnChanges {
  public url = null;

  @Input() letter = null;
  @Input() size: EImageSize = EImageSize.ORIGIN;
  @Input() source = null;
  @Input() altText = null;
  @Input() inputUrl = null;

  @Input() width = 30;
  @Input() height = 30;
  @Output() clicked = new EventEmitter();
  @Output() handledLoaded = new EventEmitter();

  constructor(
    readonly avatarService: AvatarService,
    readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public onClicked(): void {
    this.clicked.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['inputUrl'] && changes?.['inputUrl'].currentValue) {
      this.url = changes?.['inputUrl'].currentValue;
    } else
      if (changes?.['altText'] || changes?.['source']) {
        this.subscribe(this.avatarService.getViewOnly(this.altText, ImageSizeGenerate(this.source, this.size)), res => {
          if (res.toString().includes('http')) {
            this.url = res;
            this.letter = null;
            this.handledLoaded.emit(this.url);
          } else {
            this.letter = res;
            this.url = null;
          }
          this.changeDetectorRef.detectChanges();
        });
      }
  }
}

