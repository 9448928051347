import { NgModule, Type } from '@angular/core';

import { BadgeModule } from '../../atoms/badge/badge.module';
import { CardViewHeaderAttachmentCountModule } from '../card-view-header-attachment-count/card-view-header-attachment-count.module';
import { CardViewHeaderAttachmentModule } from '../card-view-header-attachment/card-view-header-attachment.module';
import { CardViewHeaderLinkModule } from '../card-view-header-link/card-view-header-link.module';
import { CommonModule } from '@angular/common';
import { FeedbackSearchKnowledgeResultsComponent } from './feedback-search-knowledge-results.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const components: Type<any>[] = [FeedbackSearchKnowledgeResultsComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule, BadgeModule, CardViewHeaderAttachmentModule, CardViewHeaderLinkModule, CardViewHeaderAttachmentCountModule],
  declarations: [components],
  exports: [components],
})
export class FeedbackSearchKnowledgeResultsModule { }
