import { IsDeleted } from '../../../kernel/base/value';
import { Column, getColumns } from '../../../../utility/decorator/entity/column.decorator';
import { Entity } from '../../../../utility/model/entity.model';
import { Timestamp } from '../../../../utility/model/timestamp.value';
import { AccountOrganizationId } from '../../account/value';
import { CategoryLabel } from '../../category/value';
import { OrganizationId } from '../../organization/value';
import { SharingVendorBody, SharingVendorDocumentConcerns, SharingVendorDocumentDisplayName, SharingVendorDocumentId } from '../value';

export class SharingVendorDocument implements Entity {
  @Column()
  readonly id: SharingVendorDocumentId;
  @Column()
  organizationId: OrganizationId;
  @Column()
  sendBy: AccountOrganizationId;
  @Column()
  sharedUserName: SharingVendorDocumentDisplayName;
  @Column()
  registeredBy: AccountOrganizationId;
  @Column()
  createdUserName: SharingVendorDocumentDisplayName;
  @Column()
  concerns: SharingVendorDocumentConcerns;
  @Column()
  category: CategoryLabel;
  @Column()
  body: SharingVendorBody;
  @Column()
  wroteAt: Timestamp;
  @Column()
  createdAt: Timestamp;
  @Column()
  updatedAt: Timestamp;
  @Column()
  isDeleted: IsDeleted;

  constructor(id: SharingVendorDocumentId) {
    this.id = id;
  }

  // Dirty code
  static get allFields(): (keyof SharingVendorDocument)[] {
    return getColumns(new SharingVendorDocument(SharingVendorDocumentId.create('')));
  }

  equals(value: SharingVendorDocument): boolean {
    return this.id.equals(value.id);
  }
}
