import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { ELimitCharacters } from 'frontend/admin/app/enum/limit.characters.enum';
import { EPostType } from 'domain/kernel/post';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';

@Component({
  selector: 'app-timeline-card-body',
  templateUrl: './timeline-card-body.component.html',
  styleUrls: ['./timeline-card-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineCardBodyComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;
  @Input() isNavDetail: boolean = false;
  @Input() isShowUpdatedAtJIT: boolean = false;
  @Output() showDetail = new EventEmitter();

  @Input() isExpanded: boolean = false;
  @Input() isShowless: boolean = false;
  @Input() isQuotation: boolean = false;

  quotation: KnowledgeSummary = null;
  organizationId: string = null;
  elimitCharacters = ELimitCharacters;
  postType = {
    SHARING_VENDOR: EPostType.SHARING_VENDOR,
    KNOWLEDGE: EPostType.KNOWLEDGE,
    KNOWLEDGE_REQUEST: EPostType.KNOWLEDGE_REQUEST,
  }

  @ViewChild("knowledgeContent") knowledgeContentRef: ElementRef;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  protected override onInit(): void {
  }

  protected override onAfterViewInit(): void {
    const offsetHeight = this.knowledgeContentRef?.nativeElement?.offsetHeight;
    this.isShowless = offsetHeight > this.elimitCharacters.KNOWLEDGE_CONTENT_OFFSET_HEIGHT;
    this.changeDetectorRef.detectChanges();
  }

  onClickToggle() {
    this.isExpanded = !this.isExpanded;
  }
}
