import { EMPTY, Observable } from 'rxjs';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IMyKnowledgeRepository } from '../../repository';
import { IsDeleted } from '../../../base/value';
import { KnowledgeId } from '../../../knowledge/value';
import { MyKnowledge } from '../../entity';
import { MyKnowledgeId } from '../../value';
import { MyKnowledgeNotFoundError } from '../../exception';
import { Timestamp } from 'utility/model/timestamp.value';

export class MyKnowledgeFirestoreRepository implements IMyKnowledgeRepository {
  private static readonly collectionId = 'my_knowledge';

  constructor(private readonly firestoreService: IFirestoreService) { }

  selectAll(builder: FirestoreQueryBuilder<MyKnowledge>): Observable<MyKnowledge> {
    return this.firestoreService.getCollection(MyKnowledgeFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(MyKnowledgeFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  select(id: MyKnowledgeId): Observable<MyKnowledge> {
    return this.firestoreService.getDocument(MyKnowledgeFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          throw new MyKnowledgeNotFoundError(`my_knowledge id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  selectForUpdateEs(id: MyKnowledgeId): Observable<MyKnowledge> | null {
    return this.firestoreService.getDocument(MyKnowledgeFirestoreRepository.collectionId, id).pipe(
      map(item => item ? this.convertToEntity(item) : null)
    );
  }

  selectIfExists(builder: FirestoreQueryBuilder<MyKnowledge>): Observable<any> {
    return this.firestoreService.getCollection(MyKnowledgeFirestoreRepository.collectionId, builder).pipe(
      take(1),
      map(items => items.length > 0 ? items[0] : null),
      map(item => item ? this.convertToEntity(item) : null),
    );
  }

  insert(item: MyKnowledge): Observable<MyKnowledge> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date());
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(MyKnowledgeFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(myKnowledge: MyKnowledge): Observable<MyKnowledge> {
    myKnowledge.updatedAt = Timestamp.createByMillsec(+new Date());
    return this.firestoreService.getDocument(MyKnowledgeFirestoreRepository.collectionId, myKnowledge.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item.isDeleted === true) {
          throw new MyKnowledgeNotFoundError(`MyKnowledge id「${myKnowledge.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(MyKnowledgeFirestoreRepository.collectionId, this.convertToMap(myKnowledge));
      }),
      map(_ => myKnowledge)
    );
  }

  reInsert(myKnowledge: MyKnowledge): Observable<MyKnowledge> {
    myKnowledge.createdAt = Timestamp.createByDate(new Date());
    myKnowledge.updatedAt = Timestamp.createByDate(new Date());
    myKnowledge.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(MyKnowledgeFirestoreRepository.collectionId, this.convertToMap(myKnowledge)).pipe(map(() => myKnowledge));
  }

  delete(id: MyKnowledgeId): Observable<void> {
    const proposal = new MyKnowledge(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByDate(new Date());
    return this.firestoreService.setDocument(MyKnowledgeFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): MyKnowledgeId {
    return MyKnowledgeId.create(this.firestoreService.generateId());
  }

  private convertToMap(myKnowledge: MyKnowledge): object {
    return MyKnowledge.allFields.reduce((p, key) => {
      if (myKnowledge[key] === undefined) {
        return p;
      }
      const value = myKnowledge[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const output = new MyKnowledge(MyKnowledgeId.create(item.id));
    output.knowledgeId = KnowledgeId.create(item.knowledgeId);
    output.createdBy = AccountOrganizationId.create(item.createdBy);
    output.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    output.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    output.isDeleted = IsDeleted.create(item.isDeleted);
    return output;
  }
}
