import { NgModule, Type } from '@angular/core';

import { BadgeComponent } from './badge.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';

const components: Type<any>[] = [BadgeComponent];

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [components],
  exports: [components],
})
export class BadgeModule { }
