<section class="knowledge-select">
  <div>
    <div class="knowledge-select__action" [class.-inProcessSelectKnowledge]="isOpenSelectKnowledge">
      <p>knowledge: </p>
      <ng-container *ngIf="isAdd; else selectAutoComplete">
        <!-- <app-button class="btn-add" (clickButton)="()"> ＋送信するナレッジを選択</app-button> -->
        <div class="btn" (click)="onOpenSelectKnowledge()">
          <span class="btn--add"> ＋送信するナレッジを選択</span>
        </div>
      </ng-container>
      <ng-template #selectAutoComplete>
        <!-- 17/03/2023 -> remove not used app-select-extra-auto-complete -->
      </ng-template>
    </div>
    <ng-container *ngIf="listOfSelectedOption.length > 0">
      <div class="knowledge-select__review">
        <ng-container *ngFor="let option of listOfSelectedOption">
          <ng-container *ngIf="findKnowledgeSummary(option)">
            <div class="knowledge-select__review__summary">
              <div class="content">
                <app-timeline-card [knowledgeSummary]="findKnowledgeSummary(option)"></app-timeline-card>
              </div>
              <div class="overlay" (click)="onRemoveSelectedTag(option.value)">
                <fa-icon [icon]="appData.faTimes"></fa-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>
