import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { EKnowledgeActivityType } from '../enum';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class KnowledgeActivityType extends PrimitiveValueObject<EKnowledgeActivityType> {
  static create(value: EKnowledgeActivityType): KnowledgeActivityType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new KnowledgeActivityType(value);
  }
}
