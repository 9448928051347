<div class="card-view-header-attachment">
  <ng-container *ngIf="files?.length === 1">
    <a>
      <app-icon-attachment [type]="files[0].type"></app-icon-attachment>
    </a>
  </ng-container>
  <ng-container *ngIf="files?.length > 1">
    <div>
      <app-card-view-header-attachment-count [count]="files.length"></app-card-view-header-attachment-count>
    </div>
  </ng-container>
</div>
