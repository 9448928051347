import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { IOption } from 'frontend/lib/model/option.model';

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTagComponent {
  @Input() dataSource: IOption<string, string>[] = [];
  @Input() selectedTags: IOption<string, string>[] = [];

  @Output() selected = new EventEmitter();

  public get isValidate(): boolean {
    return this.selectedTags.length > CommonConstant.ITEM_TAG_MAXIMUM;
  }

  public onSelectedTags($event: IOption<string, string>[]): void {
    this.selectedTags = $event;
    const output = this.selectedTags.map(x => x.value);
    this.selected.emit(output);
  }
}
