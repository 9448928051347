import { NgModule, Type } from '@angular/core';

import { AttachmentFileViewCardComponent } from './attachment-file-view-card.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconAttachmentModule } from '../../atoms/icon-attachment/icon-attachment.module';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';

const components: Type<any>[] = [AttachmentFileViewCardComponent];

@NgModule({
  imports: [
    CommonModule,
    IconAttachmentModule,
    FontAwesomeModule,
    PipesModule
  ],
  declarations: [components],
  exports: [components],
})
export class AttachmentFileViewCardModule { }
