import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { CommonConstant } from 'frontend/admin/app/shared/constants/common.constant';
import { ObjectUtils } from 'frontend/admin/app/shared/utils/objects.utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface TagOption {
  label: string;
  value: string;
  shortImg?: string;
  order?: number;
}

@Component({
  selector: 'app-search-tag-autocomplete',
  templateUrl: './search-tag-autocomplete.component.html',
  styleUrls: ['./search-tag-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTagAutocompleteComponent extends BaseComponent implements OnChanges {
  @Input() data: TagOption[] = [];
  @Input() initValue: string[] = [];

  @Output() selectItem = new EventEmitter();

  public inputSearchForm = new FormControl();
  public filteredOptions: TagOption[] = [];

  public choseItems: TagOption[] = [];

  public isSearch = false;
  public faPlus = faPlus;

  private primitiveValue: TagOption[] = [];

  constructor(
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  public onVisibleChange($event: boolean): void {
    if (!$event) {
      this.isSearch = false;
    } else {
      if (this.inputSearchForm?.value) {
        this.inputSearchForm?.setValue('');
      }
      this.filteredOptions = this.primitiveValue.filter((item) => !this.choseItems.find(({ value }) => item.value === value) && item.value);
      this.data = this.filteredOptions;
    }
  }

  public onRemove($event: Event, item: TagOption): void {
    this.choseItems = this.choseItems.filter(x => x.value !== item.value);
    this.filteredOptions.push(item);
    this.optimizeList();
    $event.preventDefault();
    $event.stopPropagation();
  }

  public onChange(value: string): void {
    this.filteredOptions = this.data.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onSelect(option: TagOption): void {
    this.choseItems.push(option);
    this.filteredOptions = this.filteredOptions.filter(x => x.value !== option.value);
    this.optimizeList();
  }

  protected override onInit(): void {
    this.subscribe(
      this.inputSearchForm.valueChanges.pipe(debounceTime(CommonConstant.INPUT_DEBOUNCE_INTERVAL), distinctUntilChanged()),
      searchValue => {
        this.isSearch = !!searchValue;
        this.filteredOptions = this.data.filter(option => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        this.cd.detectChanges();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      const { currentValue, previousValue } = changes?.['data'];
      if (currentValue && !ObjectUtils.isComparisonObject(currentValue, previousValue) && currentValue?.length > 0) {
        this.filteredOptions = currentValue.map((x, i) => {
          x.order = i;
          return x;
        });
        this.primitiveValue = currentValue;
      }
    }

    if (changes['initValue']) {
      const { currentValue, previousValue } = changes?.['initValue'];
      if (currentValue && !ObjectUtils.isComparisonObject(currentValue, previousValue) && currentValue?.length > 0) {
        this.data.forEach(x => {
          currentValue.forEach(y => {
            if (x.value === y) {
              this.choseItems.push(x);
            }
          });
        });
        this.optimizeList();
      }
    }
  }

  private optimizeList(): void {
    // check duplicate list
    this.choseItems = ObjectUtils.filterDuplicate<TagOption>(this.choseItems);

    // sort list by order fetch API
    this.choseItems = this.choseItems.sort((a, b) => a.order - b.order);

    // emit value
    this.selectItem.next(this.choseItems);
  }
}
