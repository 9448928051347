import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Account } from 'frontend/lib/model/account.model';

export interface InviteAccountModalState {
  accounts: Account[];
  selectedAccount: Account;
  loading: boolean;
}

export function createInitialState(): InviteAccountModalState {
  return {
    accounts: null,
    selectedAccount: null,
    loading: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invite-account-modal' })
export class InviteAccountModalStore extends Store<InviteAccountModalState> {
  constructor() {
    super(createInitialState());
  }
}
