import { Store, StoreConfig } from '@datorama/akita';

import { Injectable } from '@angular/core';

export interface CreateRequestModalState {
  loading: boolean;
  errMsgs: string[];
  failed: boolean;
}

export function createInitialState(): CreateRequestModalState {
  return {
    loading: false,
    failed: false,
    errMsgs: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'create-request-modal' })
export class CreateRequestModalStore extends Store<CreateRequestModalState> {
  constructor() {
    super(createInitialState());
  }
}
