import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SendFeedbackModalState, SendFeedbackModalStore } from './send-feedback-modal.store';

@Injectable({ providedIn: 'root' })
export class SendFeedbackModalQuery extends Query<SendFeedbackModalState> {
  constructor(protected store: SendFeedbackModalStore) {
    super(store);
  }

  selectAccounts() {
    return this.select('accounts');
  }

  selectIntegrations() {
    return this.select('integrations');
  }

  selectSending() {
    return this.select('sending');
  }
}
