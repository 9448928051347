import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faChevronLeft } from '@fortawesome/fontawesome-pro-light';
import { EPostType } from 'domain/kernel/post';
import { EScrollPaging } from 'frontend/admin/app/enum/scroll-paging.enum';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { KnowledgeSearchHeaderService } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.service';
import { BucketResultQuery } from 'frontend/admin/app/pages/knowledge-search/components/knowledge-search-header/state/knowledge-search-header.store';
import { WorkspaceQuery } from 'frontend/admin/app/pages/workspace/components/workspace/state/workspace.query';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { SearchESResult } from 'frontend/admin/app/shared/interfaces/search-result-es.interface';
import { AccountOrganization } from 'frontend/lib/model/account-organization.model';
import { GetDistinctArray, MergeArray } from 'frontend/utl/builder/array-transform.builder';
import { convertToKnowledgeSummary } from 'frontend/utl/generate/knowledge-summary.generate';
import { of } from 'rxjs';
import { filter, mergeMap, take, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-select-knowledge',
  templateUrl: './feedback-select-knowledge.component.html',
  styleUrls: ['./feedback-select-knowledge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackSelectKnowledgeComponent extends BaseComponent {
  readonly appData = {
    faChevronLeft: faChevronLeft,
    faCheck: faCheck
  }

  readonly pageSetting = {
    // for infinite scroll
    throttle: EScrollPaging.PAGING_INFINITE_THROTTLE,
    scrollDistance: EScrollPaging.PAGING_INFINITE_SCROLL_DISTANCE,
    scrollWindow: false,
    // for paging ES
    initPage: EScrollPaging.INITIAL_PAGE_INDEX,
    pageSize: EScrollPaging.DEFAULT_PAGE_SIZE,
    scrollPageSize: EScrollPaging.DEFAULT_MAXIMUM_SIZE,
  };

  knowledgeSearchResults = {
    // for scroll elastic search
    scrollId: 1,
    maxScrollId: 1,
    vault: [],//KnowledgeSummary[],

    // for paging on client side
    summaries: [],// KnowledgeSummary[],
    current: 1,
    max: 1,
    total: 0,
  };

  isOpenSelectKnowledge: boolean = true;
  keyword: string = null;
  isSearching: boolean = false;
  selectedKnowledgeSummaries: KnowledgeSummary[] = [];
  @Input() initSelectedKnowledgeSummaries: KnowledgeSummary[] = [];
  @Input() multiple: boolean = false;
  @Output() handleSearch = new EventEmitter();
  @Output() handleSelectKnowledge = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor(
    private readonly workspaceQuery: WorkspaceQuery,
    private readonly knowledgeSearchHeaderService: KnowledgeSearchHeaderService,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  protected override onInit(): void {
    this.selectedKnowledgeSummaries = this.initSelectedKnowledgeSummaries;
  }

  protected override onDestroy(): void {
  }

  onSearch($event: any) {
    this.keyword = $event;
    this.isSearching = false;
    this.clearSearchResult();
    return this.search(this.keyword, this.pageSetting.pageSize, this.pageSetting.initPage, this.pageSetting.scrollPageSize);
  }

  onChangeKeywords($event: any) {

  }

  onSelectKnowledge(knowledge: KnowledgeSummary): void {
    if (this.multiple) {
      if (this.selectedKnowledgeSummaries.length > 0 && this.selectedKnowledgeSummaries.find(item => item.id == knowledge.id)) {
        this.selectedKnowledgeSummaries = this.selectedKnowledgeSummaries.filter(item => item.id !== knowledge.id);
      } else {
        this.selectedKnowledgeSummaries.push(knowledge);
      }
    } else {
      this.selectedKnowledgeSummaries = [];
      this.selectedKnowledgeSummaries.push(knowledge)
    }
    this.handleSelectKnowledge.emit(this.selectedKnowledgeSummaries);
  }

  onToggleOpenSelectKnowledge() {
    this.isOpenSelectKnowledge = !this.isOpenSelectKnowledge;
  }

  onClose() {
    this.close.emit();
  }

  onScrollDown() {
    const { current, scrollId, maxScrollId } = this.knowledgeSearchResults;
    if (scrollId <= maxScrollId) {
      this.updatePagingSearchResult(current + 1, this.pageSetting.pageSize);
    }
  }

  isSelectedKnowledge(knowledge: KnowledgeSummary) {
    return this.selectedKnowledgeSummaries.length > 0 && this.selectedKnowledgeSummaries.find(item => item.id == knowledge.id);
  }

  private clearSearchResult() {
    this.knowledgeSearchResults = {
      // for scroll elastic search
      scrollId: 1,
      maxScrollId: 1,
      vault: [],//KnowledgeSummary[],

      // for paging on client side
      summaries: [],// KnowledgeSummary[],
      current: 1,
      max: 1,
      total: 0
    };
  }

  private search(
    keyword: string,
    pageSize: number,
    scrollId: number,
    scrollLimitPageSize: number
  ) {
    let currentAccountOrganization = null;
    this.workspaceQuery.selectCurrentAccountOrganization()
      .pipe(
        take(1),
        filter(accountOrganization => accountOrganization !== null),
        tap(accountOrganization => currentAccountOrganization = accountOrganization),
        mergeMap(_ =>
          this.knowledgeSearchHeaderService.searchKnowledgeES(keyword, currentAccountOrganization, scrollId, scrollLimitPageSize, [EPostType.KNOWLEDGE])),
        mergeMap(searchResults => searchResults),
        tap(bucket => this.pushBucket(bucket, currentAccountOrganization, scrollId, scrollLimitPageSize, pageSize)),
        toArray(),
        tap(_ => this.updateSummaries(this.pageSetting.pageSize)),
        tap(_ => this.isSearching = true)
      ).subscribe({
        next: (_) => this.handleSearch.emit(true),
        complete: () => this.changeDetectorRef.detectChanges(),
        error: err => {
          console.error(err);
        },
      });
  }

  private pushBucket(bucket: SearchESResult, accountOrganization: AccountOrganization, scrollId: number, scrollLimitPageSize: number, pageSize: number) {
    const bucketResultQuery = ({
      summaries: [],// bucket.results && bucket.results.length > 0 && bucket.results.map(item => convertToKnowledgeSummary(item.data, accountOrganization)),
      current: scrollId,
      max: bucket && bucket.page && bucket.page.total_pages,
      total: bucket && bucket.page && bucket.page.total_results,
      pageSize: scrollLimitPageSize
    } as BucketResultQuery);

    // update buckets result query
    // update scrollId
    this.knowledgeSearchResults.scrollId = scrollId;

    // scan all to set maxScrollId in buckets[]
    if (bucketResultQuery.max > this.knowledgeSearchResults.maxScrollId) {
      this.knowledgeSearchResults.maxScrollId = bucketResultQuery.max
    }
    // update vault, max, total
    const bucketData = bucket.rawResults && bucket.rawResults.length > 0 && bucket.rawResults.map(item => convertToKnowledgeSummary(item, accountOrganization));
    const updatedVault = GetDistinctArray(MergeArray(this.knowledgeSearchResults.vault, bucketData), 'id');
    this.knowledgeSearchResults.vault = updatedVault;
    this.knowledgeSearchResults.total = updatedVault.length;
    this.knowledgeSearchResults.max = pageSize > 0 ? Math.ceil(updatedVault.length / pageSize) : Math.ceil(updatedVault.length / EScrollPaging.DEFAULT_PAGE_SIZE);
  }

  private updateSummaries(pageSize: number) {
    const { vault, summaries, current, total } = this.knowledgeSearchResults;
    const fromIndex = summaries && summaries.length ? summaries.length : 0;
    const toIndex = pageSize * (current - 1) + pageSize <= total ? pageSize * (current - 1) + pageSize : total;
    const updateSummaries = summaries.concat(vault.slice(fromIndex, toIndex));
    this.knowledgeSearchResults.summaries = GetDistinctArray(updateSummaries, 'id');
  }

  private updatePagingSearchResult(current: number, pageSize: number) {
    const { vault, summaries, scrollId, maxScrollId, total, max } = this.knowledgeSearchResults;
    if (current <= max) {
      this.knowledgeSearchResults.current = current;

      const fromIndex = summaries && summaries.length ? summaries.length : 0;
      const toIndex = pageSize * (current - 1) + pageSize <= total ? pageSize * (current - 1) + pageSize : total;
      const updateSummaries = summaries.concat(vault.slice(fromIndex, toIndex));

      this.knowledgeSearchResults.summaries = updateSummaries;
    }

    // TODO
    // Need to check limit pagesize before query to ES
    if (summaries.length >= total && scrollId < maxScrollId) {
      return this.search(this.keyword, pageSize, scrollId + 1, this.pageSetting.scrollPageSize);
    }

    return of(null);
  }
}
