import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';

export class AssetStorageType extends PrimitiveValueObject<string> {
  static create(value: string): AssetStorageType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new AssetStorageType(value);
  }
}
