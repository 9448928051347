import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';
import { EPostType } from '../enum/post-type.enum';

export class PostType extends PrimitiveValueObject<EPostType> {
  static create(value: EPostType): PostType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new PostType(value);
  }
}
