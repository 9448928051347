<section class="icon__section">
  <div class="icon-action" (click)="openQuotation($event)">
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_107_106)">
        <path
          d="M7.90665 0.577637L15.1276 6.44963L7.90665 12.3176V9.40964C4.96265 9.62964 3.07265 10.6746 2.13165 12.5926L1.30865 14.2746L1.00065 12.4276C0.781126 11.3247 0.802678 10.1874 1.06383 9.0936C1.32498 7.9998 1.81955 6.97541 2.51365 6.09064C3.19216 5.32682 4.02181 4.71211 4.9501 4.2854C5.87839 3.8587 6.88513 3.62927 7.90665 3.61164V0.577637ZM13.2256 6.44963L9.10665 3.09964V4.78564H8.50665C7.56018 4.73723 6.61469 4.89828 5.73762 5.25731C4.86055 5.61633 4.07351 6.16447 3.43265 6.86264C2.58731 7.91371 2.11211 9.21417 2.08065 10.5626C2.63841 9.96411 3.30492 9.47712 4.04465 9.12764C5.44314 8.50659 6.95547 8.18308 8.48565 8.17764L9.10665 8.15564V9.79864L13.2256 6.44963Z"
          fill="#707070"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_106">
          <rect width="14.255" height="13.697" fill="white" transform="translate(0.872559 0.577637)" />
        </clipPath>
      </defs>
    </svg>
  </div>
</section>
