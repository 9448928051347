import { Store, StoreConfig } from '@datorama/akita';

import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { SharingVendorView } from 'frontend/admin/app/model/sharing-vendor-view.model';

export interface MyKnowledgeSharingVendorSidebarState {
  // sharingVendors: SharingVendorView[];
  sharingVendors: KnowledgeSummary[];
  current: number, // current page index
  total: number, // total records
  max: number, // total pages
}

export function createInitialState(): MyKnowledgeSharingVendorSidebarState {
  return ({
    sharingVendors: [],
    sharingVendorsView: null,
    current: 1, // current page index
    total: 0, // total records
    max: 0, // total pages
  } as MyKnowledgeSharingVendorSidebarState);
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-knowledge-sharing-vendor-sidebar' })
export class MyKnowledgeSharingVendorSidebarStore extends Store<MyKnowledgeSharingVendorSidebarState> {
  constructor() {
    super(createInitialState());
  }
}
