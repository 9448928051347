import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconDefinition, faExternalLink } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-card-view-header-link',
  templateUrl: './card-view-header-link.component.html',
  styleUrls: ['./card-view-header-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardViewHeaderLinkComponent {

  @Input() links: string[] = [];

  public readonly type: string = "URL";
  public readonly externalLinkIcon: IconDefinition = faExternalLink;
}
