import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SearchTagAutocompleteComponent } from './search-tag-autocomplete.component';

const components: Type<any>[] = [SearchTagAutocompleteComponent];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NzAutocompleteModule, NzDropDownModule],
  declarations: [components],
  exports: [components],
})
export class SearchTagAutocompleteModule { }
