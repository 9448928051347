import { EMPTY, Observable } from 'rxjs';
import { FeedbackKnowledgeLink, FeedbackToSlackAccessToken, FeedbackToSlackEmail, FeedbackToSlackId, FeedbackToSlackStatus, FeedbackToSlackText, FeedbackToSlackTitle, TargetId } from '../../value';
import { distinct, expand, filter, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../../account/value';
import { FeedbackToSlack } from '../../entity';
import { FeedbackToSlackNotFoundError } from '../../exception';
import { FirestoreQueryBuilder } from 'gcp/builder/firestore-query.builder';
import { IFeedbackToSlackRepository } from '../../repository';
import { IFirestoreService } from 'gcp/service/firestore.service';
import { IsDeleted } from '../../../base/value';
import { Timestamp } from 'utility/model/timestamp.value';

export class FeedbackToSlackFirestoreRepository implements IFeedbackToSlackRepository {
  private static readonly collectionId = 'feedback_to_slack';

  constructor(private readonly firestoreService: IFirestoreService) { }

  select(id: FeedbackToSlackId): Observable<FeedbackToSlack> {
    return this.firestoreService.getDocument(FeedbackToSlackFirestoreRepository.collectionId, id).pipe(
      map(item => {
        if (!item || item && item.isDeleted === true) {
          console.log(`feedback_to_slack id「${id.value}」 is not found`)
          throw new FeedbackToSlackNotFoundError(`feedback_to_slack id「${id.value}」 is not found`);
        }
        return this.convertToEntity(item);
      })
    );
  }

  insert(item: FeedbackToSlack): Observable<FeedbackToSlack> {
    item.createdAt = Timestamp.createByDate(new Date());
    item.updatedAt = Timestamp.createByDate(new Date())
    item.isDeleted = IsDeleted.create(false);
    return this.firestoreService.setDocument(FeedbackToSlackFirestoreRepository.collectionId, this.convertToMap(item)).pipe(map(() => item));
  }

  update(feedbackToSlack: FeedbackToSlack): Observable<FeedbackToSlack> {
    feedbackToSlack.updatedAt = Timestamp.createByMillsec(+Date.now());
    feedbackToSlack.isDeleted = IsDeleted.create(false);
    return this.firestoreService.getDocument(FeedbackToSlackFirestoreRepository.collectionId, feedbackToSlack.id).pipe(
      take(1),
      mergeMap(item => {
        if (!item || item && item.isDeleted === true) {
          throw new FeedbackToSlackNotFoundError(`feedback_to_slack id「${feedbackToSlack.id.value}」 is not found`);
        }
        return this.firestoreService.setDocument(FeedbackToSlackFirestoreRepository.collectionId, this.convertToMap(feedbackToSlack));
      }),
      map(_ => feedbackToSlack)
    );
  }

  delete(id: FeedbackToSlackId): Observable<void> {
    const proposal = new FeedbackToSlack(id);
    proposal.isDeleted = IsDeleted.create(true);
    proposal.updatedAt = Timestamp.createByMillsec(+Date.now());
    return this.firestoreService.setDocument(FeedbackToSlackFirestoreRepository.collectionId, this.convertToMap(proposal));
  }

  generateId(): FeedbackToSlackId {
    return FeedbackToSlackId.create(this.firestoreService.generateId());
  }

  selectAll(builder: FirestoreQueryBuilder<FeedbackToSlack>) {
    return this.firestoreService.getCollection(FeedbackToSlackFirestoreRepository.collectionId, builder).pipe(
      take(1),
      expand(items =>
        items.length
          ? this.firestoreService
            .getCollection(FeedbackToSlackFirestoreRepository.collectionId, builder.startAfter(items[items.length - 1].id).limit(100))
            .pipe(take(1))
          : EMPTY
      ),
      mergeMap(items => items),
      distinct(item => item.id),
      filter(item => item.isDeleted !== true),
      map(item => this.convertToEntity(item))
    );
  }

  private convertToMap(feedbackToSlack: FeedbackToSlack): object {
    return FeedbackToSlack.allFields.reduce((p, key) => {
      if (feedbackToSlack[key] === undefined) {
        return p;
      }
      const value = feedbackToSlack[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {});
  }

  private convertToEntity(item: any) {
    const feedbackToSlack = new FeedbackToSlack(FeedbackToSlackId.create(item.id));
    feedbackToSlack.sendBy = AccountOrganizationId.create(item.sendBy);
    feedbackToSlack.receivedBy = AccountOrganizationId.create(item.receivedBy);
    feedbackToSlack.targetId = item.targetId !== null ? TargetId.create(item.targetId) : null;
    feedbackToSlack.receiverEmail = item.receiverEmail !== null ? FeedbackToSlackEmail.create(item.receiverEmail) : null;
    feedbackToSlack.slackStatus = item.slackStatus !== null ? FeedbackToSlackStatus.create(item.slackStatus) : null;
    feedbackToSlack.accessToken = item.accessToken !== null ? FeedbackToSlackAccessToken.create(item.accessToken) : null;
    feedbackToSlack.text = item.text !== null ? FeedbackToSlackText.create(item.text) : null;
    feedbackToSlack.title = item.title !== null ? FeedbackToSlackTitle.create(item.title) : null;
    feedbackToSlack.knowledgeLink = item.knowledgeLink !== null ? FeedbackKnowledgeLink.create(item.knowledgeLink) : null;
    feedbackToSlack.createdAt = Timestamp.createByMillsec(item.createdAt.seconds * 1000);
    feedbackToSlack.updatedAt = Timestamp.createByMillsec(item.updatedAt.seconds * 1000);
    feedbackToSlack.isDeleted = item && item.isDeleted ? IsDeleted.create(item.isDeleted) : IsDeleted.create(false);
    return feedbackToSlack;
  }
}
