import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FilesUploadComponent } from './files-upload.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { IconAttachmentModule } from '../../atoms/icon-attachment/icon-attachment.module';
import { PipesModule } from 'frontend/admin/app/shared/pipes/pipes.module';

const components: Type<any>[] = [FilesUploadComponent];

@NgModule({
  imports: [CommonModule, FontAwesomeModule, FormsModule, PipesModule, IconAttachmentModule],
  declarations: [components],
  exports: [components],
})
export class FilesUploadModule { }
