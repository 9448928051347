import { ArgumentNullException } from '../../../../utility/exception/argument-null.exception';
import { ECommentType } from '../enum';
import { PrimitiveValueObject } from '../../../../utility/model/primitive-value-object.model';

export class CommentType extends PrimitiveValueObject<ECommentType> {
  static create(value: ECommentType): CommentType {
    if (value === null) {
      throw new ArgumentNullException();
    }
    return new CommentType(value);
  }
}
