import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IMEEventDirectiveModule } from '../ime-event-directive/ime-event.module';
import { TextComponent } from './text.component';

const components: Type<any>[] = [TextComponent];

@NgModule({
  imports: [CommonModule, IMEEventDirectiveModule, FontAwesomeModule],
  declarations: [components],
  exports: [components],
})
export class TextModule { }
