import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-view-header-attachment-count',
  templateUrl: './card-view-header-attachment-count.component.html',
  styleUrls: ['./card-view-header-attachment-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardViewHeaderAttachmentCountComponent {
  @Input() count: number = 0;
}
