export const environment = {
  production: true,
  corsDomain: 'https://system.good-feedback.lpconsulting.jp',
  systemDomain: 'https://system.good-feedback.lpconsulting.jp',
  mailUrl: 'support@good-feedback.lpconsulting.jp',
  imageUploadMaximumFileSize: 10485760,
  hashSecretKey: 'e8aa0ece9108a4f47940ae9fdcb0353f919ed79599c77492bb8887f2c02287dd',
  firebase: {
    apiKey: 'AIzaSyAnTIbdTnqN1AjPZQqsJ4Nr4A4VeZTdzQU',
    authDomain: 'good-feedback.firebaseapp.com',
    projectId: 'good-feedback',
    storageBucket: 'good-feedback.appspot.com',
    messagingSenderId: '435393200044',
    appId: '1:435393200044:web:c44c74f315ad6d95938409',
    measurementId: 'G-WS0QXVZYGG'
  },
  slack: {
    gfbAppId: 'A01R0FEP0CE',
    clientId: '605677661446.1850524782422',
    slackScope: 'channels:history,channels:read,chat:write,commands,files:read,groups:history,groups:read,im:history,im:read,im:write,incoming-webhook,mpim:read,reactions:read,reactions:write,team:read,users:read,users:read.email'
  },
  api: {
    urls: {
      auth: 'https://api.good-feedback.lpconsulting.jp/v1/user/auth',
      slackBase: 'https://api.good-feedback.lpconsulting.jp',
      batchBase: 'https://batch-2dkfxgx6tq-an.a.run.app'
    }
  },
  elasticAppSearch: {
    searchKey: 'search-resqxekdxwtouawucyo56ti1',
    endpointBase: 'https://enterprise-search-deployment-prod.ent.asia-northeast1.gcp.cloud.es.io'
  }
};
