<ng-container *ngIf="(files && files.length > 0) || !isReadonly">
  <div class="knowledge-attachment-view">
    <div class="knowledge-attachment-view__wrapper">
      <app-files-upload
        label="ファイルを添付する"
        [inputFiles]="files"
        [isReadonly]="isReadonly"
        (upload)="emitUploadFiles($event)"
      ></app-files-upload>
    </div>
  </div>
</ng-container>
