import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';

import { AppQuery } from '../../state/app.query';
import { Injectable } from '@angular/core';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { MyKnowledge } from 'domain/kernel/my-knowledge/entity';
import { MyKnowledgeDocumentDto } from 'domain/kernel/my-knowledge-document/dto';
import { MyKnowledgeDocumentService } from 'frontend/lib/service/my-knowledge-document.service';
import { MyKnowledgeDomainService } from 'domain/kernel/my-knowledge/service';
import { MyKnowledgeDto } from 'domain/kernel/my-knowledge/dto';
import { WorkspaceQuery } from '../../pages/workspace/components/workspace/state/workspace.query';

@Injectable({ providedIn: 'root' })
export class MyKnowledgeFireStoreService {
  constructor(
    readonly appQuery: AppQuery,
    private readonly myKnowledgeDomainService: MyKnowledgeDomainService,
    readonly workspaceQuery: WorkspaceQuery,
    private readonly myKnowledgeDocumentService: MyKnowledgeDocumentService,
  ) { }

  updateAfterLikeAction(knowledgeSummary: KnowledgeSummary, likeChosen: boolean): Observable<any> {
    return this.myKnowledgeDomainService.selectAfterLikeAction(knowledgeSummary.loggedUser?.id, knowledgeSummary.id).pipe(
      mergeMap(myKnowledge => {
        if (myKnowledge === null && likeChosen === true) {
          return this.createMyKnowledge(knowledgeSummary);
        } else if (myKnowledge !== null && likeChosen === true) {
          return this.reInsertMyKnowledge(myKnowledge, knowledgeSummary);
        } else if (myKnowledge !== null && likeChosen === false) {
          return this.deleteMyKnowledge(myKnowledge, knowledgeSummary);
        } else {
          return of(null);
        }
      })
    );
  }

  private createMyKnowledge(knowledgeSummary: KnowledgeSummary) {
    return this.myKnowledgeDomainService.insert({
      knowledgeId: knowledgeSummary.id,
      createdBy: knowledgeSummary?.loggedUser?.id
    } as MyKnowledgeDto).pipe(
      take(1),
      mergeMap(myKnowledge => this.myKnowledgeDocumentService.createMyKnowledgeDocument({
        id: myKnowledge.id,
        knowledgeId: myKnowledge.knowledgeId,
        createdBy: myKnowledge.createdBy,
        createdAt: myKnowledge.createdAt,
        updatedAt: myKnowledge.updatedAt,
        isDeleted: myKnowledge.isDeleted,
        sendBy: knowledgeSummary.sendBy,
        title: knowledgeSummary.title,
        body: knowledgeSummary.body,
        category: knowledgeSummary?.categories ? knowledgeSummary.categories : '',
        postType: knowledgeSummary.postType,
        knowledgeType: knowledgeSummary.knowledgeType,
        knowledgeCreatedAt: knowledgeSummary.createdAt,
        knowledgeUpdatedAt: knowledgeSummary.updatedAt,
      } as MyKnowledgeDocumentDto)
      ),
      catchError(err => {
        console.log('updateFeedbackDocument-catchError', err)
        return EMPTY;
      })
    )
  }

  private reInsertMyKnowledge(myKnowledge: MyKnowledge, knowledgeSummary: KnowledgeSummary) {
    return this.myKnowledgeDomainService.reInsert(myKnowledge.id.value).pipe(
      take(1),
      mergeMap(myKnowledgeresult => this.myKnowledgeDocumentService.updateMyKnowledgeDocument({
        id: myKnowledgeresult.id,
        knowledgeId: knowledgeSummary.id,
        createdBy: myKnowledge.createdBy.value,
        createdAt: myKnowledgeresult.createdAt,
        updatedAt: myKnowledgeresult.updatedAt,
        isDeleted: myKnowledge.isDeleted.value,

        sendBy: knowledgeSummary.sendBy,
        title: knowledgeSummary.title,
        body: knowledgeSummary.body,
        category: knowledgeSummary?.categories ? knowledgeSummary.categories : '',
        postType: knowledgeSummary.postType,
        knowledgeType: knowledgeSummary.knowledgeType,
        knowledgeCreatedAt: knowledgeSummary.createdAt,
        knowledgeUpdatedAt: knowledgeSummary.updatedAt,
      } as MyKnowledgeDocumentDto)
      ),
      catchError(err => {
        console.log('reInsertMyKnowledge-catchError', err)
        return EMPTY;
      })
    )
  }

  private deleteMyKnowledge(myKnowledge: MyKnowledge, knowledgeSummary: KnowledgeSummary) {
    return this.myKnowledgeDomainService.delete(myKnowledge.id.value).pipe(
      take(1),
      mergeMap(_ => this.myKnowledgeDocumentService.updateMyKnowledgeDocument({
        id: myKnowledge.id.value,
        knowledgeId: knowledgeSummary.id,
        createdBy: myKnowledge.createdBy.value,
        createdAt: myKnowledge.createdAt.value,
        updatedAt: myKnowledge.updatedAt.value,
        isDeleted: true,

        sendBy: knowledgeSummary.sendBy,
        title: knowledgeSummary.title,
        body: knowledgeSummary.body,
        category: knowledgeSummary?.categories ? knowledgeSummary.categories : '',
        postType: knowledgeSummary.postType,
        knowledgeType: knowledgeSummary.knowledgeType,
        knowledgeCreatedAt: knowledgeSummary.createdAt,
        knowledgeUpdatedAt: knowledgeSummary.updatedAt,
      } as MyKnowledgeDocumentDto)
      ),
      catchError(err => {
        console.log('deleteMyKnowledge-catchError', err)
        return EMPTY;
      })
    )
  }
}
