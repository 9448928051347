import { NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IMEEventDirectiveModule } from '../ime-event-directive/ime-event.module';
import { TextareaAutosizeDirective } from './directive/textarea-autosize.directive';
import { TextareaComponent } from './textarea.component';

const components: Type<any>[] = [TextareaComponent, TextareaAutosizeDirective];

@NgModule({
  imports: [CommonModule, IMEEventDirectiveModule],
  declarations: [components],
  exports: [components],
})
export class TextareaModule { }
