import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent extends BaseComponent {
  dateRange: [Date, Date];

  @Input() date: Date = new Date();
  @Input() start: Date = null;
  @Input() end: Date = null;
  @Input() type: 'date' | 'dateRange' | 'dateTimeRange' = 'date';
  @Input() size: 'large' | 'small' | 'default' = 'default';
  @Input() label: string = '';
  @Output() onChange = new EventEmitter();

  protected override onInit(): void {
    this.dateRange = null;
    if (this.start || this.end) {
      this.dateRange = [this.start, this.end];
    }
  }
}
