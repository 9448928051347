<ng-container [ngSwitch]="version">
  <ng-container *ngSwitchCase="Version.TWO">
    <section class="button__section">
      <button [ngClass]="getClassNameByType()" [disabled]="disabled" (click)="onClick($event)">
        {{ label }}
      </button>
    </section>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div
      class="button"
      [ngClass]="{ '-flat': type === 'flat', '-secondary': type === 'secondary', '-link': type === 'link', '-disabled': disabled }"
      (click)="click()"
    >
      <p class="button__popup" *ngIf="popup">{{ popup }}</p>
      <p class="button__button">
        <ng-content></ng-content>
      </p>
    </div>
  </ng-container>
</ng-container>
