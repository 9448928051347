import { Pipe, PipeTransform } from '@angular/core';

import { toHTML } from 'slack-markdown';

@Pipe({ name: 'slackMarked' })
export class SlackMarkedPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value.length > 0) {
      return toHTML(value, { hrefTarget: '_black' });
    }
    return value;
  }
}
