import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { KnowledgeFileSummary } from 'frontend/admin/app/model/knowledge-file-summary.model';
import { KnowledgeSummary } from 'frontend/admin/app/model/knowledge-summary.model';
import { BaseComponent } from 'frontend/admin/app/shared/components/base-component/base.component';
import { FileService } from 'frontend/admin/app/shared/services/file.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-search-knowledge-results',
  templateUrl: './feedback-search-knowledge-results.component.html',
  styleUrls: ['./feedback-search-knowledge-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackSearchKnowledgeResultsComponent extends BaseComponent {
  @Input() knowledgeSummary: KnowledgeSummary;

  public files: KnowledgeFileSummary[] = [];
  public linksDetected: string[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fileService: FileService

  ) { super(); }

  protected override onInit(): void {
    if (this.knowledgeSummary) {
      this.subscribe(
        this.fileService.getKnowledgeFiles(this.knowledgeSummary.id).pipe(
          tap(files => this.files = files),
        ), _ => {
          this.linksDetected = this.knowledgeSummary?.body?.getUrls();
          this.changeDetectorRef.detectChanges();
        }
      );
    }
  }
}
