import { EPostType, PostId } from '../../../kernel/post';
import { ReactionChosen, ReactionId, ReactionTargetType, ReactionType } from '../value';
import { count, map, mergeMap, take } from 'rxjs/operators';

import { AccountOrganizationId } from '../../account/value';
import { EReactionType } from '../enum';
import { FirestoreQueryBuilder } from '../../../../lib/gcp/builder/firestore-query.builder';
import { IReactionRepository } from '../repository';
import { Observable } from 'rxjs';
import { Reaction } from '../entity';
import { ReactionDto } from '../dto';

export class ReactionDomainService {
  constructor(private readonly reactionRepository: IReactionRepository) { }

  select(reactionId: string) {
    return this.reactionRepository.select(ReactionId.create(reactionId))
      .pipe(map(item => this.convertDto(item)));
  }

  getTotalByPostId(postId: string) {
    return this.reactionRepository.selectAll(new FirestoreQueryBuilder<Reaction>()
      .equalWhere('postId', postId)
      .equalWhere('chosen', true))
      .pipe(count());
  }

  countTotalByPostId(postId: string) {
    return this.reactionRepository.countAll(new FirestoreQueryBuilder<Reaction>()
      .equalWhere('postId', postId)
      .equalWhere('chosen', true))
      .pipe(count());
  }

  selectByPostIdAndReactedBy(postId: string, reactedBy: string) {
    const builder = new FirestoreQueryBuilder<Reaction>().equalWhere('postId', postId).equalWhere('reactedBy', reactedBy);
    return this.reactionRepository.selectIfExists(builder).pipe(map(item => item ? this.convertDto(item) : null));
  }

  selectAll(builder = new FirestoreQueryBuilder<Reaction>()) {
    return this.reactionRepository.selectAll(builder).pipe(map(item => this.convertDto(item)));
  }

  insert(postId: string, reactedBy: string, target: EPostType, type: EReactionType): Observable<ReactionDto> {
    const reaction = new Reaction(this.reactionRepository.generateId());
    reaction.postId = PostId.create(postId);
    reaction.target = ReactionTargetType.create(target);
    reaction.type = ReactionType.create(type);
    reaction.chosen = ReactionChosen.create(true);
    reaction.reactedBy = AccountOrganizationId.create(reactedBy);
    return this.reactionRepository.insert(reaction).pipe(map(item => this.convertDto(item)));
  }

  update(reaction: Reaction) {
    return this.reactionRepository.update(reaction).pipe(map(item => this.convertDto(item)));
  }

  toggle(postId: string, reactedBy: string, target: EPostType, type: EReactionType): Observable<ReactionDto> {
    const builder = new FirestoreQueryBuilder<Reaction>().equalWhere('postId', postId).equalWhere('reactedBy', reactedBy).equalWhere('target', target);
    return this.reactionRepository.selectIfExists(builder).pipe(
      take(1),
      mergeMap(reaction => {
        if (reaction === null) {
          return this.insert(postId, reactedBy, target, type);
        } else {
          return this.update({
            ...reaction,
            chosen: ReactionChosen.create(!reaction?.chosen?.value)
          } as Reaction);
        }
      }),
    );
  }

  delete(reactionId: string) {
    return this.reactionRepository.delete(ReactionId.create(reactionId));
  }

  private convertDto(reaction: Reaction): ReactionDto {
    return Reaction.allFields.reduce((p, key) => {
      if (reaction[key] === undefined) {
        return p;
      }
      const value = reaction[key] as { value: any };
      p[key] = value.value;
      return p;
    }, {} as ReactionDto);
  }
}
